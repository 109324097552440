import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import InputField from "../../Components/InputField";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ToggleSwitch from "../../Components/ToggleSwitch";
import axios from "axios";
import toast from "react-hot-toast";
import { getFileName } from "../../Utils/getFileName";
import { useAdminContext } from "../../Store/adminContext";
import heic2any from "heic2any";
import { uploadBulkImages } from "../../Apis/callApi";

const AddUpdateStores = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { admin }: any = useAdminContext();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mediaUrl, setMediaUrl]: any = useState({ obj: null, url: "" });
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [type, setType] = useState("");

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [contact, setContact] = useState({ countryCode: "", mobileNumber: "" });
  const [mapUrl, setMapUrl] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [isPickupAvailable, setIsPickUpAvailable] = useState(false);
  const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(false);
  const [isStoreShoppingAvailable, setIsStoreShoppingAvailable] =
    useState(false);

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      getData();
    }
  }, []);

  const getData = () => {
    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }admins/getStoreById?identifier=${localStorage.getItem(
        "identifier"
      )}&storeId=${params.id}`,
      method: "GET",
    })
      .then((res: any) => {
        const d = res.data.offStoreData;
        setTitle(d?.title);
        setDescription(d?.description);
        setMediaUrl({ obj: null, url: d?.mediaUrl });
        setAddressLine1(d?.addressLine1);
        setAddressLine2(d?.addressLine2);
        setCity(d?.city);
        setState(d?.state);
        setCountry(d?.country);
        setType(d?.locationType);
        setContact(d?.contact);
        setPincode(d?.pincode);
        setMapUrl(d?.mapUrl);
        setLat(d?.lat);
        setLong(d?.long);
        setIsDeliveryAvailable(d?.isDeliveryAvailable);
        setIsPickUpAvailable(d?.isPickupAvailable);
        setIsStoreShoppingAvailable(d?.isStoreShoppingAvailable);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const uploadImage = async () => {
    if (mediaUrl.obj === null) {
      return "";
    }

    if (mediaUrl.obj !== null) {
      const filename = getFileName(mediaUrl.obj);
      let vv = await uploadBulkImages([mediaUrl.obj]);
      return vv.data.images[0];
    } else {
      return mediaUrl.url;
    }
  };

  const handleCreateAndUpdate = async () => {
    setLoading(true);
    const data = {
      title,
      locationType: type,
      description,
      mediaUrl: mediaUrl.url,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      pincode,
      contact,
      mapUrl,
      lat,
      long,
      isPickupAvailable,
      isDeliveryAvailable,
      isStoreShoppingAvailable,
    };

    data.mediaUrl = await uploadImage();

    axios({
      url: params?.id
        ? `${process.env.REACT_APP_BASE_URL}admins/updateStoreById`
        : `${process.env.REACT_APP_BASE_URL}admins/addStore`,
      method: params?.id ? "PATCH" : "POST",
      data: params?.id
        ? {
            identifier: localStorage.getItem("identifier"),
            storeId: params.id,
            data,
          }
        : {
            identifier: localStorage.getItem("identifier"),
            offStoreData: data,
          },
    })
      .then(() => {
        toast.success(
          params?.id ? "Updated Successfully." : "Added Successfully."
        );
        navigate("/stores");
        setLoading(false);
      })
      .catch(() => {
        toast.error("Something went wrong while adding store.");
        setLoading(false);
      });
  };

  const handleAddImage = async (e: any) => {
    const file = e.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      setMediaUrl({
        url: URL.createObjectURL(convertedFile),
        obj: convertedFile,
      });
    } else
      setMediaUrl({
        obj: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={params?.id ? "Update Location" : "Add Location"}
          showBtn1={isUserAllowedToEdit(admin)}
          btn1type={"filled"}
          btn1Text={"Save"}
          btn1Name={"btn1"}
          showBtn2={isUserAllowedToEdit(admin)}
          btn2Text={"Save as Draft"}
          btn2type="outlined"
          btn2Name="btn2"
          showBtn3={true}
          btn3type={"outlined_del"}
          btn3Text={"Cancel"}
          btn3Name={"btn3"}
          onClickHandler={(type: any) => {
            if (type === "Save") {
              handleCreateAndUpdate();
            } else if (type === "Save as Draft") {
              handleCreateAndUpdate();
            } else if (type === "Cancel") {
              navigate("/stores");
            }
          }}
        />
        <ContentBody>
          <div className="card grid">
            <div className="grid_2">
              <InputField
                type="text"
                isLabel={true}
                lable={"Title"}
                value={title}
                onChange={(e: any) => setTitle(e.target.value.toLowerCase())}
              />
              <InputField
                type="select"
                options={[
                  { title: "Shop/Studio", value: "shop/studio" },
                  { title: "Pop-up", value: "pop-up" },
                  { title: "warehouse", value: "warehouse" },
                ]}
                accessText="title"
                accessValue="value"
                isLabel={true}
                lable={"Type"}
                value={type}
                onChange={(e: any) => setType(e.target.value.toLowerCase())}
              />
            </div>
            {!mediaUrl.url && (
              <div
                className="media_container"
                // onClick={() => {
                //   image_for = "main";
                //   setOpen(true);
                // }}
              >
                <input
                  type="file"
                  accept=".heic,image/*"
                  onChange={handleAddImage}
                />
                <p>+</p>
                <p>Add Media</p>
              </div>
            )}

            {mediaUrl.url && (
              <ul className="selected_media_container">
                <div className="media_content">
                  <img src={mediaUrl.url} alt={""} />
                  <FontAwesomeIcon
                    icon={faClose}
                    color={"#36619b"}
                    onClick={() => {
                      setMediaUrl({ obj: null, url: "" });
                    }}
                  />
                </div>
              </ul>
            )}
            <InputField
              type="textarea"
              isLabel={true}
              lable={"Description"}
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
            <div className="grid_2">
              <InputField
                type="text"
                isLabel={true}
                lable={"Address Line One"}
                value={addressLine1}
                onChange={(e: any) => setAddressLine1(e.target.value)}
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"Address Line Two"}
                value={addressLine2}
                onChange={(e: any) => setAddressLine2(e.target.value)}
              />

              <InputField
                type="text"
                isLabel={true}
                lable={"City"}
                value={city}
                onChange={(e: any) => setCity(e.target.value)}
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"State"}
                value={state}
                onChange={(e: any) => setState(e.target.value)}
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"Country"}
                value={country}
                onChange={(e: any) => setCountry(e.target.value)}
              />
              <InputField
                type="number"
                isLabel={true}
                lable={"Pincode"}
                value={pincode}
                onChange={(e: any) => setPincode(e.target.value)}
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"Country Code"}
                value={contact.countryCode}
                onChange={(e: any) =>
                  setContact((prev) => ({
                    ...prev,
                    countryCode: e.target.value,
                  }))
                }
              />
              <InputField
                type="number"
                isLabel={true}
                lable={"Contact Number"}
                value={contact.mobileNumber}
                onChange={(e: any) =>
                  setContact((prev) => ({
                    ...prev,
                    mobileNumber: e.target.value,
                  }))
                }
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"Map Url"}
                value={mapUrl}
                onChange={(e: any) => setMapUrl(e.target.value)}
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"Lat"}
                value={lat}
                onChange={(e: any) => setLat(e.target.value)}
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"Long"}
                value={long}
                onChange={(e: any) => setLong(e.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "space-between",
                  height: "60px",
                  border: "1px solid #dedede",
                  borderRadius: "15px",
                  paddingInline: "10px",
                  marginTop: "29px",
                }}
              >
                <label>Is Delivery Available?</label>
                <ToggleSwitch
                  value={isDeliveryAvailable}
                  onClick={() => {
                    setIsDeliveryAvailable((prev) => !prev);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "space-between",
                  height: "60px",
                  border: "1px solid #dedede",
                  borderRadius: "15px",
                  paddingInline: "10px",
                }}
              >
                <label>Is Pickup Available?</label>
                <ToggleSwitch
                  value={isPickupAvailable}
                  onClick={() => {
                    setIsPickUpAvailable((prev) => !prev);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "space-between",
                  height: "60px",
                  border: "1px solid #dedede",
                  borderRadius: "15px",
                  paddingInline: "10px",
                }}
              >
                <label>Is Store Shopping Available?</label>
                <ToggleSwitch
                  value={isStoreShoppingAvailable}
                  onClick={() => {
                    setIsStoreShoppingAvailable((prev) => !prev);
                  }}
                />
              </div>
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.store_access.write) return true;

  return false;
};

export default AddUpdateStores;
