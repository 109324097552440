import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
  getFilteredRowModel,
  FilterFn,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faGripLines,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

type CustomColumnDef<T> = ColumnDef<T> & {
  accessorKey?: string;
  accessorFn?: (row: T) => any;
  enableColumnFilter?: boolean;
};

interface TableProps {
  columns: CustomColumnDef<any>[];
  data: any[];
  enableRowReorder?: boolean;
  onReorder?: (draggedRowIndex: number, targetRowIndex: number) => void;
  actionsColumns?: { name: string; action: any }[];
  title?: string;
  checkboxRow?: boolean;
  onSelectedRowsChange?: (selectedRows: number[]) => void;
  onRowClick?: (rowData: any) => void;
  onAdd?: () => void;
  onDelete?: (selectedIds: any[]) => void;
  customActionButtons?: {
    name: string;
    action: (selectedIds: any[]) => void;
    variant?: "default" | "danger";
  }[];
  customFilterElement?: React.ReactNode;
  settingsButton?: { name: string; action: () => void }[];
  onPaginationChange?: (page: number, limit: number) => void;
  totalRows?: number;
  initialPageSize?: number;
  initialPageIndex?: number;
  showPaginationOnEmpty?: boolean;
}

interface ColumnFilterProps {
  column: any;
  table: any;
}

const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100];

// Column filter dropdown component
const ColumnFilter: React.FC<ColumnFilterProps> = ({ column, table }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Set<any>>(new Set());
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
  });

  // Get unique values for this column
  const uniqueValues = useMemo(() => {
    const values = new Set<any>();
    table.getPreFilteredRowModel().rows.forEach((row: any) => {
      const value = row.getValue(column.id);
      if (value !== undefined && value !== null) {
        values.add(value);
      }
    });
    return Array.from(values).sort();
  }, [column.id, table]);

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX - 150, // Adjust positioning as needed
      });
    }
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (value: any) => {
    const newSelectedValues = new Set(selectedValues);
    if (newSelectedValues.has(value)) {
      newSelectedValues.delete(value);
    } else {
      newSelectedValues.add(value);
    }
    setSelectedValues(newSelectedValues);
  };

  const handleApplyFilter = () => {
    const filterValues = Array.from(selectedValues);
    column.setFilterValue(filterValues.length > 0 ? filterValues : undefined);
    setIsOpen(false);
  };

  const handleClearFilter = () => {
    setSelectedValues(new Set());
    column.setFilterValue(undefined);
    setIsOpen(false);
  };

  const isFiltered = column.getFilterValue() !== undefined;

  return (
    <div className="inline-block ml-2">
      <button
        ref={buttonRef}
        onClick={handleFilterToggle}
        className={`p-1 focus:outline-none ${
          isFiltered ? "text-blue-600" : ""
        }`}
      >
        <FontAwesomeIcon icon={faFilter} />
      </button>

      {isOpen && (
        <div
          ref={dropdownRef}
          style={{
            position: "fixed",
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            zIndex: 1000,
          }}
          className="w-64 bg-white border border-gray-200 rounded-md shadow-lg p-2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mb-2 text-sm font-medium text-gray-700">
            Filter {column.columnDef.header}
          </div>
          <div className="max-h-48 overflow-y-auto mb-2">
            {uniqueValues.length > 0 ? (
              uniqueValues.map((value, index) => (
                <div key={index} className="flex items-center p-1">
                  <input
                    type="checkbox"
                    id={`${column.id}-${index}`}
                    checked={selectedValues.has(value)}
                    onChange={() => handleFilterChange(value)}
                    className="mr-2"
                  />
                  <label
                    htmlFor={`${column.id}-${index}`}
                    className="text-sm text-gray-700 truncate"
                  >
                    {String(value)}
                  </label>
                </div>
              ))
            ) : (
              <div className="text-sm text-gray-500 p-1">No values found</div>
            )}
          </div>
          <div className="flex justify-between pt-2 border-t border-gray-200">
            <button
              onClick={handleClearFilter}
              className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
            >
              Clear All
            </button>
            <button
              onClick={handleApplyFilter}
              className="px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Custom filter function for array values
const arrayFilter: FilterFn<any> = (row, columnId, filterValue) => {
  if (!filterValue || filterValue.length === 0) return true;
  const value = row.getValue(columnId);
  return filterValue.includes(value);
};

const DynamicDataTable: React.FC<TableProps> = ({
  columns,
  data,
  enableRowReorder = false,
  onReorder,
  actionsColumns = [],
  title,
  checkboxRow = false,
  onSelectedRowsChange,
  onRowClick,
  onAdd,
  onDelete,
  customActionButtons = [],
  customFilterElement,
  settingsButton,
  onPaginationChange,
  totalRows = 0,
  initialPageSize = 15,
  initialPageIndex = 1,
  showPaginationOnEmpty = false,
}) => {
  const [draggedRowIndex, setDraggedRowIndex] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [searchColumn, setSearchColumn] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);

  // Add this function to handle nested property access
  const getNestedValue = (obj: any, path: string) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  // Add checkbox and drag handle columns based on props
  const finalColumns = useMemo(() => {
    const formattedColumns = columns.map((column) => {
      // Create a new column definition that wraps the original
      const newColumn = { ...column };

      // If the column has a custom cell renderer, preserve it
      const originalCell = column.cell;

      // If filtering is enabled for this column, add the filter function
      if (column.enableColumnFilter) {
        newColumn.filterFn = arrayFilter;
      }

      // Override the cell renderer to apply date formatting
      newColumn.cell = (props: any) => {
        // If there was an original cell renderer, use it
        if (originalCell) {
          return originalCell;
        }

        // Otherwise, apply date formatting if needed
        const value = props.getValue();
        return formatDateIfNeeded(value);
      };

      // Customize the header to include the filter icon if enabled
      if (column.enableColumnFilter) {
        const originalHeader = column.header;
        newColumn.header = (headerProps: any) => (
          <div className="flex items-center justify-center">
            {typeof originalHeader === "function"
              ? originalHeader(headerProps)
              : originalHeader || String(column.accessorKey || "")}
            <ColumnFilter
              column={headerProps.column}
              table={headerProps.table}
            />
          </div>
        );
      }

      return newColumn;
    });

    // Continue with your existing column processing logic
    const cols = [...formattedColumns];

    cols.unshift({
      id: "serialNumber",
      header: "S.No.",
      size: 60,
      cell: ({ row }: { row: { index: number } }) => {
        const { pageIndex, pageSize } = table.getState().pagination;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    });

    if (checkboxRow) {
      cols.unshift({
        id: "select",
        size: 40,
        header: () => (
          <input
            type="checkbox"
            className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            checked={selectedRows.size === data.length}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const newSelected = new Set<number>();

              if (e.target.checked) {
                data.forEach((_, index) => newSelected.add(_._id));
              }

              setSelectedRows(newSelected);
              onSelectedRowsChange?.(Array.from(newSelected));
            }}
          />
        ),
        cell: ({ row }: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={selectedRows.has(row.original._id)}
                onChange={(e) => {
                  e.stopPropagation();
                  const newSelected = new Set(selectedRows);

                  if (e.target.checked) {
                    newSelected.add(row.original._id);
                  } else {
                    newSelected.delete(row.original._id);
                  }

                  console.log("New Values ::: ", newSelected);
                  setSelectedRows(newSelected);
                  onSelectedRowsChange?.(Array.from(newSelected));
                }}
              />
            </div>
          );
        },
      });
    }

    if (enableRowReorder) {
      cols.unshift({
        id: "dragHandle",
        size: 40,
        cell: () => (
          <div className="flex justify-center cursor-move">
            <FontAwesomeIcon icon={faGripLines} />
          </div>
        ),
      });
    }

    if (actionsColumns.length > 0) {
      cols.push({
        id: "actions",
        header: "",
        cell: ({ row }) => (
          <ActionsDropdown actionsColumns={actionsColumns} row={row} />
        ),
      });
    }

    return cols;
  }, [
    columns,
    enableRowReorder,
    actionsColumns,
    checkboxRow,
    selectedRows,
    data.length,
  ]);

  const table = useReactTable({
    data,
    columns: finalColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      arrayFilter,
    },
    state: {
      pagination: {
        pageIndex: initialPageIndex ? initialPageIndex - 1 : 0, // Properly handle the initial page index
        pageSize: initialPageSize || PAGE_SIZE_OPTIONS[0],
      },
    },
    pageCount: Math.ceil(totalRows / (initialPageSize || PAGE_SIZE_OPTIONS[0])),
    manualPagination: true,
    onPaginationChange: (updater) => {
      const newState =
        typeof updater === "function"
          ? updater(table.getState().pagination)
          : updater;
      // Reset to first page when changing page size
      if (newState.pageSize !== table.getState().pagination.pageSize) {
        onPaginationChange?.(1, newState.pageSize);
      } else {
        onPaginationChange?.(newState.pageIndex + 1, newState.pageSize);
      }
    },
  });

  const getSelectedIds = () => {
    return Array.from(selectedRows)
      .map((index) => {
        const rowModel = table.getPaginationRowModel().rows;
        return rowModel[index]?.original?.id;
      })
      .filter(Boolean);
  };

  const handleDelete = () => {
    const selectedIds = getSelectedIds();
    if (selectedIds.length > 0 && onDelete) {
      onDelete(selectedIds);
      setSelectedRows(new Set());
      setActionsDropdownOpen(false);
    }
  };

  const formatDateIfNeeded = (value: any): string => {
    // Check if the value matches any ISO date format pattern
    if (
      typeof value === "string" &&
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d{1,3})?)?Z?$/.test(value)
    ) {
      // Format the date (e.g., "Feb 27, 2025 14:22")
      const date = new Date(value);
      return date.toLocaleString();
    }
    return value;
  };

  // Drag and drop handlers
  const handleDragStart = (e: React.DragEvent, index: number) => {
    setDraggedRowIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (e: React.DragEvent, targetIndex: number) => {
    e.preventDefault();
    if (draggedRowIndex !== null && onReorder) {
      onReorder(draggedRowIndex, targetIndex);
      setDraggedRowIndex(null);

      const newSelected = new Set<number>();
      selectedRows.forEach((rowIndex) => {
        if (rowIndex === draggedRowIndex) {
          newSelected.add(targetIndex);
        } else if (rowIndex < draggedRowIndex && rowIndex < targetIndex) {
          newSelected.add(rowIndex);
        } else if (rowIndex > draggedRowIndex && rowIndex > targetIndex) {
          newSelected.add(rowIndex);
        } else if (rowIndex < draggedRowIndex && rowIndex >= targetIndex) {
          newSelected.add(rowIndex + 1);
        } else if (rowIndex > draggedRowIndex && rowIndex <= targetIndex) {
          newSelected.add(rowIndex - 1);
        }
      });
      setSelectedRows(newSelected);
      onSelectedRowsChange?.(Array.from(newSelected));
    }
  };

  // Get searchable columns (excluding special columns like select, dragHandle, and actions)
  const searchableColumns = columns.filter(
    (col) => typeof col === "object" && "accessorKey" in col
  );

  const getPageNumbers = () => {
    const currentPage = table.getState().pagination.pageIndex;
    console.log("currentPage:", currentPage);
    const totalPages = table.getPageCount();
    const pageNumbers: (number | string)[] = [];

    if (totalPages <= 7) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(0);
      if (currentPage > 2) {
        pageNumbers.push("...");
      }
      for (
        let i = Math.max(1, currentPage - 1);
        i <= Math.min(totalPages - 2, currentPage + 1);
        i++
      ) {
        pageNumbers.push(i);
      }
      if (currentPage < totalPages - 3) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages - 1);
    }

    return pageNumbers;
  };

  const { pageIndex, pageSize } = table.getState().pagination;
  const currentRows = table.getPaginationRowModel().rows.length;
  const maxHeight = currentRows > 4 ? `${4 * 90 + 180}px` : "auto";

  // Close dropdowns when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (
        actionsDropdownOpen &&
        !target.closest(".actions-dropdown-container")
      ) {
        setActionsDropdownOpen(false);
      }
      if (
        settingsDropdownOpen &&
        !target.closest(".settings-dropdown-container")
      ) {
        setSettingsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionsDropdownOpen, settingsDropdownOpen]);

  const hasNoData =
    !showPaginationOnEmpty &&
    (data.length === 0 || table.getFilteredRowModel().rows.length === 0);

  const ActionsDropdown: React.FC<{
    actionsColumns: { name: string; action: any }[];
    row: any;
  }> = ({ actionsColumns, row }) => {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [dropdownPosition, setDropdownPosition] = useState({
      top: 0,
      left: 0,
    });

    useEffect(() => {
      if (open && buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX - 120,
        });
      }
    }, [open]);

    // Add click outside handler for each dropdown
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target as Node)
        ) {
          setOpen(false);
        }
      }

      if (open) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [open]);

    return (
      <div className="relative">
        <button
          ref={buttonRef}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
          className="focus:outline-none"
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {open && (
          <div
            ref={dropdownRef}
            style={{
              position: "fixed",
              top: `${dropdownPosition.top}px`,
              left: `${dropdownPosition.left}px`,
              zIndex: 1000,
            }}
            className="w-40 bg-white border border-gray-200 rounded-md shadow-lg"
          >
            {actionsColumns.map((action, index) => (
              <button
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  action.action(row.original._id);
                  setOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {action.name}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const mobileStylesString = `
  @media (max-width: 767px) {
    /* Global table styles for mobile */
    table {
      width: 100% !important;
      min-width: 100% !important;
      table-layout: fixed !important;
    }
    
    /* Keep header row alignment */
    thead tr th {
      display: table-cell !important;
    }
    
    /* Regular data rows */
    tbody tr:not(.empty-row) {
      display: table-row !important;
    }
    
    tbody tr:not(.empty-row) td {
      display: table-cell !important;
      width: auto !important;
      text-align: center;
    }
    
    /* Special styling for empty data row */
    tr.empty-row {
      display: flex !important;
      width: 100% !important;
    }
    
    tr.empty-row td {
      display: flex !important;
      width: 100% !important;
      justify-content: center;
      align-items: center;
      height: 200px !important;
      background-color: white !important;
    }
    
    
    .table-responsive-container {
      width: 100% !important;
    }
  }
`;
  console.log("pageNumber:", pageIndex);

  return (
    <div className="flex flex-col h-full w-full bg-white shadow-sm border border-gray-200">
      <div className="flex flex-col flex-grow overflow-hidden">
        <div className="flex-grow overflow-auto relative">
          <div
            className="w-full table-responsive-container"
            style={{ WebkitOverflowScrolling: "touch" }}
          >
            <style>{mobileStylesString}</style>

            <table
              className="w-full border-collapse table-fixed"
              style={{ minWidth: "800px" }}
            >
              <thead className="sticky top-0 bg-white z-10">
                {customFilterElement && (
                  <tr>
                    <th colSpan={finalColumns.length}>
                      <div className="px-6 py-2">{customFilterElement}</div>
                    </th>
                  </tr>
                )}
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="border-b border-gray-200 h-[50px]"
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-2 sm:px-5 py-2 text-center text-sm font-semibold text-gray-900 bg-blue-100 border-b border-gray-200 whitespace-nowrap"
                        style={{
                          borderRight: "none",
                          borderLeft: "none",
                          fontSize: "0.85rem",
                          width: "150px",
                          minWidth: "150px",
                          maxWidth: "150px",
                          height: "50px",
                          lineHeight: "50px",
                          padding: "0",
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {hasNoData ? (
                  <tr className="hover:bg-white overflow-hidden empty-row">
                    <td
                      colSpan={finalColumns.length}
                      className="overflow-hidden hover:bg-white w-[100vw] h-[100vh] xl:h-[100vh] 2xl:h-[100vh] align-middle"
                    >
                      <div className="overflow-hidden flex items-center justify-center h-full">
                        <p className="text-gray-500 text-lg font-medium">
                          No data available
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  data.map((row, index) => (
                    <tr
                      key={row._id || index}
                      draggable={enableRowReorder}
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, index)}
                      onClick={() => onRowClick && onRowClick(row)}
                      className={`
                        last:border-0 h-[50px]
                        ${enableRowReorder ? "cursor-move" : ""}
                        ${draggedRowIndex === index ? "opacity-50" : ""}
                        hover:bg-gray-50 transition-colors
                        ${
                          selectedRows.has(index)
                            ? "bg-blue-50 hover:bg-blue-100"
                            : ""
                        }
                      `}
                    >
                      {finalColumns.map((column, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="px-2 sm:px-6 py-1 text-center text-sm text-gray-900 whitespace-nowrap border-b border-gray-200 truncate"
                          style={{
                            borderRight: "none",
                            borderLeft: "none",
                            width: "150px",
                            minWidth: "150px",
                            maxWidth: "150px",
                            height: "50px",
                            lineHeight: "50px",
                            padding: "0",
                          }}
                        >
                          {column.id === "serialNumber" ? (
                            <span>{index + 1 + pageIndex * pageSize}</span>
                          ) : column.id === "select" ? (
                            <div onClick={(e) => e.stopPropagation()}>
                              <input
                                type="checkbox"
                                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                checked={selectedRows.has(row._id)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const newSelected = new Set(selectedRows);

                                  if (e.target.checked) {
                                    newSelected.add(row._id);
                                  } else {
                                    newSelected.delete(row._id);
                                  }

                                  setSelectedRows(newSelected);
                                  onSelectedRowsChange?.(
                                    Array.from(newSelected)
                                  );
                                }}
                              />
                            </div>
                          ) : column.id === "dragHandle" ? (
                            <div className="flex justify-center cursor-move">
                              <FontAwesomeIcon
                                icon={faGripLines}
                                className="text-gray-400"
                              />
                            </div>
                          ) : column.id === "actions" ? (
                            <ActionsDropdown
                              actionsColumns={actionsColumns}
                              row={{ original: row }}
                            />
                          ) : column.accessorFn ? (
                            column.accessorFn(row)
                          ) : column.accessorKey ? (
                            formatDateIfNeeded(
                              getNestedValue(row, column.accessorKey)
                            )
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Pagination Controls - Sticky at bottom */}
      {!hasNoData && (
        <div className="px-2 sm:px-8 py-2 border-t border-gray-200 flex flex-col sm:flex-row justify-between items-center bg-white w-full sticky bottom-0 z-10">
          <div className="text-xs sm:text-sm text-gray-600 mb-2 sm:mb-0">
            Showing {pageIndex * pageSize + 1} to{" "}
            {Math.min((pageIndex + 1) * pageSize, totalRows)} of {totalRows}{" "}
            results
          </div>
          <div className="flex items-center gap-2 mb-2 sm:mb-0">
            <span className="text-xs sm:text-sm text-gray-600">
              Rows per page:
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                const newPageSize = Number(e.target.value);
                table.setPageSize(newPageSize);
                onPaginationChange?.(1, newPageSize); // Reset to first page when changing page size
              }}
              className="px-0 py-0 w-fit h-fit border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900"
            >
              {PAGE_SIZE_OPTIONS.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-1 sm:gap-2 items-center">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="px-2 sm:px-3 py-1 border border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
              style={{ fontSize: "0.85rem" }}
            >
              Prev
            </button>

            {/* Page Numbers - Simplified for mobile */}
            <div className="flex gap-1">
              {getPageNumbers()
                .slice(0, window.innerWidth < 640 ? 3 : undefined)
                .map((pageNumber, index) =>
                  pageNumber === "..." ? (
                    <span
                      key={`ellipsis-${index}`}
                      className="px-2 sm:px-3 py-1"
                      style={{ fontSize: "0.85rem" }}
                    >
                      ...
                    </span>
                  ) : (
                    <button
                      key={`page-${pageNumber}`}
                      onClick={() => table.setPageIndex(pageNumber as number)}
                      className={`px-2 sm:px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-50 min-w-[30px] sm:min-w-[40px] ${
                        pageIndex === pageNumber
                          ? "bg-blue-50 border-blue-500 text-blue-600"
                          : ""
                      }`}
                      style={{ fontSize: "0.85rem" }}
                    >
                      {(pageNumber as number) + 1}
                    </button>
                  )
                )}
            </div>

            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className="px-2 sm:px-3 py-1 border border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
              style={{ fontSize: "0.85rem" }}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicDataTable;
