import React, { useState, useRef } from 'react';
import { useKYC } from '../Store/kycContext';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { handleUpdateDataLocally } from '../Apis/kycApi';
import { IoCloudDone } from 'react-icons/io5';
interface UploadAadhaarProps {
    onClose?: () => void;
}

const UploadAadhaar: React.FC<UploadAadhaarProps> = ({ onClose }) => {
    const { updateKYCData, kycData } = useKYC();
    const navigate = useNavigate();
    const [frontImage, setFrontImage] = useState<File | null>(null);
    const [backImage, setBackImage] = useState<File | null>(null);
    const [aadhaarNumber, setAadhaarNumber] = useState<string>('');
    const frontRef = useRef<HTMLInputElement | null>(null);
    const backRef = useRef<HTMLInputElement | null>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, side: 'front' | 'back') => {
        const file = event.target.files?.[0];
        if (file) {
            if (side === 'front') {
                setFrontImage(file);
            } else {
                setBackImage(file);
            }
        }
    };

    const handleSubmit = () => {
        console.log('aadhar submitting');
        console.log('frontImage', frontImage);
        console.log('backImage', backImage);
        console.log('aadhaarNumber', aadhaarNumber);
        if (frontImage && backImage && aadhaarNumber) {
            const frontUrl = URL.createObjectURL(frontImage);
            const backUrl = URL.createObjectURL(backImage);

            console.log('updating kyc data', frontUrl, backUrl, aadhaarNumber,frontImage,backImage);
            updateKYCData({
               
                identityProof: {
                    type: 'AADHAAR',
                    number: aadhaarNumber,
                    documentUrl: `${frontUrl},${backUrl}`,
                    file: frontImage,
                    aadhaar: {
                        number: aadhaarNumber,
                        frontUrl: frontUrl,
                        backUrl: backUrl,
                        frontFile: frontImage,
                        backFile: backImage
                    }
                }
            })
            updateKYCData({
              documents: [...kycData.documents, {
                name: 'aadhar front document',
                obj: frontImage,
                url: frontUrl
              },
              {
                name: 'aadhar back document',
                obj: backImage,
                url: backUrl
              }]
            })
                handleUpdateDataLocally({
                
                    identityProof: {
                        type: 'AADHAAR',
                        number: aadhaarNumber,
                        documentUrl: `${frontUrl},${backUrl}`,
                        file: frontImage,
                        aadhaar: {
                            number: aadhaarNumber,
                            frontUrl: frontUrl,
                            backUrl: backUrl,
                            frontFile: frontImage,
                            backFile: backImage
                        }
                    }
                });
                console.log('aadhar submitted');
                
                    toast.success('Aadhaar uploaded successfully');
                  
                    navigate("/onboarding/dpiit");
                  
              
            
        }
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <div className=" rounded-lg  w-full">
            {onClose !== undefined &&<div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">Upload Aadhaar Card</h3>
                <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
                    <RxCross2 size={24} />
                </button>
            </div>}

            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Aadhaar Number
                    </label>
                    <input
                        type="text"
                        value={aadhaarNumber}
                        onChange={(e) => setAadhaarNumber(e.target.value.replace(/\D/g, '').slice(0, 12))}
                        className="w-full border rounded-lg p-2 focus:outline-none"
                        placeholder="Enter 12-digit Aadhaar number"
                        maxLength={12}
                    />
                </div>

                <div>
                    {/* <label className="block text-sm font-medium text-gray-700 mb-2">
                        Front Side
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileUpload(e, 'front')}
                        className="w-full border rounded-lg p-2"
                    /> */}

                    {frontImage? (
                                <div className="border-dashed border-2 border-gray-300 p-4 rounded-md text-center flex justify-between items-center mt-4">
                                  <span>
                                    <h4>File Uploaded</h4>
                                    <input
                                      type="file"
                                      ref={frontRef}
                                      className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 hidden"
                                      onChange={(e) => handleFileUpload(e, 'front')}
                                    />
                                    <button
                                      className="text-blue-500 text-xs"
                                      onClick={() => frontRef.current?.click()}
                                    >
                                      Re-Upload
                                    </button>
                                  </span>
                                  <IoCloudDone color="green" size={25} />
                                </div>
                              ) : (
                                <span className="block mt-4">
                                  <div className="flex justify-between">
                                    <h4>Upload Front Image</h4>
                                    <input
                                      type="file"
                                      className="hidden"
                                      ref={frontRef}
                                      onChange={(e) => handleFileUpload(e, 'front')}
                                    />
                                    <button
                                      className="text-blue-500"
                                      onClick={() => frontRef.current?.click()}
                                    >
                                      Upload
                                    </button>
                                  </div>
                                </span>
                              )}
                </div>

                <div>
                   {backImage ? (
                               <div className="border-dashed border-2 border-gray-300 p-4 rounded-md text-center flex justify-between items-center mt-4">
                                 <span>
                                   <h4>File Uploaded</h4>
                                   <input
                                     type="file"
                                     ref={backRef}
                                     className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 hidden"
                                     onChange={(e) => handleFileUpload(e, 'back')}
                                   />
                                   <button
                                     className="text-blue-500 text-xs"
                                     onClick={() => backRef.current?.click()}
                                   >
                                     Re-Upload
                                   </button>
                                 </span>
                                 <IoCloudDone color="green" size={25} />
                               </div>
                             ) : (
                               <span className="block mt-4">
                                 <div className="flex justify-between">
                                   <h4>Upload Back Image</h4>
                                   <input
                                     type="file"
                                     className="hidden"
                                     ref={backRef}
                                     onChange={(e) => handleFileUpload(e, 'back')}
                                   />
                                   <button
                                     className="text-blue-500"
                                     onClick={() => backRef.current?.click()}
                                   >
                                     Upload
                                   </button>
                                 </div>
                               </span>
                             )}
                </div>

                <button
                    onClick={handleSubmit}
                    disabled={!frontImage || !backImage || !aadhaarNumber || aadhaarNumber.length !== 12}
                    className={`w-full py-2 rounded-lg ${
                        !frontImage || !backImage || !aadhaarNumber || aadhaarNumber.length !== 12
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 text-white'
                    }`}
                >
                    Upload
                </button>
            </div>
        </div>
    );
};

export default UploadAadhaar;