import React, { useRef, useEffect, useState } from 'react';
import RootLayout from '../RootLayout';
import logo from '../../../Assets/images/logo-rounded-bg.png';
import { IoCloudDone } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { useKYC } from '../../../Store/kycContext';
import { handleUpdateDataLocally, uploadMedia } from '../../../Apis/kycApi';
import { useNavigate } from 'react-router-dom';
import { url } from 'inspector';
const PANNumber = () => {
  const { kycData, updateKYCData } = useKYC();
  const navigate = useNavigate();
 
  const panRef = useRef<HTMLInputElement | null>(null);


  const validatePAN = (pan: string) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(pan);
  };

  const handlePanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    if (value.length <= 10) {
      updateKYCData({ 
        panNumber: {
          ...kycData.panNumber,
          number: value
        }
      });
    }
  };

  const handleUploadPan = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    try {
      const file = e.target.files[0];
      console.log('file in PAN', file)
      const fileUrl = URL.createObjectURL(file);
      
      const localData = localStorage.getItem("MERCHENT_DATA") || "{}";
      const parsedData = JSON.parse(localData);
      
      const updatedLegalDocuments = parsedData?.legalDocuments
        ? [
            ...parsedData.legalDocuments,
            { type: "pan", url: fileUrl },
          ]
        : [{ type: "pan", url: fileUrl }];

      handleUpdateDataLocally({ legalDocuments: updatedLegalDocuments });
      updateKYCData({
        panNumber: {
          ...kycData.panNumber,
          documentUrl: fileUrl,
          file: file
        }
      });
      updateKYCData({
        documents: [...kycData.documents, {
          
          name:'pan document',
          obj:file,
          url:fileUrl
        }]
      })
      toast.success("PAN uploaded");
      setIsPanUploaded(true);
    } catch (error) {
      toast.error("File Not Uploaded");
    }
  };

  const handleSubmit = () => {
    if (!kycData.panNumber.number) {
      toast.error("Please enter your PAN number");
      return;
    }

    if (!validatePAN(kycData.panNumber.number)) {
      toast.error("Please enter a valid PAN number (e.g., ABCDE1234F)");
      return;
    }
    console.log(kycData.panNumber);

    if (!kycData.panNumber.documentUrl) {
      toast.error("Please upload your PAN document");
      return;
    }

    handleUpdateDataLocally({ PAN: kycData.panNumber.number });
    navigate("/onboarding/identity-proof");
  };

  const [isPanUploaded, setIsPanUploaded] = useState(!!kycData.panNumber.documentUrl);

  return (
    <RootLayout>
      <div className="flex flex-col gap-6 justify-center items-center p-8">
        <img src={logo} alt="ShopIQ Logo" className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg" />
        
        <div className="w-full max-w-md">
          <span>
            <h2 className="text-3xl font-semibold mb-6 text-center">
              {kycData.businessType === "Individual " || kycData.businessType === "Proprietorship"
                ? "What's your Personal PAN Number?"
                : "What's your Brand's PAN Number?"}
            </h2>
            <div className="flex items-center rounded-md border border-[#DDD] h-[55px]">
              <input
                type="text"
                name="pan"
                placeholder="Enter PAN number (e.g., ABCDE1234F)"
                value={kycData.panNumber.number}
                onChange={handlePanChange}
                className="h-full w-full outline-none pl-5"
                maxLength={15}
              />
            </div>
          </span>

          {isPanUploaded ? (
            <div className="border-dashed border-2 border-gray-300 p-4 rounded-md text-center flex justify-between items-center mt-4">
              <span>
                <h4>File Uploaded</h4>
                <input
                  type="file"
                  ref={panRef}
                  className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 hidden"
                  onChange={handleUploadPan}
                />
                <button
                  className="text-blue-500 text-xs"
                  onClick={() => panRef.current?.click()}
                >
                  Re-Upload
                </button>
              </span>
              <IoCloudDone color="green" size={25} />
            </div>
          ) : (
            <span className="block mt-4">
              <div className="flex justify-between">
                <h4>Upload PAN</h4>
                <input
                  type="file"
                  className="hidden"
                  ref={panRef}
                  onChange={handleUploadPan}
                />
                <button
                  className="text-blue-500"
                  onClick={() => panRef.current?.click()}
                >
                  Upload
                </button>
              </div>
            </span>
          )}

          <button
            onClick={handleSubmit}
            disabled={!kycData.panNumber.number || !isPanUploaded}
            className={`w-full mt-6 py-3 rounded-md ${
              kycData.panNumber.number && isPanUploaded
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Continue
          </button>
        </div>
      </div>
    </RootLayout>
  );
};

export default PANNumber;