import { useState } from "react";
import styles from "../../Styles/applayout.module.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useAdminContext } from "../../Store/adminContext";
import { ReactComponent as LogoutIcon } from "../../Assets/icons/logout.svg";
import { ReactComponent as SettingsIcon } from "../../Assets/icons/setting.svg";
import { useLocation, useNavigate } from "react-router-dom";

const AppLayout = (props: any) => {
  const { getInitials }: any = useAdminContext();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  if (pathname.includes("/onboarding/")) return <>{props.children}</>;

  return (
    <div>
      <div
        style={{
          height: "50px",
          background: "var(--blue-light)",
          padding: "10px 30px 10px 20px",
          display: "flex",
          alignItems: "center",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 4px",
          gap: "20px",
        }}
      >
        <img src="/logo.png" style={{ height: "100%" }} />
        <a
          style={{
            marginLeft: "auto",
            padding: 0,
            background: "transparent",
            color: "var(--brand-color)",
            fontSize: ".85rem",
            textDecoration: "underline",
          }}
          href={`https://${localStorage.getItem("identifier")}`}
          target="_blank"
        >
          Visit Website
        </a>
        <div className={styles.user_and_menu} style={{ height: "35px" }}>
          <div
            style={{ height: "100%", aspectRatio: "1/1", position: "relative" }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "var(--brand-color)",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: ".9rem",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              {getInitials()}
            </div>
            {open && (
              <ul
                className={styles.profile_menu}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <li
                  onClick={() => {
                    setOpen(false);
                    navigate("/settings");
                  }}
                >
                  <SettingsIcon />
                  Settings
                </li>
                <li
                  onClick={() => {
                    localStorage.removeItem("token-BO-ADMIN");
                    localStorage.removeItem("blog");
                    localStorage.removeItem("contact");
                    localStorage.removeItem("career");
                    localStorage.clear();
                    navigate("/");
                    window.location.reload();
                  }}
                >
                  <LogoutIcon />
                  Logout
                </li>
              </ul>
            )}
            {open && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  background: "transparent",
                  zIndex: 10000000,
                }}
                onClick={() => setOpen(false)}
              ></div>
            )}
          </div>
          <div className={styles.sidebar_mob}>
            <Sidebar />
          </div>
        </div>
      </div>
      <div className={styles.applayout_container}>
        {/* sidebar */}
        <div className={styles.sidebar_desktop}>
          <Sidebar />
        </div>
        <div className={styles.sidebar_and_content_container}>
          {/* navbar */}
          <Navbar />

          {/* children screen */}
          <div className={styles.content_container}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
