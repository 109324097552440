import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../../Components/Modal";
import toast from "react-hot-toast";
import { useAdminContext } from "../../Store/adminContext";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import { getResourceLimit } from "../../Utils/checkPermissions/planPermissions";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import DynamicDataTable from "../../UI/DynamicTable";
import TableLoading from "../../Components/LoadingUi/TableLoading";

const base_url = process.env.REACT_APP_BASE_URL;

let inFocus = "";

const SubCategories = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(15);

  const { admin }:any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  useEffect(() => {
    getData();
  }, [location.search]);

  const getData = () => {
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      url: `${base_url}productsubcategory/?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${currentPage}&limit=${currentLimit}`,
      method: "get",
    })
      .then((res) => {
        setData(res.data.productSubCategories);
        setTotal(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        setTotal(0);
      });
  };

  const handleAction = () => {
    setModal(false);

    const promise = new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `${base_url}productsubcategory/${inFocus}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res) => {
          inFocus = "";
          resolve(true);
          getData();
        })
        .catch(() => {
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Deleting...",
        success: "Deleted Successfully.",
        error: "Error when deleting the entry.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  // Prepare columns for DynamicDataTable
  const columns = [
    {
      accessorKey: "title",
      header: "Title",
      size: 200,
    }
  ];

  // Define table headers for loading state
  const tableHead = [
    { title: "S.No.", value: "s.no" },
    { title: "Title", value: "title" },
  ];
  
  // Add action column to table headers if user has permission
  if (isUserAllowedToDelete(admin)) {
    tableHead.push({ title: "Action", value: "action" });
  }

  // Define actions for DynamicDataTable
  const actionsColumns = isUserAllowedToDelete(admin) ? [
    {
      name: "Delete",
      action: (id) => {
        if (isAllowed) {
          inFocus = id;
          setModal(true);
        } else {
          toast.error("Please Complete Setup Steps to access this route.");
        }
      }
    }
  ] : [];

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          inFocus = "";
          setModal(false);
        }}
        onBtn2Click={handleAction}
      />

      <Content>
        <ContentHead
          title="Sub Categories"
          showBtn1={isUserAllowedToEdit(admin)}
          btn1Name="btn1"
          iconType1="plus"
          btn1Text="New"
          btn1type="filled"
          onClickHandler={() => {
            if (isAllowed) {
              if (
                getResourceLimit(admin.selectedPlan, "subCategory") > data.length
              ) {
                navigate("/sub-categories/add");
              } else {
                toast.error("You have reached your plan's limit.");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
        />
        <ContentBody style={{ padding: 0, position: "relative" }}>
          {loading ? (
            <TableLoading head={tableHead} />
          ) : (
            <DynamicDataTable
              columns={columns}
              data={data}
              actionsColumns={actionsColumns}
              title="Sub Categories"
              onRowClick={(rowData) => {
                if (isAllowed) {
                  navigate("/sub-categories/update/" + rowData._id);
                } else {
                  toast.error("Please Complete Setup Steps to access this route.");
                }
              }}
              onPaginationChange={(page, limit) => {
                setPageNo(page);
                setPerPage(limit);
                // Update URL with new page number and limit
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('page', page.toString());
                searchParams.set('limit', limit.toString());
                navigate(`/sub-categories?${searchParams.toString()}`);
              }}
              totalRows={total}
              initialPageSize={perPage}
              initialPageIndex={pageNo}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

const isUserAllowedToDelete = (admin) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.delete) return true;

  return false;
};

export default SubCategories;