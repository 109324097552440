import styles from "../../Styles/content.module.css";
import Pagination from "../Table/Pagination";

type props = {
  children: any;
  style?: any;
  isPagination?: boolean;
  pageNo?: any;
  changePage?: any;
  total?: any;
  showDrop?: boolean;
  changeDrop?: any;
  dropValue?: any;
};

const ContentBody = (props: props) => {
  return (
    <div
      className={styles.content_body_container}
      style={{
        ...props?.style,
        paddingBottom: props?.isPagination ? "40px" : "",
      }}
    >
      {props.children}
      {props?.isPagination && (
        <div
          style={{
            height: "40px",
            padding: "5px",
            background: "var(--blue-light)",
            borderRadius: "0px 0px 10px 10px",
            display: "flex",
            justifyContent: "right",
            width: "calc(100vw - 14vw - 20px)",
            position: "fixed",
            bottom: "5px",
            zIndex: 10,
          }}
        >
          <Pagination
            showDrop={props?.showDrop || false}
            pageNo={props?.pageNo}
            changePage={props?.changePage}
            total={props?.total}
            changeDrop={props?.changeDrop}
            dropValue={props?.dropValue || 15}
          />
        </div>
      )}
    </div>
  );
};

export default ContentBody;
