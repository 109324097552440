import CustomButton from "../../../Components/CustomButton";
import { useKYC } from "../../../Store/kycContext";
import React, { useEffect, useState } from "react";
import RootLayout from "../RootLayout";
import { handleUpdateDataLocally } from "../../../Apis/kycApi";
import logo from "../../../Assets/images/logo-rounded-bg.png";
import { useNavigate } from "react-router-dom";
const BusinessAddress = () => {
  const { kycData, updateKYCData } = useKYC();
  const [addLineOne, setAddLineOne] = useState<string>("");
  const [addLineTwo, setAddLineTwo] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (kycData.location) {
      setAddLineOne(kycData.location.address1);
      setAddLineTwo(kycData.location.address2);
      setPincode(kycData.location.pincode);
      setCity(kycData.location.city);
      setState(kycData.location.state);
    }
  }, [kycData]);

  const handleSubmit = () => {
    const address = `${addLineOne}${addLineTwo ? `, ${addLineTwo}` : ""}`;
    updateKYCData({
      location: {
        address1: addLineOne,
        address2: addLineTwo,
        pincode,
        city,
        state,
      },
    });
    handleUpdateDataLocally({
      location: {
        address1: addLineOne,
        address2: addLineTwo,
        pincode,
        city,
        state,
      },
    });
    navigate("/onboarding/gst-verification");
  };

  return (
    <RootLayout>
      <div className="flex flex-col gap-6 justify-center items-center p-8">
        <img
          src={logo}
          alt="ShopIQ Logo"
          className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg"
        />
        <span>
          <h2 className="text-3xl font-semibold mb-6">
            Add Your Business Address
          </h2>
          <div className="flex flex-col gap-5">
            <input
              type="text"
              name="addLineOne"
              placeholder="Address One"
              value={addLineOne}
              onChange={(e) => setAddLineOne(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              name="addLineTwo"
              placeholder="Address Two"
              value={addLineTwo}
              onChange={(e) => setAddLineTwo(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              name="Pincode"
              placeholder="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex gap-4 w-full justify-between">
              <input
                type="text"
                name="City"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </span>

        <CustomButton
          text="Continue"
          variant="filled"
          fullWidth={true}
          onClick={handleSubmit}
          disabled={!addLineOne || !pincode || !city || !state}
        />
      </div>
    </RootLayout>
  );
};

export default BusinessAddress;
