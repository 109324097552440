import React, { useState, useEffect } from "react";
import WithTableView from "../../GenericPages/WithTableView";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import ObjToExcel from "../../Utils/objToExcel";
import Pagination from "../../Components/Table/Pagination";
import { folder_name } from "../../Utils/UploadImage";
import { ReactComponent as Cross } from "../../Assets/icons/cross.svg";
import styles from "../../Styles/applications.module.css";
import style from "../../Styles/orderDetails.module.css";
import { useLangContext } from "../../Store/langContext";
import langObj from "../../Utils/lang";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import TableHead from "../../Components/Table/TableHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/trash.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import { useQuery } from "../../Utils/getQuery";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import DynamicDataTable from "../../UI/DynamicTable";

type props = {
  data?: any;
  modal?: boolean;
  modalData?: any;
  loading?: boolean;
  deleteModal?: boolean;
};

let inFocus: any;

const Applications: React.FC<props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang }: any = useLangContext();

  const [data, setData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [secondaryView, setSecondaryView] = useState(false);
  const [inView, setInView]: any = useState(null);
  const [disableForAction, setDisableForAction] = useState(false);
  const [tab, setTab] = useState("overview");
  const [remarks, setRemarks] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_BASE_URL
      }aspirants?identifier=${localStorage.getItem("identifier")}&page=${
        Number(search.get("page")) || 1
      }&limit=${Number(search.get("limit")) || 15}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setData(
          res.data.aspirants.map((val: any) => ({
            ...val,
            createdAt: val.createdAt.split("T")[0].split("-").join("-"),
            dob: val?.dob
              ? val?.dob.split("T")[0].split("-").reverse().join("-")
              : "",
          }))
        );
        setLoading(false);
        setTotal(res?.data?.total);
      })
      .catch(() => {
        setLoading(false);
        setData([]);
      });
  };

  const handleDelete = () => {
    setDeleteModal(false);

    const promise = new Promise<void>((resolve, reject) => {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_BASE_URL}aspirants/${inFocus}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then(() => {
          inFocus = "";
          resolve();
          getData();
        })
        .catch(() => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then();
  };

  const handleJoiningStatusChange = (e: any, a_id: any) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}aspirants/${a_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: {
          joiningStatus: e.target.value,
        },
      })
        .then((res) => {
          getData();
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Updating joining status...",
        success: "Joining status updated successfully.",
        error: "Error when updating joining status.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  const handleRemarkUpdate = (data: any, remark: any) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}aspirants/${data._id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: { remarksInput: remark },
      })
        .then((res: any) => {
          getData();
          resolve(true);
        })
        .catch((err: any) => {
          toast.error(err.response.data.message);
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Updating remarks...",
        success: "Remarks updated successfully.",
        error: "Error when updating remarks.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  const handleDropdownUpdate = (data: any, event: any) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}aspirants/${data._id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: { joiningStatus: event.target.value },
      })
        .then((res: any) => {
          getData();
          resolve(true);
        })
        .catch((err: any) => {
          toast.error(err.response.data.message);
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Updating status ...",
        success: "Status updated successfully.",
        error: "Error while updating status.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  const exportExcel = async () => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const response = await axios({
            url: `${
              process.env.REACT_APP_BASE_URL
            }aspirants?identifier=${localStorage.getItem(
              "identifier"
            )}&limit=${total}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
            },
          });

          const data = response.data.aspirants.map((val: any) => ({
            ...val,
            createdAt: val?.createdAt
              ? val?.createdAt.split("T")[0].split("-").reverse().join("-")
              : "",
            cvUrl: val?.cvUrl ? `${val?.cvUrl}` : "",
          }));

          ObjToExcel(data, "Applicants", "applicants", [
            { title: "Applied On", value: "createdAt" },
            { title: "Name", value: "firstName" },
            { title: "Applied Position", value: "appliedPosition" },
            { title: "Current City", value: "currentCity" },
            { title: "Phone Number", value: "mobileNumber" },
            { title: "Email", value: "email" },
            { title: "DOB", value: "dob" },
            { title: "Notice Period", value: "noticePeriod" },
            { title: "Qualification", value: "qualification" },
            { title: "Joining Status", value: "joiningStatus" },
            { title: "Experience", value: "experience" },
            { title: "Short Bio", value: "bioShort" },
            { title: "CV URL", value: "cvUrl", link: true },
          ]);
          resolve(true);
        } catch (err) {
          toast.error("Something went wrong while generating excel.");
          reject(true);
        }
      }),
      {
        loading: "Generating excel, Please wait...",
        success: "Excel Generated Successfully.",
        error: "Something went wrong while generating excel.",
      }
    );
  };

  const handleSaveRemarks = () => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}aspirants/${inView._id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: { remarksInput: remarks },
      })
        .then((res: any) => {
          getData();
          resolve(true);
        })
        .catch((err: any) => {
          toast.error(err.response.data.message);
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Updating remarks ...",
        success: "Remarks updated successfully.",
        error: "Error while updating remarks.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  if (loading)
    return (
      <>
        <TableLoading
          head={[
            { title: "" },
            { title: "S.No." },
            { title: "Blog Title" },
            { title: "Status" },
            { title: "" },
          ]}
        />
      </>
    );

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={deleteModal}
        onBtn1Click={() => {
          inFocus = "";
          setDeleteModal(false);
        }}
        onBtn2Click={handleDelete}
      />
      {modal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
            background: "rgba(0,0,0,.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxHeight: "90vh",
              width: "60vw",
              background: "white",
              overflowY: "scroll",
              padding: "20px",
              borderRadius: "15px",
              position: "relative",
            }}
          >
            <Cross
              style={{
                position: "absolute",
                fill: "black",
                height: "30px",
                width: "30px",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModal(false);
                setModalData({});
              }}
            />
            <h1
              style={{
                borderBottom: "2px solid black",
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              {modalData?.firstName}
              {modalData?.lastName}
            </h1>
            <div className={styles.enteries}>
              {modalData?.createdAt && (
                <p>
                  Applied On :<span>{modalData?.createdAt}</span>
                </p>
              )}
              <p>
                {/* @ts-ignore */}
                Email Id : <span>{modalData?.email}</span>
              </p>
              <p>
                {/* @ts-ignore */}
                Mobile : <span>{modalData?.mobileNumber}</span>
              </p>
              <p>
                {/* @ts-ignore */}
                DOB : <span>{modalData?.dob}</span>
              </p>
              <p>
                Current City : {/* @ts-ignore */}{" "}
                <span>{modalData?.currentCity}</span>
              </p>
              <p>
                Applied Position : {/* @ts-ignore */}{" "}
                <span>{modalData?.appliedPosition}</span>
              </p>
              <p>
                Education Qualification : {/* @ts-ignore */}{" "}
                <span>{modalData?.qualification}</span>
              </p>
              <p>
                Experience : {/* @ts-ignore */}{" "}
                <span>{modalData?.experience}</span>
              </p>
              <p>
                CV Url :{" "}
                <span>
                  <a href={modalData?.cvUrl} target="_blank" rel="noreferrer">
                    {/* @ts-ignore */}
                    {modalData?.cvUrl}
                  </a>
                </span>
                <span
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "25px",
                    width: "25px",
                    aspectRatio: "1/1",
                    marginLeft: "10px",
                    background: "rgba(0,0,0, .5)",
                    borderRadius: "5px",
                    color: "white",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  <a
                    // @ts-ignore
                    href={`${modalData?.cvUrl}`}
                    download={true}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    ↓
                  </a>
                </span>
              </p>
              <p>
                Notice Period :{/* @ts-ignore */}{" "}
                <span>{modalData?.noticePeriod}</span>
              </p>
              <p>
                Message :{/* @ts-ignore */}{" "}
                <span>{modalData?.noticePeriod}</span>
              </p>
            </div>
          </div>
        </div>
      )}
      <Content>
        <ContentHead
          title="Applications"
          isMoreSettings={true}
          moreSettingsList={[
            { icon: "export", title: "Export", name: "export" },
          ]}
          handleMoreClick={() => {
            exportExcel();
          }}
        />
        <ContentBody style={{ padding: 0 }}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {secondaryView ? (
              <div className={style.secondary_view}>
                {/* For mobile screens, we'll stack the list and detail views */}
                <div
                  className={`${style.secondary_list_view} ${style.responsive_list}`}
                >
                  {data.map((val: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={style.secondary_short_item}
                        style={
                          val._id === inView?._id
                            ? {
                                background: "var(--brand-color)",
                                color: "white",
                              }
                            : {}
                        }
                        onClick={() => {
                          setRemarks(val?.remarksInput || "");
                          setInView(val);
                          // On mobile, we'll show the detail view when an item is clicked
                          if (window.innerWidth <= 768) {
                            document
                              .querySelector(`.${style.secondary_detail_view}`)
                              .classList.add(style.show_mobile_detail);
                          }
                        }}
                      >
                        <div>
                          <p>{val?.firstName || "--"}</p>
                          <span>{val?.appliedPosition || "--"}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={style.secondary_detail_view}>
                  <div>
                    <div className={style.detail_header}>
                      <div className={style.sdv_head}>
                        <p style={{ textTransform: "capitalize" }}>
                          {inView?.firstName}
                        </p>
                      </div>
                      <CloseIcon
                        className={style.sdv_close_btn}
                        onClick={() => {
                          setSecondaryView(false);
                          setInView({});
                          // On mobile, we'll hide the detail view when closed
                          if (window.innerWidth <= 768) {
                            document
                              .querySelector(`.${style.secondary_detail_view}`)
                              .classList.remove(style.show_mobile_detail);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.tabs_section}>
                    <div
                      className={tab === "overview" ? style.tab_selected : ""}
                      onClick={() => setTab("overview")}
                    >
                      Overview
                    </div>
                    <div
                      className={tab === "remarks" ? style.tab_selected : ""}
                      onClick={() => setTab("remarks")}
                    >
                      Remarks
                    </div>
                  </div>
                  {tab === "overview" && (
                    <>
                      <div className={style.secondary_content}>
                        <div className={style.info_grid}>
                          <div>
                            <span>First Name</span>
                            <p>{inView?.firstName || "--"}</p>
                          </div>
                          <div>
                            <span>Last Name</span>
                            <p>{inView?.lastName || "--"}</p>
                          </div>
                          <div>
                            <span>Email</span>
                            <p>{inView?.email || "--"}</p>
                          </div>
                          <div>
                            <span>Phone</span>
                            <p>{inView?.mobileNumber || "--"}</p>
                          </div>
                          <div>
                            <span>DOB</span>
                            <p>{inView?.dob || "--"}</p>
                          </div>
                          <div>
                            <span>Status</span>
                            <p>{inView?.status || "--"}</p>
                          </div>
                          <div>
                            <span>Current City</span>
                            <p>{inView?.currentCity || "--"}</p>
                          </div>
                          <div>
                            <span>Applied Position</span>
                            <p>{inView?.appliedPosition || "--"}</p>
                          </div>
                          <div>
                            <span>Education Qualification</span>
                            <p>{inView?.qualification || "--"}</p>
                          </div>
                          <div>
                            <span>Experience</span>
                            <p>{inView?.experience || "--"}</p>
                          </div>
                          <div>
                            <span>CV URL</span>
                            {inView?.cvUrl ? (
                              <a
                                href={inView?.cvUrl}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  height: "fit-content",
                                  width: "fit-content",
                                  padding: 0,
                                  background: "transparent",
                                  display: "block",
                                  color: "var(--brand-color)",
                                }}
                              >
                                View
                              </a>
                            ) : (
                              <p>--</p>
                            )}
                          </div>
                          <div>
                            <span>Notice Period</span>
                            <p>{inView?.noticePeriod || "--"}</p>
                          </div>
                          <div className={style.full_width_field}>
                            <span>Message</span>
                            <p>{inView?.message || "--"}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {tab === "remarks" && (
                    <div className={style.remarks}>
                      <div className={style.remark_input}>
                        <label>Remarks</label>
                        <textarea
                          value={remarks}
                          onChange={(e: any) => setRemarks(e.target.value)}
                        />
                        <div className={style.remark_btn}>
                          <button onClick={handleSaveRemarks}>Save</button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* Mobile back button to return to list */}
                <div
                  className={style.mobile_back_btn}
                  onClick={() => {
                    document
                      .querySelector(`.${style.secondary_detail_view}`)
                      .classList.remove(style.show_mobile_detail);
                  }}
                >
                  Back to List
                </div>
              </div>
            ) : (
              <>
                <DynamicDataTable
                  data={data}
                  columns={[
                    { accessorKey: "createdAt", header: "Applied On" },
                    { accessorKey: "firstName", header: "First Name" },
                    { accessorKey: "email", header: "Email" },
                    { accessorKey: "mobileNumber", header: "Phone No." },
                    {
                      accessorKey: "appliedPosition",
                      header: "Applied Position",
                    },
                    { accessorKey: "remarks", header: "Remarks" },
                    { accessorKey: "status", header: "Application Status" },
                  ]}
                  actionsColumns={[
                    {
                      name: "Delete",
                      action: (rowId) => {
                        inFocus = rowId;
                        setDeleteModal(true);
                      },
                    },
                  ]}
                  onRowClick={(v) => {
                    setInView(v);
                    setSecondaryView(true);
                  }}
                  onPaginationChange={(newPage, newLimit) => {
                    setPage(newPage);
                    setLimit(newLimit);
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set("page", newPage.toString());
                    searchParams.set("limit", newLimit.toString());
                    navigate(`/applicants?${searchParams.toString()}`);
                  }}
                  totalRows={total}
                  initialPageSize={limit}
                  initialPageIndex={page}
                />
                {/* <div className={style.table_container}>
                  <table className={style.responsive_table}>
                    <TableHead
                      head={[
                        { title: "S.No." },
                        { title: "Applied On" },
                        { title: "First Name" },
                        { title: "Email" },
                        { title: "Phone no." },
                        { title: "Applied Position" },
                        { title: "Remarks" },
                        { title: "Application Status" },
                        { title: "Action" },
                      ]}
                      filtervalue={{}}
                      onHeadClick={() => {}}
                      showIconAt={false}
                    />
                    <tbody>
                      {data?.map((val: any, index: any) => {
                        return (
                          <SingleRow
                            data={val}
                            key={index}
                            index={
                              Math.abs(
                                Number(
                                  new URLSearchParams(location.search).get(
                                    "page"
                                  )
                                ) || 1 - 1
                              ) *
                                15 +
                              index
                            }
                            changeSelect={() => {}}
                            disableForAction={disableForAction}
                            getData={getData}
                            onRowClick={() => {
                              setRemarks(val?.remarksInput);
                              setInView(val);
                              setSecondaryView(true);
                            }}
                            handleGenetrateLabel={() => {
                              setInView(val);
                            }}
                            handleOptionClick={() => {
                              setInView(val);
                            }}
                            setModal={setDeleteModal}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div> */}
                {/* {true && (
                  <div
                    style={{
                      // border: "1px solid ",
                      paddingBlock: "5px",
                      background: "var(--blue-light)",
                      borderRadius: "0px 0px 10px 10px",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Pagination
                      pageNo={
                        Number(
                          new URLSearchParams(location.search).get("page")
                        ) || 1
                      }
                      changePage={(val: number) => {
                        let search = new URLSearchParams(location.search);

                        search.set("page", String(val));

                        navigate(`/applicants?${String(search)}`);
                      }}
                      total={total}
                      showDrop={false}
                      perPage={15}
                      changeDrop={(val: number) => {
                        let search = new URLSearchParams(location.search);

                        search.set("limit", String(val));

                        navigate(`/applicants?${String(search)}`);
                      }}
                    />
                  </div>
                )} */}
              </>
            )}
          </div>
        </ContentBody>
      </Content>
      {/* <WithTableView
        loading={loading}
        heading="Applications"
        head={[
          { title: "S.No.", value: "s.no" },
          { title: "Applied On", value: "createdAt" },
          { title: "First Name", value: "firstName" },
          { title: "Email", value: "email" },
          { title: "Phone no.", value: "phone" },
          { title: "Applied Position", value: "applied_position" },
          { title: "Remarks", value: "" },
          { title: "Application Status", value: "" },
          { title: "Action", value: "action" },
          // delete, view(text)
        ]}
        body={[...data]}
        body_keys={[
          "createdAt",
          "firstName",
          "email",
          "mobileNumber",
          "appliedPosition",
          "remarks",
          "status:dropdown",
          "action:delete",
        ]}
        page={Number(search.get("page"))}
        show_btn_1={true}
        btn1Text={langObj.exportExcel[lang]}
        btn1Type="light_grey"
        onClickHandler={() => exportExcel()}
        onRowClick={(val: any) => {
          setModal(true);
          setModalData(val);
        }}
        onRemarksSave={handleRemarkUpdate}
        onActionClick={(name: any, id: any) => {
          inFocus = id;
          setDeleteModal(true);
        }}
        handleDropdownUpdate={handleDropdownUpdate}
        customElement={
          <Pagination
            pageNo={
              Number(new URLSearchParams(location.search).get("page")) || 1
            }
            changePage={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("page", String(val));

              navigate(`/applicants?${String(search)}`);
            }}
            total={total}
            showDrop={true}
            perPage={
              Number(new URLSearchParams(location.search).get("limit")) || 15
            }
            changeDrop={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("limit", String(val));

              navigate(`/applicants?${String(search)}`);
            }}
          />
        }
      /> */}
    </>
  );
};

const SingleRow = (props: any) => {
  const handleDelete = (event: any) => {
    event.stopPropagation();
    inFocus = props.data._id;
    props.setModal(true);
  };

  return (
    <tr onClick={props.onRowClick}>
      <td>{props.index + 1}</td>
      <td>{props.data.createdAt}</td>
      <td>{props.data.firstName}</td>
      <td>{props.data.email}</td>
      <td>{props.data.mobileNumber}</td>
      <td>{props.data.appliedPosition}</td>
      <td>{props.data.remarks || "--"}</td>
      <td>{props.data.status || "--"}</td>
      <td>
        <DeleteIcon
          style={{ width: "20px", height: "20px" }}
          onClick={handleDelete}
        />
      </td>
    </tr>
  );
};

export default Applications;
