import { useState } from "react";
import Content from "../../../Components/Content/Content";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import styles from "./importProducts.module.css";
import { ReactComponent as RightIcon } from "../../../Assets/icons/right.svg";
import { ReactComponent as ImportIcon } from "../../../Assets/icons/import.svg";
import { ReactComponent as LeftIcon } from "../../../Assets/icons/left.svg";
import Info from "../../../Components/info";
import InputField from "../../../Components/InputField";
import ContactConfiguration from "../../../Components/Leads/ImportLeads/contactConfiguration";
import { parseCSVOrTSV, parseExcel } from "../../../Utils/excelFunctions";
import ContactMapFields from "../../../Components/Leads/ImportLeads/contactMapFields";
import ContactPreview from "../../../Components/Leads/ImportLeads/contactPreview";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ProductConfiguration from "../../../Components/Products/Import/productConfiguration";
import * as XLSX from "xlsx";

const ImportProducts = () => {
  const [platform, setPlatform] = useState("shopiq");
  const [sheets, setSheets]: any = useState([]);
  const [entries, setEntries] = useState("all");
  const [pages, setPages] = useState("all");
  const [pageFrom, setPageFrom]: any = useState([]);
  const [customEntries, setCustomEntries]: any = useState({});
  const [pageTo, setPageTo] = useState("");
  const [stage, setStage] = useState("configuration");
  const [files, setFiles] = useState([]);
  const [encoding, setEncoding] = useState("utf-8");
  const [delimiter, setDelimiter] = useState("comma");
  const [tsvDelimiter, setTsvDelimiter] = useState("tab");
  const [processing, setProcessing] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [assignedFields, setAssignedFields] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    personalEmail: "",
    cooperateEmail: "",
    mobileNumber: "",
    altMobileNumber: "",
    contactOwner: "",
    companyName: "",
    companyWebsite: "",
    companyHQ: "",
    status: "",
    reason1: "",
    reason2: "",
    message: "",
    country: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
    remarks: "",
  });
  const [data, setData]: any = useState([]);
  const [ready, setReady]: any = useState([]);
  const [skipped, setSkipped]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicate, setDuplicate] = useState("skip");
  const navigate = useNavigate();

  const handleUpload = () => {
    setLoading(true);

    axios({
      url: `${process.env.REACT_APP_BASE_URL}products/bulkUpload`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        toast.success("Uploaded Successfully.");
        navigate("/products");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong.");
      });
  };

  const handleNext = () => {
    if (stage === "configuration") {
      setProcessing(true);
      //   processFiles();
      // bulkUpload();
      sheetConversion();
      setProcessing(false);
      setStage("preview");
    } else if (stage === "preview") {
      handleUpload();
    }
  };

  const handlePrevious = () => {
    if (stage === "preview") {
      setStage("configuration");
    }
  };

  const getSheetsInFile = (file: any) => {
    let reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = (e: any) => {
      const data = e.target.result;
      // Parse the file into a workbook using XLSX
      let workbook = XLSX.read(data, { type: "binary" });
      // Get all sheet names in the workbook
      const sheetNames = workbook.SheetNames;

      // Number of sheets
      const numberOfSheets = sheetNames.length;

      // Log sheet names and the number of sheets

      setSheets(sheetNames);
      let temp: any = {};
      sheetNames.forEach((v: any) => {
        temp[v] = { from: "", to: "" };
      });
      setCustomEntries(temp);
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      toast.error("Unable to read file.");
    };
  };

  const bulkUpload = () => {
    const file: any = files[0];
    let reader = new FileReader();

    // Check the file type (CSV/TSV/Excel) before reading
    const isCSV =
      file.type.includes("csv") ||
      file.type.includes("tsv") ||
      file.type.includes("text");
    const isExcel =
      file.type.includes("xls") || file.type.includes("spreadsheet");

    if (isCSV) {
      // Process CSV or TSV
      reader.readAsText(file);
    } else if (isExcel) {
      // Process Excel
      reader.readAsBinaryString(file);
    }

    reader.onload = async (e: any) => {
      let data = e.target.result;

      if (isCSV) {
        // For CSV/TSV files, use the previously given CSV/TSV parser
        const delimiter = file.name.endsWith(".csv") ? "," : "\t"; // Adjust based on file type
        const parsedData = await parseCSVOrTSV(file, "utf-8", delimiter, "\t"); // Modify to pass the correct delimiter
        readData(parsedData);
      } else if (isExcel) {
        // For Excel, use XLSX to parse the file
        let workbook = XLSX.read(data, { type: "binary" });
        let sheet_name_list = workbook.SheetNames;

        // Loop through selected sheets in pageFrom
        for (let sheetName of pageFrom) {
          if (sheet_name_list.includes(sheetName)) {
            let xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
              header: 1, // Keep headers in two rows
              defval: "",
            });

            // Use customEntries to define which rows to read from the sheet
            if (customEntries[sheetName]) {
              const { from, to } = customEntries[sheetName];

              let hh1 = xlData.splice(0, 1);
              let hh2 = xlData.splice(0, 1);

              xlData = xlData.slice(from - 1, to); // Slice data based on customEntries
              xlData = [hh1[0], hh2[0], ...xlData];

              xlData = xlData.filter((v: any) => {
                return v.filter((x: any) => x).length !== 0;
              });
            }

            // Pass the filtered data for further processing
            readData(xlData);
          }
        }

        // let xlData = XLSX.utils.sheet_to_json(
        //   workbook.Sheets[sheet_name_list[0]],
        //   {
        //     header: 1, // Keep headers in two rows
        //     defval: "",
        //   }
        // );
        // readData(xlData);
      }
    };
  };

  const readData = async (jsonData: any) => {
    try {
      if (jsonData.length < 3) {
        toast.error("No Data Found");
        return;
      }

      let header1: any = jsonData[0];

      let k = 0;
      while (true) {
        if (header1[k] === undefined) {
          break;
        }
        if (
          header1[k].includes("advancePricingValues") ||
          header1[k].includes("pricing") ||
          header1[k].includes("inventory") ||
          header1[k].includes("variant") ||
          header1[k].includes("specifications") ||
          header1[k].includes("shipping") ||
          header1[k].includes("isAvailable") ||
          header1[k].includes("profit") ||
          header1[k].includes("seListing") ||
          header1[k].includes("document") ||
          header1[k].includes("mediaUrl") ||
          header1[k].includes("tags")
        ) {
          if (header1[k].includes("advancePricingValues")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }

          if (header1[k].includes("pricing")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            header1[k + 3] = header1[k];
            header1[k + 4] = header1[k];
            header1[k + 5] = header1[k];
            header1[k + 6] = header1[k];
            header1[k + 7] = header1[k];
            k += 8;
            continue;
          }

          if (header1[k].includes("inventory")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            header1[k + 3] = header1[k];
            header1[k + 4] = header1[k];
            header1[k + 5] = header1[k];
            header1[k + 6] = header1[k];
            k += 7;
            continue;
          }

          if (header1[k].includes("variant")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            header1[k + 3] = header1[k];
            header1[k + 4] = header1[k];
            k += 5;
            continue;
          }

          if (header1[k].includes("specifications")) {
            header1[k + 1] = header1[k];
            k += 2;
            continue;
          }

          if (header1[k].includes("shipping")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }

          if (header1[k].includes("isAvailable")) {
            header1[k + 1] = header1[k];
            k += 2;
            continue;
          }

          if (header1[k].includes("profit")) {
            header1[k + 1] = header1[k];
            k += 2;
            continue;
          }

          if (header1[k].includes("document")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }

          if (header1[k].includes("seListing")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }
        } else if (header1[k] !== "") {
        } else {
          break;
        }
        k++;
      }

      header1 = header1.filter((val: any) => val);

      let header2: any = jsonData[1];
      let dataRows: any = jsonData.slice(2);

      let result: any = [];

      for (let i = 0; i < dataRows.length; i++) {
        if (dataRows[i].filter((val: any) => val).length === 0) break;
        let obj: any = {};

        obj.identifier = localStorage.getItem("identifier");

        for (let j = 0; j < dataRows[i].length; j++) {
          if (header2[j] === "" && header1[j] !== "") {
            if (header1[j] === "similarProduct") {
              // obj[header1[j]] = dataRows[i][j].split(",");
            } else if (header1[j] === "advancePricing") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "isBestSeller") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "isFeatured") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "requestQuote") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "status") {
              obj[header1[j]] = dataRows[i][j] || "active";
            } else if (header1[j].includes("mediaUrl")) {
            } else if (header1[j].includes("tags")) {
            } else {
              obj[header1[j]] = dataRows[i][j];
            }
          }

          if (
            header2[j] !== "" &&
            header1[j].includes("advancePricingValues")
          ) {
            if (obj?.advancePricingValues) {
              obj.advancePricingValues.push({
                [header2[j]]: dataRows[i][j],
                [header2[j + 1]]: dataRows[i][j + 1],
                [header2[j + 2]]: dataRows[i][j + 2],
              });
            } else {
              obj.advancePricingValues = [
                {
                  [header2[j]]: dataRows[i][j],
                  [header2[j + 1]]: dataRows[i][j + 1],
                  [header2[j + 2]]: dataRows[i][j + 2],
                },
              ];
            }
            j += 2;
            continue;
          }

          if (header2[j] === "" && header1[j].includes("mediaUrl")) {
            if (obj?.mediaUrl) {
              if (dataRows[i][j]) obj.mediaUrl.push(dataRows[i][j]);
            } else {
              if (dataRows[i][j]) obj.mediaUrl = [dataRows[i][j]];
            }
          }

          if (header2[j] === "" && header1[j].includes("tags")) {
            if (obj?.tags) {
              if (dataRows[i][j]) obj.tags.push(dataRows[i][j]);
            } else {
              if (dataRows[i][j]) obj.tags = [dataRows[i][j]];
            }
          }

          if (header2[j] !== "" && header1[j].includes("pricing")) {
            obj.pricing = {
              price: dataRows[i][j],
              comparePrice: dataRows[i][j + 1],
              makingCharge: dataRows[i][j + 2],
              cgst: dataRows[i][j + 3],
              sgst: dataRows[i][j + 4],
              igst: dataRows[i][j + 5],
              purchaseCurrency: dataRows[i][j + 6],
              minimumOrderQuantity: dataRows[i][j + 7],
            };
            j += 7;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("inventory")) {
            obj.inventory = {
              [header2[j]]: !!dataRows[i][j],
              // [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
              [header2[j + 3]]: dataRows[i][j + 3],
              [header2[j + 4]]: !!dataRows[i][j + 4],
              [header2[j + 5]]: dataRows[i][j + 5],
              [header2[j + 6]]: dataRows[i][j + 6],
            };

            j += 6;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("variant")) {
            let t: any = {};

            if (dataRows[i][j]) t.variantProductId = dataRows[i][j];
            if (dataRows[i][j]) t.slug = dataRows[i][j + 1];
            if (dataRows[i][j]) t.optionSku = dataRows[i][j + 2];
            if (dataRows[i][j]) t.options_name = dataRows[i][j + 3];
            if (dataRows[i][j]) t.options_value = dataRows[i][j + 4];

            if (obj?.variant) {
              obj.variant.push(t);
            } else {
              obj.variant = [t];
            }
            j += 4;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("specifications")) {
            if (obj?.specifications && dataRows[i][j + 1]) {
              obj.specifications.push({
                options_name: dataRows[i][j],
                options_value: String(dataRows[i][j + 1]).includes(",")
                  ? dataRows[i][j + 1].split(",")
                  : [dataRows[i][j + 1]],
              });
            } else {
              if (dataRows[i][j + 1])
                obj.specifications = [
                  {
                    options_name: dataRows[i][j],
                    options_value: String(dataRows[i][j + 1]).includes(",")
                      ? dataRows[i][j + 1].split(",")
                      : [dataRows[i][j + 1]],
                  },
                ];
            }

            j += 1;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("shipping")) {
            obj.shipping = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
            };
            j += 2;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("document")) {
            obj.document = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
            };
            j += 2;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("seListing")) {
            obj.seListing = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
            };

            j += 2;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("isAvailable")) {
            obj.isAvailable = {
              [header2[j]]: !!dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
            };
            j += 1;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("profit")) {
            obj.profit = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
            };
            j += 1;
            continue;
          }
        }

        result.push(obj);
      }

      // Remove duplicates
      result = result.filter(
        (item: any, index: any, self: any) =>
          index ===
          self.findIndex((t: any) => JSON.stringify(t) === JSON.stringify(item))
      );

      setData((prev: any) => [...prev, ...result]);
    } catch (err) {
      console.error("Error Uploading file:", err);
      toast.error("An error occurred during file upload.");
    }
  };

  const processFiles = async () => {
    try {
      const allData = await Promise.all(
        files.map(async (file: File) => {
          if (
            file.type.includes("csv") ||
            file.type.includes("tsv") ||
            file.type.includes("text")
          ) {
            return await parseCSVOrTSV(file, encoding, delimiter, tsvDelimiter);
          } else if (
            file.type.includes("xls") ||
            file.type.includes("spreadsheet")
          ) {
            return await parseExcel(file, encoding);
          }
          return []; // Return an empty array for unsupported file types
        })
      );

      let combinedData = allData.flat(); // Combine all arrays into a single array

      // Remove duplicates where all fields are identical
      combinedData = combinedData.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item))
      );

      setData(combinedData);

      let keys: any = [];
      combinedData.forEach((d: any) => {
        keys.push(...Object.keys(d));
      });

      keys = Array.from(new Set(keys));

      const temp: any = { ...assignedFields };

      keys.forEach((k: any) => {
        if (temp.hasOwnProperty(k)) {
          temp[k] = k;
        }
      });
      setHeaders(keys);
      setAssignedFields(temp);
    } catch (error) {
      console.error("Error processing files:", error);
    }
  };

  const sheetConversion = () => {
    const file: any = files[0];
    let reader = new FileReader();

    // Check the file type (CSV/TSV/Excel) before reading
    const isCSV =
      file.type.includes("csv") ||
      file.type.includes("tsv") ||
      file.type.includes("text");
    const isExcel =
      file.type.includes("xls") || file.type.includes("spreadsheet");

    if (isCSV) {
      // Process CSV or TSV
      reader.readAsText(file);
    } else if (isExcel) {
      // Process Excel
      reader.readAsBinaryString(file);
    }

    reader.onload = async (event: any) => {
      let data = event.target.result;

      if (isCSV) {
      } else if (isExcel) {
        let workbook = XLSX.read(data, { type: "binary" });
        let sheet_name_list = workbook.SheetNames;

        let pgs = pageFrom;

        if (pages === "all") pgs = sheet_name_list;

        for (let sheetName of pgs) {
          if (sheet_name_list.includes(sheetName)) {
            let xlData: any = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName],
              {
                header: 1,
                defval: "",
              }
            );

            // Use customEntries to define which rows to read from the sheet
            if (customEntries[sheetName].to) {
              const { from, to } = customEntries[sheetName];

              let hh1 = xlData.splice(0, 1);
              let hh2 = xlData.splice(0, 1);

              xlData = xlData.slice(from - 1, to); // Slice data based on customEntries
              xlData = [hh1[0], hh2[0], ...xlData];

              xlData = xlData.filter((v: any) => {
                return v.filter((x: any) => x).length !== 0;
              });
            }

            if (xlData[0].filter((v: any) => v).length < 4) {
              xlData.shift();
            }

            if (platform === "amazon") {
              handleAmazonFile(
                xlData.filter((v: any) => {
                  let a = v.filter((x: any) => x);
                  return a.length;
                })
              );
            } else if (platform === "shopiq") {
              readData(xlData);
            }
          }
        }
      }
    };
  };

  const handleAmazonFile = (xlData: any) => {
    xlData[0] = xlData[0].map((v: any) => {
      let small = v.toLowerCase();
      if (
        small.includes("templatetype=") ||
        small.includes("version=") ||
        small.includes("templatesignature=") ||
        small.includes("settings=")
      ) {
        return "";
      } else {
        return v.trim();
      }
    });

    let final_obj: any = { basic: {} };

    // add main keys
    xlData[0].filter((v: any) => v).forEach((x: any) => (final_obj[x] = {}));

    // change header 1 and add values in empty section
    let head_value = "basic";
    xlData[0] = xlData[0].map((v: any) => {
      if (!v) {
        return head_value;
      } else {
        head_value = v;
        return head_value;
      }
    });

    const values = xlData.slice(2);

    const final_obj_array: any = [];

    values.forEach((valueArray: any, index: any) => {
      let temp: any = JSON.parse(JSON.stringify(final_obj));

      valueArray.forEach((value: any, sub_index: any) => {
        const firstKey = xlData[0][sub_index];
        const secondKey = xlData[1][sub_index];

        try {
          if (temp[firstKey][secondKey]) {
            // means already exists
            if (Array.isArray(temp[firstKey][secondKey])) {
              temp[firstKey][secondKey].push(value);
            } else {
              temp[firstKey][secondKey] = [temp[firstKey][secondKey], value];
            }
          } else {
            // means key does not exists
            if (!temp[firstKey]) {
              temp[firstKey] = {};
            }

            temp[firstKey][secondKey] = value;
          }
        } catch (err) {
          // means key does not exists
          if (!temp[firstKey]) {
            temp[firstKey] = {};
          }

          temp[firstKey][secondKey] = value;
        }
      });

      final_obj_array.push(temp);
    });

    let bo_object_array: any = [];

    final_obj_array.forEach((v: any, i: any) => {
      // going through basic details
      const basic = v.basic; // done
      const images = v.Images; // done
      const compliance = v.Compliance;
      const dimensions = v.Dimensions; // done
      const discovery = v.Discovery; // done
      const offer = v.Offer;
      const productEnrichment = v["Product Enrichment"]; // done
      const variation = v.Variation;
      const b2b = v.b2b;

      const bo_object: any = {};

      bo_object.identifier = localStorage.getItem("identifier");

      Object.keys(basic).forEach((key: any, index: any) => {
        if (
          key.trim() === "Product ID" ||
          key.trim() === "Product ID Type" ||
          key.trim() === "Update Delete"
        ) {
          return;
        }
        if (key.includes("Item Name (aka Title)")) {
          bo_object.title = basic[key];
          return;
        }
        if (key.includes("Product Description")) {
          bo_object.description = basic[key];
          return;
        }
        if (key.includes("Brand Name")) {
          bo_object.brandName = basic[key];
          return;
        }
        if (key.includes("Manufacturer")) {
          bo_object.manufacturer = basic[key];
          return;
        }
        if (key.includes("Manufacturer Part Number")) {
          bo_object.manufacturerPartNumber = basic[key];
          return;
        }
        if (key.includes("Model Number")) {
          bo_object.modelNumber = basic[key];
          return;
        }
        if (key.includes("Product Type")) {
          bo_object.productType = basic[key];
          return;
        }
        if (key.includes("Your price")) {
          if (!bo_object.pricing) {
            bo_object.pricing = {};
          }
          bo_object.pricing.price = Number(basic[key]);
          return;
        }
        if (key.includes("Seller SKU")) {
          if (!bo_object.inventory) {
            bo_object.inventory = {};
          }
          bo_object.inventory.sku = basic[key];
          return;
        }
        if (key.includes("Quantity")) {
          if (!bo_object.inventory) {
            bo_object.inventory = {};
          }
          bo_object.inventory.currentQuantity = Number(basic[key]);
          if (Number(basic[key]) > 0) {
            bo_object.inventory.trackQuantity = true;
          }
          return;
        }

        let camel_case = toCamelCase(key);

        if (!bo_object.basic) {
          bo_object.basic = {};
        }

        bo_object.basic[camel_case] = basic[key];
      });

      Object.keys(images).forEach((key: any, index: any) => {
        if (key.includes("Main Image URL")) {
          bo_object.mediaUrl = [images[key]];
        }
        if (key.includes("Other Image URL")) {
          if (bo_object.hasOwnProperty("otherImageUrl")) {
            bo_object.otherImageUrl.push(images[key]);
          } else {
            bo_object.otherImageUrl = [images[key]];
          }
        }
        if (key.includes("Swatch Image URL")) {
          bo_object.swatchImageUrl = images[key];
        }
      });

      Object.keys(compliance).forEach((key: any, index: any) => {
        // Batteries are included
        // Battery type/size
        // Fabric Type
        // Lithium Battery Packaging
        // Lithium Battery Weight Unit Of Measure
        // Lithium content
        // Number of batteries
        // Specific Uses for product
        // Warranty Description

        if (key.includes("HSN Code")) {
        }

        if (key.includes("Country/Region Of Origin")) {
        }
      });

      Object.keys(dimensions).forEach((key: any, index: any) => {
        if (bo_object.hasOwnProperty("specifications")) {
          if (dimensions[key]) {
            bo_object.specifications.push({
              options_name: key,
              options_value: Array.isArray(dimensions[key])
                ? dimensions[key]
                : [dimensions[key]],
            });
          }
        } else {
          if (dimensions[key]) {
            bo_object.specifications = [
              {
                options_name: key,
                options_value: Array.isArray(dimensions[key])
                  ? dimensions[key]
                  : [dimensions[key]],
              },
            ];
          }
        }
      });

      Object.keys(discovery).forEach((key: any, index: any) => {
        if (bo_object.hasOwnProperty("specifications")) {
          if (discovery[key]) {
            bo_object.specifications.push({
              options_name: key,
              options_value: Array.isArray(discovery[key])
                ? discovery[key]
                : [discovery[key]],
            });
          }
        } else {
          if (discovery[key]) {
            bo_object.specifications = [
              {
                options_name: key,
                options_value: Array.isArray(discovery[key])
                  ? discovery[key]
                  : [discovery[key]],
              },
            ];
          }
        }
      });

      Object.keys(productEnrichment).forEach((key: any, index: any) => {
        if (bo_object.hasOwnProperty("productEnrichment")) {
          bo_object.productEnrichment.push({
            options_name: key,
            options_value: productEnrichment[key],
          });
        } else {
          bo_object.productEnrichment = [
            {
              options_name: key,
              options_value: productEnrichment[key],
            },
          ];
        }
      });

      bo_object_array.push(bo_object);
    });

    setData((prev: any) => [...prev, ...bo_object_array]);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentBody
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <div style={{ flex: 1 }}>
            <div className={styles.heading}>Import Products</div>
            <div className={styles.progress_container}>
              <div
                className={`${styles.progress_state} ${styles.active_progress_state}`}
              >
                <div>1</div>
                <p>Configuration</p>
              </div>
              {/* <div className={styles.progress_between}></div> */}
              {/* <div
                className={`${styles.progress_state} ${
                  (stage === "map_fields" || stage === "preview") &&
                  styles.active_progress_state
                }`}
              >
                <div>2</div>
                <p>Map Fields</p>
              </div> */}
              <div className={styles.progress_between}></div>
              <div
                className={`${styles.progress_state} ${
                  stage === "preview" && styles.active_progress_state
                }`}
              >
                <div>3</div>
                <p>Preview</p>
              </div>
            </div>
            {stage === "configuration" && (
              <ProductConfiguration
                files={files}
                setFiles={setFiles}
                getSheetsInFile={getSheetsInFile}
                encoding={encoding}
                setEncoding={setEncoding}
                setDelimiter={setDelimiter}
                delimiter={delimiter}
                tsvDelimiter={tsvDelimiter}
                setTsvDelimiter={setTsvDelimiter}
                duplicate={duplicate}
                setDuplicate={setDuplicate}
                platform={platform}
                setPlatform={setPlatform}
                pages={pages}
                setPages={setPages}
                pageFrom={pageFrom}
                setPageFrom={setPageFrom}
                pageTo={pageTo}
                setPageTo={setPageTo}
                entries={entries}
                setEntries={setEntries}
                sheets={sheets}
                customEntries={customEntries}
                setCustomEntries={setCustomEntries}
              />
            )}
            {stage === "map_fields" && (
              <ContactMapFields
                files={files}
                assignedFields={assignedFields}
                setAssignedFields={setAssignedFields}
                headers={headers}
              />
            )}
            {stage === "preview" && (
              <ContactPreview
                headers={headers}
                assignedFields={assignedFields}
                ready={ready}
                skipped={skipped}
                duplicate={duplicate}
              />
            )}
          </div>
          <div className={styles.footer_actions}>
            <button onClick={() => navigate("/contacts")}>Cancel</button>
            <div style={{ display: "flex", gap: "10px" }}>
              {stage !== "configuration" && (
                <button onClick={handlePrevious}>
                  <LeftIcon />
                  Previous
                </button>
              )}
              <button
                className={styles.blue_btn}
                style={
                  files.length === 0 && stage === "configuration"
                    ? { opacity: ".5", cursor: "not-allowed" }
                    : {}
                }
                onClick={() => {
                  if (files.length === 0 && stage === "configuration") {
                  } else handleNext();
                }}
              >
                {stage === "preview" ? "Import" : "Next"} <RightIcon />
              </button>
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

function isValidEmail(email: any) {
  // Regular expression for validating an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regular expression
  return emailRegex.test(email);
}

function toCamelCase(str: string) {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(" ") // Split by spaces
    .map(
      (
        word,
        index // Map each word
      ) =>
        index === 0 // If it's the first word, keep it lowercase
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of other words
    )
    .join(""); // Join all words together without spaces
}

const leads = [
  "new",
  "mql",
  "sql",
  "open",
  "working",
  "nurture",
  "unqualified",
  "opportunity",
  "won",
  "lost",
  "in_progress",
  "open_deal",
  "attempted_to_contact",
  "connected",
  "bad_timing",
  "unassigned",
  "complete",
];

const key_values = {
  "Age Range Description": "ageRangeDescription",
  "Closure Type": "",
  "GTIN Exemption Reason": "",
  "Product Care Instructions": "",
  "Recommended Browser Nodes": "",
};

export default ImportProducts;
