import React, { useState } from "react";
import { Search, RefreshCw, Edit, X } from "lucide-react";

const DomainManagement = () => {
  return <></>;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [newDomain, setNewDomain] = useState({
    domain: "",
    redirectTo: "",
  });

  const [domains] = useState([
    {
      name: "domain-name-example",
      isValid: true,
      assignedToMain: true,
    },
  ]);

  return (
    <div className="w-full space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-black">Domains</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-4 py-2 bg-white text-black rounded-lg hover:bg-gray-100 transition-colors"
        >
          Add
        </button>
      </div>

      <p className="text-gray-500">
        These domains are assigned to your Production Deployments. Optionally, a
        different redirection to another domain can be configured for each one.
      </p>

      <div className="relative">
        <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
        <input
          className="w-full pl-10 pr-4 py-2 bg-white border border-gray-800 rounded-lg text-white focus:outline-none focus:border-gray-700"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="bg-white border border-gray-800 rounded-lg">
        <div className="p-6">
          {domains.map((domain, index) => (
            <div key={index} className="flex items-center justify-between">
              <div className="space-y-2">
                <h3 className="text-black text-lg">{domain.name}</h3>
                <div className="flex gap-4">
                  {domain.isValid && (
                    <span className="flex items-center gap-2 text-gray-400">
                      <div className="w-4 h-4 rounded-full bg-blue-500" />
                      Valid Configuration
                    </span>
                  )}
                </div>
              </div>
              <div className="flex gap-2">
                <button className="p-2 text-gray-400 hover:bg-gray-800 rounded-lg transition-colors">
                  <RefreshCw className="h-4 w-4" />
                </button>
                <button className="p-2 text-gray-400 hover:bg-gray-800 rounded-lg transition-colors">
                  <Edit className="h-4 w-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black z-120 bg-opacity-20 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-[400px]">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-black">Add Domain</h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-400 hover:text-gray-700 transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm text-gray-400 mb-1">
                  Domain
                </label>
                <input
                  value={newDomain.domain}
                  onChange={(e) =>
                    setNewDomain((prev) => ({
                      ...prev,
                      domain: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-2 bg-gray-100 rounded-lg text-white focus:outline-none focus:border-gray-600"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-400 mb-1">
                  Redirect to
                </label>
                <input
                  value={newDomain.redirectTo}
                  onChange={(e) =>
                    setNewDomain((prev) => ({
                      ...prev,
                      redirectTo: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-2 bg-gray-100 rounded-lg text-white focus:outline-none focus:border-gray-600"
                />
              </div>
              <div className="flex justify-end gap-2 mt-6">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-gray-500 rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    // Handle save logic here
                    setIsModalOpen(false);
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DomainManagement;
