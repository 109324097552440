import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handleUpdateDataLocally = async (data: any) => {
  const localData = localStorage.getItem("MERCHENT_DATA") || "{}";
  const parsedData = JSON.parse(localData);
  const updatedData = JSON.stringify({ ...parsedData, ...data });
  localStorage.setItem("MERCHENT_DATA", updatedData);
  return true;
};

export const handleUpdateData = async (data: any) => {
  const token = localStorage.getItem("token-BO-ADMIN");
  try {
    const res: any = await axios({
      url: `${baseUrl}/admins/updateSelf`,
      method: "patch",
      headers: { Authorization: `Bearer ${token}` },
      data: { data: data },
    });
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const sendOTP = async (data: any) => {
  try {
    const token = localStorage.getItem("token-BO-ADMIN");
    handleUpdateDataLocally({ contactInfo: { mobileNumber: data.mobile } });

    // First update the contact info in the account
    if (data.mobile) {
      await axios({
        url: `${baseUrl}admins/updateSelf`,
        method: "patch",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          data: {
            mobileNumber: {
              value: data.mobile,
            },
          },
        },
      });
    } else if (data.email) {
      await axios({
        url: `${baseUrl}/admins/updateSelf`,
        method: "patch",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          data: {
            email: data.email,
          },
        },
      });
    }

    const res: any = await axios({
      url: `${baseUrl}admins/sendotp`,
      method: "post",
      data: data.mobile
        ? { phone: Number(data.mobile) }
        : { email: data.email },
    });

    // localStorage.setItem("token-BO-ADMIN", res.data.token);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const verifyOTP = async (data: any) => {
  const token = localStorage.getItem("token-BO-ADMIN");
  const newotp = data?.otp.join("");

  try {
    const res = await axios({
      url: `${baseUrl}/admins/verifyemailandsignup`,
      method: "post",
      headers: { Authorization: `${token}` },
      data: {
        receivedOtp: newotp,
        email: data.email,
      },
    });
    // localStorage.setItem("MERCHENT_TOKEN", res.data.sessionToken);
    handleUpdateDataLocally({ isOTPVerified: true });
    await handleUpdateData({ onboardingStage: "initiated" });
    return res;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getGSTDetails = async (gstNumber: string) => {
  const token = localStorage.getItem("token-BO-ADMIN");
  try {
    const res = await axios({
      url: `${baseUrl}/admins/verify-gst`,
      method: "post",
      headers: { Authorization: `Bearer ${token}` },
      data: { gstNumber },
    });
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadMedia = async ({ file }: { file: File }) => {
  const token = localStorage.getItem("token-BO-ADMIN");
  console.log(file);
  const formData = new FormData();
  formData.append("file", file);

  try {
    const res = await axios({
      url: `${baseUrl}upload`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadKycDocuments = async (documents: any[]) => {
  const token = localStorage.getItem("token-BO-ADMIN");
  const identifier = localStorage.getItem("identifier");

  const uploadPromises = documents.map(async (doc) => {
    if (!doc?.obj) return { url: doc?.url, type: doc.name };

    const formData = new FormData();
    formData.append("files", doc.obj);
    formData.append("path", identifier);

    try {
      const res = await axios({
        url: `${baseUrl}uploadBulkImages/uploadBulkImages?identifier=${identifier}`,
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        data: formData,
      });
      return { url: res.data.images[0], type: doc.name };
    } catch (error) {
      console.error("Error uploading document:", error);
      throw error;
    }
  });

  return Promise.all(uploadPromises);
};

export const saveAadharDetails = async (aadharData: any) => {
  const token = localStorage.getItem("token-BO-ADMIN");
  try {
    const res = await axios({
      url: `${baseUrl}aadhar/`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: aadharData,
    });
    return res;
  } catch (error) {
    console.error("Error saving Aadhar details:", error);
    throw error;
  }
};

export const submitKycRegistration = async (kycData: any, admin: any) => {
  const token = localStorage.getItem("token-BO-ADMIN");
  const identifier = localStorage.getItem("identifier");

  try {
    // Prepare the base KYC data
    const data = {
      firstName: kycData.firstName,
      lastName: kycData.lastName,
      email: kycData.contactInfo.email || admin?.email,
      mobileNumber: kycData.contactInfo.mobileNumber,
      businessName: admin?.businessName,
      typeOfBusiness: kycData.businessType,
      address: { ...kycData.location, country: "India" },
      PAN: kycData.panNumber.number,
      gst: kycData.gstVerification.gstNumber,
      dpiitNumber: kycData.dpiitVerification.dpiitNumber,
      kycSubmitted: "submitted",
    };
    console.log(data);

    console.log("admin:", admin);

    // Upload all documents
    const uploadPromises = kycData.documents.map(async (doc) => {
      console.log("doc", doc);
      if (!doc?.obj) return { url: doc?.url, type: doc.name };

      const formData = new FormData();
      formData.append("files", doc.obj);
      formData.append("path", identifier);

      try {
        const res = await axios({
          url: `${baseUrl}uploadBulkImages/uploadBulkImages?identifier=${identifier}`,
          method: "post",
          headers: { Authorization: `Bearer ${token}` },
          data: formData,
        });
        return { url: res.data.images[0], type: doc.name };
      } catch (error) {
        console.error("Error uploading document:", error);
        throw error;
      }
    });

    const uploadedDocs = await Promise.all(uploadPromises);
    console.log("uploadedDocs", uploadedDocs);

    // Handle Aadhaar documents if they exist
    let aadharId = null;
    if (kycData.identityProof.aadhaar) {
      const aadharDocs = {
        aadharNumber: kycData.identityProof.aadhaar.number,
        aadharFrontImage: uploadedDocs.find(
          (v) => v.type === "aadhar front document"
        )?.url,
        aadharBackImage: uploadedDocs.find(
          (v) => v.type === "aadhar back document"
        )?.url,
      };
      console.log("aadharDocs", aadharDocs);

      const aadharRes = await axios({
        url: `${baseUrl}aadhar/`,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        data: aadharDocs,
      });
      aadharId = aadharRes.data.data._id;
    }
    console.log("aadharId", aadharId);

    // Prepare final data with uploaded documents
    const finalData = {
      ...data,
      aadharId,
      legalDocuments: uploadedDocs.filter(
        (v) =>
          v.type !== "aadhar front document" &&
          v.type !== "aadhar back document"
      ),
    };
    console.log("finalData", finalData);

    // // Submit the final KYC data
    const res = await axios({
      url: `${baseUrl}admins/updateSelf`,
      method: "PATCH",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        identifier,
        data: finalData,
      },
    });

    return res;
  } catch (error) {
    console.error("Error submitting KYC:", error);
    throw error;
  }
};

export const verifyGST = async (gstNo: string) => {
  try {
    const res = await axios({
      url: `${baseUrl}admins/get-gst-details?gst=${gstNo}`,
    });
    return res;
  } catch (error) {
    console.error("Error verifying GST:", error);
    throw error;
  }
};
