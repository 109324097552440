import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../../Store/adminContext";
import styles from "../../Styles/content.module.css";

const Content = ({ children }: any) => {
  const { admin }: any = useAdminContext();
  const navigate = useNavigate();

  return (
    <div className={styles.content_main}>
      {(admin.kycSubmitted === "rejected" ||
        admin.kycSubmitted === "not submitted" ||
        admin.kycSubmitted === "draft") &&
        admin?.role !== "subadmin" && (
          <div
            className="w-full bg-red-200 text-red-600 py-2 text-primary px-4 underline cursor-pointer"
            onClick={() => navigate("/onboarding/mobile")}
          >
            Complete Your KYC, to accept orders.
          </div>
        )}
      {children}
    </div>
  );
};

export default Content;
