import React from "react";
import callApi from "../../Apis/callApi";
import axios from "axios";
import withRouter from "../../Components/withRouter";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import DynamicDataTable from "../../UI/DynamicTable";
import TableLoading from "../../Components/LoadingUi/TableLoading";

class Compliance extends React.Component<any, any> {
  state = {
    data: [],
    loading: true,
    showbtn: false,
    modal: false,
    inFocus: "",
    pageNo: 1,
    perPage: 15,
    total: 0
  };

  componentDidMount(): void {
    this.getData();
  }

  componentDidUpdate(prevProps: any): void {
 
    if (prevProps.router.location.search !== this.props.router.location.search) {
      this.getData();
    }
  }

  getData = () => {
    const search = new URLSearchParams(this.props.router.location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}compliances?identifier=` +
        localStorage.getItem("identifier") +
        `&page=${currentPage}` +
        `&limit=${currentLimit}`,
      method: "get",
    })
      .then((res) => {

        console.log('res.data compliance', res.data);
        this.setState({ 
          data: res.data.compliances, 
          loading: false,
          total: res.data.total || 0,
          pageNo: currentPage,
          perPage: currentLimit
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleAction = (val: string, id: string) => {
    if (this.props.admin?.selectedPlan) {
      if (val === "delete") {
        this.setState({ modal: true, inFocus: id });
      }

      if (val === "archive") {
        const promise = new Promise((resolve, reject) => {
          axios({
            method: "patch",
            data: { status: "draft" },
            url: `${process.env.REACT_APP_BASE_URL}compliances/${id}`,
          })
            .then((res) => {
              resolve(true);
            })
            .catch(() => {
              reject();
            });
        });

        toast
          .promise(
            promise,
            {
              loading: "Archiving...",
              success: "Archived Successfully.",
              error: "Error when archiving the entry.",
            },
            { success: { duration: 2000 }, error: { duration: 2000 } }
          )
          .then(() => {
            this.getData();
          });
      }

      if (val === "unarchive") {
        const promise = new Promise((resolve, reject) => {
          axios({
            method: "patch",
            data: { status: "live" },
            url: `${process.env.REACT_APP_BASE_URL}compliances/${id}`,
          })
            .then((res) => {
              resolve(true);
            })
            .catch(() => {
              reject();
            });
        });

        toast
          .promise(
            promise,
            {
              loading: "UnArchiving...",
              success: "UnArchived Successfully.",
              error: "Error when unarchiving the entry.",
            },
            { success: { duration: 2000 }, error: { duration: 2000 } }
          )
          .then(() => {
            this.getData();
          });
      }
    } else {
      toast.error("Please Complete Setup Steps to access this route.");
    }
  };

  checkForAddBtn = () => {
    if (localStorage.getItem("ecommerce") === "true") {
      if (this.state.data.length === 5) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "returnPolicy" ||
          val.typeName === "cancellationPolicy" ||
          val.typeName === "dataPolicy" ||
          val.typeName === "about" ||
          val.typeName === "buy-back"
        ) {
          return true;
        }
        return false;
      }).length === 5
        ? false
        : true;
    } else if (localStorage.getItem("service") === "true") {
      if (this.state.data.length === 3) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "cancellationPolicy" ||
          val.typeName === "about"
        ) {
          return true;
        }
        return false;
      }).length === 3
        ? false
        : true;
    } else if (localStorage.getItem("blog") === "true") {
      if (this.state.data.length === 3) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "refund" ||
          val.typeName === "about"
        ) {
          return true;
        }

        return false;
      }).length === 3
        ? false
        : true;
    }
    return false;
  };

  delete = () => {
    this.setState({ modal: false });
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_BASE_URL}compliances/${this.state.inFocus}`,
      })
        .then((res) => {
          this.setState({ inFocus: "" });
          resolve(true);
        })
        .catch((err) => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        this.getData();
      });
  };

  prepareTableColumns = () => {
    const baseColumns = [
      { accessorKey: "title", header: "Title" },
      { accessorKey: "typeName", header: "Type" },
      { accessorKey: "createdAt", header: "Created At" },
      { accessorKey: "lastUpdate", header: "Last Updated" },
      { accessorKey: "status", header: "Status" }
    ];
    
    return baseColumns;
  };

  prepareActionsColumns = () => {
    const admin = this.props.admin;
    const actions = [];
    
    if (admin.role !== "subadmin" || (admin.role === "subadmin" && admin.compliance_access.delete)) {
      actions.push({ 
        name: "Delete", 
        action: (id: string) => this.handleAction("delete", id) 
      });
    }
    
    if (admin.role !== "subadmin" || (admin.role === "subadmin" && admin.compliance_access.write)) {
      actions.push({ 
        name: "Archive", 
        action: (id: string) => this.handleAction("archive", id) 
      });
      actions.push({ 
        name: "Unarchive", 
        action: (id: string) => this.handleAction("unarchive", id) 
      });
    }
    
    return actions;
  };

  render(): React.ReactNode {
    const columns = this.prepareTableColumns();
    const actionsColumns = this.prepareActionsColumns();
    const isUserAllowedToAdd = isUserAllowedToEdit(this.props.admin) ? this.checkForAddBtn() : false;

    return (
      <>
        <Modal
          title={"Delete"}
          description={
            "Do you really want to delete this record? This process cannot be undone."
          }
          state={this.state.modal}
          onBtn1Click={() => this.setState({ modal: false, inFocus: "" })}
          onBtn2Click={this.delete}
        />
        <Content>
          <ContentHead
            title="Compliance"
            showBtn1={isUserAllowedToAdd}
            btn1Name="btn1"
            iconType1="plus"
            btn1Text="New"
            btn1type="filled"
            onClickHandler={() => {
              if (this.props.admin?.selectedPlan) {
                this.props.router.navigate("/compliance/add");
              } else {
                toast.error("Please Complete Setup Steps to access this route.");
              }
            }}
          />
          <ContentBody style={{ padding: 0, position: "relative" }}>
            {this.state.loading ? (
              <TableLoading head={complianceHead(this.props.admin)} />
            ) : (
              <DynamicDataTable
                columns={columns}
                data={this.state.data}
                actionsColumns={actionsColumns}
                title="Compliance"
                onRowClick={(val: any) => {
                  if (this.props.admin?.selectedPlan) {
                    this.props.router.navigate(`/compliance/update/${val._id}`);
                  } else {
                    toast.error("Please Complete Setup Steps to access this route.");
                  }
                }}
                onPaginationChange={(page, limit) => {
                  // Update URL with new page number and limit
                  const searchParams = new URLSearchParams(this.props.router.location.search);
                  searchParams.set('page', page.toString());
                  searchParams.set('limit', limit.toString());
                  this.props.router.navigate(`/compliance?${searchParams.toString()}`);
                }}
                totalRows={this.state.total}
                initialPageSize={this.state.perPage}
                initialPageIndex={this.state.pageNo}
              />
            )}
          </ContentBody>
        </Content>
      </>
    );
  }
}

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.compliance_access.write) return true;

  return false;
};

const complianceHead = (admin: any) => {
  const base = [
    { title: "S.No." },
    { title: "Title" },
    { title: "Type" },
    { title: "Created At" },
    { title: "Last Updated" },
    { title: "Status" },
  ];

  if (admin.role !== "subadmin") return [...base, { title: "" }];

  if (admin.role === "subadmin") {
    if (admin.compliance_access.delete) {
      return [...base, { title: "" }];
    }
    if (admin.compliance_access.write) {
      return [...base];
    }
  }

  return base;
};

export default withRouter(Compliance);