"use client";

import React, {useState } from "react";

import RootLayout from "../RootLayout";
import { useNavigate } from "react-router-dom";
import logo from '../../../Assets/images/logo-rounded-bg.png';
import { sendOTP } from "../../../Apis/kycApi";
import { toast } from "react-hot-toast";
import { useKYC } from "../../../Store/kycContext";
import { useAdminContext } from "../../../Store/adminContext";
import CustomButton from "../../../Components/CustomButton";

const Mobile = () => {
//   const [mobile, setMobile] = useState<string>("");
//   const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();
  const {admin}:any = useAdminContext();
  const {kycData,updateKYCData}:any = useKYC();
  const [loading, setLoading] = useState(false);

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
   
    if (/^\d*$/.test(value) && value.length <= 10) {
      updateKYCData({contactInfo:{mobileNumber:value}});
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateKYCData({contactInfo:{email:e.target.value}});
  }

  const handleSubmit = async (value: string) => {
    setLoading(true);
if (admin?.email) {
  if (!value || value.length !== 10) {
    toast.error("Please enter a valid 10-digit mobile number");
    return;
  }
  
  try {
    const data = await sendOTP({mobile: value});
    console.log('OTP sent:', data);
    await updateKYCData({contactInfo:{mobileNumber:value}});
    toast.success("OTP sent successfully");
   
      navigate("/onboarding/verify-otp");
   
  } catch (error) {
    console.error("Error:", error);
    toast.error("Something went wrong");
  } finally {
    setLoading(false);
  }
} else {
  try {
      if (!value || !value.includes('@')) {
        toast.error("Please enter a valid email address");
        return;
      }
      const data=await sendOTP({email: value})
      
        setLoading(false);
          console.log('OTP sent:', data);
          updateKYCData({contactInfo:{email:value}});
      
            navigate("/onboarding/verify-otp");
         
          } catch (error) {
            console.error("Error:", error);
            toast.error("Something went wrong");
          } finally {
            setLoading(false);
          }
    
     
    }
  }

  return (
    <RootLayout>
      <div className="flex flex-col gap-8 p-8 justify-center items-center">
        <span className="grid gap-[2rem] justify-center items-center">
          <img
            src={logo}
            alt="ShopIQ Logo"
            className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg"
          />
          
        </span>
        <span>
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">
            {admin?.email ? "What's your Mobile Number?" : "What's your Email Address?"}
          </h2>
          {admin?.email ? (
            <div
              className={`flex items-center rounded-md px-3 py-2 border border-[#DDD] h-[55px]`}
            >
              <span className="text-lg text-gray-500">+91</span>
              <input
                type="text"
                name="number"
                placeholder="Enter mobile number"
                value={kycData?.contactInfo?.mobileNumber}
                onChange={handleMobileChange}
                className="h-full w-full outline-none pl-5"
              />
            </div>
          ) : (
            <div
              className={`flex items-center rounded-md px-3 py-2 border border-[#DDD] h-[55px]`}
            >
              <input
                type="email"
                name="email"
                placeholder="Enter email address"
                value={kycData?.contactInfo?.email}
                onChange={handleEmailChange}
                className="h-full w-full outline-none"
              />
            </div>
          )}
        </span>

        <CustomButton
          onClick={() => handleSubmit(admin?.email ? kycData?.contactInfo?.mobileNumber : kycData?.contactInfo?.email)}
          text={loading ? "Submitting..." : "Submit"}
          variant="filled"
          size="md"
          fullWidth={true}
          disabled={loading || (admin?.email ? !kycData?.contactInfo?.mobileNumber : !kycData?.contactInfo?.email)}
        />

        
      </div>
    </RootLayout>
  );
};

export default Mobile; 