import React, { useState } from "react";
import { LineChart } from "../../Charts/LineChart";
import TopCard from "./TopCard";
import styles from "./orders.module.css";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { useAdminContext } from "../../../Store/adminContext";
import { isUserAllowed } from "../../../Utils/checkPermissions/checkForKycAndPlan";
import toast from "react-hot-toast";
import CustomButton from "../../CustomButton";
import { useNavigate } from "react-router-dom";

type Props = {
  order: any;
  startDate: string;
  endDate: string;
  setStartDate: any;
  setEndDate: any;
};

const Orders = (props: Props) => {
  const orders = ["All", "COD", "Online", "Cancelled"];
  const [orderType, setOrderType] = useState("All");
  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);
  const navigate = useNavigate();

  console.log(
    "orders ::: ",
    Object.keys(props.order).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    )
  );

  const isEmpty = Object.keys(props.order).every((arr) => {
    return Array.isArray(props.order[arr]) && props.order[arr].length === 0;
  });

  if (isEmpty)
    return (
      <>
        <h3>Orders</h3>
        <div
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "gray",
          }}
        >
          <span>No Data Found</span>
        </div>
      </>
    );

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Orders</h3>
        <div className={styles.date}>
          <input
            type="date"
            value={props.startDate?.split("T")[0]}
            onChange={(e: any) => {
              props.setStartDate(e.target.value);
            }}
            style={{ fontSize: ".9rem" }}
          />
          <input
            type="date"
            value={props.endDate?.split("T")[0]}
            onChange={(e: any) => {
              props.setEndDate(e.target.value);
            }}
            style={{ fontSize: ".9rem" }}
          />
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.header}></div>
        <div className={styles.topCardContainer}>
          <TopCard
            orders={orders}
            orderType={orderType}
            setOrderType={setOrderType}
          />
        </div>
        <LineChart
          orderType={orderType}
          dataset={props.order}
          startDate={props.startDate}
          endDate={props.endDate}
        />
        <div className={styles.btnContainer}>
          {isAllowed ? (
            // <Link to={"/analytics/orders"} className={styles.btn}>
            <CustomButton
              text="See More"
              variant="link"
              icon="arrow-right"
              iconPosition="right"
              onClick={() => {
                navigate("/analytics/orders");
              }}
            />
          ) : (
            // </Link>
            <p
              // className={styles.btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                toast.error(
                  "Please Complete Setup Steps to access this route."
                );
              }}
            >
              <CustomButton
                text="See More"
                variant="link"
                icon="arrow-right"
                iconPosition="right"
              />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Orders;
