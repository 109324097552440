import React, { useEffect, useState } from "react";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import { useNavigate, useParams } from "react-router-dom";
import ContentBody from "../../../Components/Content/ContentBody";
import InputField from "../../../Components/InputField";
import ToggleSwitch from "../../../Components/ToggleSwitch";

import styles from "./addUpdateContent.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import callApi, { uploadBulkImages } from "../../../Apis/callApi";
import { getFileName } from "../../../Utils/getFileName";
import UploadImage, { folder_name } from "../../../Utils/UploadImage";
import toast from "react-hot-toast";
import axios from "axios";
import { useAdminContext } from "../../../Store/adminContext";
import heic2any from "heic2any";
import Info from "../../../Components/info";
import ImageFolder from "../../../Components/ImageFolder";
import TextEditor from "../../../Components/TextEditor";

const AddUpdateBanner1 = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg]: any = useState("");
  const [mobImg, setMobImg]: any = useState("");
  const [onClickUrl, setOnClickUrl] = useState("");
  const [isButton, setIsButton] = useState(false);
  const [buttonValue, setButtonValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [hero, setHero]: any = useState([]);

  const [contentId, setContentId] = useState("");

  useEffect(() => {
    getAllContent();
  }, []);

  const getAllContent = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}contents?identifier=` +
        localStorage.getItem("identifier"),
      method: "GET",
    })
      .then((res: any) => {
        setHero((pre: any) => {
          return [...res.data.contents[0].banner1];
        });

        if (params.id) {
          setContentId(res.data.contents[0]._id);
          let temp = res.data.contents[0].banner1.filter(
            (val: any) => val._id === params.id
          );

          setTitle(temp[0].title);
          setOnClickUrl(temp[0].onClickUrl);
          setIsButton(temp[0].isButton);
          setButtonValue(temp[0].buttonValue);
          setImg(temp[0].mediaUrl);
          setMobImg(temp[0].mobMediaUrl);
          setDescription(temp[0].description);
        }
      })
      .catch((err: any) => {});
  };

  const handleClick = (e: any) => {
    switch (e) {
      case "Save":
        prepareData();
        break;
      case "Cancel":
        navigate("/content");
    }
  };

  const prepareData = async () => {
    setLoading(true);
    if (params.id) {
      let index = 0;

      for (let i = 0; i < hero.length; i++) {
        if (params.id === hero[i]._id) {
          index = i;
          break;
        }
      }

      let temp = hero;

      temp[index].title = title;
      temp[index].onClickUrl = onClickUrl;
      temp[index].isButton = isButton;
      temp[index].buttonValue = buttonValue;
      temp[index].mediaUrl = img;
      temp[index].mobMediaUrl = mobImg;
      temp[index].description = description;
      delete temp[index]._id;
      delete temp[index].id;
      updateContent(temp);
    } else {
      const hero_data = {
        title,
        mediaUrl: img,
        mobMediaUrl: mobImg,
        onClickUrl,
        priority: hero.length === 0 ? 0 : hero[hero.length - 1].priority + 1,
        isButton,
        description,
        buttonValue,
      };

      const data = {
        identifier: localStorage.getItem("identifier"),
        banner1: [...hero, { ...hero_data }],
      };

      createContent(data);
    }
  };

  const createContent = async (data: any) => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}contents`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setLoading(false);
        navigate("/content");
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error("Something went wrong while creating content.");
      });
  };

  const updateContent = async (data: any) => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}contents/` + contentId,
      method: "PATCH",
      data: { identifier: localStorage.getItem("identifier"), banner1: data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setLoading(false);
        navigate("/content");
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error("Something went wrong while updating content.");
      });
  };

  return (
    <>
      {loading && (
        <div className="loading">
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={
            params?.id ? "Update Banner1 Carousel" : "Add Banner1 Carousel"
          }
          showBtn1={isUserAllowedToEdit(admin)}
          showBtn2={true}
          btn1Name="btn1"
          btn2Name="btn2"
          btn2Text={"Cancel"}
          btn1Text={"Save"}
          btn1type="filled"
          btn2type="outlined_del"
          onClickHandler={handleClick}
        />
        <ContentBody>
          <div className="card grid">
            <InputField
              type="text"
              isLabel={true}
              lable={"Title"}
              value={title}
              onChange={(e: any) => {
                setTitle(e.target.value);
              }}
            />
            <div className="grid_3">
              <InputField
                type="text"
                isLabel={true}
                lable={"On Click Url"}
                value={onClickUrl}
                onChange={(e: any) => {
                  setOnClickUrl(e.target.value);
                }}
              />
              <div
                style={{
                  height: "60px",
                  border: "1px dashed lightgrey",
                  borderRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingInline: "20px",
                  marginTop: "28px",
                }}
              >
                <p>Is Button</p>
                <ToggleSwitch
                  value={isButton}
                  onClick={() => {
                    setIsButton((prev) => !prev);
                  }}
                />
              </div>
              {isButton && (
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Button Value"}
                  value={buttonValue}
                  onChange={(e: any) => {
                    setButtonValue(e.target.value);
                  }}
                />
              )}
            </div>

            <div className="grid" style={{ paddingTop: "20px" }}>
              <TextEditor
                content={description}
                handleChange={(val: any) => {
                  setDescription(val);
                }}
                placeholder="Add Description"
              />
            </div>

            <div className={styles.img_container}>
              <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                Upload Desktop Media{" "}
                <Info
                  text="Recommended Size is 1920px x 1080px"
                  containerStyle={{ height: "1.1rem", width: "1.1rem" }}
                />
              </p>
              <ImageFolder
                onSave={() => {}}
                selectMultiple={false}
                alreadySelected={[]}
                media={!!img ? [img] : []}
                numberOfImagesPerRow={3}
                updateMedia={(value: any) => {
                  setImg(value[0]);
                }}
                text=""
              />
            </div>

            <div className={styles.img_container}>
              <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                Upload Mobile Media
                <Info
                  text="Recommended Size is 1080px x 1350px"
                  containerStyle={{ height: "1.1rem", width: "1.1rem" }}
                />
              </p>
              <ImageFolder
                onSave={() => {}}
                selectMultiple={false}
                alreadySelected={[]}
                media={!!mobImg ? [mobImg] : []}
                numberOfImagesPerRow={3}
                updateMedia={(value: any) => {
                  setMobImg(value[0]);
                }}
              />
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.content_access.write) return true;

  return false;
};

export default AddUpdateBanner1;
