import React, { useRef, useState, useEffect } from 'react';
import RootLayout from '../RootLayout';
import logo from '../../../Assets/images/logo-rounded-bg.png';

import { verifyOTP } from '../../../Apis/kycApi';
import CustomButton from '../../../Components/CustomButton';
import { useKYC } from '../../../Store/kycContext';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const VerifyOTP: React.FC = () => {
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [number, setNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { kycData } = useKYC();
  const localData = localStorage.getItem("MERCHENT_DATA") || "{}";
  const parsedData = JSON.parse(localData);
  const navigate = useNavigate();
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  const handleChangeOTP = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleVerifyOTP = () => {
    setIsLoading(true);
    verifyOTP({otp: otp, number: number})
    .then((data:any)=>{ 
        console.log('OTP verified:', data);
        navigate("/onboarding/name");
    })
    .catch((error:any)=>{
      toast.error("Something went wrong");
      console.log(error)
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {

  
      setNumber(kycData?.contactInfo?.mobileNumber || "");
    
  }, [kycData]);

  return (
    <RootLayout>
      <div className="flex flex-col gap-8 p-8 justify-center items-center">
        <span className="grid gap-[2rem]">
          <img src={logo} alt="ShopIQ Logo" className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg" />
        </span>
        
        <div className="flex flex-col gap-6">
          <span>
            
            <h2 className="text-2xl md:text-3xl font-semibold mb-6 line-clamp-3 overflow-hidden leading-10 text-center">
              Login using the OTP sent to {kycData?.contactInfo?.mobileNumber || kycData?.contactInfo?.email|| "your email or mobile number"}
            </h2>
            <div className="flex items-between gap-5 rounded-md px-3 py-2 h-[55px] items-center justify-center">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onChange={(e) => handleChangeOTP(e, index)}
                  ref={(el) => (inputsRef.current[index] = el)}
                  className="w-[35px] h-[35px] md:w-[40px] md:h-[40px] text-[20px] outline-none border text-center"
                />
              ))}
            </div>
       
          </span>
          
          <CustomButton
            onClick={handleVerifyOTP}
            text={isLoading ? "Verifying..." : "Verify OTP"}
            variant="filled"
            size="md"
            fullWidth={true}
            disabled={otp.some(digit => digit === "") || isLoading}
          />
         
           
        </div>
      </div>
    </RootLayout>
  );
};

export default VerifyOTP;

