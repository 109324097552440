import React, { useState } from "react";
import { LineChart } from "../../Charts/LineChart";
// import TopCard from "./TopCard";
import styles from "./newOrder.module.css";
import { LineChart2 } from "../../Charts/LineChart/LineChart2";
import TopCard from "../Orders/TopCard";

type Props = {
  order: any;
  startDate: string;
  endDate: string;
  setStartDate: any;
  setEndDate: any;
};

const NewOrders = (props: Props) => {
  const orders = ["all", "COD", "online", "cancelled"];
  const [orderType, setOrderType] = useState("all");

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.topCardContainer}>
          <TopCard
            orders={orders}
            orderType={orderType}
            setOrderType={setOrderType}
          />
        </div>
        <div className={styles.date}>
          <input
            type="date"
            value={props.startDate?.split("T")[0]}
            onChange={(e: any) => {
              props.setStartDate(e.target.value);
            }}
          />
          <input
            type="date"
            value={props.endDate?.split("T")[0]}
            onChange={(e: any) => {
              props.setEndDate(e.target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.chartArea}>
        <LineChart2
          orderType={orderType}
          dataset={props.order}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </div>
    </div>
  );
};

export default NewOrders;
