import axios from "axios";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { generatePermissions } from "../Utils/generatePermissions";

const AdminContext = createContext({});

const AdminContextProvider = ({ children }: { children: ReactNode }) => {
  const [admin, setAdmin]: any = useState(null);
  const [permissions, setPermissions]: any = useState({});
  const [loading, setLoading] = useState(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) localStorage.setItem("token-BO-ADMIN", token);

    if (localStorage.getItem("token-BO-ADMIN") || token) {
      getAdminData(token);
    } else {
      setLoading(false);
    }

    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "visible" &&
        (localStorage.getItem("token-BO-ADMIN") || token)
      ) {
        startLogoutCheck();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const getAdminData = (token = "") => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/getAdminByToken`,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          token || localStorage.getItem("token-BO-ADMIN")
        }`,
      },
    })
      .then((res: any) => {
        
        setAdmin(res.data.data);
        setPermissions(generatePermissions(res.data.data));
        setLoading(false);
        startLogoutCheck();
      })
      .catch((err: any) => {
        console.log("ERrrrr ::: ", err);
        toast.error("Session Expired, Please Login Again");
        localStorage.clear();
        sessionStorage.clear();
        setLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 3000); // Redirect after 3 seconds
      });
  };

  const checkingTime = () => {
    const tokenExpirationTime = localStorage.getItem("login_log");

    if (tokenExpirationTime) {
      const remainingTime = calculateRemainingTime(tokenExpirationTime);

      let total_time = 1440;

      let diff = total_time - remainingTime;

      if (
        diff < 30 &&
        diff >= 25 &&
        !JSON.parse(localStorage.getItem("30_25"))
      ) {
        toast.error(
          `Your session will expire in ${diff} minutes. Please save your work to avoid losing progress.`
        );
        localStorage.setItem("30_25", JSON.stringify(true));
      }

      if (
        diff < 20 &&
        diff >= 15 &&
        !JSON.parse(localStorage.getItem("20_15"))
      ) {
        toast.error(
          `Your session will expire in ${diff} minutes. Please save your work to avoid losing progress.`
        );
        localStorage.setItem("20_15", JSON.stringify(true));
      }

      if (diff < 10 && diff >= 0 && !JSON.parse(localStorage.getItem("10_0"))) {
        toast.error(
          `Your session will expire in ${diff} minutes. Please save your work to avoid losing progress.`
        );
        localStorage.setItem("10_0", JSON.stringify(true));
      }

      if (diff <= 0) {
        toast.error("Session Expired, Please Login Again");
        localStorage.clear();
        sessionStorage.clear();
        setLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 3000); // Redirect after 3 seconds
      }
    } else {
      // toast.error("Session Expired, Please Login Again");
      // localStorage.clear();
      // sessionStorage.clear();
      // setLoading(false);
      // setTimeout(() => {
      //   window.location.href = "/";
      // }, 3000); // Redirect after 3 seconds
    }
  };

  const startLogoutCheck = () => {
    // Clear any existing interval to prevent duplicates
    if (intervalRef.current) clearInterval(intervalRef.current);

    // Start a new interval to check every 5 minutes
    checkingTime();
    intervalRef.current = setInterval(checkingTime, 5 * 60 * 1000); // 5 minutes
  };

  const calculateRemainingTime = (expirationTime: string) => {
    try {
      const currentTime = new Date().getTime();
      const expiration = new Date(expirationTime).getTime();
      return Math.floor((currentTime - expiration) / (1000 * 60)); // Remaining time in minutes
    } catch (error) {
      console.error("Invalid expiration time:", expirationTime);
      toast.error(
        "Invalid expiration time, Please login again to avoid this issue."
      );
      return -1; // Return negative time if parsing fails
    }
  };

  const getInitials = () => {
    const fn = admin?.firstName || "";
    const ln = admin?.lastName || "";

    let initials = "";

    if (fn) {
      initials += fn?.split("").splice(0, 1);
    }

    if (ln) {
      initials += ln?.split("").splice(0, 1);
    } else {
      initials += fn?.split("").splice(2, 1);
    }

    if (initials) {
      if (initials?.length === 1) {
        initials += "b";
      }
    } else {
      initials = "UN";
    }

    return initials || "UN";
  };

  const updateAdmin = (data: any) => {
    return axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/updateSelf`,
      method: "PATCH",
      data: { identifier: localStorage.getItem("identifier"), data: data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    });
  };

  const value = {
    admin,
    permissions,
    getInitials,
    getAdminData,
    updateAdmin,
  };

  if (loading)
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="loader"></span>
      </div>
    );

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};

const useAdminContext = () => {
  return useContext(AdminContext);
};

export { AdminContextProvider, useAdminContext };
