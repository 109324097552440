import React, { useEffect, useState, useMemo } from "react";
import ContentHead from "../../Components/Content/ContentHead";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import DynamicDataTable from "../../UI/DynamicTable";

import styles from "./content.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "../../Components/Modal";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import { useAdminContext } from "../../Store/adminContext";
import ContentOuterHead from "../../Components/Content/contentOuterHead";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import { hasPermission } from "../../Utils/checkPermissions/planPermissions";

const ContentPage = () => {
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  const [data, setData] = useState([]);
  const [on, setOn] = useState("hero");

  const [loading, setLoading] = useState(false);

  const [hero, setHero]: any = useState([]);
  const [banner1, setBanner1]: any = useState([]);
  const [banner2, setBanner2]: any = useState([]);

  const [modal, setModal]: any = useState(false);
  const [inFocus, setInFocus]: any = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }contents/?identifier=${localStorage.getItem("identifier")}`,
      method: "GET",
    })
      .then((res: any) => {
        setLoading(false);

        setHero(
          [...res.data.contents[0]?.hero].sort(
            (a, b) => a.priority - b.priority
          )
        );
        setBanner1([...res.data.contents[0]?.banner1]);
        setBanner2([...res.data.contents[0]?.banner2]);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const handleHeroSwap = (draggedRowIndex: number, targetRowIndex: number) => {
    let temp: any = [...hero];
    
    // Get the items being swapped
    const draggedItem = temp[draggedRowIndex];
    const targetItem = temp[targetRowIndex];
    
    // Get the priorities
    const priority1 = draggedItem.priority;
    const priority2 = targetItem.priority;

    if (priority1 > priority2) {
      // Moved from bottom to top
      draggedItem.priority = priority2;
      for (let i = 0; i < temp.length; i++) {
        if (i !== draggedRowIndex && temp[i].priority >= priority2 && temp[i].priority < priority1) {
          temp[i].priority += 1;
        }
      }
    } else if (priority2 > priority1) {
      // Moved from top to bottom
      draggedItem.priority = priority2;
      for (let i = 0; i < temp.length; i++) {
        if (i !== draggedRowIndex && temp[i].priority <= priority2 && temp[i].priority > priority1) {
          temp[i].priority -= 1;
        }
      }
    }

    setHero(temp.sort((a: any, b: any) => a.priority - b.priority));
    updateHeroSwap(temp.sort((a: any, b: any) => a.priority - b.priority));
  };

  const updateHeroSwap = (val: any) => {
    let data = {
      identifier: localStorage.getItem("identifier"),
      hero: [...val],
    };

    setLoading(true);

    axios({
      url: `${process.env.REACT_APP_BASE_URL}contents`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        toast.success("Priority updated successfully.");

        getData();
      })
      .catch((err: any) => {
        toast.error("Something went wrong while updating priority.");
        getData();
      });
  };

  const handleBanner1Swap = (draggedRowIndex: number, targetRowIndex: number) => {
    let temp: any = [...banner1];
    
    // Get the items being swapped
    const draggedItem = temp[draggedRowIndex];
    const targetItem = temp[targetRowIndex];
    
    // Get the priorities
    const priority1 = draggedItem.priority;
    const priority2 = targetItem.priority;

    if (priority1 > priority2) {
      // Moved from bottom to top
      draggedItem.priority = priority2;
      for (let i = 0; i < temp.length; i++) {
        if (i !== draggedRowIndex && temp[i].priority >= priority2 && temp[i].priority < priority1) {
          temp[i].priority += 1;
        }
      }
    } else if (priority2 > priority1) {
      // Moved from top to bottom
      draggedItem.priority = priority2;
      for (let i = 0; i < temp.length; i++) {
        if (i !== draggedRowIndex && temp[i].priority <= priority2 && temp[i].priority > priority1) {
          temp[i].priority -= 1;
        }
      }
    }

    setBanner1(temp.sort((a: any, b: any) => a.priority - b.priority));
    updateBanner1Swap(temp.sort((a: any, b: any) => a.priority - b.priority));
  };

  const updateBanner1Swap = (val: any) => {
    let data = {
      identifier: localStorage.getItem("identifier"),
      banner1: [...val],
    };

    setLoading(true);

    axios({
      url: `${process.env.REACT_APP_BASE_URL}contents`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        toast.success("Priority updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error("Something went wrong while updating priority.");

        getData();
      });
  };

  const handleBanner2Swap = (draggedRowIndex: number, targetRowIndex: number) => {
    let temp: any = [...banner2];
    
    // Get the items being swapped
    const draggedItem = temp[draggedRowIndex];
    const targetItem = temp[targetRowIndex];
    
    // Get the priorities
    const priority1 = draggedItem.priority;
    const priority2 = targetItem.priority;

    if (priority1 > priority2) {
      // Moved from bottom to top
      draggedItem.priority = priority2;
      for (let i = 0; i < temp.length; i++) {
        if (i !== draggedRowIndex && temp[i].priority >= priority2 && temp[i].priority < priority1) {
          temp[i].priority += 1;
        }
      }
    } else if (priority2 > priority1) {
      // Moved from top to bottom
      draggedItem.priority = priority2;
      for (let i = 0; i < temp.length; i++) {
        if (i !== draggedRowIndex && temp[i].priority <= priority2 && temp[i].priority > priority1) {
          temp[i].priority -= 1;
        }
      }
    }

    setBanner2(temp.sort((a: any, b: any) => a.priority - b.priority));
    updateBanner2Swap(temp.sort((a: any, b: any) => a.priority - b.priority));
  };

  const updateBanner2Swap = (val: any) => {
    let data = {
      identifier: localStorage.getItem("identifier"),
      banner2: [...val],
    };

    setLoading(true);

    axios({
      url: `${process.env.REACT_APP_BASE_URL}contents`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        toast.success("Priority updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error("Something went wrong while updating priority.");

        getData();
      });
  };

  const handleDelete = () => {
    setModal(false);
    if (on === "hero") {
      let temp = [...hero];
      let l_hero = temp.filter((val) => val._id !== inFocus);

      const promise = new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}contents`,
          method: "POST",
          data: {
            identifier: localStorage.getItem("identifier"),
            hero: l_hero,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            setInFocus("");
            resolve(true);
          })
          .catch((err: any) => {
            reject();
          });
      });

      toast
        .promise(promise, {
          loading: "Deleting....",
          success: "Deleted Successfully",
          error: "Error when deleting the entry.",
        })
        .then(() => {
          getData();
        });
    }

    if (on === "b1") {
      let temp = [...banner1];
      let l_banner1 = temp.filter((val) => val._id !== inFocus);

      const promise = new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}contents`,
          method: "POST",
          data: {
            identifier: localStorage.getItem("identifier"),
            banner1: l_banner1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            setInFocus("");
            resolve(true);
          })
          .catch((err: any) => {
            reject();
          });
      });

      toast
        .promise(promise, {
          loading: "Deleting....",
          success: "Deleted Successfully",
          error: "Error when deleting the entry.",
        })
        .then(() => {
          getData();
        });
    }

    if (on === "b2") {
      let temp = [...banner2];
      let l_banner2 = temp.filter((val) => val._id !== inFocus);

      const promise = new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}contents`,
          method: "POST",
          data: {
            identifier: localStorage.getItem("identifier"),
            banner2: l_banner2,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            setInFocus("");
            resolve(true);
          })
          .catch((err: any) => {
            reject();
          });
      });

      toast
        .promise(promise, {
          loading: "Deleting....",
          success: "Deleted Successfully",
          error: "Error when deleting the entry.",
        })
        .then(() => {
          getData();
        });
    }
  };

  // Define the table columns for each section
  const heroColumns = useMemo(() => [
    {
      accessorKey: "title",
      header: "Title",
      size: 200,
    },
    {
      accessorKey: "clickCount",
      header: "Click Count",
      size: 120,
    }
  ], []);

  // Define actions based on user role and permissions
  const getActionsColumns = () => {
    const actions = [];
    
    if (isUserAllowedToEdit(admin)) {
      actions.push({
        name: "Edit",
        action: (id: string) => {
          if (isAllowed) {
            if (on === "hero") {
              navigate("/content/update/" + id);
            } else if (on === "b1") {
              navigate("/content/banner1/update/" + id);
            } else if (on === "b2") {
              navigate("/content/banner2/update/" + id);
            }
          } else {
            toast.error("Please Complete Setup Steps to access this route.");
          }
        }
      });
    }
    
    if (admin.role !== "subadmin" || (admin.role === "subadmin" && admin.content_access.delete)) {
      actions.push({
        name: "Delete",
        action: (id: string) => {
          if (isAllowed) {
            setModal(true);
            setInFocus(id);
          } else {
            toast.error("Please Complete Setup Steps to access this route.");
          }
        }
      });
    }
    
    return actions;
  };

  // Get current data based on active tab
  const getCurrentData = () => {
    if (on === "hero") return hero;
    if (on === "b1") return banner1;
    if (on === "b2") return banner2;
    return [];
  };

  // Get current handler based on active tab
  const getCurrentSwapHandler = () => {
    if (on === "hero") return handleHeroSwap;
    if (on === "b1") return handleBanner1Swap;
    if (on === "b2") return handleBanner2Swap;
    return () => {};
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleDelete}
        state={modal}
      />
      <Content>
        <ContentHead title={"Content"} />
        <ContentBody>
          <div className={styles.tabs}>
            <div
              className={on === "hero" ? styles.active : ""}
              onClick={() => setOn("hero")}
            >
              Hero Carousel
            </div>
            <div
              className={on === "b1" ? styles.active : ""}
              onClick={() => setOn("b1")}
            >
              Banner 1
            </div>
            {hasPermission(admin?.selectedPlan, "banner2") && (
              <div
                className={on === "b2" ? styles.active : ""}
                onClick={() => setOn("b2")}
              >
                Banner 2
              </div>
            )}
            {isUserAllowedToEdit(admin) && (
              <span
                onClick={() => {
                  if (isAllowed) {
                    if (on === "hero") {
                      navigate("/content/add");
                    } else if (on === "b1") {
                      navigate("/content/banner1/add");
                    } else if (on === "b2") {
                      navigate("/content/banner2/add");
                    }
                  } else {
                    toast.error(
                      "Please Complete Setup Steps to access this route."
                    );
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                + New
              </span>
            )}
          </div>
          
          <div className="card">
            <h3 className={styles.heading}>
              {on === "hero" ? "Hero Carousel" : on === "b1" ? "Banner 1" : "Banner 2"}
            </h3>
            <div style={{ paddingTop: "20px" }}>
              {loading ? (
                <TableLoading head={["S.No.", "Title", "Click Count", ""]} />
              ) : (
                <DynamicDataTable
                  columns={heroColumns}
                  data={getCurrentData()}
                  enableRowReorder={true}
                  onReorder={getCurrentSwapHandler()}
                  actionsColumns={getActionsColumns()}
                  title={on === "hero" ? "Hero Carousel" : on === "b1" ? "Banner 1" : "Banner 2"}
                />
              )}
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.content_access.write) return true;

  return false;
};

export default ContentPage;