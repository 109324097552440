import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../Styles/table.module.css";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { CiFilter } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import langObj from "../../Utils/lang";
import { useLangContext } from "../../Store/langContext";

type Props = {
  head: any;
  filtervalue?: any;
  onHeadClick?: any;
  showIconAt?: any;
  icon?: any;
};

const TableHead = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setsearchParams] = useSearchParams();
  const [dropdownVisibility, setDropdownVisibility]: any = useState({});
  const { lang }: any = useLangContext();

  const [selectedFilter, setSelectedFilter]: any = useState({
    Category: [],
    Collection: [],
  });

  useEffect(() => {
    const restoredData = RestoreFilterData();

    setSelectedFilter({ ...restoredData });
  }, []);

  const searchPar: any = new URLSearchParams(location.search);

  const RestoreFilterData = () => {
    const paramsObject: any = {};
    for (const [key, value] of searchPar.entries()) {
      // If the key already exists, convert it to an array
      if (paramsObject.hasOwnProperty(key)) {
        if (!Array.isArray(paramsObject[key])) {
          paramsObject[key] = [paramsObject[key]];
        }
        paramsObject[key].push(value);
      } else {
        paramsObject[key] = [value];
      }
    }
    return paramsObject;
  };

  const handleFilterDropDown = (filterTitle: string) => {
    // Toggle the visibility of the dropdown for the clicked filter
    setDropdownVisibility((prevVisibility: any) => ({
      ...Object.fromEntries(
        Object.keys(prevVisibility).map((key) => [key, false])
      ),
      [filterTitle]: !prevVisibility[filterTitle],
    }));
  };

  const handleFilter = (e: any, title: any, value: any) => {
    if (e.target.checked) {
      let temp: any = selectedFilter[title] ? [...selectedFilter[title]] : [];
      temp.push(value);
      setSelectedFilter({ ...selectedFilter, [title]: temp });
    } else {
      let dump: any = [...selectedFilter[title]];
      dump.splice(selectedFilter[title].indexOf(value), 1);
      setSelectedFilter({ ...selectedFilter, [title]: dump });
    }
  };

  const constructSearchQuery = (updatedFilter?: any) => {
    if (updatedFilter) setSelectedFilter({ ...updatedFilter });
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.search = "";

    // Add selected filters as search parameters
    Object.entries(selectedFilter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          url.searchParams.append(key, val);
        });
      } else {
        // @ts-ignore
        url.searchParams.append(key, value);
      }
      navigate(`${url.search}`, { replace: true });
    });

    return url.href;
  };

  const handleApply = (updatedFilter?: any) => {
    constructSearchQuery();
    setDropdownVisibility({});
  };

  const clearAll = (val: any) => {
    setSelectedFilter((prevSelectedFilter: any) => {
      Object.entries(prevSelectedFilter).forEach(([key, value]) => {
        prevSelectedFilter[key] = [];
      });
      return { ...prevSelectedFilter };
    });

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.search = "";
    navigate(`${url.search}`, { replace: true });
  };

  return (
    <thead
      style={{
        zIndex: 1,
        position: "sticky",
        top: 0,
        background: "rgb(218,228,245)",
        textAlign: "center",
      }}
    >
      <tr>
        {props.head.map((val: any, index: any) => {
          return (
            <>
              {val.title === "_select" ? (
                <th

                  key={index}
                  style={{ width: "50px", textAlign: "center" }}
                >
                  <input type="checkbox" />
                </th>
              ) : (
                <th

                  key={index}
                  style={
                    val?.filter
                      ? {
                        textAlign: "center",
                        position: "relative",
                        cursor: "pointer",
                      }
                      : {
                        textAlign: "center"
                      }
                  }
                  onClick={() => {
                    if (val.title === props?.showIconAt) {
                      props.onHeadClick();
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <p>{langObj[val.title][lang]}</p>
                    {val.title === props?.showIconAt && props?.icon}
                    {val?.filter && (
                      <>
                        {searchParams?.get(val?.value) ? (
                          <FontAwesomeIcon
                            icon={faFilter}
                            color="grey"
                            onClick={() => handleFilterDropDown(val.value)}
                          />
                        ) : (
                          <CiFilter
                            size={16}
                            color={"grey"}
                            onClick={() => handleFilterDropDown(val.value)}
                          />
                        )}
                      </>
                    )}
                  </div>
                  {dropdownVisibility[val.value] && (
                    <div className={styles.dropdown}>
                      {props.filtervalue[val.value]?.map(
                        (value: any, ind: any) => (
                          <div className={styles.drop_val}>
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              onClick={(e: any) =>
                                handleFilter(e, val.value, value)
                              }
                              checked={
                                selectedFilter[val?.value]?.includes(value)
                                  ? true
                                  : false
                              }
                            />
                            <p key={ind}>{value}</p>
                          </div>
                        )
                      )}
                      <div className={styles.dropdown_btn}>
                        <button onClick={handleApply}>APPLY</button>
                        <button onClick={() => clearAll(val.value)}>
                          CLEAR ALL
                        </button>
                      </div>
                    </div>
                  )}
                </th>
              )}
            </>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
