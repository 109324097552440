import { useContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Toaster } from "react-hot-toast";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { SideBarContext, SideBarProvider } from "./Store/sideBar";
import App from "./App/App";
import Blogs from "./Pages/Blogs";
import CreateBlog from "./Pages/Blogs/createBlog";
import UpdateBlog from "./Pages/Blogs/updateBlog";
import Leads from "./Pages/Leads";
import Job from "./Pages/Job";
import CreateJob from "./Pages/Job/createJob";
import UpdateJob from "./Pages/Job/updateJob";
import Applications from "./Pages/Applications";
import Compliance from "./Pages/Compliance";
import UpdateCompliance from "./Pages/Compliance/Update";
import AddCompliance from "./Pages/Compliance/Add";
import Products from "./Pages/Products";
import AddProduct from "./Pages/Products/Add";
import UpdateProduct from "./Pages/Products/Update";
import OrderDetails from "./Pages/Orders/OrderDetails";
import Users from "./Pages/Users";
import UserDetails from "./Pages/UserDetials";
import Discounts from "./Pages/Discounts";
import AddUpdateDiscount from "./Pages/Discounts/AddUpdateDiscount";
import Services from "./Pages/Services";
import AddUpdateServices from "./Pages/Services/AddUpdateServices";
import CaseStudy from "./Pages/caseStudy";
import AddUpdateCaseStudy from "./Pages/caseStudy/AddUpdateCaseStudy";
import ProductVideo from "./Pages/ProductVideo";
import AddUpdateProductVideo from "./Pages/ProductVideo/addUpdateProductVideo";
import AddOrder from "./Pages/Orders/AddOrder";
import UploadProduct from "./Pages/Products/UploadProduct";
import LeadDetailView from "./Pages/Leads/leadDetailView";
import Content from "./Pages/Content";
import ContentPage from "./Pages/Content";
import AddUpdateContent from "./Pages/Content/AddUpdateContent/addUpdateContent";
import AddUpdateBanner1 from "./Pages/Content/AddUpdateBanner2/addUpdateBanner1";
import AddUpdateBanner2 from "./Pages/Content/AddUpdateBanner1/addUpdateBanner2";
import Categories from "./Pages/Categories";
import Collections from "./Pages/Collections";
import AddUpdateCategory from "./Pages/Categories/AddUpdateCategory";
import AddUpdateCollection from "./Pages/Collections/AddUpdateCollection";
import BulkMedia from "./Pages/BulkMedia";
import Cart from "./Pages/Cart";
import Settings from "./Pages/Settings";
import { LangContextProvider } from "./Store/langContext";
import ProductVideoCheckLayout from "./Pages/ProductVideo/productVideoCheckLayout";
import SubCategories from "./Pages/SubCategories";
import AddUpdateSubCategory from "./Pages/SubCategories/AddUpdateSubCategory";
import Stores from "./Pages/Stores";
import AddUpdateStores from "./Pages/Stores/AddUpdateStores";
import CloneProduct from "./Pages/Products/Clone";
import Analytics from "./Pages/Analytics";
import Profile from "./Pages/Profile";
import AddSubAdmin from "./Pages/Settings/addSubAdmin";
import { AdminContextProvider, useAdminContext } from "./Store/adminContext";
import MostViewedProductPage from "./Pages/Analytics/MostViewedProducts";
import TopSellingProductsPage from "./Pages/Analytics/TopSellingProducts";
import OrdersPage from "./Pages/Analytics/orders";
import ComingFromPage from "./Pages/Analytics/comingFrom";
import CreateLead from "./Pages/Leads/createLead";
import ContactLogs from "./Pages/Leads/ContactLogs";
import ImportLeads from "./Pages/Leads/importLeads";
import AddUpdateLeads from "./Pages/Leads/addUpdateLead";
import CartDetails from "./Pages/Cart/cartDetails";
import ProductOrderDetails from "./Pages/Orders/productOrders/ProductOrderDetails";
import Segments from "./Pages/Segments";
import AddUpdateSegments from "./Pages/Segments/addUpdateSegments";
import ServiceOrders from "./Pages/Orders/serviceOrders";
import Apps from "./Pages/Apps";
import ImportProducts from "./Pages/Products/Import/ImportProducts";
import BulkImageUpload from "./Pages/BulkImageUpload";
import BrowseMedia from "./Pages/BulkImageUpload/BrowseMedia/browseMedia";
import B2BOrders from "./Pages/Orders/b2bOrders";
import Events from "./Pages/Events";
import CreateEvent from "./Pages/Events/addEvent";
import UpdateEvent from "./Pages/Events/updateEvent";
import EventsView from "./Pages/Events/eventsView";
import KycRegistration from "./Pages/KycRegistration/page";
import PlanPage from "./Pages/Plan/page";
import { isUserAllowed } from "./Utils/checkPermissions/checkForKycAndPlan";
import { hasPermission } from "./Utils/checkPermissions/planPermissions";
import Onboarding from "./Pages/Onboarding/Mobile";
import { KYCProvider } from "./Store/kycContext";
import getProtectedRoutes from "./App/ProtectedRoute";

import { useKYC } from "./Store/kycContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Main = () => {
  const { admin }: any = useAdminContext();
  const context: any = useContext(SideBarContext);
  const { kycData } = useKYC();
  const protectedRoutes = getProtectedRoutes();

  useEffect(() => {
    const disableRightClick = (event) => event.preventDefault();
    document.addEventListener("contextmenu", disableRightClick);
    return () => document.removeEventListener("contextmenu", disableRightClick);
  }, []);

  const isAdminAllowed = isUserAllowed(admin);

  const children: any = [];

  if (admin?.role === "admin" || admin?.role === "superAdmin") {
    if (!admin.blog || admin.ecommerce) {
      children.push({ path: "/analytics", element: <Analytics /> });
      if (isAdminAllowed) {
        children.push({
          path: "/analytics/mostViewedProducts",
          element: <MostViewedProductPage />,
        });
        children.push({
          path: "/analytics/orders",
          element: <OrdersPage />,
        });
        children.push({
          path: "/analytics/topSellingProducts",
          element: <TopSellingProductsPage />,
        });
        children.push({
          path: "/analytics/coming-from",
          element: <ComingFromPage />,
        });
      }
    }

    if (admin.blog) {
      children.push({ path: "/blogs", element: <Blogs /> });
      if (isAdminAllowed) {
        children.push({ path: "/blog/create_blog", element: <CreateBlog /> });
        children.push({
          path: "/blog/update_blog/:id",
          element: <UpdateBlog />,
        });
      }
    }

    if (admin.contact) {
      children.push({ path: "/contacts", element: <Leads /> });
      if (isAdminAllowed) {
        children.push({
          path: "/contacts/update/:id",
          element: <AddUpdateLeads />,
        });
        children.push({ path: "/contacts/:id", element: <LeadDetailView /> });
        children.push({ path: "/contacts/add", element: <CreateLead /> });
        children.push({ path: "/contacts/logs", element: <ContactLogs /> });
        children.push({
          path: "/contacts/import-file",
          element: <ImportLeads />,
        });
      }
    }

    if (admin.career) {
      children.push({
        path: "/jobs",
        element: <Job />,
      });

      if (isAdminAllowed) {
        children.push({
          path: "/jobs/create_job",
          element: <CreateJob />,
        });

        children.push({
          path: "/jobs/update_job/:id",
          element: <UpdateJob />,
        });
      }

      children.push({
        path: "/applicants",
        element: <Applications />,
      });
    }

    if (true) {
      if (isAdminAllowed) {
        children.push({
          path: "/events/create_event",
          element: <CreateEvent />,
        });
      }

      children.push({
        path: "/events",
        element: <Events />,
      });

      if (isAdminAllowed) {
        children.push({
          path: "/events/:id",
          element: <EventsView />,
        });
        children.push({
          path: "/events/update_event/:id",
          element: <UpdateEvent />,
        });
      }
    }

    // compliance
    children.push({ path: "/compliance", element: <Compliance /> });

    if (admin?.selectedPlan) {
      children.push({ path: "/compliance/add", element: <AddCompliance /> });
      children.push({
        path: "/compliance/update/:id",
        element: <UpdateCompliance />,
      });
    }

    if (admin.ecommerce) {
      // products
      children.push({ path: "/stores", element: <Stores /> });

      if (isAdminAllowed) {
        children.push({ path: "/stores/add", element: <AddUpdateStores /> });
        children.push({
          path: "/stores/update/:id",
          element: <AddUpdateStores />,
        });
      }

      children.push({ path: "/products", element: <Products /> });

      if (isAdminAllowed) {
        children.push({ path: "/media", element: <BulkMedia /> });
      }

      children.push({
        path: "/bulk-imageupload",
        element: <BulkImageUpload />,
      });

      children.push({ path: "/browse-media", element: <BrowseMedia /> });
      children.push({ path: "/categories", element: <Categories /> });
      children.push({ path: "/sub-categories", element: <SubCategories /> });
      if (isAdminAllowed) {
        children.push({
          path: "/categories/add",
          element: <AddUpdateCategory />,
        });

        children.push({
          path: "/sub-categories/add",
          element: <AddUpdateSubCategory />,
        });
        children.push({
          path: "/categories/update/:id",
          element: <AddUpdateCategory />,
        });
        children.push({
          path: "/sub-categories/update/:id",
          element: <AddUpdateSubCategory />,
        });
      }

      children.push({ path: "/carts", element: <Cart /> });

      if (isAdminAllowed) {
        children.push({
          path: "/carts/details/:id",
          element: <CartDetails />,
        });
      }
      children.push({ path: "/collections", element: <Collections /> });
      if (isAdminAllowed) {
        children.push({
          path: "/collections/add",
          element: <AddUpdateCollection />,
        });

        children.push({
          path: "/collections/update/:id",
          element: <AddUpdateCollection />,
        });
      }

      if (isAdminAllowed) {
        children.push({ path: "/products/upload", element: <UploadProduct /> });
        children.push({ path: "/products/add", element: <AddProduct /> });
        children.push({
          path: "/products/import",
          element: <ImportProducts />,
        });
        children.push({
          path: "products/update/:id",
          element: <UpdateProduct />,
        });
        children.push({
          path: "products/clone/:cloneid",
          element: <CloneProduct />,
        });
      }

      children.push({
        path: "/orders",
        element: <ProductOrderDetails />,
      });

      if (isAdminAllowed) {
        children.push({
          path: "/orders/details/:id",
          element: <OrderDetails />,
        });
        children.push({ path: "/orders/add", element: <AddOrder /> });
        children.push({ path: "/users/:id", element: <UserDetails /> });

        if (hasPermission(admin.selectedPlan, "segments")) {
          children.push({
            path: "/segments/add",
            element: <AddUpdateSegments />,
          });
          children.push({
            path: "/segments/:id",
            element: <AddUpdateSegments />,
          });
        }

        children.push({
          path: "/discounts/add",
          element: <AddUpdateDiscount />,
        });
        children.push({
          path: "/discounts/update/:id",
          element: <AddUpdateDiscount />,
        });
      }

      children.push({ path: "/users", element: <Users /> });

      if (hasPermission(admin.selectedPlan, "segments")) {
        children.push({ path: "/segments", element: <Segments /> });
      }

      children.push({ path: "/discounts", element: <Discounts /> });
    }

    // product video
    children.push({
      path: "/product-video",
      element: <ProductVideoCheckLayout />,
      children: [
        {
          index: true,
          element: <ProductVideo />,
        },
        { path: "add", element: <AddUpdateProductVideo /> },
        { path: "update/:id", element: <AddUpdateProductVideo /> },
      ],
    });

    // Services
    if (admin.service) {
      children.push({ path: "/services", element: <Services /> });

      if (isAdminAllowed) {
        children.push({
          path: "/services/add",
          element: <AddUpdateServices />,
        });
      }

      children.push({
        path: "/orders",
        element: <ServiceOrders />,
      });

      if (isAdminAllowed) {
        children.push({
          path: "/orders/details/:id",
          element: <OrderDetails />,
        });
        children.push({ path: "/orders/add", element: <AddOrder /> });
        children.push({ path: "/users/:id", element: <UserDetails /> });

        if (hasPermission(admin.selectedPlan, "segments")) {
          children.push({
            path: "/segments/add",
            element: <AddUpdateSegments />,
          });
          children.push({
            path: "/segments/:id",
            element: <AddUpdateSegments />,
          });
        }

        children.push({ path: "/carts", element: <Cart /> });

        if (isAdminAllowed) {
          children.push({
            path: "/carts/details/:id",
            element: <CartDetails />,
          });
        }

        children.push({
          path: "/services/update/:id",
          element: <AddUpdateServices />,
        });
        children.push({
          path: "/discounts/add",
          element: <AddUpdateDiscount />,
        });
        children.push({
          path: "/discounts/update/:id",
          element: <AddUpdateDiscount />,
        });
      }

      children.push({ path: "/users", element: <Users /> });

      if (hasPermission(admin.selectedPlan, "segments")) {
        children.push({ path: "/segments", element: <Segments /> });
      }

      children.push({ path: "/discounts", element: <Discounts /> });
    }

    children.push({ path: "/content", element: <ContentPage /> });

    if (isAdminAllowed) {
      children.push({ path: "/content/add", element: <AddUpdateContent /> });
      children.push({
        path: "/content/update/:id",
        element: <AddUpdateContent />,
      });
      children.push({
        path: "/content/banner1/add",
        element: <AddUpdateBanner1 />,
      });
      children.push({
        path: "/content/banner1/update/:id",
        element: <AddUpdateBanner1 />,
      });
      children.push({
        path: "/content/banner2/add",
        element: <AddUpdateBanner2 />,
      });
      children.push({
        path: "/content/banner2/update/:id",
        element: <AddUpdateBanner2 />,
      });
    }

    children.push({
      path: "/b2b-orders",
      element: <B2BOrders />,
    });
    // }

    if (admin.caseStudy) {
      children.push({ path: "/case-study", element: <CaseStudy /> });
      if (isAdminAllowed) {
        children.push({
          path: "/case-study/create",
          element: <AddUpdateCaseStudy />,
        });
        children.push({
          path: "/case-study/update/:id",
          element: <AddUpdateCaseStudy />,
        });
      }
    }

    children.push({ path: "/apps", element: <Apps /> });
    children.push({ path: "/settings", element: <Settings /> });

    if (isAdminAllowed) {
      children.push({
        path: "/settings/addNewSubAdmin",
        element: <AddSubAdmin />,
      });
      children.push({
        path: "/settings/subadmin/update/:id",
        element: <AddSubAdmin />,
      });
    }

    children.push({ path: "/profile", element: <Profile /> });
  } else if (admin?.role === "subadmin") {
    if (
      admin.overview_access.read ||
      admin.overview_access.write ||
      admin.overview_access.delete
    ) {
      children.push({ path: "/analytics", element: <Analytics /> });
    }

    if (
      admin.blog_access.read ||
      admin.blog_access.write ||
      admin.blog_access.delete
    ) {
      children.push({ path: "/blogs", element: <Blogs /> });
      children.push({ path: "/blog/update_blog/:id", element: <UpdateBlog /> });

      if (admin.blog_access.write) {
        children.push({ path: "/blog/create_blog", element: <CreateBlog /> });
      }
    }

    if (
      admin.contact_access.read ||
      admin.contact_access.write ||
      admin.contact_access.delete
    ) {
      children.push({ path: "/contacts", element: <Leads /> });
      children.push({
        path: "/contacts/update/:id",
        element: <AddUpdateLeads />,
      });
      children.push({ path: "/contacts/:id", element: <LeadDetailView /> });
      children.push({ path: "/contacts/add", element: <CreateLead /> });
      children.push({ path: "/contacts/logs", element: <ContactLogs /> });
      children.push({
        path: "/contacts/import-file",
        element: <ImportLeads />,
      });
    }

    if (
      admin.job_access.read ||
      admin.job_access.write ||
      admin.job_access.delete
    ) {
      children.push({
        path: "/jobs",
        element: <Job />,
      });
      children.push({
        path: "/jobs/update_job/:id",
        element: <UpdateJob />,
      });

      if (admin.job_access.write) {
        children.push({
          path: "/jobs/create_job",
          element: <CreateJob />,
        });
      }
    }

    if (
      admin.applicant_access.read ||
      admin.applicant_access.write ||
      admin.applicant_access.delete
    ) {
      children.push({
        path: "/applicants",
        element: <Applications />,
      });
    }

    if (
      admin.compliance_access.read ||
      admin.compliance_access.write ||
      admin.compliance_access.delete
    ) {
      children.push({ path: "/compliance", element: <Compliance /> });
      children.push({
        path: "/compliance/update/:id",
        element: <UpdateCompliance />,
      });

      if (admin.compliance_access.write) {
        children.push({ path: "/compliance/add", element: <AddCompliance /> });
      }
    }

    if (
      admin.catalog_access.read ||
      admin.catalog_access.write ||
      admin.catalog_access.delete
    ) {
      children.push({ path: "/products", element: <Products /> });
      children.push({
        path: "products/update/:id",
        element: <UpdateProduct />,
      });
      children.push({ path: "/categories", element: <Categories /> });
      children.push({
        path: "/categories/update/:id",
        element: <AddUpdateCategory />,
      });
      children.push({ path: "/sub-categories", element: <SubCategories /> });
      children.push({
        path: "/sub-categories/update/:id",
        element: <AddUpdateSubCategory />,
      });
      children.push({ path: "/collections", element: <Collections /> });
      children.push({
        path: "/collections/update/:id",
        element: <AddUpdateCollection />,
      });

      if (admin.catalog_access.write) {
        children.push({
          path: "products/clone/:cloneid",
          element: <CloneProduct />,
        });
        children.push({ path: "/products/upload", element: <UploadProduct /> });
        children.push({ path: "/products/add", element: <AddProduct /> });
        children.push({
          path: "/products/import",
          element: <ImportProducts />,
        });
        children.push({
          path: "/categories/add",
          element: <AddUpdateCategory />,
        });
        children.push({
          path: "/sub-categories/add",
          element: <AddUpdateSubCategory />,
        });
        children.push({
          path: "/collections/add",
          element: <AddUpdateCollection />,
        });
        children.push({
          path: "/bulk-imageupload",
          element: <BulkImageUpload />,
        });
      }
    }

    if (
      admin.sales_access.read ||
      admin.sales_access.write ||
      admin.sales_access.delete
    ) {
      children.push({
        path: "/orders",
        element: admin.ecommerce ? <ProductOrderDetails /> : <ServiceOrders />,
      });
      children.push({ path: "/orders/details/:id", element: <OrderDetails /> });
      children.push({ path: "/carts", element: <Cart /> });

      if (admin.sales_access.write) {
        children.push({ path: "/orders/add", element: <AddOrder /> });
      }
    }

    if (
      admin.users_access.read ||
      admin.users_access.write ||
      admin.users_access.delete
    ) {
      children.push({ path: "/users", element: <Users /> });
      children.push({ path: "/users/:id", element: <UserDetails /> });
      children.push({ path: "/segments", element: <Segments /> });
      children.push({ path: "/segments/add", element: <AddUpdateSegments /> });
      children.push({ path: "/segments/:id", element: <AddUpdateSegments /> });
    }

    if (
      admin.discount_access.read ||
      admin.discount_access.write ||
      admin.discount_access.delete
    ) {
      children.push({ path: "/discounts", element: <Discounts /> });
      children.push({
        path: "/discounts/update/:id",
        element: <AddUpdateDiscount />,
      });

      if (admin.discount_access.write) {
        children.push({
          path: "/discounts/add",
          element: <AddUpdateDiscount />,
        });
      }
    }

    if (
      admin.shoppableVideo_access.read ||
      admin.shoppableVideo_access.write ||
      admin.shoppableVideo_access.delete
    ) {
      if (admin.shoppableVideo_access.write) {
        children.push({
          path: "/product-video",
          element: <ProductVideoCheckLayout />,
          children: [
            {
              index: true,
              element: <ProductVideo />,
            },
            { path: "add", element: <AddUpdateProductVideo /> },
            { path: "update/:id", element: <AddUpdateProductVideo /> },
          ],
        });
      } else {
        children.push({
          path: "/product-video",
          element: <ProductVideoCheckLayout />,
          children: [
            {
              index: true,
              element: <ProductVideo />,
            },
            { path: "update/:id", element: <AddUpdateProductVideo /> },
          ],
        });
      }
    }

    if (
      admin.content_access.read ||
      admin.content_access.write ||
      admin.content_access.delete
    ) {
      children.push({ path: "/content", element: <ContentPage /> });
      children.push({
        path: "/content/update/:id",
        element: <AddUpdateContent />,
      });
      children.push({
        path: "/content/banner1/update/:id",
        element: <AddUpdateBanner1 />,
      });
      children.push({
        path: "/content/banner2/update/:id",
        element: <AddUpdateBanner2 />,
      });

      if (admin.content_access.write) {
        children.push({ path: "/content/add", element: <AddUpdateContent /> });
        children.push({
          path: "/content/banner1/add",
          element: <AddUpdateBanner1 />,
        });
        children.push({
          path: "/content/banner2/add",
          element: <AddUpdateBanner2 />,
        });
      }
    }

    if (
      admin.caseStudy_access.read ||
      admin.caseStudy_access.write ||
      admin.caseStudy_access.delete
    ) {
      children.push({ path: "/case-study", element: <CaseStudy /> });
      children.push({
        path: "/case-study/update/:id",
        element: <AddUpdateCaseStudy />,
      });

      if (admin.caseStudy_access.write) {
        children.push({
          path: "/case-study/create",
          element: <AddUpdateCaseStudy />,
        });
      }
    }

    if (
      admin.store_access.read ||
      admin.store_access.write ||
      admin.store_access.delete
    ) {
      children.push({ path: "/stores", element: <Stores /> });
      children.push({
        path: "/stores/update/:id",
        element: <AddUpdateStores />,
      });

      if (admin.store_access.write) {
        children.push({ path: "/stores/add", element: <AddUpdateStores /> });
      }
    }

    // children.push({ path: "/apps", element: <Apps /> });
    children.push({ path: "/settings", element: <Settings /> });
    children.push({ path: "/profile", element: <Profile /> });

    console.log("Chhhhhhh ::: ", children);
  }

  if (children.length > 0) {
    children.unshift({
      index: true,
      element: <Navigate to={children[0].path} replace={true} />,
    });
  }

  children.push({ path: "/kyc-registration", element: <KycRegistration /> });

  children.push({ path: "/select-plan", element: <PlanPage /> });

  console.log("Childddrennnn ::: ", children);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <h1>Error</h1>,
      children: [...children, ...protectedRoutes],
    },
  ]);

  return <RouterProvider router={router} />;
};

root.render(
  <LangContextProvider>
    <AdminContextProvider>
      <KYCProvider>
        <SideBarProvider>
          <Toaster />
          <Main />
        </SideBarProvider>
      </KYCProvider>
    </AdminContextProvider>
  </LangContextProvider>
);
