import React from "react";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InputField from "../../Components/InputField";
import TextEditor from "../../Components/TextEditor";
import withRouter from "../../Components/withRouter";
import { getFileName } from "../../Utils/getFileName";
import langObj from "../../Utils/lang";
import heic2any from "heic2any";
import ImageFolder from "../../Components/ImageFolder";

class AddUpdateCaseStudy extends React.Component<any, any> {
  state = {
    imageUrl: "",
    imageObject: "",
    content: "", 
    title: "",
    shortDescription: "", 
    category: "",
    tags: "",
    adOneUrl: "",
    adTwoUrl: "",
    buttonOneUrl: "",
    buttonTwoUrl: "",
    loading: false,
    isImageChanged: false,
    author: "",
    mediaImages: [], 
  };

  componentDidMount(): void {
    if (this.props.router.params.id) {
      this.setState({ loading: true });
      this.getData();
    }
  }

  getData = () => {
    callApi({
      method: "get",
      url: this.props.router.params.id,
      data: {},
      type: "casestudy",
    })
      .then((res) => {
        let data = res.data.caseStudy;

        this.setState({
          adOneUrl: data.adOneUrl,
          adTwoUrl: data.adTwoUrl,
          content: data.body,
          buttonOneUrl: data.buttonOneUrl,
          buttonTwoUrl: data.buttonTwoUrl,
          category: data.category,
          shortDescription: data.description,
          identifier: data.identifier,
          tags: data.tags,
          title: data.title,
          imageUrl: data.image,
          mediaImages: data.image ? [data.image] : [], 
          author: data?.others?.author ? data.others?.author : "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  
  handleImage = async (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      this.setState({
        imageUrl: URL.createObjectURL(convertedFile),
        imageObject: convertedFile,
        isImageChanged: true,
      });
    } else
      this.setState({
        imageUrl: URL.createObjectURL(event.target.files[0]),
        imageObject: event.target.files[0],
        isImageChanged: true,
      });
  };

  
  updateMediaImages = (images: string[]) => {
    if (images && images.length > 0) {
      this.setState({
        mediaImages: images,
        imageUrl: images[0], 
        isImageChanged: true,
      });
    } else {
      this.setState({
        mediaImages: [],
        imageUrl: "",
        imageObject: "",
      });
    }
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  validate = () => {
    let text = "";

   
    if (!this.state.imageUrl && !text) {
      text = "Banner image";
    }

    
    if (!this.state.title && !text) {
      text = "Blog title";
    }

    if (text) {
      toast.error(`${text} is Required.`);
      return false;
    }

    return true;
  };

  handleSubmit = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/case-study");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });

        
        if (this.state.mediaImages.length > 0 && this.state.mediaImages[0].startsWith('http')) {
          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.mediaImages[0], 
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
          };

          callApi({
            url: "",
            data,
            method: "post",
            type: "casestudy",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Case Study Created Successfully");
              this.props.router.navigate("/case-study");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        } else if (this.state.imageObject) {
         
          const filename = getFileName(this.state.imageObject);

          UploadImage({ file: this.state.imageObject, name: filename })
            .then((res) => {
              const image_url = res;
              const data = {
                title: this.state.title,
                description: this.state.shortDescription,
                image: image_url,
                body: this.state.content,
                adOneUrl: this.state.adOneUrl,
                adTwoUrl: this.state.adTwoUrl,
                buttonOneUrl: this.state.buttonOneUrl,
                buttonTwoUrl: this.state.buttonTwoUrl,
                category: this.state.category,
                tags: this.state.tags,
              };

              callApi({
                url: "",
                data,
                method: "post",
                type: "casestudy",
                header: true,
                identifier: true,
              })
                .then((res: any) => {
                  toast.success("Case Study Created Successfully");
                  this.props.router.navigate("/case-study");
                  this.setState({ loading: false });
                })
                .catch((err: any) => {
                  toast.error(err.response.data.message);
                  this.setState({ loading: false });
                });
            })
            .catch((res) => {
              toast.error("Failed to upload image");
              this.setState({ loading: false });
            });
        }
      }
    }
  };

  updateBlog = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/case-study");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });


        if (this.state.mediaImages.length > 0 && this.state.mediaImages[0].startsWith('http')) {
          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.mediaImages[0], 
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
          };

          callApi({
            url: this.props.router.params.id,
            data,
            method: "patch",
            type: "casestudy",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Case Study Updated Successfully");
              this.props.router.navigate("/case-study");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        } else if (!!this.state.imageUrl && !this.state.isImageChanged) {
      
          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.imageUrl,
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
          };

          callApi({
            url: this.props.router.params.id,
            data,
            method: "patch",
            type: "casestudy",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Case Study Updated Successfully");
              this.props.router.navigate("/case-study");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        } else if (this.state.imageObject) {
         
          const filename = getFileName(this.state.imageObject);

          UploadImage({ file: this.state.imageObject, name: filename })
            .then((res) => {
              const image_url = res;
              const data = {
                title: this.state.title,
                description: this.state.shortDescription,
                image: image_url,
                body: this.state.content,
                adOneUrl: this.state.adOneUrl,
                adTwoUrl: this.state.adTwoUrl,
                buttonOneUrl: this.state.buttonOneUrl,
                buttonTwoUrl: this.state.buttonTwoUrl,
                category: this.state.category,
                tags: this.state.tags,
              };

              callApi({
                url: this.props.router.params.id,
                data,
                method: "patch",
                type: "casestudy",
                header: true,
                identifier: true,
              })
                .then((res: any) => {
                  toast.success("Case Study Updated Successfully");
                  this.props.router.navigate("/case-study");
                  this.setState({ loading: false });
                })
                .catch((err: any) => {
                  toast.error(err.response.data.message);
                  this.setState({ loading: false });
                });
            })
            .catch((res) => {
              toast.error("Failed to upload image");
              this.setState({ loading: false });
            });
        }
      }
    }
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={
              this.props?.title
                ? langObj.updateCaseStudy[this.props.lang]
                : langObj.addNewCaseStudy[this.props.lang]
            }
            showBtn1={
              this.props.admin.role === "subadmin"
                ? this.props.admin.caseStudy_access.write
                : true
            }
            showBtn2={
              this.props.admin.role === "subadmin"
                ? this.props.admin.caseStudy_access.write
                : true
            }
            showBtn3={true}
            btn1type="filled"
            btn2type="outlined"
            btn3type="outlined_del"
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text={langObj.save[this.props.lang]}
            btn2Text={langObj.saveAsDraft[this.props.lang]}
            btn3Text={langObj.cancel[this.props.lang]}
            onClickHandler={
              this.props.router.params.id ? this.updateBlog : this.handleSubmit
            }
          />
          <ContentBody>
            <>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginBottom: "10px",
                }}
              >
                {langObj.bannerImage[this.props.lang]}
                <span style={{ color: "#a3a3a3", fontSize: ".8rem" }}>
                  {`(${langObj.uploadAnImageOfUpto5MB[this.props.lang]})`}
                </span>
              </p>

            
              <ImageFolder
                selectMultiple={false} 
                media={this.state.mediaImages}
                updateMedia={this.updateMediaImages}
                onSave={this.updateMediaImages}
                alreadySelected={this.state.mediaImages}
                text={langObj.uploadBannerImage ? langObj.uploadBannerImage[this.props.lang] : "Upload Banner Image"}
               
              />

              

              <InputField
                isLabel={true}
                value={this.state.title}
                lable={langObj.Title[this.props.lang]}
                type="text"
                isRequired={false}
                placeholder=""
                onChange={(event: any) => {
                  this.setState({ title: event.target.value });
                }}
              />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.category}
                  lable={langObj.category[this.props.lang]}
                  isRequired={false}
                  placeholder={langObj.category[this.props.lang]}
                  onChange={(event: any) => {
                    this.setState({ category: event.target.value });
                  }}
                />
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.tags}
                  lable={langObj.tags[this.props.lang]}
                  isRequired={false}
                  placeholder={langObj.commaSeperatedTags[this.props.lang]}
                  onChange={(event: any) => {
                    this.setState({
                      tags: event.target.value,
                    });
                  }}
                />
              </div>

              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginTop: "20px",
                }}
              >
                {langObj.description[this.props.lang]}
              </p>
              <TextEditor
                content={this.state.shortDescription}
                handleChange={(value: any) => {
                  this.setState({ shortDescription: value });
                }}
              />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  marginTop: "20px",
                  transform: "translateX(5px)",
                  marginBottom: "10px",
                }}
              >
                {langObj.body[this.props.lang]}
              </p>
              <TextEditor
                content={this.state.content}
                handleChange={(value: any) => {
                  this.setState({ content: value });
                }}
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(AddUpdateCaseStudy);