import React from "react";
import CreateEvent from "./addEvent";
import { useParams } from "react-router-dom";

const UpdateEvent = () => {
  const params = useParams();

  return (
    <>
      <CreateEvent id={params.id} />
    </>
  );
};

export default UpdateEvent;
