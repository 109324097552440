import BackBtn from "@/components/common/backBtn";
import React, { useEffect } from "react";
import heroPattern from "../../Assets/images/hero_pattern.png";
import logo from "../../Assets/images/logo-rounded-bg.png";
import { KYCProvider, useKYC } from "../../Store/kycContext";
import { useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { createPortal } from "react-dom";

const RootLayoutContent = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { kycData } = useKYC();
  const handleBack = () => {
    navigate(-1);
  };

  const content = (
    <main
      style={{
        backgroundImage: `url(${heroPattern})`,
      }}
      className="h-[100vh] w-[100vw] border bg-cover grid place-items-center"
    >
      <button
        onClick={() => navigate("/")}
        className="absolute top-6 left-6 p-2 hover:bg-gray-100 rounded-full transition-colors"
      >
        <IoClose className="w-6 h-6" />
      </button>
      {/* <button 
        onClick={handleBack}
        className="absolute top-6 right-6 p-2 hover:bg-gray-100 rounded-full transition-colors"
      >
        
      </button> */}

      <div className="max-h-screen h-[40vh] md:h-[60vh] flex items-center justify-center bg-gray-50 shadow-lg w-[90vw] md:w-[40vw] rounded-sm justify-self-center items-center">
        <button
          onClick={handleBack}
          className="top-[23%] left-[32%] fixed left-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="w-6 h-6" />
        </button>

        {children}
      </div>
      <div className="absolute bottom-6 right-8 flex flex-col items-end">
        <p>© 2024 </p>
        <span>
          <a
            href="https://www.shopiq.app/compliances/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="underline bg-transparent text-black"
          >
            Privacy Policy
          </a>
          {" • "}
          <a
            href="https://www.shopiq.app/compliances/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="underline bg-transparent text-black"
          >
            Terms of Service
          </a>
        </span>
      </div>
    </main>
  );

  return content;
};

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  return <RootLayoutContent>{children}</RootLayoutContent>;
};

export default RootLayout;
