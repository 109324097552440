import React, { useEffect, useState } from "react";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import styles from "../../../Styles/discounts.module.css";
import InputField from "../../../Components/InputField";
import axios from "axios";
import { folder_name } from "../../../Utils/UploadImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faClose,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddProductOrder = () => {
  const [products, setProducts] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedProduct, setSelectedProduct]: any = useState([]);
  const [isUserAccountVerified, setIsUserAccountVerified] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [email, setEmail] = useState("");
  const [otpSent, setOptSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [key, setKey] = useState("");
  const [vToken, setVToken] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const [payment, setPayment] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [billingAddress, setBillingAddress] = useState({
    addLineOne: "",
    addLineTwo: "",
    city: "",
    state: "",
    pincode: "",
  });

  const navigate = useNavigate();

  const handleClick = (type: any) => {
    if (type === "Save") {
      handleUserCreate();
    } else if (type === "Cancel") {
      navigate("/orders");
    }
  };

  const calculateTotal = () => {
    return 0;
  };

  const getAllProducts = () => {
    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }products/filter?identifier=${localStorage.getItem(
        "identifier"
      )}&limit=1000000000`,
      method: "GET",
    })
      .then((res: any) => {
        setProducts(res.data.data);
      })
      .catch((err: any) => {});
  };

  const getAllUsers = (key: any) => {
    axios({
      url: "https://backend.retaino.in/api/users/" + key,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setAllUsers(res.data.allowedUsers);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    getAllProducts();
    getKey();
    getAllDiscounts();
  }, []);

  const handleProductAdd = (e: any) => {
    setSelectedProduct((prev: any): any => {
      const productId: any = products.filter(
        (val: any) => val._id === e.target.value
      )[0];

      const order_obj = {
        productId,
        quantity: 1,
        variant: [],
      };

      if (productId.variant.length !== 0) {
        let temp_variant = productId?.variant.map((val: any) => {
          return { options_name: val.options_name, options_value: [] };
        });

        order_obj.variant = temp_variant;
      }

      return [...prev, order_obj];
    });
  };

  const handleUserCreate = () => {
    axios({
      url: "https://backend.retaino.in/api/users/" + userId,
      method: "PATCH",
      data: {
        firstName,
        lastName,
        mobile: { number: mobileNumber },
        billingAddress: [{ billingAddress, name: firstName + " " + lastName }],
      },
    })
      .then((res: any) => {
        setIsUserAccountVerified(true);
        toast.success("User Created Successfully.");
      })
      .catch((res: any) => {
        toast.error("Something went wrong.");
      });
  };

  const getKey = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}users/verifyuser`,
      method: "POST",
      data: {
        identifier: `${localStorage.getItem("identifier")}`,
        consent: true,
      },
    })
      .then((res) => {
        setKey(res.data.key);
        getAllUsers(res.data.key);
      })
      .catch((error) => {});
  };

  const sendOtp = () => {
    axios({
      url: "https://backend.retaino.in/api/users/verifyuser",
      method: "POST",
      data: {
        email,
        key,
        consent: true,
      },
    })
      .then((res) => {
        setVToken(res.data.verificationToken);
      })
      .catch((err) => {});
  };

  const verifyOtp = () => {
    axios({
      url: "https://backend.retaino.in/api/users/verifyotp/" + vToken,
      method: "POST",
      data: {
        otp,
      },
    })
      .then((res: any) => {
        setUserId(res.data.userData.userId);
        setVerified(true);
      })
      .catch((err: any) => {});
  };

  const getAllDiscounts = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}discounts?identifier=` +
        localStorage.getItem("identifier"),
      method: "GET",
    })
      .then((res: any) => {
        setDiscounts(res.data.discounts);
      })
      .catch((err: any) => {});
  };

  const selectDiscount = (e: any) => {
    let temp: any = discounts.filter(
      (val: any) => val._id === e.target.value
    )[0];

    if (temp.bulkDiscount) {
      setDiscountType(temp.bulkDiscountDetails[0].discountType);
      setDiscountValue(temp.bulkDiscountDetails[0].discountValue);
    } else {
      if (temp.value.number && temp.value.typeName) {
        setDiscountType(temp.value.typeName);
        setDiscountValue(temp.value.number);
      }
    }

    setSelectedDiscount(e.target.value);
  };

  return (
    <Content>
      <ContentHead
        title="Create Order"
        showBtn1={true}
        showBtn2={true}
        btn1Name="btn1"
        btn2Name="btn2"
        btn1Text="Save"
        btn2Text="Cancel"
        btn1type="outlined"
        btn2type="outlined_del"
        onClickHandler={handleClick}
      />
      <ContentBody>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div className={`card`} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <h2>Order Details</h2>
              <p>₹ {calculateTotal()}</p>
            </div>

            <InputField
              type={"select"}
              options={[...products]}
              accessText={"title"}
              accessValue={"_id"}
              isLabel={true}
              lable={"Product"}
              value={""}
              onChange={handleProductAdd}
              placeholder="Select the product"
            />

            {selectedProduct.length ? (
              <div 
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                  gap: "20px",
                  width: "100%"
                }}
              >
                {selectedProduct.map((vall: any, key: any) => {
                  return (
                    <div
                      key={key}
                      style={{
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "10px",
                        padding: "10px",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClose}
                        color="#36619b"
                        style={{
                          position: "absolute",
                          background: "var(--blue-light)",
                          height: "20px",
                          width: "20px",
                          padding: "5px",
                          borderRadius: "100%",
                          right: 0,
                          top: 0,
                          transform: "translate(50%, -50%)",
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          setSelectedProduct((prev: any) => {
                            let temp = [...prev];
                            temp.splice(key, 1);
                            return temp;
                          });
                        }}
                      />
                      <img
                        style={{
                          width: "100%",
                          aspectRatio: "1/1",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                        src={vall.productId.mediaUrl[0]}
                        alt=""
                      />
                      <p
                        style={{
                          fontSize: ".9rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {vall.productId.title}
                      </p>
                      <ul
                        style={{
                          listStyle: "none",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        {vall?.variant.map((v: any, i: any) => {
                          return (
                            <li
                              key={i}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "8px",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: ".9rem",
                                  color: "grey",
                                  textTransform: "capitalize",
                                  marginBottom: "4px",
                                }}
                              >
                                {v.options_name}
                              </label>
                              <select
                                style={{
                                  width: "100%",
                                  height: "36px",
                                  borderRadius: "5px",
                                  borderColor: "lightgrey",
                                  outline: "none",
                                  padding: "0 8px",
                                }}
                                onChange={(e: any) => {
                                  setSelectedProduct((prev: any) => {
                                    let temp: any = [...prev];
                                    temp[key].variant[i].options_value = [
                                      e.target.value,
                                    ];
                                    return temp;
                                  });
                                }}
                              >
                                {vall.productId.variant
                                  .filter(
                                    (t_v: any) =>
                                      t_v.options_name === v.options_name
                                  )[0]
                                  .options_value.map((o_v: any, ov_i: any) => {
                                    return (
                                      <option key={ov_i} value={o_v}>
                                        {v.options_name === "color"
                                          ? o_v.split(":")[0]
                                          : o_v}
                                      </option>
                                    );
                                  })}
                              </select>
                            </li>
                          );
                        })}
                      </ul>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          gap: "10px",
                          alignItems: "center",
                          marginTop: "auto",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          color={"#36619b"}
                          style={{
                            background: "#eff5fc",
                            flex: 1,
                            height: "20px",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor:
                              vall.quantity === 1 ? "not-allowed" : "pointer",
                          }}
                          onClick={() => {
                            if (vall.quantity > 1) {
                              setSelectedProduct((prev: any) => {
                                let temp = [...prev];
                                temp[key].quantity -= 1;
                                return temp;
                              });
                            }
                          }}
                        />
                        <p style={{ flex: 1, textAlign: "center" }}>
                          {vall.quantity}
                        </p>
                        <FontAwesomeIcon
                          icon={faPlus}
                          color="#36619b"
                          style={{
                            background: "#eff5fc",
                            flex: 1,
                            height: "20px",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedProduct((prev: any) => {
                              let temp = [...prev];
                              temp[key].quantity += 1;
                              return temp;
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div 
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "20px",
              width: "100%"
            }}
          >
            <div className="card">
              <div className="card_head" style={{ marginBottom: "15px" }}>
                <h2>Customer Details</h2>
                {!isUserAccountVerified && (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
                    <button
                      className={styles.add_btn}
                      onClick={() => setUserToggle((prev) => !prev)}
                    >
                      {userToggle ? (
                        <>Cancel</>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faAdd} /> New Customer
                        </>
                      )}
                    </button>
                    {userToggle && (
                      <button
                        className={styles.add_btn}
                        onClick={handleUserCreate}
                      >
                        Save
                      </button>
                    )}
                  </div>
                )}
              </div>
              {userToggle ? (
                <div 
                  style={{
                    display: "grid", 
                    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                    gap: "15px"
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <InputField
                      type={"email"}
                      isLabel={true}
                      lable={"Email"}
                      value={email}
                      onChange={(e: any) => {
                        if (!otpSent) {
                          setEmail(e.target.value);
                        }
                      }}
                      placeholder="Enter Email"
                    />
                    {email !== "" && (
                      <div 
                        style={{
                          display: "flex", 
                          flexWrap: "wrap", 
                          gap: "10px",
                          marginTop: "10px"
                        }}
                      >
                        {verified ? (
                          <span
                            style={{
                              color: "green",
                              fontSize: "1rem",
                              display: "flex",
                              alignItems: "center",
                              padding: "8px 12px",
                              background: "#f0f8f0",
                              borderRadius: "5px",
                            }}
                          >
                            Verified
                          </span>
                        ) : (
                          <>
                            <button
                              style={{
                                padding: "8px 16px",
                                background: "black",
                                color: "white",
                                borderRadius: "8px",
                                border: "none",
                                fontSize: "0.9rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                sendOtp();
                                setOptSent(true);
                              }}
                            >
                              {otpSent ? "Resend OTP" : "Verify"}
                            </button>
                            {otpSent && (
                              <button
                                style={{
                                  padding: "8px 16px",
                                  background: "black",
                                  color: "white",
                                  borderRadius: "8px",
                                  border: "none",
                                  fontSize: "0.9rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOptSent(false);
                                }}
                              >
                                Edit
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  {otpSent && !verified && (
                    <div style={{ position: "relative" }}>
                      <InputField
                        type={"number"}
                        isLabel={true}
                        lable={"Enter OTP to verify email"}
                        value={otp}
                        onChange={(e: any) => {
                          setOtp(e.target.value);
                        }}
                        placeholder="Enter OTP"
                      />
                      {String(otp).length === 6 && (
                        <button
                          style={{
                            marginTop: "10px",
                            padding: "8px 16px",
                            background: "black",
                            color: "white",
                            borderRadius: "8px",
                            border: "none",
                            fontSize: "0.9rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOptSent(true);
                            verifyOtp();
                          }}
                        >
                          Verify
                        </button>
                      )}
                    </div>
                  )}
                  {verified && (
                    <>
                      <InputField
                        type={"text"}
                        isLabel={true}
                        lable={"First Name"}
                        value={firstName}
                        onChange={(e: any) => {
                          setFirstName(e.target.value);
                        }}
                        placeholder="Enter First Name"
                      />
                      <InputField
                        type={"text"}
                        isLabel={true}
                        lable={"Last Name"}
                        value={lastName}
                        onChange={(e: any) => {
                          setLastName(e.target.value);
                        }}
                        placeholder="Enter Last Name"
                      />
                      <InputField
                        type={"number"}
                        isLabel={true}
                        lable={"Phone No."}
                        value={mobileNumber}
                        onChange={(e: any) => {
                          setMobileNumber(e.target.value);
                        }}
                        placeholder="Enter Phone No."
                      />
                      <InputField
                        type="text"
                        isLabel={true}
                        lable={"Address Line One"}
                        value={billingAddress.addLineOne}
                        onChange={(e: any) => {
                          setBillingAddress((prev) => ({
                            ...prev,
                            addLineOne: e.target.value,
                          }));
                        }}
                      />
                      <InputField
                        type="text"
                        isLabel={true}
                        lable={"Address Line Two"}
                        value={billingAddress.addLineTwo}
                        onChange={(e: any) => {
                          setBillingAddress((prev) => ({
                            ...prev,
                            addLineTwo: e.target.value,
                          }));
                        }}
                      />
                      <InputField
                        type="text"
                        isLabel={true}
                        lable={"City"}
                        value={billingAddress.city}
                        onChange={(e: any) => {
                          setBillingAddress((prev) => ({
                            ...prev,
                            city: e.target.value,
                          }));
                        }}
                      />
                      <InputField
                        type="text"
                        isLabel={true}
                        lable={"State"}
                        value={billingAddress.state}
                        onChange={(e: any) => {
                          setBillingAddress((prev) => ({
                            ...prev,
                            state: e.target.value,
                          }));
                        }}
                      />
                      <InputField
                        type="number"
                        isLabel={true}
                        lable={"Pincode"}
                        value={billingAddress.pincode}
                        onChange={(e: any) => {
                          setBillingAddress((prev) => ({
                            ...prev,
                            pincode: e.target.value,
                          }));
                        }}
                      />
                    </>
                  )}
                </div>
              ) : (
                <InputField
                  type={"select"}
                  options={allUsers?.map((val: any) => ({
                    title: val.email,
                    value: val._id,
                  }))}
                  accessText={"title"}
                  accessValue={"value"}
                  isLabel={true}
                  lable={"Select Customer"}
                  value={""}
                  onChange={() => {}}
                  placeholder="Select Customer"
                />
              )}
            </div>
            <div className="card">
              <div>
                <h2>Discount</h2>
                <InputField
                  type={"select"}
                  options={discounts}
                  accessText={"discountTitle"}
                  accessValue={"_id"}
                  isLabel={true}
                  lable={"Select Discount"}
                  value={selectedDiscount}
                  onChange={selectDiscount}
                  placeholder="Select Discount"
                />
              </div>
            </div>
            <div className="card">
              <h2>Mode Of Payment</h2>
              <InputField
                type={"select"}
                options={[
                  { title: "Cash", value: "cod" },
                  { title: "UPI 1", value: "upi1" },
                  { title: "UPI 2", value: "upi2" },
                  { title: "Wallet", value: "wallet" },
                  { title: "Credit Card", value: "credit" },
                  { title: "Debit Card", value: "debit" },
                ]}
                accessText={"title"}
                accessValue={"value"}
                isLabel={true}
                lable={"Select Payment Mode"}
                value={payment}
                onChange={(e: any) => setPayment(e.target.value)}
                placeholder="Select Payment Mode"
              />
            </div>
            <div className="card">
              <h2>Payment Status</h2>
              <InputField
                type={"select"}
                options={[
                  { title: "Paid", value: "paid" },
                  { title: "UnPaid", value: "unpaid" },
                ]}
                accessText={"title"}
                accessValue={"value"}
                isLabel={true}
                lable={"Select Payment Status"}
                value={paymentStatus}
                onChange={(e: any) => setPaymentStatus(e.target.value)}
                placeholder="Select Payment Status"
              />
            </div>
          </div>
        </div>
      </ContentBody>
    </Content>
  );
};

export default AddProductOrder;