import {
  faAdd,
  faCheckCircle,
  faCircle,
  faCircleDot,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../InputField";
import { useAdminContext } from "../../Store/adminContext";
import countries from "../../data/countries";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import CustomButton from "../CustomButton";
import axios from "axios";

const StoreSetup = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const { admin, updateAdmin, getAdminData }: any = useAdminContext();

  const [country, setCountry] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  const [countryLoading, setCountryLoading] = useState(false);
  const [tempPayment, setTempPayment] = useState("");
  const [complianceState, setComplianceState] = useState(false);

  const PLAN_TIERS = {
    "677cbc603397a27ca3ce1819": "Starter",
    "6740b7f8a30b4510370bba2e": "Growth",
    "6740b73ba30b4510370bba18": "Pro",
  } as const;

  useEffect(() => {
    setCountry(admin?.address?.country);
  }, [admin]);

  useEffect(() => {
    checkForPolicies();
  }, []);

  const checkForPolicies = () => {
    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }compliances?identifier=${localStorage.getItem("identifier")}`,
      method: "get",
    })
      .then((res: any) => {
        setComplianceState(res.data.compliances.length !== 0);
      })
      .catch(() => {});
  };

  const handleCountryUpdate = () => {
    setCountryLoading(true);

    const update: any = { address: { ...admin.address, country: country } };

    if (country === "India") {
      update.kycSubmitted = "not submitted";
    } else {
      update.kycSubmitted = "approved";
    }

    updateAdmin({ address: { ...admin.address, country: country } })
      .then(() => {
        toast.success("Country Updated Successfully.");
        getAdminData();
        setCountryLoading(false);
        setIsChanged(false);
      })
      .catch(() => {
        toast.error("Something went wrong while updating country.");
        setCountryLoading(false);
      });
  };

  const isPgAvailable =
    admin.integration.filter(
      (v) =>
        v.integrationName.toLowerCase().trim() === "phonepe" ||
        v.integrationName.toLowerCase().trim() === "razorpay"
    ).length !== 0;

  if (
    admin?.address?.country &&
    admin?.kycSubmitted === "approved" &&
    admin?.selectedPlan &&
    isPgAvailable &&
    complianceState
  )
    return <></>;

  console.log("admin", admin);

  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        padding: "15px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        background: "var(--blue-light)",
        borderRadius: "10px",
        border: ".785px solid var(--blue-dark)",
      }}
    >
      <p
        style={{
          // fontSize: "1.3rem",
          paddingBottom: "5px",
          color: "var(--brand-color)",
          fontWeight: 500,
        }}
        className="text-heading"
      >
        Let's Setup your store
      </p>
      <ul
        style={{
          listStyle: "none",
          fontSize: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {/* Country */}
        <li
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            borderRadius: "10px",
            background: "#fefefe",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
            className="items-center"
          >
            {!admin?.address?.country ? (
              <div
                style={{
                  height: "1.1rem",
                  width: "1.1rem",
                  border: "1px solid var(--brand-color)",
                  borderRadius: "100%",
                  marginTop: "5px",
                }}
              ></div>
            ) : (
              <FontAwesomeIcon
                icon={faCheckCircle}
                type="regular"
                style={{
                  color: "var(--brand-color)",
                  paddingTop: "2px",
                  fontSize: "1.1rem",
                }}
              />
            )}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div>
                <p className="text-primary">COUNTRY</p>
                <p className="text-secondary text-tertiary_color">
                  Select the country where your business is located.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "1rem",
                }}
              >
                <InputField
                  type="select"
                  options={countries.map((v) => ({
                    title: v.name,
                    value: v.name,
                  }))}
                  accessText="title"
                  accessValue="value"
                  value={country}
                  onChange={(e: any) => {
                    setCountry(e.target.value);
                    setIsChanged(true);
                  }}
                  placeholder="Select Country"
                  isLabel
                />
              </div>
            </div>
            {isChanged && (
              <>
                <CustomButton
                  size="md"
                  variant="filled"
                  text="Save"
                  parentClass="ml-auto"
                  disabled={countryLoading}
                  loading={countryLoading}
                  onClick={() => {
                    if (!countryLoading) {
                      handleCountryUpdate();
                    }
                  }}
                />
              </>
            )}
          </div>
        </li>

        {/* KYC */}
        {admin?.address?.country &&
          admin?.address.country.toLowerCase().trim() === "india" && (
            <li
              style={{
                border: "1px solid lightgrey",
                padding: "15px",
                borderRadius: "10px",
                background: "#fefefe",
              }}
            >
              <div
                style={{
                  display: "flex",

                  gap: "10px",
                }}
                className="items-center"
              >
                {admin?.kycSubmitted === "not submitted" ? (
                  <div
                    style={{
                      height: "1.1rem",
                      width: "1.1rem",
                      border: "1px solid var(--brand-color)",
                      borderRadius: "100%",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : admin?.kycSubmitted === "submitted" ? (
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{
                      color: "orange",
                      paddingTop: "2px",
                      fontSize: "1.1rem",
                    }}
                  />
                ) : admin?.kycSubmitted === "draft" ? (
                  <FontAwesomeIcon
                    icon={faCircleDot}
                    style={{
                      color: "orange",
                      paddingTop: "2px",
                      fontSize: "1.1rem",
                    }}
                  />
                ) : admin?.kycSubmitted === "rejected" ? (
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{
                      color: "red",
                      paddingTop: "2px",
                      fontSize: "1.1rem",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      color: "var(--brand-color)",
                      paddingTop: "2px",
                      fontSize: "1.1rem",
                    }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div>
                    <p className="text-primary">
                      COMPLETE KYC
                      {admin?.kycSubmitted === "submitted" && (
                        <span
                          style={{
                            background: "var(--blue-light)",
                            color: "var(--blue-dark)",
                            padding: "3px 5px",
                            borderRadius: "5px",
                          }}
                          className="text-primary"
                        >
                          Sent For Approval
                        </span>
                      )}
                      {admin?.kycSubmitted === "draft" && (
                        <span
                          style={{
                            background: "var(--blue-light)",
                            color: "var(--blue-dark)",
                            padding: "3px 5px",
                            borderRadius: "5px",
                          }}
                          className="text-primary"
                        >
                          Saved as Draft
                        </span>
                      )}
                      {admin?.kycSubmitted === "approved" && (
                        <span
                          style={{
                            background: "var(--blue-light)",
                            color: "var(--blue-dark)",
                            padding: "3px 5px",
                            borderRadius: "5px",
                          }}
                          className="text-primary"
                        >
                          Approved
                        </span>
                      )}
                      {admin?.kycSubmitted === "rejected" && (
                        <span
                          style={{
                            background: "rgba(255,0,0,.1)",
                            color: "red",
                            padding: "3px 5px",
                            borderRadius: "5px",
                          }}
                          className="text-primary"
                        >
                          Rejected, resubmit your kyc
                        </span>
                      )}
                    </p>
                    <p className="text-secondary text-tertiary_color">
                      Start updating your website and accepting orders online by
                      completing KYC.
                    </p>
                  </div>
                  {admin?.kycSubmitted === "rejected" && (
                    <div style={{ color: "red" }}>
                      <p>Rejection Reasons :</p>
                      <ul style={{ listStyle: "inside" }}>
                        {admin?.rejectionReasons?.map((v) => {
                          return <li>{v}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                {admin?.kycSubmitted !== "submitted" &&
                  admin?.kycSubmitted !== "approved" && (
                    <CustomButton
                      parentClass="ml-auto"
                      size="md"
                      variant="filled"
                      text={
                        admin?.kycSubmitted === "rejected"
                          ? "Resubmit KYC"
                          : "Complete KYC"
                      }
                      onClick={() => {
                        // navigate("/kyc-registration")
                        navigate("/onboarding/mobile");
                      }}
                    />
                  )}
              </div>
            </li>
          )}

        {/* Plan */}
        <li
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            borderRadius: "10px",
            background: "#fefefe",
            opacity: admin?.address?.country
              ? admin?.address?.country.toLowerCase() === "india"
                ? admin?.kycSubmitted !== "not submitted"
                  ? "1"
                  : ".5"
                : "1"
              : ".5",
          }}
        >
          <div
            style={{ display: "flex", gap: "10px" }}
            className="items-center"
          >
            {!admin?.selectedPlan ? (
              <div
                style={{
                  height: "1.1rem",
                  width: "1.1rem",
                  border: "1px solid var(--brand-color)",
                  borderRadius: "100%",
                  marginTop: "5px",
                }}
              ></div>
            ) : (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  color: "var(--brand-color)",
                  paddingTop: "2px",
                  fontSize: "1.1rem",
                }}
              />
            )}

            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div>
                <span
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <p className="text-primary uppercase">Select A Plan </p>
                  <span
                    style={{
                      background: "var(--blue-light)",
                      color: "var(--blue-dark)",
                      padding: "3px 5px",
                      borderRadius: "5px",
                    }}
                    className="text-primary"
                  >
                    {PLAN_TIERS[admin?.selectedPlan as keyof typeof PLAN_TIERS]}
                  </span>
                </span>
                <p className="text-secondary text-tertiary_color">
                  Select the plan that best suits your requirements and aligns
                  with your needs.
                </p>
              </div>
            </div>

            <CustomButton
              disabled={
                admin?.address?.country
                  ? admin?.address?.country.toLowerCase() === "india"
                    ? admin?.kycSubmitted
                      ? false
                      : true
                    : false
                  : true
              }
              parentClass="ml-auto"
              size="md"
              variant="filled"
              text="Change Plan"
              onClick={() => navigate("/select-plan")}
            />
          </div>
        </li>

        {/* Payment Gateway */}
        {admin?.address?.country &&
          admin?.address.country.toLowerCase().trim() === "india" && (
            <li
              style={{
                border: "1px solid lightgrey",
                padding: "15px",
                borderRadius: "10px",
                background: "#fefefe",
                opacity: admin?.selectedPlan ? "1" : ".5",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {!isPgAvailable &&
                tempPayment !== "do not have one" &&
                !localStorage?.getItem(
                  `${localStorage.getItem("identifier")}_pg`
                ) ? (
                  <div
                    style={{
                      height: "1.1rem",
                      width: "1.1rem",
                      border: "1px solid var(--brand-color)",
                      borderRadius: "100%",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : tempPayment !== "do not have one" ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      color: "var(--brand-color)",
                      paddingTop: "1px",
                      fontSize: "1.1rem",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      color: "var(--brand-color)",
                      paddingTop: "1px",
                      fontSize: "1.1rem",
                    }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    flex: 1,
                  }}
                >
                  <div>
                    <p className="text-primary uppercase">Payment Gateway </p>
                    <p className="text-secondary text-tertiary_color">
                      Seamlessly connect and manage online payments with ease.
                    </p>
                  </div>
                  {admin.integration.filter(
                    (v) =>
                      v.integrationName.toLowerCase().trim() === "phonepe" ||
                      v.integrationName.toLowerCase().trim() === "razorpay"
                  ).length === 0 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "1rem",
                        marginTop: "5px",
                      }}
                    >
                      <InputField
                        placeholder="Select Payment Gateway"
                        type="select"
                        isLabel={false}
                        lable={"Select Payment Gateway"}
                        options={[
                          { title: "Razorpay", value: "razorpay" },
                          { title: "PhonePe", value: "phonepe" },
                          {
                            title: "I don't have payment gateway",
                            value: "do not have one",
                          },
                        ]}
                        value={
                          tempPayment
                            ? tempPayment
                            : localStorage.getItem(
                                `${localStorage.getItem("identifier")}_pg`
                              )
                            ? "do not have one"
                            : ""
                        }
                        onChange={(e: any) => {
                          if (e.target.value === "do not have one") {
                            localStorage.setItem(
                              `${localStorage.getItem("identifier")}_pg`,
                              "true"
                            );
                          } else {
                            localStorage.removeItem(
                              `${localStorage.getItem("identifier")}_pg`
                            );
                          }
                          setTempPayment(e.target.value);
                        }}
                        accessText={"title"}
                        accessValue="value"
                      />
                    </div>
                  )}
                </div>
                {tempPayment === "do not have one" ||
                localStorage.getItem(
                  `${localStorage.getItem("identifier")}_pg`
                ) ? (
                  <a
                    style={{
                      color: "var(--brand-color)",
                      height: "50px",
                      marginTop: "25px",
                      background: "none",
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                    href="https://rzp.io/i/UGdZFnjv"
                    target="_blank"
                    className=" mt-auto"
                  >
                    Get now?
                  </a>
                ) : admin.integration.filter(
                    (v) =>
                      v.integrationName.toLowerCase().trim() === "phonepe" ||
                      v.integrationName.toLowerCase().trim() === "razorpay"
                  ).length === 0 ? (
                  <CustomButton
                    disabled={admin?.selectedPlan ? false : true}
                    icon="plus"
                    parentClass="ml-auto"
                    size="md"
                    variant="filled"
                    text="Payment Gateway"
                    onClick={() => {
                      navigate(`/apps?add=${tempPayment}`);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </li>
          )}

        {/* Policy */}
        <li
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            borderRadius: "10px",
            background: "#fefefe",
            opacity:
              admin.integration.filter(
                (v) =>
                  v.integrationName.toLowerCase().trim() === "phonepe" ||
                  v.integrationName.toLowerCase().trim() === "razorpay"
              ).length === 0 && tempPayment !== "do not have one"
                ? "0.5"
                : tempPayment === "do not have one"
                ? "1"
                : "1",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {!complianceState ? (
              <div
                style={{
                  height: "1.1rem",
                  width: "1.1rem",
                  border: "1px solid var(--brand-color)",
                  borderRadius: "100%",
                  marginTop: "2px",
                }}
              ></div>
            ) : (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  color: "var(--brand-color)",
                  paddingTop: "0px",
                  fontSize: "1.1rem",
                }}
              />
            )}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div>
                <p className="text-primary uppercase">Store Policy </p>
                <p className="text-secondary text-tertiary_color">
                  Add store policy to ensure compliance and improve trust.
                </p>
              </div>
            </div>
            {!complianceState && (
              <CustomButton
                disabled={
                  admin.integration.filter(
                    (v: any) =>
                      v.integrationName.toLowerCase().trim() === "phonepe" ||
                      v.integrationName.toLowerCase().trim() === "razorpay"
                  ).length === 0 && tempPayment !== "do not have one"
                    ? true
                    : tempPayment === "do not have one"
                    ? false
                    : false
                }
                icon="plus"
                text="Store Policies"
                parentClass="ml-auto"
                size="md"
                variant="filled"
                onClick={() => navigate("/compliance/add")}
              />
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default StoreSetup;
