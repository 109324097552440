import { useCallback, useEffect, useState } from "react";
import { blog_access_keys } from "../../Utils/Data";
import withRouter from "../../Components/withRouter";
import WithTableView from "../../GenericPages/WithTableView";
import axios from "axios";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import withContext from "../../Store/withContext";
import Pagination from "../../Components/Table/Pagination";
import Modal from "../../Components/Modal";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import DraggableTable from "../../Components/DraggableTable";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import { useLocation, useNavigate } from "react-router-dom";
import langObj from "../../Utils/lang";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import ContentOuterHead from "../../Components/Content/contentOuterHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styles from "./blogs.module.css";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import DynamicDataTable from "../../UI/DynamicTable";
import { getResourceLimit } from "../../Utils/checkPermissions/planPermissions";

interface Blog {
  id: string;
  priority: number;
  blogTitle: string;
  category: string;
  description: string;
  author: string;
  image: string;
  slug: string;
  tags: string;
  buttonOneUrl: string;
  buttonTwoUrl: string;
  status: string;
}

const Blogs = ({ router }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  const { lang }: any = useLangContext();

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        getData();
      }, 1500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      getData();
    }
  }, [location.search, searchTerm]);

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_BASE_URL
      }blogs?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${currentPage}&limit=${currentLimit}&${
        searchTerm ? `search=${searchTerm}` : ""
      }`,
    })
      .then((res: any) => {
        console.log("res", res);
        let temp = res?.data?.blogs.map((val: any) => ({
          ...val,
          id: val._id,
        }));
        let temp_blogs = temp.sort((a: any, b: any) => a.priority - b.priority);

        let last_index = -1;
        temp_blogs = temp_blogs.map((v: any, i: any) => {
          let emp = { ...v };

          if (v?.priority) {
            last_index = v.priority;
          } else {
            emp.priority = last_index + 1;
            last_index = emp.priority;
          }
          return emp;
        });

        setBlogs(temp_blogs);
        setLoading(false);
        setTotal(res.data?.totalBlogsCount);
        setPage(currentPage);
        setLimit(currentLimit);
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleSwap = async (draggedRowIndex, targetRowIndex) => {
    if (admin.role === "subadmin" && !admin.blog_access.write) {
      toast.error("You don't have permission to perform this action.");
      return;
    }

    setLoading(true);

    let tempBlogs = [...blogs];
    const draggedBlog = tempBlogs[draggedRowIndex];
    const targetBlog = tempBlogs[targetRowIndex];

    if (!draggedBlog || !targetBlog) {
      setLoading(false);
      return;
    }

    tempBlogs.splice(draggedRowIndex, 1);
    tempBlogs.splice(targetRowIndex, 0, draggedBlog);
    tempBlogs = tempBlogs.map((blog, index) => ({
      ...blog,
      priority: index + 1,
    }));

    const updatePromises = tempBlogs.map((blog) =>
      callApi({
        method: "patch",
        data: { priority: blog.priority },
        url: blog.id,
        type: "blogs",
        header: true,
        identifier: true,
      })
    );

    try {
      await Promise.all(updatePromises);
      setBlogs(tempBlogs);
    } catch (error) {
      toast.error("Failed to update blog priorities");
      getData();
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setModal(false);

    const promise = new Promise((resolve, reject) => {
      callApi({
        method: "delete",
        url: inFocus,
        data: {},
        header: true,
        type: "blogs",
      })
        .then((res) => {
          setInFocus("");
          resolve(true);
        })
        .catch((err) => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        getData();
      });
  };

  const handleAction = (val: string, id: string) => {
    if (val === "delete") {
      setModal(true);
      setInFocus(id);
    }

    if (val === "archive" || val === "unarchive") {
      const status = val === "archive" ? "draft" : "live";

      const promise = new Promise((resolve, reject) => {
        callApi({
          method: "patch",
          data: { status },
          url: id,
          type: "blogs",
          header: true,
        })
          .then((res) => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });

      const actionText = val === "archive" ? "Archiving" : "UnArchiving";

      toast
        .promise(
          promise,
          {
            loading: `${actionText}...`,
            success: `${actionText} Successfully.`,
            error: `Error when ${actionText.toLowerCase()} the entry.`,
          },
          { success: { duration: 2000 }, error: { duration: 2000 } }
        )
        .then(() => {
          getData();
        });
    }
  };

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  if (loading)
    return (
      <>
        <TableLoading
          head={[
            { title: "" },
            { title: "S.No." },
            { title: "Blog Title" },
            { title: "Status" },
            { title: "" },
          ]}
        />
      </>
    );

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => setModal(false)}
        onBtn2Click={handleDelete}
        state={modal}
      />
      <Content>
        <ContentHead
          title={langObj.blog[lang]}
          showBtn1={admin.role === "subadmin" ? admin.blog_access.write : true}
          btn1Name="btn1"
          btn1Text={langObj["New Blog"][lang]}
          btn1type="filled"
          iconType1="plus"
          onClickHandler={() => {
            if (isAllowed) {
              if (getResourceLimit(admin.selectedPlan, "blogs") > total) {
                router.navigate("/blog/create_blog");
              } else {
                toast.error("You have reached your plan's limit.");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search blogs ..."
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          <DynamicDataTable
            data={blogs}
            columns={[
              { accessorKey: "title", header: "Blog Title" },
              { accessorKey: "status", header: "Status" },
            ]}
            enableRowReorder={true}
            onReorder={handleSwap}
            actionsColumns={[
              {
                name: "Edit",
                action: (row) => {
                  router.navigate("/blog/update_blog/" + row);
                },
              },
              {
                name: "Delete",
                action: (row) => {
                  setInFocus(row);
                  handleAction("delete", row);
                },
              },
            ]}
            title={"Blogs"}
            onAdd={() => {
              router.navigate("/blog/create_blog");
            }}
            onPaginationChange={(newPage, newLimit) => {
              setPage(newPage);
              setLimit(newLimit);
              const searchParams = new URLSearchParams(location.search);
              searchParams.set("page", newPage.toString());
              searchParams.set("limit", newLimit.toString());
              navigate(`/blogs?${searchParams.toString()}`);
            }}
            totalRows={total}
            initialPageSize={limit}
            initialPageIndex={page}
          />
        </ContentBody>
      </Content>
    </>
  );
};

export default withRouter(withContext(Blogs));
