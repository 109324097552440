import React, { forwardRef, useEffect, useState } from "react";
import styles from "./invoice.module.css";
import { numberToWords } from "../../Utils/numberToWordsConverter";
import SubAdminPanel from "../Settings/subAdminPanel/SubAdminPanel";
import {
  convertImageToDataURL,
  createPlaceholderImage,
} from "../../Utils/imageUtils";
import PrintableLogo from "./PrintableLogo";

const Invoice = forwardRef((props: any, ref: any) => {
  let { user, order, admin } = props;
  const [logoDataUrl, setLogoDataUrl] = useState<string | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrinting(true);
    };

    const handleAfterPrint = () => {
      setIsPrinting(false);
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  useEffect(() => {
    const loadLogo = async () => {
      if (!admin?.logoUrl) return;

      try {
        // Try to convert the image to a data URL
        const dataUrl = await convertImageToDataURL(admin.logoUrl);
        if (dataUrl) {
          setLogoDataUrl(dataUrl);
        } else {
          // If conversion fails, create a placeholder
          setLogoDataUrl(createPlaceholderImage("LOGO"));
        }
      } catch (error) {
        console.error("Error loading logo:", error);
        // Create a placeholder if there's an error
        setLogoDataUrl(createPlaceholderImage("LOGO"));
      }
    };

    loadLogo();
  }, [admin?.logoUrl]);

  if (!Object.keys(order)?.length) {
    return <></>;
  }

  const calculateAmt = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ) => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return (subTotal * (100 / (100 + gst))).toFixed(2);
    }
    // this will return the price after adding tax .
    return (subTotal + (gst / 100) * subTotal).toFixed(2);
  };

  const calculateTaxAmount = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ): string => {
    if (isInclusive) {
      return (subTotal - (subTotal / 1 + gst / 100)).toFixed(2);
    }
    // this will return the price after adding tax .
    return ((gst / 100) * subTotal).toFixed(2);
  };

  const totalIGSTAmount = () => {
    const totaligst = order?.products?.reduce((acc: any, product: any) => {
      const taxAmount = calculateTaxAmount(
        product?.productId?.pricing?.price,
        product?.productId?.pricing?.inclusiveOfGST,
        product?.productId?.pricing?.igst
      );
      const taxAmountWithQuantity = Number(taxAmount) * product.quantity;
      return acc + taxAmountWithQuantity;
    }, 0);
    return totaligst;
  };

  //  order = order[0];
  return (
    <div className={styles.invoice} ref={ref}>
      <style type="text/css" media="print">
        {
          "\
  @page { size: 220mm 297mm; }\
"
        }
      </style>
      <div className={styles.inner_container}>
        <div className={styles.header}>
          {admin?.logoUrl && (
            <div style={{ marginBottom: "20px" }}>
              {admin?.identifier === "jssolitaire.in" ? (
                <img
                  src="/logo/js.png"
                  alt="Company Logo"
                  style={{
                    maxHeight: "70px",
                    width: "auto",
                    border: "1px solid #ddd",
                  }}
                />
              ) : (
                <PrintableLogo logoUrl={admin.logoUrl} />
              )}
            </div>
          )}
          <div className={styles.companyDetails}>
            <h2>{admin.address?.companyName}</h2>
            <p>{admin?.address?.addLineOne}</p>
            <p>{admin?.address?.addLineTwo}</p>

            <p>{admin?.address?.landmark} </p>
            <p> {admin?.address?.city}</p>

            <p>{admin.address?.companyStateZip}</p>
            <p>{admin.address?.companyCountry}</p>
          </div>
          <div className={styles.invoiceTitle}>
            <h1>TAX INVOICE</h1>
          </div>
        </div>

        <div className={styles.invoiceDetails}>
          <div className={styles.detailsColumn}>
            <p>
              <strong>Order Number:</strong>
              <span>{order.orderRefId}</span>
            </p>
          </div>
          <div className={styles.detailsColumn}>
            <p>
              <strong>Order Date:</strong>
              <span>{order?.date}</span>
            </p>
          </div>
          <div className={styles.detailsColumn}>
            <p>
              <strong>Invoice Date:</strong>
              <span>{new Date().toLocaleDateString("en-GB")}</span>
            </p>
          </div>
          <div className={styles.detailsColumn}>
            <p>
              <strong>Mode of Payment:</strong>
              <span>
                {order?.paymentGatewayDetails?.paymentInstrument?.type || "N/A"}
              </span>
            </p>
          </div>
        </div>
        <div className={styles.customerDetails}>
          <div className={styles.addressContainer}>
            <div className={styles.addressColumn}>
              <h5>
                <strong>Shipped From</strong>
              </h5>
              <div>
                <p>{props?.warehouse?.title}</p>
                <p>
                  {[
                    props?.warehouse?.addressLine1,
                    props?.warehouse?.addressLine2,
                    props?.warehouse?.city,
                    props?.warehouse?.state,
                    props?.warehouse?.pincode,
                  ]
                    .filter((v) => v)
                    .join(", ")}
                </p>
                <p>GSTIN: {admin.gst}</p>
                <p>
                  EMAIL:{" "}
                  <span style={{ textTransform: "lowercase" }}>
                    {admin?.email}
                  </span>
                </p>
                <p>PHONE: +91 {props?.warehouse?.contact?.mobileNumber}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.customerDetails}>
          <div className={styles.addressContainer}>
            <div className={styles.addressColumn}>
              <h5>
                <strong>Customer Billing Address</strong>
              </h5>
              <div>
                <p>{order.billingAddress?.name}</p>
                <p>
                  {[
                    order.billingAddress?.addLineOne,
                    order.billingAddress?.addLineTwo,
                    order.billingAddress?.landmark,
                    order.billingAddress?.city,
                    order.billingAddress?.state,
                    order.billingAddress?.pincode,
                  ]
                    .filter((v) => v)
                    .join(", ")}
                </p>
              </div>
            </div>
            <div className={styles.addressDivider}></div>
            <div className={styles.addressColumn}>
              <h5>
                <strong>Customer Shipping Address</strong>
              </h5>
              <div>
                <p>{order.shippingAddress?.name}</p>
                <p>
                  {[
                    order.shippingAddress?.addLineOne,
                    order.shippingAddress?.addLineTwo,
                    order.shippingAddress?.landmark,
                    order.shippingAddress?.city,
                    order.shippingAddress?.state,
                    order.shippingAddress?.pincode,
                  ]
                    .filter((v) => v)
                    .join(", ")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th style={{ textAlign: "left" }}>Item & Description</th>
                <th>Qty.</th>
                <th>Rate</th>
                {order.billingAddress?.state?.toLowerCase().trim() ===
                props.warehouse.state.toLowerCase().trim() ? (
                  <>
                    <th colSpan={2} style={{ textAlign: "center" }}>
                      SGST
                    </th>
                    <th colSpan={2} style={{ textAlign: "center" }}>
                      CGST
                    </th>
                  </>
                ) : (
                  <th colSpan={2} style={{ textAlign: "center" }}>
                    IGST
                  </th>
                )}
                <th>Amount</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                {order.billingAddress?.state?.toLowerCase().trim() ===
                props.warehouse.state.toLowerCase().trim() ? (
                  <>
                    <th>%</th>
                    <th>Amt</th>
                    <th>%</th>
                    <th>Amt</th>
                  </>
                ) : (
                  <>
                    <th>%</th>
                    <th>Amt</th>
                  </>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {order.products?.map((item: any, ind: any) => {
                const igstAmount = Number(
                  calculateTaxAmount(
                    item?.productId?.pricing?.price,
                    item?.productId?.pricing?.inclusiveOfGST,
                    item?.productId?.pricing?.igst
                  )
                );
                const sgstAmount =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? (igstAmount / 2).toFixed(3)
                    : 0;
                const cgstAmount =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? (igstAmount / 2).toFixed(3)
                    : 0;
                const igstPercentage = item?.productId?.pricing?.igst;
                const sgstPercentage =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? igstPercentage / 2
                    : 0;
                const cgstPercentage =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? igstPercentage / 2
                    : 0;

                return (
                  <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td style={{ textAlign: "left" }}>
                      {item.productId?.title}
                    </td>
                    <td>{item?.quantity || "NA"}</td>
                    <td>
                      ₹{" "}
                      {item?.productId?.pricing?.inclusiveOfGST
                        ? calculateAmt(
                            item?.productId?.pricing?.price,
                            item?.productId?.pricing?.inclusiveOfGST,
                            item?.productId.pricing?.igst
                          )
                        : item?.productId?.pricing?.price}
                    </td>
                    {order.billingAddress?.state?.toLowerCase().trim() ===
                    props.warehouse.state.toLowerCase().trim() ? (
                      <>
                        <td>{sgstPercentage}%</td>
                        <td>₹ {sgstAmount}</td>
                        <td>{cgstPercentage}%</td>
                        <td>₹ {cgstAmount}</td>
                      </>
                    ) : (
                      <>
                        <td>{igstPercentage}%</td>
                        <td>₹ {igstAmount.toFixed(3)}</td>
                      </>
                    )}
                    <td>
                      ₹{" "}
                      {item?.quantity *
                        Number(
                          calculateAmt(
                            item?.productId?.pricing?.price,
                            item?.productId?.pricing?.inclusiveOfGST,
                            item?.productId?.pricing?.igst
                          )
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={styles.summary}>
          <div className={styles.amountInWords}>
            <p>
              <strong>Total In Words:</strong>
              <br /> {numberToWords(Number(order.total))}
            </p>
          </div>
          <div className={styles.totalAmount}>
            <p>
              <strong>Sub Total:</strong>₹ {order?.subTotal}
            </p>
            {order?.shippingCharges?.subTotal && (
              <p>
                <strong>Shipping Charge:</strong>₹{" "}
                {order?.shippingCharges?.subTotal}
              </p>
            )}
            {order?.discount?.length !== 0 && (
              <p>
                <strong>Discount:</strong>- ₹{" "}
                {order?.discount[0]?.totalDiscount || 0}
              </p>
            )}
            <p>
              <strong>Total:</strong>₹ {order?.total.toFixed(2)}
            </p>
          </div>
        </div>

        {admin?.bankDetails?.bankName && (
          <div className={styles.footer}>
            <p>
              <strong>Bank Name:</strong> {admin?.bankDetails?.bankName}
            </p>
            {admin?.bankDetails?.branch && (
              <p>
                <strong>Branch Name:</strong>
                {admin?.bankDetails?.branch}
              </p>
            )}
            {admin?.bankDetails?.accountNumber && (
              <p>
                <strong>Account Number:</strong>{" "}
                {admin?.bankDetails?.accountNumber}
              </p>
            )}
            {admin?.bankDetails?.ifsc && (
              <p>
                <strong>IFSC:</strong> {admin?.bankDetails?.ifsc}
              </p>
            )}
            {admin?.bankDetails?.UPI && (
              <p>
                <strong>UPI:</strong> {admin?.bankDetails?.UPI}
              </p>
            )}
            <p style={{ marginTop: "10px" }}>
              <em>
                We declare that this invoice shows the actual price of the
                service described and that all the particulars are true and
                correct.
              </em>
            </p>
          </div>
        )}
        <div
          className={styles.table}
          style={{
            pageBreakBefore: order.products?.length >= 5 ? "always" : "unset",
          }}
        >
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>HSN/SAC</th>
                <th>Taxable Amount</th>
                {order.billingAddress?.state?.toLowerCase().trim() ===
                props.warehouse.state.toLowerCase().trim() ? (
                  <>
                    <th colSpan={2} style={{ textAlign: "center" }}>
                      SGST
                    </th>
                    <th colSpan={2} style={{ textAlign: "center" }}>
                      CGST
                    </th>
                  </>
                ) : (
                  <th colSpan={2} style={{ textAlign: "center" }}>
                    IGST
                  </th>
                )}
                <th>Total Tax Amount</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                {order.billingAddress?.state?.toLowerCase().trim() ===
                props.warehouse.state.toLowerCase().trim() ? (
                  <>
                    <th>%</th>
                    <th>Amt</th>
                    <th>%</th>
                    <th>Amt</th>
                  </>
                ) : (
                  <>
                    <th>%</th>
                    <th>Amt</th>
                  </>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {order.products?.map((item: any, ind: any) => {
                const igstAmount = Number(
                  calculateTaxAmount(
                    item?.productId?.pricing?.price,
                    item?.productId?.pricing?.inclusiveOfGST,
                    item?.productId?.pricing?.igst
                  )
                );
                const sgstAmount =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? (igstAmount / 2).toFixed(3)
                    : 0;
                const cgstAmount =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? (igstAmount / 2).toFixed(3)
                    : 0;
                const igstPercentage = item?.productId?.pricing?.igst;
                const sgstPercentage =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? igstPercentage / 2
                    : 0;
                const cgstPercentage =
                  order.billingAddress?.state?.toLowerCase().trim() ===
                  props.warehouse.state.toLowerCase().trim()
                    ? igstPercentage / 2
                    : 0;

                return (
                  <tr key={ind}>
                    <td>{item?.productId?.hsnsac || "NA"}</td>
                    <td>
                      ₹
                      {item.productId?.pricing?.inclusiveOfGST
                        ? item?.quantity *
                          Number(
                            calculateAmt(
                              item?.productId?.pricing?.price,
                              item?.productId?.pricing?.inclusiveOfGST,
                              item?.productId.pricing?.igst
                            )
                          )
                        : item?.quantity * item?.productId?.pricing?.price}
                    </td>
                    {order.billingAddress?.state?.toLowerCase().trim() ===
                    props.warehouse.state.toLowerCase().trim() ? (
                      <>
                        <td>{sgstPercentage}%</td>
                        <td>₹ {sgstAmount}</td>
                        <td>{cgstPercentage}%</td>
                        <td>₹ {cgstAmount}</td>
                      </>
                    ) : (
                      <>
                        <td>{igstPercentage}%</td>
                        <td>₹ {igstAmount.toFixed(3)}</td>
                      </>
                    )}
                    <td>
                      ₹{" "}
                      {order.billingAddress?.state?.toLowerCase().trim() ===
                      props.warehouse.state.toLowerCase().trim()
                        ? (Number(sgstAmount) + Number(cgstAmount)).toFixed(3)
                        : igstAmount.toFixed(3)}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>Total</td>
                <td>₹{order.subTotal}</td>
                {order.billingAddress?.state?.toLowerCase().trim() ===
                props.warehouse.state.toLowerCase().trim() ? (
                  <>
                    <td colSpan={2}>₹{(totalIGSTAmount() / 2).toFixed(3)}</td>
                    <td colSpan={2}>₹{(totalIGSTAmount() / 2).toFixed(3)}</td>
                  </>
                ) : (
                  <td colSpan={2}>₹{totalIGSTAmount().toFixed(3)}</td>
                )}
                <td>₹{totalIGSTAmount().toFixed(3)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        POWERED BY
        <img src="/logo.png" alt="" style={{ width: "40px", height: "auto" }} />
      </div>
    </div>
  );
});

export default Invoice;
