import styles from "../../Styles/products.module.css";
import InputField from "../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faImage,
  faPalette,
  faPallet,
} from "@fortawesome/free-solid-svg-icons";
import { SketchPicker } from "react-color";
import { useState } from "react";
import { toast } from "react-hot-toast";
import ImageFolder from "../ImageFolder";
import { getFileName } from "../../Utils/getFileName";
import { folder_name } from "../../Utils/UploadImage";

interface IProps {
  state: any;
  handleAddVariant: any;
  handleVariantName: any;
  createCombination: any;
  handleVariantImageAdd: any;
  handleVariantImageRemove: any;
  handleRemoveVariant: any;
  setState: any;
  handleVariantImageName: any;
  handleProductAddInVariant: any;
}

/*
 * Variant Model
 * - options_name : ""
 * - options_values : []
 * - images : []
 * - variantProductId : ""
 * - slug: "",
 * - optionSku: ""
 */

const Variants = (props: IProps) => {
  const [imageUrl, setImageUrl] = useState("");

  const removeImageFromVariant = (index: any, indexx: any) => {
    let temp: any = props.state.variants;
    temp[index].images.splice(indexx, 1);
    props.setState({ variants: [...temp] });
  };

  const removeValue = (index: any, indexx: any) => {
    let temp: any = props.state.variants;
    temp[index].options_values.splice(indexx, 1);
    props.setState({ variants: [...temp] });
  };

  return (
    <>
      <div className={`${styles.field_container}`}>
        <div className={styles.variant_heading}>
          <h3>Variants</h3>
          <button onClick={props.handleAddVariant}>Add</button>

          {/* {props.state.variants.filter(
            (val: any) => val.options_name === "style"
          ).length !== 0 &&
            props.state.variants.filter(
              (val: any) => val.options_name === "design"
            ).length !== 0 && (
              <>
                <div style={{ flexGrow: 1 }}></div>
                <div onClick={props.createCombination}>View Variants</div>
              </>
            )} */}
        </div>
        <div className={styles.variants_container}>
          {[...props.state.variants].map((val: any, index: any) => {
            return (
              <SingleElement
                state={props.state}
                key={index}
                val={val}
                index={index}
                props={props}
                removeValue={removeValue}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                removeImageFromVariant={removeImageFromVariant}
                handleProductAddInVariant={props.handleProductAddInVariant}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const SingleElement = ({
  state,
  val,
  index,
  props,
  removeValue,
  imageUrl,
  setImageUrl,
  removeImageFromVariant,
  handleProductAddInVariant,
}: any) => {
  const [color_name, setColorName] = useState("");
  const [displayColor, setDisplayColor] = useState(false);
  const [color, setColor] = useState("");

  const handleClick = () => {
    setDisplayColor((val) => !val);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setDisplayColor(false);
  };

  const handleColorAdd = () => {
    if (!color_name) {
      toast.error("Color name is required.");
    } else {
      let temp: any = props.state.variants;

      if (
        val.variantProductId &&
        temp[index].options_values.length + temp[index].images.length === 1
      ) {
        toast.error(
          "Only add one color variant when the product is selected. Please use the 'Add Variant' button to include another color variant."
        );
      } else {
        if (color) {
          temp[index].options_values.push(color_name + ":" + color);
          setColor("");
          setColorName("");
          props.setState({ variants: [...temp] });
        } else if (imageUrl) {
          temp[index].images.push({ imageUrl, imageName: color_name });
          setColor("");
          setColorName("");
          setImageUrl("");
          props.setState({ variants: [...temp] });
        }
      }
    }
  };

  const handleImageClick = (e: any) => {
    setImageUrl({
      url: URL.createObjectURL(e.target.files[0]),
      obj: e.target.files[0],
    });
  };

  const drop_down_products = state.category
    ? state.products
        .filter((val: any) => val.category === state.category)
        .map((val: any) => ({
          title: val.title,
          value: val._id,
        }))
    : state.products.map((val: any) => ({
        title: val.title,
        value: val._id,
      }));

  return (
    <>
      <div className={styles.variant_container} key={index}>
        <InputField
          type="select"
          value={val.options_name}
          onChange={(event: any) => {
            props.handleVariantName(event, index);
            let temp = [...props.state.variants];
            temp[index] = {
              ...temp[index],
              options_values: [],
              images: [],
              variantProductId: "",
              slug: "",
              optionSku: "",
            };
            props.setState({ variants: temp });
          }}
          options={[
            { title: "Color", value: "color" },
            { title: "Material", value: "material" },
            // { title: "Style (Layer 1)", value: "style" },
            // { title: "Design (Layer 2)", value: "design" },
            { title: "Size/Capacity", value: "size" },
            { title: "Custom - 1", value: "custom-1" },
            { title: "Custom - 2", value: "custom-2" },
          ]}
          isLabel={true}
          lable="Option name"
          accessValue="value"
          accessText="title"
        />
        {(val.options_name === "style" || val.options_name === "design") && (
          <div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                }}
              >
                Upload Image
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  background: "#EFF5FC",
                  paddingInline: "10px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  color: "#36619B",
                  position: "relative",
                }}
              >
                +
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    opacity: 0,
                    cursor: "pointer",
                  }}
                  onChange={(event: any) =>
                    props.handleVariantImageAdd(event, index)
                  }
                />
              </p>
            </div>
            {/* show image */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                marginTop: "30px",
                gap: "20px",
              }}
            >
              {val.images.map((val: any, indexx: any) => {
                return (
                  <div className={styles.variant_with_img_and_name}>
                    <div
                      style={{
                        position: "relative",
                        width: "150px",
                        flexShrink: 0,
                        border: "1px solid black",
                      }}
                      key={indexx + "_upload_image"}
                    >
                      <img
                        src={val.imageUrl}
                        alt=""
                        style={{
                          height: "100px",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <InputField
                      isLabel={true}
                      lable="Name"
                      value={val.imageName}
                      onChange={(event: any) => {
                        props.handleVariantImageName(index, indexx, event);
                      }}
                      type="text"
                    />
                    <FontAwesomeIcon
                      icon={faClose}
                      color="#36619B"
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        borderRadius: "100%",
                        padding: "5px",
                        cursor: "pointer",
                        aspectRatio: "1/1",
                        background: "#EFF5FC",
                      }}
                      onClick={() =>
                        props.handleVariantImageRemove(index, indexx)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {val.options_name === "style" || val.options_name === "design" ? (
          <></>
        ) : val.options_name === "color" ? (
          <>
            <div className="grid_1">
              <InputField
                type={"select"}
                value={val.variantProductId}
                options={drop_down_products}
                accessText="title"
                accessValue="value"
                isLabel={true}
                lable={"Product"}
                onChange={(e: any) =>
                  handleProductAddInVariant(e.target.value, index)
                }
              />
            </div>
            {val.variantProductId &&
            val.options_values.length + val.images.length === 1 ? (
              <></>
            ) : (
              <div className={styles.color_picker_container}>
                <InputField
                  value={color_name}
                  onChange={(e: any) => setColorName(e.target.value)}
                  isLabel={true}
                  lable={imageUrl ? "Image Name" : "Color Name"}
                  type="text"
                />

                <div className={styles.color_and_add_btn}>
                  <div
                    className={styles.color_part}
                    style={{ background: color }}
                    onClick={handleClick}
                  >
                    <FontAwesomeIcon icon={faPalette} color="grey" />
                    {displayColor && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            // background: "orange",
                            zIndex: 10000,
                          }}
                          onClick={handleClose}
                        ></div>
                        <div
                          className={styles.palette_part}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <SketchPicker
                            color={color}
                            onChange={(color) => setColor(color.hex)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {imageUrl ? (
                    <div
                      className={styles.color_part}
                      style={{ position: "relative" }}
                    >
                      <img
                        src={imageUrl.url}
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        alt=""
                      />
                      <FontAwesomeIcon
                        icon={faClose}
                        color="#36619b"
                        style={{
                          position: "absolute",
                          height: "18px",
                          width: "18px",
                          background: "#eff5fc",
                          padding: "2px",
                          borderRadius: "100%",
                          top: 0,
                          right: 0,
                          transform: "translate(50%, -50%)",
                        }}
                        onClick={() => setImageUrl("")}
                      />
                    </div>
                  ) : (
                    <div className={styles.color_part}>
                      <FontAwesomeIcon color={"grey"} icon={faImage} />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageClick}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          opacity: 0,
                        }}
                      />
                    </div>
                  )}

                  <div
                    className={styles.add_part}
                    onClick={() => {
                      handleColorAdd();
                    }}
                  >
                    Add
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <InputField
              type={"select"}
              value={val.variantProductId}
              options={drop_down_products}
              accessText="title"
              accessValue="value"
              isLabel={true}
              lable={"Product"}
              onChange={(e: any) =>
                handleProductAddInVariant(e.target.value, index)
              }
            />
            <InputField
              type={"text"}
              isLabel={true}
              lable="Option values (Press Enter to add value)"
              // @ts-ignore
              value={props.state.variants[index].temp_text}
              onChange={(event: any) => {
                let temp: any = props.state.variants;
                temp[index].temp_text = event.target.value;
                props.setState({ variants: [...temp] });
              }}
              onKeyPress={(event: any) => {
                if (event.key === "Enter") {
                  let temp: any = props.state.variants;
                  temp[index].options_values.push(temp[index].temp_text);
                  temp[index].temp_text = "";
                  props.setState({ variants: [...temp] });
                }
              }}
            />
          </>
        )}
        <div className={styles.variant_option_values}>
          {val.options_values.map((vall: any, indexx: any) => {
            return val.options_name === "color" ? (
              <div className={styles.color_option_values}>
                <div
                  style={{
                    height: "20px",
                    width: "45px",
                    background: vall?.split(":")[1],
                  }}
                ></div>
                <FontAwesomeIcon
                  icon={faClose}
                  color="grey"
                  onClick={() => {
                    removeValue(index, indexx);
                  }}
                />
              </div>
            ) : (
              <div className={styles.variant_option_value}>
                <p>{vall}</p>
                <FontAwesomeIcon
                  icon={faClose}
                  color="grey"
                  onClick={() => {
                    removeValue(index, indexx);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          {val.images.map((vall: any, indexx: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  width: "fit-content",
                  paddingRight: "3px",
                  position: "relative",
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  color="#36619b"
                  style={{
                    position: "absolute",
                    height: "18px",
                    width: "18px",
                    background: "#eff5fc",
                    padding: "2px",
                    borderRadius: "100%",
                    top: 0,
                    right: 0,
                    transform: "translate(50%, -50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => removeImageFromVariant(index, indexx)}
                />
                <img
                  src={
                    vall.imageUrl.obj ? vall.imageUrl.url : vall.imageUrl.url
                  }
                  style={{
                    width: "100px",
                    aspectRatio: "1/1",
                    borderRadius: "8px 8px 0 0",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  alt=""
                />
                <p
                  style={{
                    padding: "3px",
                    textWrap: "wrap",
                    fontSize: ".8rem",
                  }}
                >
                  {vall.imageName}
                </p>
              </div>
            );
          })}
        </div>
        <div
          className={styles.remove_variant}
          onClick={() => props.handleRemoveVariant(index)}
        >
          <FontAwesomeIcon icon={faClose} color="white" />
        </div>
      </div>
    </>
  );
};

export default Variants;
