import React, { useEffect, useState } from 'react';
import RootLayout from '../RootLayout';
import { useKYC } from '../../../Store/kycContext';
import { handleUpdateDataLocally } from '../../../Apis/kycApi';
import logo from '../../../Assets/images/logo-rounded-bg.png';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';
const Name = () => {
  const { kycData, updateKYCData } = useKYC();
  const [firstName, setFirstName] = useState(kycData.firstName || '');
  const [lastName, setLastName] = useState(kycData.lastName || '');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (kycData.firstName) setFirstName(kycData.firstName);
    if (kycData.lastName) setLastName(kycData.lastName);
  }, [kycData.firstName, kycData.lastName]);

  const handleSubmit = async () => {
    if(!firstName.trim() || !lastName.trim()) return;
    
    setIsLoading(true);
    try {
      await handleUpdateDataLocally({
        firstName: firstName.trim(),
        lastName: lastName.trim()
      });
      updateKYCData({ 
        firstName: firstName.trim(),
        lastName: lastName.trim()
      });
      navigate("/onboarding/business-type");
    } catch (error) {
      console.error('Error updating name:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RootLayout>
      <div className="flex flex-col gap-6 justify-center items-center">
        <span className="grid gap-[2rem]">
          <img src={logo} alt="ShopIQ Logo" className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg" />
        </span>
        <span className="w-full max-w-md">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">
            What's your Name?
          </h2>
          <div className="flex flex-col gap-4">
            <div className="flex items-center rounded-md border border-[#DDD] h-[55px]">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="h-full w-full outline-none pl-5"
              />
            </div>
            <div className="flex items-center rounded-md border border-[#DDD] h-[55px]">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="h-full w-full outline-none pl-5"
              />
            </div>
          </div>
        </span>
        <CustomButton
          onClick={handleSubmit}
          text="Continue"
          disabled={!firstName.trim() || !lastName.trim()}
          loading={isLoading}
          variant='filled'
          fullWidth={true}
        />
      </div>
    </RootLayout>
  );
};

export default Name;