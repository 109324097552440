import RootLayout from "../RootLayout";
import React, { Suspense, useEffect, useState } from "react";

import { useAdminContext } from "../../../Store/adminContext";
import { useNavigate } from "react-router-dom";
import popperImage from "../../../Assets/images/popper.webp";

const Thankyou = () => {
  const navigate = useNavigate();

  const [timer, setTimer] = useState(5);
  const { getAdminData }: any = useAdminContext();

  useEffect(() => {
    const interval: any = setInterval(() => {
      setTimer((prev: number) => {
        if (prev <= 1) {
          window.location.href = "/";

          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    getAdminData();

    return () => clearInterval(interval);
  }, []);

  return (
    <RootLayout>
      <div className="max-h-screen h-[80vh] flex items-center justify-center bg-gray-50 shadow-lg w-[90%] md:w-[45vw] rounded-sm">
        <div className="w-full h-full flex flex-col justify-between px-10 md:px-16 py-8 bg-white rounded-lg">
          <div className="flex flex-col items-center gap-6">
            <img
              src={popperImage}
              className="flex justify-center h-[8rem] md:h-[15rem] w-auto"
            />
            <h3 className="text-2xl md:text-3xl font-semibold mb-6 self-start text-center">
              Thank you for KYC submission! Our team will review it shortly.
            </h3>
          </div>
          <p className="text-center text-xl">Redirecting in {timer}</p>
        </div>
      </div>
    </RootLayout>
  );
};

export default Thankyou;
