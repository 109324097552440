import React, { useState, useEffect } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Modal from "../../Components/Modal";
import toast from "react-hot-toast";
import callApi from "../../Apis/callApi";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../../Components/Table/Pagination";
import withRouter from "../../Components/withRouter";
import { useAdminContext } from "../../Store/adminContext";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import { getResourceLimit } from "../../Utils/checkPermissions/planPermissions";
import DynamicDataTable from "../../UI/DynamicTable";
import TableLoading from "../../Components/LoadingUi/TableLoading";

const Discounts: React.FC<any> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const [inFocus, setInFocus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(15);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_BASE_URL
      }discounts?identifier=${localStorage.getItem("identifier")}&page=${currentPage}&limit=${currentLimit}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setData([...res.data.discounts]);
        setTotal(res?.data?.discountsCount);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: inFocus,
          method: "delete",
          type: "discounts",
          header: false,
        })
          .then((res: any) => {
            setInFocus("");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  // Define columns for DynamicDataTable
  const columns = [
    {
      accessorKey: "discountTitle",
      header: "Discount Title",
    },
    {
      accessorKey: "counponCode",
      header: "Discount Code",
    }
  ];

  // Define actions for DynamicDataTable
  const actionsColumns = isUserAllowedToEdit(admin) ? [
    {
      name: "Delete",
      action: (id: string) => {
        if (isAllowed) {
          setModal(true);
          setInFocus(id);
        } else {
          toast.error("Please Complete Setup Steps to access this route.");
        }
      }
    }
  ] : [];

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleAction}
      />

      <Content>
        <ContentHead
          title="Discounts"
          showBtn1={isUserAllowedToEdit(admin)}
          btn1Name="btn1"
          iconType1="plus"
          btn1Text="New"
          btn1type="filled"
          onClickHandler={() => {
            if (isAllowed) {
              if (getResourceLimit(admin.selectedPlan, "discount") > total) {
                router.navigate("/discounts/add");
              } else {
                toast.error("You have reached your plan's limit.");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
          isIcon={true}
          iconType="add"
        />
        <ContentBody style={{ padding: 0, position: "relative" }}>
          {loading ? (
            <TableLoading head={discountHead(admin)} />
          ) : (
            <DynamicDataTable
              columns={columns}
              data={data}
              actionsColumns={actionsColumns}
              checkboxRow={false}
              onRowClick={(val: any) => {
                if (isAllowed) {
                  router.navigate(`/discounts/update/${val._id}`);
                } else {
                  toast.error("Please Complete Setup Steps to access this route.");
                }
              }}
              title="Discounts"
              onPaginationChange={(page, limit) => {
                setPageNo(page);
                setPerPage(limit);
                // Update URL with new page number and limit
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('page', page.toString());
                searchParams.set('limit', limit.toString());
                navigate(`/discounts?${searchParams.toString()}`);
              }}
              
              totalRows={total || 0}
              initialPageSize={perPage}
              initialPageIndex={pageNo}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.discount_access.write) return true;

  return false;
};

const discountKeys = (admin: any) => {
  const base = ["discountTitle", "counponCode"];

  if (admin.role !== "subadmin") return [...base, "action:delete"];

  if (admin.role === "subadmin") {
    if (admin.discount_access.delete) {
      return [...base, "action:delete"];
    }
    if (admin.discount_access.write) {
      return base;
    }
  }

  return base;
};

const discountHead = (admin: any) => {
  const base = [
    { title: "S.No." },
    { title: "Discount Title" },
    { title: "Discount Code" },
  ];

  if (admin.role !== "subadmin") return [...base, { title: "" }];

  if (admin.role === "subadmin") {
    if (admin.discount_access.delete) {
      return [...base, { title: "" }];
    }
    if (admin.discount_access.write) {
      return base;
    }
  }

  return base;
};

export default withRouter(Discounts);