import React, { useState } from 'react';
import { useKYC } from '../Store/kycContext';
import { useNavigate } from 'react-router-dom';
import UploadAadhaar from './UploadAadhaar';

const DOCUMENT_TYPES = [
    'AADHAAR',
    'Driving License',
    'Voter ID',
    'Passport'
];

const UploadWithDropdown: React.FC = () => {
    const { kycData, updateKYCData } = useKYC();
    const [selectedType, setSelectedType] = useState<string>('');
    const [documentNumber, setDocumentNumber] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate();

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = event.target.files?.[0];
        if (uploadedFile) {
            setFile(uploadedFile);
        }
    };

    const handleSubmit = async () => {
        if (selectedType && documentNumber && file) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('documentType', selectedType);
                formData.append('documentNumber', documentNumber);

                const documentUrl = URL.createObjectURL(file);
                if(selectedType && documentNumber && file){

                updateKYCData({
                    ...kycData,
                    identityProof: {
                        type: selectedType,
                        number: documentNumber,
                        documentUrl: documentUrl,
                        file: file,
                        aadhaar:{
                            ...kycData.identityProof.aadhaar
                        }
                    }
                });
                if(selectedType!== 'AADHAAR'){
                updateKYCData({
                    documents: [...kycData.documents, {
                        name: selectedType,
                        obj: file,
                        url: documentUrl
                    }]
                });
            }}
                navigate("/onboarding/dpiit");
            } catch (error) {
                console.error('Error uploading document:', error);
            }
        }
    };

    const handleCloseAadhaar = () => {
        setSelectedType('');
    };

    // if (selectedType === 'AADHAAR') {
    //     return <UploadAadhaar onClose={handleCloseAadhaar} />;
    // }

    return (
        <div className="w-full max-w-md p-6">
            <h2 className="text-3xl font-semibold mb-6">
                Add an identity proof for {kycData.firstName}
            </h2>
            
            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Document Type
                    </label>
                    <select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        className="w-full border rounded-lg p-2"
                    >
                        <option value="">Select a document type</option>
                        {DOCUMENT_TYPES.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedType !== 'AADHAAR' ? (
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Document Number
                            </label>
                            <input
                                type="text"
                                value={documentNumber}
                                onChange={(e) => setDocumentNumber(e.target.value)}
                                className="w-full border rounded-lg p-2"
                                placeholder="Enter document number"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Upload Document
                            </label>
                            <input
                                type="file"
                                accept="image/*,.pdf"
                                onChange={handleFileUpload}
                                className="w-full border rounded-lg p-2"
                            />
                        </div>

                        <button
                            onClick={handleSubmit}
                            disabled={!selectedType || !documentNumber || !file}
                            className={`w-full py-2 rounded-lg ${
                                !selectedType || !documentNumber || !file
                                    ? 'bg-gray-300 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                            }`}
                        >
                            Submit
                        </button>
                    </div>
                ) : (
                    <UploadAadhaar  />
                )}
            </div>
        </div>
    );
};

export default UploadWithDropdown; 