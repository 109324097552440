import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useKYC } from "../Store/kycContext";
import toast from "react-hot-toast";
import Onboarding from "../Pages/Onboarding/Mobile";
import VerifyOTP from "../Pages/Onboarding/verifyOTP";
import Name from "../Pages/Onboarding/Name";
import BusinessType from "../Pages/Onboarding/BusinessType";
import BusinessAddress from "../Pages/Onboarding/BusinessAddress";
import GSTVerification from "../Pages/Onboarding/GSTverification";
import PANNumber from "../Pages/Onboarding/PANNumber";
import IdentityProof from "../Pages/Onboarding/IdentityProof";
import DPIIT from "../Pages/Onboarding/DPIIT";
import Thankyou from "../Pages/Onboarding/Thankyou";

const ProtectedRoute = ({ element, requiredFields }) => {
  const { kycData } = useKYC();
  const location = useLocation();

  if (location.pathname === "/onboarding/thankyou") return element;

  const merchantData = JSON.parse(
    localStorage.getItem("MERCHANT_DATA") || "{}"
  );

  const hasRequiredFields = () => {
    for (const field of requiredFields) {
      if (field.includes(".")) {
        const [parent, child] = field.split(".");

        if (!kycData?.[parent]?.[child] && !merchantData?.[parent]?.[child]) {
          return false;
        }
      } else {
        if (!kycData?.[field] && !merchantData?.[field]) {
          return false;
        }
      }
    }

    return true;
  };

  const getRedirectPath = () => {
    if (location.pathname === "/onboarding/thankyou") {
      return location.pathname;
    }
    if (
      !kycData.contactInfo?.mobileNumber &&
      !kycData.contactInfo?.email &&
      !merchantData.contactInfo?.mobileNumber &&
      !merchantData.contactInfo?.email
    ) {
      toast.error("Please enter your contact information first");
      return "/onboarding/mobile";
    }

    if (
      !kycData.firstName &&
      !merchantData.firstName &&
      !kycData.lastName &&
      !merchantData.lastName
    ) {
      toast.error("Please complete your name details first");
      return "/onboarding/name";
    }

    if (!kycData.businessType && !merchantData.businessType) {
      toast.error("Please select your business type first");
      return "/onboarding/business-type";
    }

    if (!kycData.location?.address1 && !merchantData.location?.address1) {
      toast.error("Please complete your business address first");
      return "/onboarding/business-address";
    }

    if (
      !kycData.gstVerification?.gstNumber &&
      !merchantData.gstVerification?.gstNumber
    ) {
      toast.error("Please complete GST verification first");
      return "/onboarding/gst-verification";
    }

    if (!kycData.panNumber?.number && !merchantData.panNumber?.number) {
      toast.error("Please complete PAN verification first");
      return "/onboarding/pan-number";
    }

    if (
      !kycData.identityProof?.number &&
      !kycData?.identityProof?.aadhaar?.number &&
      !merchantData?.identityProof?.aadhaar?.number &&
      !merchantData?.identityProof?.number
    ) {
      toast.error("Please complete identity verification first");
      return "/onboarding/identity-proof";
    }

    return "/onboarding/mobile";
  };

  if (hasRequiredFields()) {
    console.log("returning ::: ", element, requiredFields);
    return element;
  } else {
    const redirectPath = getRedirectPath();
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }
};

const getProtectedRoutes = () => [
  {
    path: "/onboarding/mobile",
    element: <Onboarding />,
  },
  {
    path: "/onboarding/verify-otp",
    element: (
      <ProtectedRoute
        element={<VerifyOTP />}
        requiredFields={["contactInfo.mobileNumber"]}
      />
    ),
  },
  {
    path: "/onboarding/name",
    element: (
      <ProtectedRoute
        element={<Name />}
        requiredFields={["contactInfo.mobileNumber"]}
      />
    ),
  },
  {
    path: "/onboarding/business-type",
    element: (
      <ProtectedRoute
        element={<BusinessType />}
        requiredFields={["contactInfo.mobileNumber", "firstName", "lastName"]}
      />
    ),
  },
  {
    path: "/onboarding/business-address",
    element: (
      <ProtectedRoute
        element={<BusinessAddress />}
        requiredFields={[
          "contactInfo.mobileNumber",
          "firstName",
          "lastName",
          "businessType",
        ]}
      />
    ),
  },
  {
    path: "/onboarding/gst-verification",
    element: (
      <ProtectedRoute
        element={<GSTVerification />}
        requiredFields={[
          "contactInfo.mobileNumber",
          "firstName",
          "lastName",
          "businessType",
          "location.address1",
          "location.city",
          "location.state",
          "location.pincode",
        ]}
      />
    ),
  },
  {
    path: "/onboarding/pan-number",
    element: (
      <ProtectedRoute
        element={<PANNumber />}
        requiredFields={[
          "contactInfo.mobileNumber",
          "firstName",
          "lastName",
          "businessType",
          "location.address1",
          "location.city",
          "location.state",
          "location.pincode",
          "gstVerification.gstNumber",
        ]}
      />
    ),
  },
  {
    path: "/onboarding/identity-proof",
    element: (
      <ProtectedRoute
        element={<IdentityProof />}
        requiredFields={[
          "contactInfo.mobileNumber",
          "firstName",
          "lastName",
          "businessType",
          "location.address1",
          "location.city",
          "location.state",
          "location.pincode",
          "gstVerification.gstNumber",
          "panNumber.number",
        ]}
      />
    ),
  },
  {
    path: "/onboarding/dpiit",
    element: (
      <ProtectedRoute
        element={<DPIIT />}
        requiredFields={[
          "contactInfo.mobileNumber",
          "firstName",
          "lastName",
          "businessType",
          "location.address1",
          "location.city",
          "location.state",
          "location.pincode",
          "gstVerification.gstNumber",
          "panNumber.number",
          "identityProof.number",
        ]}
      />
    ),
  },
  {
    path: "/onboarding/thankyou",
    element: <ProtectedRoute element={<Thankyou />} requiredFields={[]} />,
  },
];

export default getProtectedRoutes;
