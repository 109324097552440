import React from "react";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import InputField from "../../../Components/InputField";
import TextEditor from "../../../Components/TextEditor";
import withRouter from "../../../Components/withRouter";
import axios from "axios";
import callApi from "../../../Apis/callApi";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { folder_name } from "../../../Utils/UploadImage";
import styles from "../../../Styles/products.module.css";
import { faClose } from "@fortawesome/free-solid-svg-icons";

class AddCompliance extends React.Component<any, any> {
  state = {
    title: "",
    body: "",
    typeName: "",
    company: "",
    allCompliances: [],
    terms: true,
    privacy: true,
    returnPolicy: true,
    cancellationPolicy: true,
    dataPolicy: true,
    loading: true,
    refund: true,
    ip: true,
    cookie: true,
    sitemap: true,
    return: true,
    shipping: true,
    legalDetails: true,
    about: true,
    buyBack: true,
    businessName: "",
    typeOfBusiness: "",
    businessCategory: "",
    registrationDate: "",
    businessSubCategory: "",
    officeAddress: {},
    invoicePhoneNumber: "",
    invoiceEmail: "",
    cin: "",
    gst: "",
    PAN: "",
    logoUrl: "",
  };

  componentDidMount(): void {
    this.checkPage();
    this.get();
  }

  handleDropDown = () => {
    //  "ip", "cookie", "sitemap"
    if (localStorage.getItem("ecommerce") === "true") {
      const temp = [
        {
          title: "Terms",
          value: "terms",
          disabled: !this.state.terms,
        },
        {
          title: "Privacy",
          value: "privacy",
          disabled: !this.state.privacy,
        },
        {
          title: "Shipping",
          value: "shipping",
          disabled: !this.state.shipping,
        },
        {
          title: "Cancellation Policy",
          value: "cancellation",
          disabled: !this.state.cancellationPolicy,
        },
        {
          title: "Return Policy",
          value: "return",
          disabled: !this.state.return,
        },
        {
          title: "Refund Policy",
          value: "refund",
          disabled: !this.state.refund,
        },
        {
          title: "Data Policy",
          value: "data",
          disabled: !this.state.dataPolicy,
        },
        { title: "IP Policy", value: "ip", disabled: !this.state.ip },
        {
          title: "Cookie Policy",
          value: "cookie",
          disabled: !this.state.cookie,
        },
        { title: "Sitemap", value: "sitemap", disable: !this.state.cookie },
        {
          title: "Legal Details",
          value: "legalDetails",
          disable: !this.state.legalDetails,
        },
        {
          title: "About",
          value: "about",
          disable: !this.state.about,
        },
        { title: "Buy Back", disable: !this.state.buyBack, value: "buy-back" },
      ];

      return temp;
    } else if (localStorage.getItem("service") === "true") {
      const temp = [
        {
          title: "Terms",
          value: "terms",
          disabled: !this.state.terms,
        },
        {
          title: "Privacy",
          value: "privacy",
          disabled: !this.state.privacy,
        },
        {
          title: "Cancellation Policy",
          value: "cancellation",
          disabled: !this.state.cancellationPolicy,
        },
        {
          title: "About",
          value: "about",
          disable: !this.state.about,
        },
      ];

      return temp;
    } else if (localStorage.getItem("blog") === "true") {
      return [
        {
          title: "Terms",
          value: "terms",
          disabled: !this.state.terms,
        },
        {
          title: "Privacy",
          value: "privacy",
          disabled: !this.state.privacy,
        },
        {
          title: "Refund Policy",
          value: "refund",
          disabled: !this.state.refund,
        },
        {
          title: "About",
          value: "about",
          disable: !this.state.about,
        },
      ];
    }
  };

  get = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}compliances?identifier=` +
        localStorage.getItem("identifier"),
      method: "get",
    })
      .then((res) => {
        let data = res.data.compliances;

        this.setState({ allCompliance: data, loading: false });

        data.forEach((element: any) => {
          if (element.typeName === "terms") {
            this.setState({ terms: false });
          }
          if (element.typeName === "privacy") {
            this.setState({ privacy: false });
          }
          if (element.typeName === "dataPolicy") {
            this.setState({ dataPolicy: false });
          }
          if (element.typeName === "cancellation") {
            this.setState({ cancellationPolicy: false });
          }
          if (element.typeName === "returnPolicy") {
            this.setState({ returnPolicy: false });
          }
          if (element.typeName === "shipping") {
            this.setState({ shipping: false });
          }
          if (element.typeName === "about") {
            this.setState({ about: false });
          }
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  addLegaldetail = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}compliances`,
      method: "post",
      data: {
        identifier: localStorage.getItem("identifier"),
        typeName: this.state.typeName,
        company: this.state.company,
        title: this.state.title,
        body: this.state.body,
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        this.props.router.navigate("/compliance");
        this.setState({ loading: false });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ loading: false });
      });
  };

  handleClick = (type:any) => {
    if (this.state.typeName === "legalDetails") {
      this.addLegaldetail();
    } else {
      if (type === "Cancel") {
        this.props.router.navigate("/compliance");
      } else {
        this.setState({ loading: true });
        axios({
          url: `${process.env.REACT_APP_BASE_URL}compliances`,
          method: "post",
          data: {
            identifier: localStorage.getItem("identifier"),
            typeName: this.state.typeName,
            company: this.state.company,
            title: this.state.title,
            body: this.state.body,
            status: type === "Save" ? "live" : "draft",
          },
        })
          .then((res) => {
            toast.success(res.data.message);
            this.props.router.navigate("/compliance");
            this.setState({ loading: false });
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            this.setState({ loading: false });
          });
      }
    }
  };

  handleUpdate = (type:any) => {
    if (type === "Cancel") {
      this.props.router.navigate("/compliance");
    } else {
      this.setState({ loading: true });

      axios({
        url: `${process.env.REACT_APP_BASE_URL}compliances/${this.props.router.params.id}`,
        method: "patch",
        data: {
          identifier: localStorage.getItem("identifier"),
          typeName: this.state.typeName,
          company: this.state.company,
          title: this.state.title,
          body: this.state.body,
          status: type === "Save" ? "live" : "draft",
        },
      })
        .then((res) => {
          toast.success(res.data.message);
          this.props.router.navigate("/compliance");
          this.setState({ loading: false });
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          this.setState({ loading: false });
        });
    }
  };

  getData = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}compliances/${this.props.router.params.id}`,
      method: "get",
    })
      .then((res) => {
        let data = res.data.compliance;

        this.setState({
          title: data?.title,
          body: data?.body,
          typeName: data?.typeName,
          company: data?.company,
          loading: false,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ loading: false });
      });
  };

  checkPage = () => {
    if (this.props.router.params.id) {
      this.setState({ loading: true });
      this.getData();
    }
  };

  handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // let temp={...this.state.officeAddress}

    this.setState({
      officeAddress: { ...this.state.officeAddress, [name]: value },
    });
  };

  render(): React.ReactNode {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={
              this.props.router.params.id
                ? "Update Compliance"
                : "Add Compliance"
            }
            showBtn1={isUserAllowedToEdit(this.props.admin)}
            showBtn2={isUserAllowedToEdit(this.props.admin)}
            showBtn3={true}
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text="Save"
            btn2Text="Save as Draft"
            btn3Text="Cancel"
            btn2type="outlined"
            btn1type="filled"
            btn3type="outlined_del"
            onClickHandler={
              this.props.router.params.id ? this.handleUpdate : this.handleClick
            }
          />
          <ContentBody>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              <InputField
                type={"text"}
                isLabel={true}
                lable="Title"
                value={this.state.title}
                onChange={(event: any) => {
                  this.setState({ title: event.target.value });
                }}
                placeholder="Enter Title"
              />
              <InputField
                type={"select"}
                isLabel={true}
                lable="Type"
                options={this.handleDropDown()}
                accessText="title"
                accessValue="value"
                value={this.state.typeName}
                onChange={(event: any) => {
                  this.setState({ typeName: event.target.value });
                }}
              />

              {this.state.typeName === "legalDetails" && (
                <>
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Entity Legal Name"
                    value={this.state.businessName}
                    onChange={(event: any) => {
                      this.setState({ legalName: event.target.value });
                    }}
                    placeholder="Enter Entity Legal Name"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Entity Type"
                    value={this.state.typeOfBusiness}
                    onChange={(event: any) => {
                      this.setState({ legalName: event.target.value });
                    }}
                    placeholder="Enter Entity Type"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Entity Category"
                    value={this.state.businessCategory}
                    onChange={(event: any) => {
                      this.setState({ businessCategory: event.target.value });
                    }}
                    placeholder="Enter Entity Category"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Entity Sub-Category"
                    value={this.state.businessSubCategory}
                    onChange={(event: any) => {
                      this.setState({
                        businessSubCategory: event.target.value,
                      });
                    }}
                    placeholder="Enter Entity Sub-Category"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="CIN"
                    value={this.state.cin}
                    onChange={(event: any) => {
                      this.setState({
                        cin: event.target.value,
                      });
                    }}
                    placeholder="Enter Entity CIN"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Entity GST"
                    value={this.state.gst}
                    onChange={(event: any) => {
                      this.setState({
                        gst: event.target.value,
                      });
                    }}
                    placeholder="Enter Entity GST"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Entity PAN"
                    value={this.state.PAN}
                    onChange={(event: any) => {
                      this.setState({
                        PAN: event.target.value,
                      });
                    }}
                    placeholder="Enter Entity PAN"
                  />
                  <InputField
                    type={"date"}
                    isLabel={true}
                    lable="Entity Registration Date"
                    value={this.state.registrationDate}
                    onChange={(event: any) => {
                      this.setState({ registrationDate: event.target.value });
                    }}
                    placeholder="Enter company name"
                  />

                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Support Email"
                    value={this.state.invoiceEmail}
                    onChange={(event: any) => {
                      this.setState({ invoiceEmail: event.target.value });
                    }}
                    placeholder="Enter Support Email"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Support Phone"
                    value={this.state.invoicePhoneNumber}
                    onChange={(event: any) => {
                      this.setState({ invoicePhoneNumber: event.target.value });
                    }}
                    placeholder="Enter Support Phone"
                  />
                  <InputField
                    type={"text"}
                    isLabel={true}
                    lable="Support Phone"
                    value={this.state.invoicePhoneNumber}
                    onChange={(event: any) => {
                      this.setState({ invoicePhoneNumber: event.target.value });
                    }}
                    placeholder="Enter Support Phone"
                  />
                  <div
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                      borderRadius: "12px",
                      padding: "10px",
                    }}
                  >
                    <div
                      className={`${styles.field_container} ${styles.media_container}`}
                    >
                      <h3>Media</h3>
                      {this.state.logoUrl === "" ? (
                        <div className={styles.select_media}>
                          <p>+</p>
                          <p style={{ textAlign: "center" }}>
                            Accepts images and videos
                          </p>
                          <input
                            type="file"
                            accept="image/*,video/*"
                            // onChange={this.handleMediaAdd}
                          />
                        </div>
                      ) : (
                        <div className={styles.selected_media}>
                          <div className={styles.media_image}>
                            <img
                              src={
                                this.props.router.params?.id
                                  ? this.state.logoUrl
                                    ? this.state.logoUrl
                                    : this.state.logoUrl
                                  : this.state.logoUrl
                              }
                              alt=""
                            />
                            <FontAwesomeIcon
                              color="white"
                              icon={faClose}
                              onClick={() => {
                                this.setState({
                                  logoUrl: "",
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <InputField
                      type={"address"}
                      isLabel={true}
                      lable="Entity Registered Address"
                      value={""}
                      name={"address"}
                      onChange={(event: any) => {
                        this.handleChangeAddress(event);
                      }}
                      placeholder=""
                    />
                  </div>
                </>
              )}

              {this.state.typeName !== "legalDetails" && (
                <div style={{ gridColumn: "1/3", width: "100%" }}>
                  <TextEditor
                    content={this.state.body}
                    handleChange={(val: any) => {
                      this.setState({ body: val });
                    }}
                  />
                </div>
              )}
            </div>
          </ContentBody>
        </Content>
      </>
    );
  }
}

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.compliance_access.write) return true;

  return false;
};

export default withRouter(AddCompliance);
