export const generatePermissions = (admin: any) => {
  return {
    role: admin?.role,
    subAdmin: !!admin?.subAdmin,
    event: !!admin?.event,
    blog: !!admin?.blog,
    contact: !!admin?.contact,
    career: !!admin?.career,
    caseStudy: !!admin?.caseStudy,
    ecommerce: !!admin?.ecommerce,
    content: !!admin?.content,
    isShopableVideos: !!admin?.isShopableVideos,
    compliance: !!admin?.compliance,
    service: !!admin?.service,
    event_access: {
      read: !!admin?.event_access?.read,
      write: !!admin?.event_access?.write,
      delete: !!admin?.event_access?.delete,
    },
    blog_access: {
      read: !!admin?.blog_access?.read,
      write: !!admin?.blog_access?.write,
      delete: !!admin?.blog_access?.delete,
    },
    contact_access: {
      read: !!admin?.contact_access?.read,
      write: !!admin?.contact_access?.write,
      delete: !!admin?.contact_access?.delete,
    },
    job_access: {
      read: !!admin?.job_access?.read,
      write: !!admin?.job_access?.write,
      delete: !!admin?.job_access?.delete,
    },
    applicant_access: {
      read: !!admin?.applicant_access?.read,
      write: !!admin?.applicant_access?.write,
      delete: !!admin?.applicant_access?.delete,
    },
    caseStudy_access: {
      read: !!admin?.caseStudy?.read,
      write: !!admin?.caseStudy?.write,
      delete: !!admin?.caseStudy?.delete,
    },
    catalog_access: {
      read: !!admin?.catalog_access?.read,
      write: !!admin?.catalog_access?.write,
      delete: !!admin?.catalog_access?.delete,
    },
    sales_access: {
      read: !!admin?.sales_access?.read,
      write: !!admin?.sales_access?.write,
      delete: !!admin?.sales_access?.delete,
    },
    shoppableVideo_access: {
      read: !!admin?.shoppableVideo_access?.read,
      write: !!admin?.shoppableVideo_access?.write,
      delete: !!admin?.shoppableVideo_access?.delete,
    },
    users_access: {
      read: !!admin?.users_access?.read,
      write: !!admin?.users_access?.write,
      delete: !!admin?.users_access?.delete,
    },
    discount_access: {
      read: !!admin?.discount_access?.read,
      write: !!admin?.discount_access?.write,
      delete: !!admin?.discount_access?.delete,
    },
    content_access: {
      read: !!admin?.content_access?.read,
      write: !!admin?.content_access?.write,
      delete: !!admin?.content_access?.delete,
    },
    store_access: {
      read: !!admin?.store_access?.read,
      write: !!admin?.store_access?.write,
      delete: !!admin?.store_access?.delete,
    },
    overview_access: {
      read: !!admin?.overview_access?.read,
      write: !!admin?.overview_access?.write,
      delete: !!admin?.overview_access?.delete,
    },
    compliance_access: {
      read: !!admin?.compliance_access?.read,
      write: !!admin?.compliance_access?.write,
      delete: !!admin?.compliance_access?.delete,
    },
    service_access: {
      read: !!admin?.service_access?.read,
      write: !!admin?.service_access?.write,
      delete: !!admin?.service_access?.delete,
    },
  };
};
