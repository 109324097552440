/**
 * Utility functions for handling images
 */

/**
 * Converts an image URL to a base64 data URL
 * This function should be used on the server side to generate a data URL
 * that can be embedded directly in the HTML
 *
 * @param imageUrl The URL of the image to convert
 * @returns A Promise that resolves to the base64 data URL
 */
export const convertImageToDataURL = async (
  imageUrl: string
): Promise<string | null> => {
  console.log("Starting image conversion process for URL:", imageUrl);

  try {
    // First try to fetch the image using fetch API
    console.log("Attempting to fetch image using fetch API");
    const response = await fetch(imageUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "image/*",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Convert the response to a blob
    const blob = await response.blob();
    console.log("Successfully fetched image as blob:", blob.type, blob.size);

    // Create a FileReader to convert the blob to a data URL
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        console.log("Successfully converted blob to data URL");
        resolve(reader.result as string);
      };

      reader.onerror = (e) => {
        console.error("Error reading blob:", e);
        reject(new Error("Failed to convert blob to data URL"));
      };

      // Start reading the blob as a data URL
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error in convertImageToDataURL (fetch approach):", error);

    // If fetch fails, try the canvas approach as fallback
    try {
      console.log("Fetch approach failed, trying canvas approach as fallback");

      // Create a new image element
      const img = new Image();
      img.crossOrigin = "anonymous";

      // Create a promise to handle the image loading
      const loadPromise = new Promise<HTMLImageElement>((resolve, reject) => {
        img.onload = () => {
          console.log(
            "Image loaded successfully, dimensions:",
            img.width,
            "x",
            img.height
          );
          resolve(img);
        };
        img.onerror = (e) => {
          console.error("Error loading image:", e);
          reject(new Error("Failed to load image"));
        };
      });

      // Set the source to start loading
      img.src = imageUrl;

      // Wait for the image to load
      const loadedImg = await loadPromise;

      // Create a canvas element
      const canvas = document.createElement("canvas");
      canvas.width = loadedImg.width;
      canvas.height = loadedImg.height;

      // Get the 2D context
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Failed to get canvas context");
        return null;
      }

      // Draw the image onto the canvas
      ctx.drawImage(loadedImg, 0, 0);

      // Convert the canvas to a data URL
      const dataUrl = canvas.toDataURL("image/png");
      console.log(
        "Successfully converted image to data URL using canvas approach"
      );

      return dataUrl;
    } catch (canvasError) {
      console.error(
        "Error in convertImageToDataURL (canvas approach):",
        canvasError
      );
      return null;
    }
  }
};

/**
 * Fetches an image through a proxy to bypass CORS issues
 * This is a more robust solution that should work even with strict CORS policies
 *
 * @param imageUrl The URL of the image to fetch
 * @returns A Promise that resolves to the base64 data URL
 */
export const fetchImageThroughProxy = async (
  imageUrl: string
): Promise<string | null> => {
  console.log("Fetching image through proxy:", imageUrl);

  try {
    // Use a CORS proxy service to fetch the image
    // This bypasses CORS restrictions by having the proxy server fetch the image
    const proxyUrl = `https://corsproxy.io/?${encodeURIComponent(imageUrl)}`;

    console.log("Using proxy URL:", proxyUrl);

    const response = await fetch(proxyUrl, {
      method: "GET",
      headers: {
        Accept: "image/*",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Convert the response to a blob
    const blob = await response.blob();
    console.log("Successfully fetched image as blob:", blob.type, blob.size);

    // Create a FileReader to convert the blob to a data URL
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        console.log("Successfully converted blob to data URL");
        resolve(reader.result as string);
      };

      reader.onerror = (e) => {
        console.error("Error reading blob:", e);
        reject(new Error("Failed to convert blob to data URL"));
      };

      // Start reading the blob as a data URL
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error in fetchImageThroughProxy:", error);
    return null;
  }
};

/**
 * Creates a placeholder image with text
 * This can be used as a fallback when the actual image cannot be loaded
 *
 * @param text The text to display in the placeholder
 * @param width The width of the placeholder
 * @param height The height of the placeholder
 * @returns A data URL of the placeholder image
 */
export const createPlaceholderImage = (text: string): string => {
  console.log("Creating placeholder image with text:", text);

  // Create a canvas element
  const canvas = document.createElement("canvas");
  canvas.width = 200;
  canvas.height = 100;

  // Get the 2D context
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    console.error("Failed to get canvas context for placeholder");
    return "";
  }

  // Fill the background
  ctx.fillStyle = "#f0f0f0";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Add text
  ctx.fillStyle = "#333333";
  ctx.font = "16px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillText(text, canvas.width / 2, canvas.height / 2);

  // Convert to data URL
  const dataUrl = canvas.toDataURL("image/png");
  console.log("Successfully created placeholder image");

  return dataUrl;
};
