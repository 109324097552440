import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

export interface KYCData {
  contactInfo: {
    email: string;
    mobileNumber: string;
  };
  location: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    pincode: string;
  };
  gstVerification: {
    gstNumber: string;
    isVerified: boolean;
  };
  firstName: string;
  lastName: string;

  businessType: string;
  panNumber: {
    number: string;
    documentUrl: string;
    file?: File;
  };
  identityProof: {
    type: string;
    number: string;
    documentUrl: string;
    file?: File;
    aadhaar?: {
      number: string;
      frontUrl: string;
      backUrl: string;
      frontFile?: File;
      backFile?: File;
    };
  };
  dpiitVerification: {
    dpiitNumber: string;
    isVerified: boolean;
    documentUrl?: string;
    file?: File;
  };
  documents?: any[];
}

interface KYCContextType {
  kycData: KYCData;
  updateKYCData: any;
}

const initialKYCData: KYCData = {
  contactInfo: {
    email: "",
    mobileNumber: "",
  },
  location: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
  },
  gstVerification: {
    gstNumber: "",
    isVerified: false,
  },
  firstName: "",
  lastName: "",

  businessType: "",
  panNumber: {
    number: "",
    documentUrl: "",
  },
  identityProof: {
    type: "",
    number: "",
    documentUrl: "",
    aadhaar: {
      number: "",
      frontUrl: "",
      backUrl: "",
    },
  },
  dpiitVerification: {
    dpiitNumber: "",
    isVerified: false,
  },
};

const KYCContext = createContext<KYCContextType | undefined>(undefined);

export const KYCProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [kycData, setKycData] = useState<KYCData>(initialKYCData);

  useEffect(() => {
    console.log("KYC CONTEXT CHECK:", kycData);
  }, [kycData]);

  const updateKYCData = (newData) => {
    console.log("Updating KYC data:", newData);
    setKycData((prevData) => {
      const deepMerge = (oldObj, newObj) => {
        return Object.keys(newObj).reduce(
          (acc, key) => {
            if (
              typeof newObj[key] === "object" &&
              newObj[key] !== null &&
              !Array.isArray(newObj[key])
            ) {
              acc[key] = deepMerge(oldObj[key] || {}, newObj[key]); // Deep merge nested objects
            } else {
              acc[key] = newObj[key]; // Overwrite non-objects or arrays
            }
            return acc;
          },
          { ...oldObj }
        );
      };

      const updatedData = deepMerge(prevData, newData);
      console.log("Updated KYC data:", updatedData);
      return updatedData;
    });
  };

  return (
    <KYCContext.Provider value={{ kycData, updateKYCData }}>
      {children}
    </KYCContext.Provider>
  );
};

export const useKYC = () => {
  const context = useContext(KYCContext);
  if (context === undefined) {
    throw new Error("useKYC must be used within a KYCProvider");
  }
  return context;
};
