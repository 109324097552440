export function checkInputType(input) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{7,15}$/; // Only digits, 7 to 15 characters long

  if (emailRegex.test(input)) {
    return "email";
  } else if (phoneRegex.test(input)) {
    return "mobile";
  } else {
    return "invalid";
  }
}
