import React, { useState, useEffect } from 'react';
import RootLayout from '../RootLayout';
import logo from '../../../Assets/images/logo-rounded-bg.png';
import { useKYC } from '../../../Store/kycContext';
import { FaCircleCheck } from 'react-icons/fa6';
import { handleUpdateDataLocally } from '../../../Apis/kycApi';
import toast from 'react-hot-toast';
import UploadAadhaar from '../../../Components/UploadAadhaar';
import UploadWithDropdown from '../../../Components/IdentityDocument'
import { useNavigate } from 'react-router-dom';
import { submitKycRegistration } from '../../../Apis/kycApi';

const IdentityProof = () => {
    const { kycData, updateKYCData } = useKYC();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isAadharUploaded, setIsAadharUploaded] = useState(false);

    const navigate = useNavigate();

    console.log('kycData in identity proof:', kycData);

    useEffect(() => {
        if (kycData.identityProof.documentUrl) {
            setIsAadharUploaded(true);
        }
    }, [kycData.identityProof.documentUrl]);
    

    const handleSubmit = async () => {
        try {
            // Update local storage with existing identity proof data
            await handleUpdateDataLocally({
                identityProof: kycData.identityProof
            });
           
            
            toast.success('Identity proof saved');
            
            setTimeout(() => {
                navigate("/onboarding/dpiit");
            }, 500);
        } catch (error) {
            console.error('Error saving identity proof:', error);
            toast.error('Error saving identity proof');
        }
    };
  
    return (
        <RootLayout>
            <div className="flex flex-col gap-6 justify-center items-center p-8">
                <img src={logo} alt="ShopIQ Logo" className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg" />
                
                {kycData.businessType === "Individual" || kycData.businessType === "Proprietorship" ? (
                    <>
                    <h2 className="text-xl font-semibold text-center">Upload your identity proof</h2>
                    <p className="text-sm text-center text-gray-500">Please upload your identity proof to continue</p>
                        
                            <UploadAadhaar  />

                       
                      
                      
                    </>
                ) : (
                    <UploadWithDropdown />
                )}
            </div>
        </RootLayout>
    );
};

export default IdentityProof;