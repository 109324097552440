import axios from "axios";
import React, { useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import { product_body_keys } from "../../Utils/Data";
import withRouter from "../../Components/withRouter";
import { toast } from "react-hot-toast";
import Pagination from "../../Components/Table/Pagination";
import Modal from "../../Components/Modal";
import DropBtn from "../../Components/DropBtn";
import { faPlus, faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllCategoryList } from "../../Apis/filterApi";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "../../Utils/getQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAdminContext } from "../../Store/adminContext";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import {
  getResourceLimit,
  hasPermission,
} from "../../Utils/checkPermissions/planPermissions";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import DynamicDataTable from "../../UI/DynamicTable";
import Table from "../../Components/Table/Table";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

type IState = {
  data: any[];
  loading: boolean;
  page: number;
  total: number;
  modal: boolean;
  inFocus: string;
};

let inFocus = "";

const Products = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [searched, setSearched] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [exportLoading, setExportLoading] = useState(false);

  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  const location = useLocation();

  const navigate = useNavigate();
  const query_data: string = useQuery();

  // Add categories effect
  useEffect(() => {
    getAllCategoryList()
      .then((result) => {
        setFilterData({
          Category: result.distinctCategory.filter((v: any) => v),
          Collection: result.distinctCollectionName.filter((v: any) => v),
        });
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors here
      });
  }, []);

  // Add pagination change handler
  const handlePaginationChange = (newPage: number, newLimit: number) => {
    const search = new URLSearchParams(location.search);
    search.set("page", String(newPage));
    search.set("limit", String(newLimit));
    setPage(newPage);
    navigate(`/products?${String(search)}`);
  };

  // Add effect to sync page state with URL
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const pageFromUrl = Number(search.get("page")) || 1;
    const limitFromUrl = Number(search.get("limit")) || 15;
    setPage(pageFromUrl);

    if (searched) {
      getSearchData();
    } else {
      getData();
    }
  }, [location.search]);

  useEffect(() => {
    if (searched) {
      const delayDebounceFn = setTimeout(() => {
        const search = new URLSearchParams(location.search);
        search.set("page", "1");
        navigate(`/products?${String(search)}`);
        getSearchData();
      }, 1500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searched]);

  const getData = () => {
    setLoading(true);

    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}products/getallproductsforadmin?identifier=` +
        localStorage.getItem("identifier") +
        `&${query_data}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log(
          "res:",
          res,
          "currentPage:",
          currentPage,
          "currentLimit:",
          currentLimit
        );

        setLoading(false);
        setData(
          res.data.products.map((v: any) => ({
            ...v,
            node: v?.isVariant ? "Variant" : "Main",
            inventory: {
              ...v.inventory,
              currentQuantity: v?.inventory?.trackQuantity
                ? v?.inventory?.currentQuantity
                : "--",
            },
          }))
        );
        setTotal(res.data.total);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error fetching products");
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}products/` + inFocus,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            inFocus = "";
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  const getSearchData = () => {
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    if (searched !== "") {
      setLoading(true);
      axios({
        url: `${
          process.env.REACT_APP_BASE_URL
        }products/search/${localStorage.getItem(
          "identifier"
        )}?text=${searched}&page=${currentPage}&limit=${currentLimit}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res: any) => {
          console.log(
            "res:",
            res,
            "currentPage:",
            currentPage,
            "currentLimit:",
            currentLimit
          );
          setData(
            res.data.data.map((v: any) => ({
              ...v,
              node: v?.isVariant ? "Variant" : "Main",
              inventory: {
                ...v.inventory,
                currentQuantity: v?.inventory?.trackQuantity
                  ? v?.inventory?.currentQuantity
                  : "--",
              },
            }))
          );

          setTotal(res.data.total || res.data.data.length);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Error searching products");
        });
    } else {
      getData();
    }
  };

  const exportToExcelWithCustomHeaders = (
    headerData: any,
    data: any,
    fileName: any
  ) => {
    const workbook = XLSX.utils.book_new();

    // Step 1: Create a blank worksheet
    const worksheet: any = {};

    // Step 2: Add custom headers and define merges
    const merges: any = [];
    let headerRow1: any = [];
    let headerRow2: any = [];

    headerData.forEach((header: any, index: any) => {
      const columnIndex = XLSX.utils.encode_col(index);
      const firstCell = `${columnIndex}1`;
      const secondCell = `${columnIndex}2`;

      if (typeof header === "string") {
        // Merge Row 1 and Row 2 for single-level headers
        merges.push({ s: { r: 0, c: index }, e: { r: 1, c: index } }); // Merge both rows
        headerRow1.push(header); // Add value to Row 1
        headerRow2.push(""); // Leave Row 2 empty
      } else if (typeof header === "object") {
        // If it's an object with subheaders
        const key = Object.keys(header)[0];
        const subHeaders = header[key];
        const subHeaderKeys = Object.keys(subHeaders);

        // Merge the top-level header
        merges.push({
          s: { r: 0, c: index },
          e: { r: 0, c: index + subHeaderKeys.length - 1 },
        });
        headerRow1.push(key);

        // Add subheaders
        subHeaderKeys.forEach((subKey, subIndex) => {
          headerRow2[index + subIndex] = subHeaders[subKey];
        });
      }
    });

    // Add header rows to the worksheet
    XLSX.utils.sheet_add_aoa(worksheet, [headerRow1], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(worksheet, [headerRow2], { origin: "A2" });

    // Apply merges to the worksheet
    worksheet["!merges"] = merges;

    // Step 3: Add data
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: "A3",
      skipHeader: true,
    });

    // Step 4: Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Step 5: Write and save the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const file = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(file, `${fileName}.xlsx`);
  };

  const exportProducts = (
    rowData: any,
    subHeadings: any,
    data: any,
    fileName: any
  ) => {
    const workbook = XLSX.utils.book_new(); // Create a new workbook

    // Combine rowData, subHeadings, and data into the worksheet
    const worksheetData = [rowData, subHeadings, ...data]; // Combine rowData, subHeadings, and data into one array

    // Create a worksheet
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Initialize merges array
    const merges: XLSX.Range[] = [];

    // Loop through rowData to find horizontal merges
    let horizontalStartIndex: number | null = null; // Track the start of a horizontal merge range
    let lastNonEmptyIndex: number | null = null; // Track the last non-empty cell index

    rowData.forEach((value: string, index: number) => {
      if (value === "") {
        if (horizontalStartIndex === null) horizontalStartIndex = index; // Start a new range if not already started
      } else {
        if (horizontalStartIndex !== null) {
          // If a merge range is in progress, finalize it
          merges.push({
            s: { r: 0, c: lastNonEmptyIndex ?? 0 },
            e: { r: 0, c: index - 1 },
          });
          horizontalStartIndex = null; // Reset horizontalStartIndex
        }
        lastNonEmptyIndex = index; // Update the last non-empty cell index
      }
    });

    // Handle any remaining horizontal merge at the end of the row
    if (horizontalStartIndex !== null) {
      merges.push({
        s: { r: 0, c: lastNonEmptyIndex ?? 0 },
        e: { r: 0, c: rowData.length - 1 },
      });
    }

    // Loop through subHeadings to find vertical merges
    subHeadings.forEach((value: string, index: number) => {
      if (value === "") {
        // Merge the current cell in the second row with the one above it
        merges.push({
          s: { r: 0, c: index }, // Starting cell (row 1, current column)
          e: { r: 1, c: index }, // Ending cell (row 2, same column)
        });
      }
    });

    // Assign merges to the worksheet
    worksheet["!merges"] = merges;

    // Apply styling to the first two rows
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"] || "A1"); // Get range
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      // Apply styles for Row 1
      const cellAddressRow1 = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddressRow1]) continue;
      worksheet[cellAddressRow1].s = {
        font: { bold: true },
        fill: { fgColor: { rgb: "D3D3D3" } }, // Light grey background
        alignment: { horizontal: "center", vertical: "center" },
      };

      // Apply styles for Row 2
      const cellAddressRow2 = XLSX.utils.encode_cell({ r: 1, c: C });
      if (worksheet[cellAddressRow2]) {
        worksheet[cellAddressRow2].s = {
          font: { bold: true },
          fill: { fgColor: { rgb: "D3D3D3" } }, // Light grey background
          alignment: { horizontal: "center", vertical: "center" },
        };
      }
    }

    // Auto-size columns based on rowData and subHeadings
    const colWidths = rowData.map((_: any, index: any) => ({
      wch: Math.max(
        rowData[index]?.toString().length || 10,
        subHeadings[index]?.toString().length || 10
      ),
    }));
    worksheet["!cols"] = colWidths;

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate and download the file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true, // Enable cell styles
    });

    const file = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(file, `export_products_${getDDMMYYYY()}.xlsx`);
    setExportLoading(false);
  };

  const handleExport = () => {
    setExportLoading(true);
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}products/getallproductsforadmin?identifier=` +
        localStorage.getItem("identifier") +
        `&limit=1000000`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        let pds = res.data.products.map((v: any) => ({
          ...v,
          inventory: { ...v.inventory, addQuantity: "" },
        }));

        let count: any = {
          advancePricingValues: 1,
          variant: 1,
          specifications: 1,
          crossSellProducts: 1,
          epc: 1,
          mediaUrl: 1,
          tags: 1,
        };

        pds.forEach((product: any) => {
          if (
            product?.advancePricingValues?.length > count.advancePricingValues
          ) {
            count.advancePricingValues = product?.advancePricingValues?.length;
          }

          if (product?.variant?.length > count.variant) {
            count.variant = product?.variant?.length;
          }

          if (product?.specifications?.length > count.specifications) {
            count.specifications = product?.specifications?.length;
          }

          if (product?.crossSellProducts?.length > count.crossSellProducts) {
            count.crossSellProducts = product?.crossSellProducts?.length;
          }

          if (product?.epc?.length > count.epc) {
            count.epc = product?.epc?.length;
          }

          if (product?.mediaUrl?.length > count.mediaUrl) {
            count.mediaUrl = product?.mediaUrl?.length;
          }

          if (product?.tags?.length > count.tags) {
            count.tags = product?.tags?.length;
          }
        });

        let h1 = [
          "title",
          "advancePricing",
          "advancePricingValues1",
          "",
          "",
          "description",
          "status",
          "pricing",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "productCost",
          "inventory",
          "",
          "",
          "",
          "",
          "",
          "",
          "variant1",
          "",
          "",
          "",
          "",
          "specifications1",
          "",
          "shipping",
          "",
          "",
          "category",
          "subCategory",
          "collectionName",
          "hsnsac",
          "productType",
          "isBestSeller",
          "isAvailable",
          "",
          "isFeatured",
          "profit",
          "",
          "requestQuote",
          "document",
          "",
          "",
          "countryOfOrigin",
          "similarProduct",
          "seListing",
          "",
          "",
        ];

        Object.keys(count).forEach((v: any) => {
          if (count[v] > 1) {
            if (v === "advancePricingValue") {
              let to_insert = [];
              for (let i = 2; i <= count[v]; i++) {
                to_insert.push(...[`${v}${i}`, "", ""]);
              }
              let index = h1.indexOf("advancePricingValue1");
              h1.splice(index + 3, 0, ...to_insert);
            }
            if (v === "variant") {
              let to_insert = [];
              for (let i = 2; i <= count[v]; i++) {
                to_insert.push(...[`${v}${i}`, "", "", "", ""]);
              }
              let index = h1.indexOf("variant1");
              h1.splice(index + 5, 0, ...to_insert);
            }
            if (v === "specifications") {
              let to_insert = [];
              for (let i = 2; i <= count[v]; i++) {
                to_insert.push(...[`${v}${i}`, ""]);
              }
              let index = h1.indexOf("specifications1");
              h1.splice(index + 2, 0, ...to_insert);
            }
            if (v === "crossSellProducts") {
            }
            if (v === "epc") {
            }
            if (v === "mediaUrl") {
              let to_insert = [];
              for (let i = 1; i <= count[v]; i++) {
                to_insert.push(...[`${v}${i}`]);
              }
              h1.push(...to_insert);
            }
            if (v === "tags") {
              let to_insert = [];
              for (let i = 1; i <= count[v]; i++) {
                to_insert.push(...[`${v}${i}`]);
              }
              h1.push(...to_insert);
            }
          }
        });

        const h2: any = [];

        for (let i = 0; i < h1.length; i++) {
          let vv = h1[i];

          if (vv.includes("advancePricingValues")) {
            h2.push(...["optionTitle", "optionSpec", "optionValue"]);
            i += 2;
          } else if (vv.includes("pricing")) {
            h2.push(
              ...[
                "price",
                "comparePrice",
                "makingCharge",
                "cgst",
                "sgst",
                "igst",
                "purchaseCurrency",
                "minimumOrderQuantity",
              ]
            );
            i += 7;
          } else if (vv.includes("inventory")) {
            h2.push(
              ...[
                "trackQuantity",
                "addQuantity",
                "currentQuantity",
                "safetyStock",
                "sellOutstock",
                "sku",
                "Barcode",
              ]
            );
            i += 6;
          } else if (vv.includes("variant")) {
            h2.push(
              ...[
                "variantProductId",
                "slug",
                "optionSku",
                "options_name",
                "options_value",
              ]
            );
            i += 4;
          } else if (vv.includes("specifications")) {
            h2.push(...["options_name", "options_value"]);
            i += 1;
          } else if (vv.includes("shipping")) {
            h2.push(...["weight", "unit", "costPerUnitWeight"]);
            i += 2;
          } else if (vv.includes("isAvailable")) {
            h2.push(...["cod", "Discount"]);
            i += 1;
          } else if (vv.includes("profit")) {
            h2.push(...["typeName", "value"]);
            i += 1;
          } else if (vv.includes("document")) {
            h2.push(...["documentTitle", "documentUrl", "documentType"]);
            i += 2;
          } else if (vv.includes("seListing")) {
            h2.push(...["title", "metaDescription", "routeHandle"]);
            i += 2;
          } else {
            h2.push("");
          }
        }

        let h1_clone: any = [];

        for (let i = 0; i < h1.length; i++) {
          if (h1[i]) {
            h1_clone.push(h1[i]);
          } else {
            h1_clone.push(h1_clone[h1_clone.length - 1]);
          }
        }

        let data: any = [];

        pds.forEach((product: any, index: any) => {
          let values: any = [];

          h1_clone.map((x: any, ii: any) => {
            if (x === "title") {
              values.push(product?.title);
            } else if (x === "advancePricing") {
              values.push(product?.advancePricing);
            } else if (x.includes("advancePricingValues")) {
              // if(product?.advancePricingValues)
              let nn = getLastNumber(x);
              if (nn !== null) {
                if (product.advancePricingValues.length <= Number(nn)) {
                  values.push(
                    product.advancePricingValues[Number(nn) - 1][h2[ii]] || ""
                  );
                }
              }
            } else if (x === "description") {
              values.push(product?.description);
            } else if (x === "status") {
              values.push(product?.status);
            } else if (x.includes("pricing")) {
              values.push(product.pricing[h2[ii]] || "");
            } else if (x === "productCost") {
              values.push(product.productCost || "");
            } else if (x.includes("inventory")) {
              values.push(product.inventory[h2[ii]] || "");
            } else if (x.includes("variant")) {
              let nn = getLastNumber(x);
              if (product.variant[Number(nn) - 1]) {
                values.push(product.variant[Number(nn) - 1][h2[ii]]);
              } else {
                values.push("");
              }
            } else if (x.includes("specifications")) {
              let nn = getLastNumber(x);

              if (product.specifications[Number(nn) - 1]) {
                values.push(
                  product.specifications[Number(nn) - 1][h2[ii]] || ""
                );
              } else {
                values.push("");
              }
            } else if (x.includes("shipping")) {
              values.push(product.shipping[h2[ii]] || "");
            } else if (x === "category") {
              values.push(product?.category || "");
            } else if (x === "subCategory") {
              values.push(product?.subCategory || "");
            } else if (x === "collectionName") {
              values.push(product?.collectionName);
            } else if (x === "hsnsac") {
              values.push(product?.hsnsac);
            } else if (x === "productType") {
              values.push(product?.productType);
            } else if (x === "isBestSeller") {
              values.push(product?.isBestSeller);
            } else if (x.includes("isAvailable")) {
              values.push(product.isAvailable[h2[ii]]);
            } else if (x === "isFeatured") {
              values.push(product.isFeatured);
            } else if (x === "profit") {
              values.push(product.profit[h2[ii]]);
            } else if (x === "requestQuote") {
              values.push(product.requestQuote || "");
            } else if (x === "document") {
              values.push(product.document[h2[ii]]);
            } else if (x === "countryOfOrigin") {
              values.push(product.countryOfOrigin);
            } else if (x === "similarProduct") {
              values.push(product.similarProduct);
            } else if (x === "seListing") {
              values.push(product.seListing[h2[ii]]);
            } else if (x.includes("mediaUrl")) {
              let nn = getLastNumber(x);

              values.push(product.mediaUrl[Number(nn - 1)] || "");
            } else if (x.includes("tags")) {
              let nn = getLastNumber(x);
              values.push(product.tags[Number(nn)] || "");
            }
          });

          data.push(values);
        });

        exportProducts(h1, h2, data, "test");
      })
      .catch((err: any) => {
        toast.error("Something went wrong, while exporting products.");
        setExportLoading(false);
      });
  };

  return (
    <>
      {exportLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
          inFocus = "";
        }}
        onBtn2Click={handleAction}
      />
      <Content>
        <ContentHead
          title={"Products"}
          showBtn1={true}
          btn1Name="btn1"
          iconType1="plus"
          btn1Text="New"
          btn1type="filled"
          onClickHandler={() => {
            if (isAllowed) {
              if (getResourceLimit(admin.selectedPlan, "products") > total) {
                navigate("/products/add");
              } else {
                toast.error("Your have reached your plan's limit.");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
          isIcon={true}
          iconType="add"
          customElement={
            <>
              <div
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  height: "37px",
                  width: "300px",
                  borderRadius: "10px",
                  background: "white",
                  overflow: "hidden",
                  alignItems: "center",
                  paddingLeft: "10px",
                  border: "1px solid #ddd",
                  marginRight: "10px",
                }}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ height: "17px", color: "lightgrey" }}
                />
                <input
                  placeholder="Search Products By Name, sku, ..."
                  value={searched}
                  onChange={(e: any) => setSearched(e.target.value)}
                  style={{
                    height: "100%",
                    border: "none",
                    outline: "none",
                    fontSize: "0.9rem",
                    padding: "10px",
                    flex: 1,
                  }}
                />
              </div>
            </>
          }
          isMoreSettings={hasPermission(
            admin.selectedPlan,
            "products",
            "import"
          )}
          moreSettingsList={[
            { icon: "import", title: "Import", name: "import" },
            { icon: "export", title: "Export", name: "export" },
          ]}
          isIcon1={true}
          handleMoreClick={(name: any) => {
            if (isAllowed) {
              if (name === "import") {
                navigate("/products/import");
              } else if (name === "export") {
                handleExport();
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
        />
        <ContentBody
          style={{
            padding: 0,
            position: "relative",
          }}
        >
          {loading ? (
            <TableLoading head={productHead(admin)} />
          ) : (
            <>
              <DynamicDataTable
                columns={[
                  { accessorKey: "inventory.sku", header: "SKU" },
                  { accessorKey: "title", header: "Product Name" },
                  {
                    accessorKey: "category",
                    header: "Category",
                    enableColumnFilter: true,
                  },
                  {
                    accessorKey: "collectionName",
                    header: "Collection",
                    enableColumnFilter: true,
                  },
                  {
                    accessorKey: "inventory.currentQuantity",
                    header: "Current Stock",
                  },
                  { accessorKey: "pricing.price", header: "Price" },
                  { accessorKey: "node", header: "variant" },
                  { accessorKey: "createdAt", header: "Created At" },
                ]}
                data={data}
                onRowClick={(val: any) => {
                  if (isAllowed) {
                    navigate(`/products/update/${val._id}`);
                  } else {
                    toast.error(
                      "Please Complete Setup Steps to access this route."
                    );
                  }
                }}
                actionsColumns={[
                  {
                    name: "delete",
                    action: (rowId) => {
                      if (isAllowed) {
                        setModal(true);
                        inFocus = rowId;
                      } else {
                        toast.error(
                          "Please Complete Setup Steps to access this route."
                        );
                      }
                    },
                  },
                  {
                    name: "clone",
                    action: (rowId) => {
                      if (isAllowed) {
                        console.log("rowId at clone:", rowId);
                        navigate(`/products/clone/${rowId}`);
                      } else {
                        toast.error(
                          "Please Complete Setup Steps to access this route."
                        );
                      }
                    },
                  },
                ]}
                onPaginationChange={handlePaginationChange}
                totalRows={total}
                initialPageSize={
                  Number(new URLSearchParams(location.search).get("limit")) ||
                  15
                }
                initialPageIndex={
                  Number(new URLSearchParams(location.search).get("page")) || 1
                }
              />
            </>
          )}
        </ContentBody>
      </Content>
    </>
  );
};

function getDDMMYYYY() {
  const date = new Date();

  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Concatenate in ddmmyyyy format
  return `${day}${month}${year}`;
}

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

const productHead = (admin: any) => {
  const base_head = [
    { title: "S.No.", filter: false },
    { title: "SKU", filter: false },
    { title: "Product Name", filter: false },
    { title: "Category", value: "Category", filter: true },
    { title: "Collection", value: "Collection", filter: true },
    { title: "Current Stock", filter: false },
    { title: "Price", filter: false },
    { title: "variant", filter: false },
    { title: "Created At", filter: false },
  ];

  if (admin.role !== "subadmin")
    return [...base_head, { title: "", filter: false }];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base_head, { title: "", filter: false }];
    }
    if (admin.catalog_access.write) {
      return [...base_head, { title: "", filter: false }];
    }
  }

  return base_head;
};

const productKeys = (admin: any) => {
  const base_keys = [
    "inventory.sku",
    "title",
    "category",
    "collectionName",
    "inventory.currentQuantity",
    "pricing.price",
    "node",
    "createdAt",
  ];

  if (admin.role !== "subadmin") return [...base_keys, "action:more"];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base_keys, "action:more"];
    }
    if (admin.catalog_access.write) {
      return [...base_keys, "action:more-clone"];
    }
  }

  return base_keys;
};

function removeLastDigits(str: any) {
  return str.replace(/\d+$/, ""); // Removes the last set of digits if present
}

function getLastNumber(str: any) {
  const match = str.match(/\d+$/); // Matches one or more digits at the end of the string
  return match ? match[0] : null; // Returns the match or null if no match is found
}

export default withRouter(Products);
