import { useNavigate } from "react-router-dom";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import WithTableView from "../../GenericPages/WithTableView";
import { category_body_keys, category_head } from "../../Utils/Data";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import toast from "react-hot-toast";
import Modal from "../../Components/Modal";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import DraggableTable from "../../Components/DraggableTable";
import { useAdminContext } from "../../Store/adminContext";
import ContentOuterHead from "../../Components/Content/contentOuterHead";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import { getResourceLimit } from "../../Utils/checkPermissions/planPermissions";
import DynamicDataTable from "../../UI/DynamicTable";
import { useLocation } from "react-router-dom";

let inFocus = "";

const Categories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData]: any = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(15);

  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  useEffect(() => {
    getData();
  }, [location.search]);

  const getData = () => {
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }productcategory/?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${currentPage}&limit=${currentLimit}`,
    })
      .then(async (res: any) => {
        const data = res.data.productCategorys;
        setTotal(res.data.results);

        let found = false;

        // check if data have priority value
        for (let i = 0; i < data.length; i++) {
          if (data[i].priority === undefined) {
            found = true;
            // add priority
            const _ = await axios({
              method: "PATCH",
              url:
                `${process.env.REACT_APP_BASE_URL}productcategory/` +
                data[i]._id,
              data: { ...data[i], priority: i },
            });
          }
        }

        if (found) getData();

        setData(
          data
            .map((val: any) => ({ ...val, id: val._id }))
            .sort((a: any, b: any) => a.priority - b.priority)
        );

        setLoading(false);
      })
      .catch((error: any) => {
        toast.error("Something went wrong.");
        setLoading(false);
        setData([]);
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}productcategory/` + inFocus,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            inFocus = "";
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  // Update the handleSwap function in the Categories component
  const handleSwap = (draggedRowIndex, targetRowIndex) => {
    let temp = [...data];
    
    // Get the actual items that need to be swapped
    let item1 = temp[draggedRowIndex];
    let item2 = temp[targetRowIndex];
    
    // Store these indices for updates
    setIndex1(draggedRowIndex);
    setIndex2(targetRowIndex);
    
    // Determine the range of items that need priority updates
    if (draggedRowIndex < targetRowIndex) {
      // Moving an item down
      // The dragged item gets the target's priority
      temp[draggedRowIndex].priority = temp[targetRowIndex].priority;
      
      // Items in between: decrement their priority
      for (let i = draggedRowIndex + 1; i <= targetRowIndex; i++) {
        temp[i].priority = temp[i].priority - 1;
      }
    } else {
      // Moving an item up
      // The dragged item gets the target's priority
      temp[draggedRowIndex].priority = temp[targetRowIndex].priority;
      
      // Items in between: increment their priority
      for (let i = targetRowIndex; i < draggedRowIndex; i++) {
        temp[i].priority = temp[i].priority + 1;
      }
    }
    
    // Sort the data by priority
    setData(temp.sort((a, b) => a.priority - b.priority));
    setLoading(true);
    updateForSwap();
  };

  const updateForSwap = async () => {
    if (index1 > index2) {
      // Moving an item up
      for (let i = index2; i <= index1; i++) {
        await axios({
          method: "patch",
          data: { priority: data[i]?.priority },
          url: `${process.env.REACT_APP_BASE_URL}productcategory/` + data[i]?.id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        });
      }
    } else {
      // Moving an item down
      for (let i = index1; i <= index2; i++) {
        await axios({
          method: "patch",
          data: { priority: data[i]?.priority },
          url: `${process.env.REACT_APP_BASE_URL}productcategory/` + data[i]?.id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        });
      }
    }

    getData();
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          inFocus = "";
          setModal(false);
        }}
        onBtn2Click={handleAction}
      />
      <Content>
        <ContentHead
          title="Categories"
          showBtn1={isUserAllowedToEdit(admin)}
          btn1Name="btn1"
          btn1Text={"New"}
          btn1type="filled"
          iconType1="plus"
          onClickHandler={() => {
            if (isAllowed) {
              if (
                getResourceLimit(admin.selectedPlan, "category") > data.length
              ) {
                navigate("/categories/add");
              } else {
                toast.error("You have reached your plan's limit.");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
        />
        <ContentBody style={{ padding: 0 }}>
          {loading ? (
            <TableLoading head={category_head} />
          ) : (
            <DynamicDataTable
              columns={[{accessorKey:'title', header:'Name'}]}
              data={data}
              enableRowReorder={true}
              onReorder={handleSwap}
              actionsColumns={[
                {
                  name:'Edit',
                  action:(id)=>{
                    if (isAllowed) {
                      navigate("/categories/update/" + id);
                    } else {
                      toast.error(
                        "Please Complete Setup Steps to access this route."
                      );
                    }
                  }
                },
                {
                  name:'Delete',
                  action:(id)=>{
                    if (isAllowed) {
                      inFocus = id;
                      setModal(true);
                    } else {
                      toast.error(
                        "Please Complete Setup Steps to access this route."
                      );
                    }
                  }
                }
              ]}
              onPaginationChange={(page, limit) => {
                setPageNo(page);
                setPerPage(limit);
                // Update URL with new page number and limit
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('page', page.toString());
                searchParams.set('limit', limit.toString());
                navigate(`/categories?${searchParams.toString()}`);
              }}
              totalRows={total}
              initialPageSize={perPage}
              initialPageIndex={pageNo}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

const categoryKeys = (admin: any) => {
  const base_keys = ["sno", "title"];

  if (admin.role !== "subadmin") return [...base_keys, "action"];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base_keys, "action"];
    }
    if (admin.catalog_access.write) {
      return [...base_keys, "write"];
    }
  }

  return [...base_keys, "show"];
};

export default Categories;
