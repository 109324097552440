export const FEATURE_FLAGS = {
  CREATE_CONTACT: "createContact",
  ASSIGN_CONTACT_OWNER: "assignContactOwner",
  IMPORT: "import",
  EXPORT: "export",
  VARIANTS: "variants",
  ADVANCE_PRICING: "advancePricing",
  PRINT_LABELS: "printLabels",
  CREATE_ORDER: "createOrderButton",
  VISIBILITY: "visibility",
  TWO_PAYMENT_GATEWAYS: "twoPaymentGateways",
  LOCATION: "location",
  SCHEDULE: "schedule",
  BULK_DISCOUNT: "bulkDiscount",
  INVENTORY: "inventory",
  EPC: "epc",
  AUTH_KEY: "authentication_key",
} as const;

// page keys as constants
const PAGES = {
  OVERVIEW: "overview",
  CONTACTS: "contacts",
  USERS: "users",
  SEGMENTS: "segments",
  BLOGS: "blogs",
  CASE_STUDY: "caseStudy",
  JOBS: "jobs",
  APPLICANTS: "applicants",
  PRODUCTS: "products",
  CATEGORIES: "categories",
  SUBCATEGORIES: "subCategories",
  COLLECTIONS: "collections",
  BULK_IMAGE_UPLOAD: "bulkImageUpload",
  ORDERS: "orders",
  ABANDONED_CARTS: "abandonedCarts",
  DISCOUNT: "discount",
  SHOPPABLE_VIDEO: "shoppableVideo",
  COMPLIANCE: "compliance",
  HERO: "hero",
  BANNER1: "banner1",
  BANNER2: "banner2",
  APPS: "apps",
  LOCATION: "location",
  SETTINGS: "settings",
} as const;

// Define plan tiers
const PLAN_TIERS = {
  STARTER: "677cbc603397a27ca3ce1819",
  GROWTH: "6740b7f8a30b4510370bba2e",
  PRO: "6740b73ba30b4510370bba18",
} as const;

// common permission presets
const PERMISSION_PRESETS = {
  FULL_ACCESS: {
    visible: true,
    notAllowed: [],
  },
  FULL_ACCESS_WITH_MAX_LIMIT: {
    visible: true,
    notAllowed: [],
    limit: Number.MAX_VALUE,
  },
  NO_ACCESS: {
    visible: false,
    notAllowed: [],
  },
  READ_ONLY: {
    visible: true,
    notAllowed: ["create", "update", "delete"],
  },
};

// resource limits per plan
const PLAN_LIMITS = {
  [PLAN_TIERS.STARTER as string]: {
    contacts: 500,
    users: 500,
    products: 50,
    shoppableVideo: 5,
    location: 1,
    team: 0,
    blogs: 10,
    category: 10,
    subCategory: 10,
    collection: 5,
    discount: 5,
    hero: 3,
    banner1: 3,
  },
  [PLAN_TIERS.GROWTH as string]: {
    contacts: 5000,
    users: Number.MAX_VALUE,
    products: 500,
    shoppableVideo: 5,
    location: 1,
    team: 0,
    blogs: 100,
    category: 20,
    subCategory: 20,
    collection: 20,
    discount: 20,
    hero: 10,
    banner1: 10,
    banner2: 10,
  },
  [PLAN_TIERS.PRO as string]: {
    contacts: Number.MAX_VALUE,
    users: Number.MAX_VALUE,
    products: Number.MAX_VALUE,
    shoppableVideo: 50,
    location: Number.MAX_VALUE,
    team: 3,
    blogs: Number.MAX_VALUE,
    category: Number.MAX_VALUE,
    subCategory: Number.MAX_VALUE,
    collection: Number.MAX_VALUE,
    discount: Number.MAX_VALUE,
    hero: Number.MAX_VALUE,
    banner1: Number.MAX_VALUE,
    banner2: Number.MAX_VALUE,
  },
};

type FeatureFlag = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];
type PageKey = (typeof PAGES)[keyof typeof PAGES];
type PlanTier = (typeof PLAN_TIERS)[keyof typeof PLAN_TIERS];

interface PagePermission {
  visible: boolean;
  notAllowed?: FeatureFlag[];
  limit?: number;
  pages?: Record<
    string,
    {
      visible?: boolean;
      notAllowed?: FeatureFlag[];
    }
  >;
}

type PlanPermissions = {
  [K in PlanTier]: Partial<Record<PageKey, PagePermission>>;
};

// Main permissions configuration
export const planPermissions: PlanPermissions = {
  [PLAN_TIERS.STARTER]: {
    [PAGES.OVERVIEW]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.CONTACTS]: {
      visible: true,
      notAllowed: [FEATURE_FLAGS.ASSIGN_CONTACT_OWNER],
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].contacts,
      pages: {
        addUpdateContacts: {
          visible: true,
          notAllowed: [FEATURE_FLAGS.ASSIGN_CONTACT_OWNER],
        },
      },
    },
    [PAGES.USERS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].users,
    },

    [PAGES.SEGMENTS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.BLOGS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].blogs,
    },
    [PAGES.CASE_STUDY]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.JOBS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.APPLICANTS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.PRODUCTS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].products,
      notAllowed: [FEATURE_FLAGS.IMPORT, FEATURE_FLAGS.EXPORT],
      pages: {
        addUpdateProduct: {
          visible: true,
          notAllowed: [
            FEATURE_FLAGS.VARIANTS,
            FEATURE_FLAGS.ADVANCE_PRICING,
            FEATURE_FLAGS.INVENTORY,
            FEATURE_FLAGS.EPC,
          ],
        },
      },
    },
    [PAGES.CATEGORIES]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].category,
    },
    [PAGES.SUBCATEGORIES]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].subCategory,
    },
    [PAGES.COLLECTIONS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].collection,
    },
    [PAGES.BULK_IMAGE_UPLOAD]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.ORDERS]: {
      visible: true,
      notAllowed: [FEATURE_FLAGS.PRINT_LABELS],
      pages: {
        createOrder: {
          visible: true,
          notAllowed: [],
        },
      },
    },
    [PAGES.ABANDONED_CARTS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.DISCOUNT]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].discount,
      pages: {
        addUpdateDiscount: {
          visible: true,
          notAllowed: [
            FEATURE_FLAGS.LOCATION,
            FEATURE_FLAGS.SCHEDULE,
            FEATURE_FLAGS.VISIBILITY,
            FEATURE_FLAGS.BULK_DISCOUNT,
          ],
        },
      },
    },
    [PAGES.SHOPPABLE_VIDEO]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].shoppableVideo,
    },
    [PAGES.COMPLIANCE]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.HERO]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].hero,
    },
    [PAGES.BANNER1]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].banner1,
    },
    [PAGES.BANNER2]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.APPS]: {
      visible: true,
      notAllowed: [FEATURE_FLAGS.TWO_PAYMENT_GATEWAYS],
    },
    [PAGES.LOCATION]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.STARTER].location,
    },
    [PAGES.SETTINGS]: {
      visible: true,
      notAllowed: [FEATURE_FLAGS.AUTH_KEY],
    },
  },
  [PLAN_TIERS.GROWTH]: {
    [PAGES.OVERVIEW]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.CONTACTS]: {
      visible: true,
      notAllowed: [FEATURE_FLAGS.ASSIGN_CONTACT_OWNER],
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].contacts,
      pages: {
        addUpdateContacts: {
          visible: true,
          notAllowed: [FEATURE_FLAGS.ASSIGN_CONTACT_OWNER],
        },
      },
    },
    [PAGES.USERS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].users,
    },
    [PAGES.SEGMENTS]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.BLOGS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].blogs,
    },
    [PAGES.CASE_STUDY]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.JOBS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.APPLICANTS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.PRODUCTS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].products,
      notAllowed: [],
      pages: {
        addUpdateProduct: {
          visible: true,
          notAllowed: [FEATURE_FLAGS.ADVANCE_PRICING],
        },
      },
    },
    [PAGES.CATEGORIES]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].category,
    },
    [PAGES.SUBCATEGORIES]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].subCategory,
    },
    [PAGES.COLLECTIONS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].collection,
    },
    [PAGES.BULK_IMAGE_UPLOAD]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.ORDERS]: {
      visible: true,
      notAllowed: [],
      pages: {
        createOrder: PERMISSION_PRESETS.FULL_ACCESS,
      },
    },
    [PAGES.ABANDONED_CARTS]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.DISCOUNT]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].discount,
      pages: {
        addUpdateDiscount: {
          visible: true,
          notAllowed: [],
        },
      },
    },
    [PAGES.SHOPPABLE_VIDEO]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].shoppableVideo,
    },
    [PAGES.COMPLIANCE]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.HERO]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].hero,
    },
    [PAGES.BANNER1]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].banner1,
    },
    [PAGES.BANNER2]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].banner2,
    },
    [PAGES.APPS]: {
      visible: true,
      notAllowed: [FEATURE_FLAGS.TWO_PAYMENT_GATEWAYS],
    },
    [PAGES.LOCATION]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.GROWTH].location,
    },
    [PAGES.SETTINGS]: PERMISSION_PRESETS.FULL_ACCESS,
  },
  [PLAN_TIERS.PRO]: {
    [PAGES.OVERVIEW]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.CONTACTS]: {
      visible: true,
      notAllowed: [],
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].contacts,
      pages: {
        addUpdateContacts: {
          visible: true,
          notAllowed: [],
        },
      },
    },
    [PAGES.USERS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].users,
    },
    [PAGES.SEGMENTS]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.BLOGS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].blogs,
    },
    [PAGES.CASE_STUDY]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.JOBS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.APPLICANTS]: PERMISSION_PRESETS.NO_ACCESS,
    [PAGES.PRODUCTS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].products,
      notAllowed: [],
      pages: {
        addUpdateProduct: {
          visible: true,
          notAllowed: [],
        },
      },
    },
    [PAGES.CATEGORIES]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].category,
    },
    [PAGES.SUBCATEGORIES]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].subCategory,
    },
    [PAGES.COLLECTIONS]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].collection,
    },
    [PAGES.BULK_IMAGE_UPLOAD]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.ORDERS]: {
      visible: true,
      notAllowed: [],
      pages: {
        createOrder: PERMISSION_PRESETS.FULL_ACCESS,
      },
    },
    [PAGES.ABANDONED_CARTS]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.DISCOUNT]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].discount,
      pages: {
        addUpdateDiscount: {
          visible: true,
          notAllowed: [],
        },
      },
    },
    [PAGES.SHOPPABLE_VIDEO]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].shoppableVideo,
    },
    [PAGES.COMPLIANCE]: PERMISSION_PRESETS.FULL_ACCESS,
    [PAGES.HERO]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].hero,
    },
    [PAGES.BANNER1]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].banner1,
    },
    [PAGES.BANNER2]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].banner2,
    },
    [PAGES.APPS]: {
      visible: true,
      notAllowed: [],
    },
    [PAGES.LOCATION]: {
      visible: true,
      limit: PLAN_LIMITS[PLAN_TIERS.PRO].location,
    },
    [PAGES.SETTINGS]: PERMISSION_PRESETS.FULL_ACCESS,
  },
};

// functions for permission checking
export const hasPermission = (
  planTier: PlanTier,
  page: PageKey,
  feature?: FeatureFlag
): boolean => {
  const pagePermission = planPermissions[planTier]?.[page];

  console.log("Page permission for ", planTier);
  console.log("Page ::: ", page);
  console.log("permssion : ", pagePermission);

  if (!pagePermission?.visible) {
    return false;
  }

  if (!feature) {
    return true;
  }

  return !pagePermission.notAllowed?.includes(feature);
};

export const getResourceLimit = (
  planTier: PlanTier,
  resource: keyof typeof PLAN_LIMITS
): number => {
  return PLAN_LIMITS[planTier || "677cbc603397a27ca3ce1819"][resource];
};

// function to check subpage permissions
export const hasSubPagePermission = (
  planTier: PlanTier,
  page: PageKey,
  subPage: string,
  feature?: FeatureFlag
): boolean => {
  const pagePermission = planPermissions[planTier]?.[page]?.pages?.[subPage];

  if (!pagePermission?.visible) {
    return false;
  }

  if (!feature) {
    return true;
  }

  return !pagePermission.notAllowed?.includes(feature);
};
