import React, { useEffect, useRef, useState } from "react";
import RootLayout from "../RootLayout";
import logo from "../../../Assets/images/logo-rounded-bg.png";
import { IoCloudDone } from "react-icons/io5";
import { toast } from "react-hot-toast";
import {
  submitKycRegistration,
  handleUpdateDataLocally,
} from "../../../Apis/kycApi";
import { uploadMedia } from "../../../Apis/kycApi";
import { useKYC } from "../../../Store/kycContext";
import { useNavigate } from "react-router-dom";
import { set } from "lodash";
import { useAdminContext } from "../../../Store/adminContext";

const DPIIT = () => {
  const { kycData, updateKYCData } = useKYC();
  const [loading, setLoading] = useState(false);
  const [dpiitNumber, setDpiitNumber] = useState(
    kycData.dpiitVerification.dpiitNumber
  );
  const [isDpiitUploaded, setIsDpiitUploaded] = useState(
    kycData.dpiitVerification.isVerified
  );
  const [dpiitDocument, setDpiitDocument] = useState<File | null>(null);
  const [dpiitDocumentUrl, setDpiitDocumentUrl] = useState<string | null>(null);
  const gstRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();

  const handleUploadCertificate = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files?.length) return;
    setDpiitDocument(e.target.files[0]);

    try {
      setLoading(true);
      const res = await uploadMedia({ file: e.target.files[0] });

      if (res.status <= 300) {
        updateKYCData({
          dpiitVerification: {
            ...kycData.dpiitVerification,
            isVerified: true,
          },
        });
        setDpiitDocumentUrl(URL.createObjectURL(e.target.files[0]));
        setIsDpiitUploaded(true);
        toast.success("File uploaded successfully");
      } else {
        toast.error("File Not Uploaded");
      }
    } catch (error) {
      toast.error("File Not Uploaded");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (kycData.dpiitVerification.isVerified) {
      setIsDpiitUploaded(true);
    }
  }, [kycData.dpiitVerification.isVerified]);

  const handleButtonClicked = async () => {
    if (!dpiitNumber.trim()) {
      toast.error("Please enter DPIIT number");
      return;
    }

    if (!isDpiitUploaded) {
      toast.error("Please upload DPIIT certificate");
      return;
    }

    try {
      // Update local data first
      handleUpdateDataLocally({
        dpiitVerification: {
          dpiitNumber,
          isVerified: true,
        },
      });

      // Update KYC context
      updateKYCData({
        dpiitVerification: {
          dpiitNumber,
          isVerified: true,
        },
      });

      updateKYCData({
        documents: [
          ...kycData.documents,
          { name: "dpiit document", url: dpiitDocumentUrl, obj: dpiitDocument },
        ],
      });

      // Prepare documents array with both identity and DPIIT documents

      await submitKycRegistration(
        {
          ...kycData,
          dpiitNumber,
        },
        admin
      )
        .then(() => {
          window.location.href = "/onboarding/thankyou";
          toast.success("KYC details saved successfully");
        })
        .catch((err) => {
          toast.error("Failed to save KYC details");
        });
    } catch (error) {
      toast.error("Failed to save KYC details");
    }
  };

  return (
    <RootLayout>
      <div className="flex flex-col gap-6 justify-center items-center p-8">
        <img
          src={logo}
          alt="ShopIQ Logo"
          className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg"
        />

        <div className="w-full max-w-md">
          <span>
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Are you a Registered StartUp?
            </h2>
            <h2 className="text-xl font-semibold mb-6">
              Enter Your DPIIT number
            </h2>
            <div className="flex items-center rounded-md border border-[#DDD] h-[55px]">
              <input
                type="text"
                name="domain"
                placeholder=""
                value={dpiitNumber}
                onChange={(e) => setDpiitNumber(e.target.value)}
                className="h-full w-full outline-none pl-5"
              />
            </div>
          </span>

          {isDpiitUploaded ? (
            <div className="border-dashed border-2 border-gray-300 p-4 rounded-md text-center flex justify-between items-center mt-4">
              <span>
                <h4>File Uploaded</h4>
                <input
                  type="file"
                  ref={gstRef}
                  className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 hidden"
                  onChange={handleUploadCertificate}
                />
                <button
                  className="text-blue-500 text-xs"
                  onClick={() => gstRef.current?.click()}
                >
                  Re-Upload
                </button>
              </span>
              <IoCloudDone color="green" size={25} />
            </div>
          ) : (
            <span className="block mt-4">
              <div className="flex justify-between">
                <h4>Upload Certificate</h4>
                <input
                  type="file"
                  className="hidden"
                  ref={gstRef}
                  onChange={handleUploadCertificate}
                />
                <button
                  className="text-blue-500"
                  onClick={() => gstRef.current?.click()}
                >
                  Upload
                </button>
              </div>
            </span>
          )}

          <button
            className={`${
              loading ? "bg-blue-200 pointer-events-none" : "bg-blue-400"
            } mt-6 w-full py-2 rounded-md text-white h-[55px] text-[1.1rem] flex justify-center items-center`}
            onClick={handleButtonClicked}
            disabled={loading}
          >
            {loading ? "Loading..." : "Continue"}
          </button>
        </div>
      </div>
    </RootLayout>
  );
};

export default DPIIT;
