import { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import InputField from "../../Components/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFile,
  faFileUpload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { useAdminContext } from "../../Store/adminContext";
import CustomButton from "../../Components/CustomButton";
import { baseUrl } from "../../Apis/filterApi";

const DocumentInput = ({
  label,
  handleFileChange,
  value,
  handleRemove,
  fileName,
  placeholder,
}: any) => {
  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "5px",
      }}
    >
      <label>{label || "Document Input"}</label>
      <div
        style={{
          height: "50px",
          overflow: "hidden",
          position: "relative",
          borderRadius: "10px",
          border: "1px solid rgb(222,222,222)",
          width: "100%",
          fontSize: ".9rem",
          padding: "5px",
        }}
      >
        {!value.url ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingInline: "10px",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <input
              type="file"
              accept="image/*,application/pdf"
              style={{
                opacity: 0,
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: "pointer",
              }}
              onChange={handleFileChange}
            />
            <FontAwesomeIcon icon={faUpload} />
            <p>{placeholder || "Upload Document"}</p>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingInline: "10px",
              cursor: "pointer",
              position: "relative",
              background: "var(--blue-light)",
              borderRadius: "5px",
            }}
          >
            <FontAwesomeIcon icon={faFile} />
            <p>{fileName}</p>
            <FontAwesomeIcon
              icon={faClose}
              style={{
                marginLeft: "auto",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={handleRemove}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const KycRegistration = () => {
  const navigate = useNavigate();
  const { admin, getAdminData }: any = useAdminContext();
  const [loading, setLoading] = useState(false);

  // Account Information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [commEmail, setCommEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState({
    countryCode: "",
    value: "",
  });

  // Business Information
  const [businessName, setBusinessName] = useState("");
  const [typeOfBusiness, setTypeOfBusiness] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [businessSubCategory, setBusinessSubCategory] = useState("");
  const [aadharId, setAadharId] = useState("");
  const [gstNo, setGSTNo] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [cinNumber, setCinNumber] = useState("");
  const [dpiitNumber, setDpiitNumber] = useState("");
  const [address, setAddress] = useState({
    addLineOne: "",
    addLineTwo: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [disableEmail, setDisableEmail] = useState(false);
  const [disablePhone, setDisablePhone] = useState(false);
  const [disableCountry, setDisableCountry] = useState(false);
  const [gstLoading, setGstLoading] = useState(false);
  const [disableBD, setDisableBD] = useState(true);

  useEffect(() => {
    if (admin?.email) {
      setEmail(admin?.email);
      setDisableEmail(true);
    }
    if (admin?.mobileNumber?.value) {
      setMobileNumber(admin?.mobileNumber);
      setDisablePhone(true);
    }
    setFirstName(admin?.firstName);
    setLastName(admin?.lastName);
    setCommEmail(admin?.commEmail);
    setBusinessName(admin?.businessName);
    setTypeOfBusiness(admin?.typeOfBusiness);
    setBusinessCategory(admin?.businessCategory);
    setBusinessSubCategory(admin?.businessSubCategory);
    setAadharId(admin?.aadharId?.aadharNumber);
    setGSTNo(admin?.gst);
    setPanNumber(admin?.PAN);
    setCinNumber(admin?.cin);
    setDpiitNumber(admin?.dpiitNumber);

    let legal_doc = [];

    legal_doc.push({
      obj: null,
      url: admin?.aadharId?.aadharFrontImage || "",
      name: "aadhar document",
    });

    legal_doc.push({
      obj: null,
      url: admin?.aadharId?.aadharBackImage || "",
      name: "aadhar back document",
    });

    legal_doc.push(
      ...admin.legalDocuments.map((v) => ({
        obj: null,
        url: v.url,
        name: v.type,
      }))
    );

    setDocuments(legal_doc);

    setAddress(admin?.address);
    if (admin?.address?.country) {
      setDisableCountry(true);
    }
  }, []);

  const [documents, setDocuments]: any = useState([]);

  const validate = (btn) => {
    if (btn === "btn2") return true;

    if (!firstName) {
      toast.error("First Name is required.");
      return false;
    }
    if (!lastName) {
      toast.error("Last Name is required.");
      return false;
    }
    if (!email) {
      toast.error("Email is required.");
      return false;
    }
    // if (!commEmail) {
    //   toast.error("Communication Email is required.");
    //   return false;
    // }
    if (!mobileNumber.value) {
      toast.error("Mobile Number is required.");
      return false;
    }
    if (!businessName) {
      toast.error("Business Name is required.");
      return false;
    }
    if (!typeOfBusiness) {
      toast.error("Type of Business is required.");
      return false;
    }
    if (!businessCategory) {
      toast.error("Business Category is required.");
      return false;
    }
    if (!businessSubCategory) {
      toast.error("Business Sub Category is required.");
      return false;
    }

    if (!gstNo) {
      toast.error("GST Number is required.");
      return false;
    }

    // Check for gstn document
    const gstDoc = documents.filter((v) => v.name === "gstn document");
    if (gstDoc.length === 0) {
      toast.error("GSTN Document is required.");
      return false;
    } else {
      if (!gstDoc[0].url) {
        toast.error("GSTN Document is required.");
        return false;
      }
    }

    // if (!aadharId) {
    //   toast.error("Aadhar Number is required.");
    //   return false;
    // }

    // if (String(aadharId).length !== 12) {
    //   toast.error("Invalid Aadhar Number.");
    //   return false;
    // }

    // // Check for aadhar document
    // const aadharDoc = documents.filter((v) => v.name === "aadhar document");
    // if (aadharDoc.length === 0) {
    //   toast.error("Aadhar Document Front Image is required.");
    //   return false;
    // } else {
    //   if (!aadharDoc[0].url) {
    //     toast.error("Aadhar Document Front Image is required.");
    //     return false;
    //   }
    // }

    // // Check for aadhar document
    // const aadharDocBack = documents.filter(
    //   (v) => v.name === "aadhar back document"
    // );
    // if (aadharDocBack.length === 0) {
    //   toast.error("Aadhar Document Back Image is required.");
    //   return false;
    // } else {
    //   if (!aadharDocBack[0].url) {
    //     toast.error("Aadhar Document Back Image is required.");
    //     return false;
    //   }
    // }

    // if (!panNumber) {
    //   toast.error("PAN Number is required.");
    //   return false;
    // }

    // // Check for pan document
    // const panDoc = documents.filter((v) => v.name === "pan document");
    // if (panDoc.length === 0) {
    //   toast.error("PAN Document is required.");
    //   return false;
    // } else {
    //   if (!panDoc[0].url) {
    //     toast.error("PAN Document is required.");
    //     return false;
    //   }
    // }

    // if (!cinNumber) {
    //   toast.error("CIN Number is required.");
    //   return false;
    // }

    // // Check for pan document
    // const cinDoc = documents.filter((v) => v.name === "cin document");
    // if (cinDoc.length === 0) {
    //   toast.error("CIN Document is required.");
    //   return false;
    // } else {
    //   if (!cinDoc[0].url) {
    //     toast.error("CIN Document is required.");
    //     return false;
    //   }
    // }

    // if (!dpiitNumber) {
    //   toast.error("DPIIT Number is required.");
    //   return false;
    // }

    // // Check for pan document
    // const dpiitDoc = documents.filter((v) => v.name === "dpiit document");
    // if (dpiitDoc.length === 0) {
    //   toast.error("DPIIT Document is required.");
    //   return false;
    // } else {
    //   if (!dpiitDoc[0].url) {
    //     toast.error("DPIIT Document is required.");
    //     return false;
    //   }
    // }

    if (!address.addLineOne) {
      toast.error("Address Line One is required.");
      return false;
    }
    if (!address.city) {
      toast.error("City is required.");
      return false;
    }
    if (!address.state) {
      toast.error("State is required.");
      return false;
    }
    if (!address.country) {
      toast.error("Country is required.");
      return false;
    }
    if (!address.pincode) {
      toast.error("Pincode is required.");
      return false;
    }

    return true;
  };

  const handleClick = async (e: any) => {
    if (e.target.name === "btn3") {
      navigate("/");
    } else if (e.target.name === "btn2" || e.target.name === "btn1") {
      if (validate(e.target.name)) {
        setLoading(true);
        const data: any = {
          firstName,
          lastName,
          email,
          commEmail,
          mobileNumber,
          businessName,
          typeOfBusiness,
          businessCategory,
          businessSubCategory,
          aadharId,
          gst: gstNo,
          PAN: panNumber,
          cin: cinNumber,
          dpiitNumber: dpiitNumber,
          address,
          kycSubmitted: e.target.name === "btn2" ? "draft" : "submitted",
        };

        const upload_documents = documents.map((doc) => {
          if (doc?.obj === null) return { url: doc?.url, type: doc.name };

          const formData = new FormData();
          formData.append("files", doc.obj);
          formData.append("path", localStorage.getItem("identifier"));

          return axios({
            url: `${
              process.env.REACT_APP_BASE_URL
            }uploadBulkImages/uploadBulkImages?identifier=${localStorage.getItem(
              "identifier"
            )}`,
            method: "post",
            data: formData,
          })
            .then((res: any) => ({
              url: res.data.images[0],
              type: doc.name,
            }))
            .catch((err: any) => {});
        });

        const value = await Promise.all(upload_documents);

        const aadharDocData = {
          aadharNumber: data.aadharId,
          aadharFrontImage: value.filter((v) => v.type === "aadhar document")[0]
            ?.url,
          aadharBackImage: value.filter(
            (v) => v.type === "aadhar back document"
          )[0]?.url,
        };

        data.legalDocuments = value.filter(
          (v) =>
            v.type !== "aadhar document" && v.type !== "aadhar back document"
        );

        const aadhar_details = await axios({
          url: `${process.env.REACT_APP_BASE_URL}aadhar/`,
          method: "POST",
          data: aadharDocData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        });

        data.aadharId = aadhar_details.data.data._id;

        axios({
          url: `${process.env.REACT_APP_BASE_URL}admins/updateSelf`,
          method: "PATCH",
          data: {
            identifier: localStorage.getItem("identifier"),
            data,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res) => {
            getAdminData();
            setLoading(false);
            navigate("/");
            toast.success("Kyc Updated Successfully.");
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Something went wrong.");
          });
      }
    }
  };

  const verifyGst = () => {
    setGstLoading(true);
    let temp_val = gstNo.split("");
    temp_val.splice(0, 2);
    temp_val.splice(-3, 3);
    setPanNumber(temp_val.join(""));
    axios({
      url: `${baseUrl}admins/get-gst-details?gst=${gstNo}`,
    })
      .then((res: any) => {
        console.log(res);
        setDisableBD(false);
        toast.error("Error fetching GST details. Please enter them manually.");
        setGstLoading(false);
      })
      .catch((err: any) => {
        console.log("err :: ", err);
        setGstLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={"KYC Registration"}
          showBtn1={true}
          showBtn2={false}
          showBtn3={true}
          btn1Name="btn1"
          btn1Text={"Submit"}
          btn2Name="btn2"
          btn3Name="btn3"
          btn2Text={"Save in Draft"}
          btn3Text={"Cancel"}
          btn1type="filled"
          btn2type="filled_blue"
          btn3type="light_grey"
          onClickHandler={handleClick}
        />
        <ContentBody>
          <div className="grid">
            <div className="card">
              <h3>Account Information</h3>
              <div className="grid_2" style={{ marginTop: "10px" }}>
                <InputField
                  type="text"
                  isLabel
                  lable={"First Name*"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"Last Name*"}
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                />
                <InputField
                  type="email"
                  isLabel
                  lable={"Email*"}
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  disabled={disableEmail}
                />
                <InputField
                  type="email"
                  isLabel
                  lable={"Communication Email"}
                  value={commEmail}
                  onChange={(e: any) => setCommEmail(e.target.value)}
                />
                <InputField
                  type="number"
                  isLabel
                  lable={"Mobile Number*"}
                  value={mobileNumber.value}
                  onChange={(e: any) =>
                    setMobileNumber({ countryCode: "", value: e.target.value })
                  }
                  disabled={disablePhone}
                />
              </div>
            </div>
            <div className="card">
              <h3>Business Information</h3>
              <div className="grid_2" style={{ marginTop: "10px" }}>
                <InputField
                  type="text"
                  isLabel
                  lable={"GSTN No.*"}
                  value={gstNo}
                  onChange={(e: any) => {
                    const inputValue = e.target.value.slice(0, 15);
                    setGSTNo(inputValue);
                  }}
                  absoluteComponent={
                    <>
                      {gstNo && (
                        <div
                          className={`${
                            gstLoading ? "opacity-50 cursor-not-allowed" : ""
                          } flex justify-center items-center absolute bg-blue-500 text-white bottom-1/2 translate-y-1/2 right-2 px-2 py-2 rounded-lg`}
                          onClick={gstLoading ? () => {} : verifyGst}
                        >
                          {gstLoading ? (
                            <span className="btn_loader_white"></span>
                          ) : (
                            "Verify"
                          )}
                        </div>
                      )}
                    </>
                  }
                />
                <DocumentInput
                  value={
                    documents?.filter((v: any) => v.name === "gstn document")
                      .length === 0
                      ? { name: "gstn document", obj: null, url: null }
                      : documents?.filter(
                          (v: any) => v.name === "gstn document"
                        )[0]
                  }
                  label="GSTN Document*"
                  fileName={"GSTN Document"}
                  placeholder={"Upload GSTN Document"}
                  handleFileChange={(e: any) => {
                    let temp = [...documents];

                    let ti = -1;

                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "gstn document") {
                        ti = i;
                        break;
                      }
                    }

                    if (ti === -1) {
                      temp.push({
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "gstn document",
                      });
                    } else {
                      temp[ti] = {
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "gstn document",
                      };
                    }

                    setDocuments(temp);
                  }}
                  handleRemove={() => {
                    let temp = [...documents];
                    let ti = -1;
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "gstn document") {
                        ti = i;
                        break;
                      }
                    }
                    if (ti === -1) {
                      temp.push({
                        obj: null,
                        url: "",
                        name: "gstn document",
                      });
                    } else {
                      temp[ti] = {
                        obj: null,
                        url: "",
                        name: "gstn document",
                      };
                    }
                    setDocuments(temp);
                  }}
                />

                <InputField
                  type="text"
                  isLabel
                  lable={"Business Name*"}
                  value={businessName}
                  onChange={(e: any) => setBusinessName(e.target.value)}
                  disabled={disableBD}
                />

                <InputField
                  type="select"
                  options={[
                    { title: "Individual", value: "Individual" },
                    { title: "Proprietorship", value: "Proprietorship" },
                    { title: "Partnership", value: "Partnership" },
                    { title: "Private Limited", value: "Private Limited" },
                    { title: "Public Limited", value: "Public Limited" },
                    { title: "LLP", value: "LLP" },
                    { title: "Trust", value: "Trust" },
                    { title: "Society", value: "Society" },
                    { title: "HUF", value: "HUF" },
                  ]}
                  accessText="title"
                  accessValue="value"
                  isLabel
                  lable={"Type of Business*"}
                  value={typeOfBusiness}
                  onChange={(e: any) => setTypeOfBusiness(e.target.value)}
                  disabled={disableBD}
                />

                <InputField
                  type="select"
                  options={[
                    { title: "Fashion & Apparel", value: "Fashion & Apparel" },
                    { title: "Electronics", value: "Electronics" },
                    { title: "Pet Supplies", value: "Pet Supplies" },
                    {
                      title: "Beauty & Personal Care",
                      value: "Beauty & Personal Care",
                    },
                    { title: "Health & Wellness", value: "Health & Wellness" },
                    { title: "Home & Garden", value: "Home & Garden" },
                    { title: "Toys & Games", value: "Toys & Games" },
                    { title: "Office Supplies", value: "Office Supplies" },
                    { title: "Food & Beverages", value: "Food & Beverages" },
                    { title: "Kitchen Supplies", value: "Kitchen Supplies" },
                    { title: "Garden Supplies", value: "Garden Supplies" },
                    { title: "Furniture", value: "Furniture" },
                    { title: "Home Decor", value: "Home Decor" },
                    { title: "Tools & Hardware", value: "Tools & Hardware" },
                    { title: "Automotive", value: "Automotive" },
                    {
                      title: "Automotive Accessories",
                      value: "Automotive Accessories",
                    },
                    { title: "Books", value: "Books" },
                    { title: "Other", value: "Other" },
                  ]}
                  accessText="title"
                  accessValue="value"
                  isLabel
                  lable={"Business Category*"}
                  value={businessCategory}
                  onChange={(e: any) => setBusinessCategory(e.target.value)}
                />

                <InputField
                  type="text"
                  isLabel
                  lable={"Business Sub Category"}
                  value={businessSubCategory}
                  onChange={(e: any) => setBusinessSubCategory(e.target.value)}
                />

                <InputField
                  type="number"
                  isLabel
                  lable={"Aadhar No."}
                  value={aadharId}
                  onChange={(e: any) => {
                    const inputValue = e.target.value.slice(0, 12); // Limit to 12 digits
                    setAadharId(inputValue);
                  }}
                />
                <DocumentInput
                  value={
                    documents?.filter((v: any) => v.name === "aadhar document")
                      .length === 0
                      ? { name: "aadhar document", obj: null, url: null }
                      : documents?.filter(
                          (v: any) => v.name === "aadhar document"
                        )[0]
                  }
                  label="Aadhar Document Front"
                  fileName={"Aadhar Document Front"}
                  placeholder={"Upload Aadhar Document Front Image"}
                  handleFileChange={(e: any) => {
                    let temp = [...documents];

                    let ti = -1;

                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "aadhar document") {
                        ti = i;
                        break;
                      }
                    }

                    if (ti === -1) {
                      temp.push({
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "aadhar document",
                      });
                    } else {
                      temp[ti] = {
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "aadhar document",
                      };
                    }

                    setDocuments(temp);
                  }}
                  handleRemove={() => {
                    let temp = [...documents];
                    let ti = -1;
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "aadhar document") {
                        ti = i;
                        break;
                      }
                    }
                    if (ti === -1) {
                      temp.push({
                        obj: null,
                        url: "",
                        name: "aadhar document",
                      });
                    } else {
                      temp[ti] = {
                        obj: null,
                        url: "",
                        name: "aadhar document",
                      };
                    }
                    setDocuments(temp);
                  }}
                />
                <DocumentInput
                  value={
                    documents?.filter(
                      (v: any) => v.name === "aadhar back document"
                    ).length === 0
                      ? { name: "aadhar back document", obj: null, url: null }
                      : documents?.filter(
                          (v: any) => v.name === "aadhar back document"
                        )[0]
                  }
                  label="Aadhar Document Back"
                  fileName={"Aadhar Document Back"}
                  placeholder={"Upload Aadhar Document Back Image"}
                  handleFileChange={(e: any) => {
                    let temp = [...documents];

                    let ti = -1;

                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "aadhar back document") {
                        ti = i;
                        break;
                      }
                    }

                    if (ti === -1) {
                      temp.push({
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "aadhar back document",
                      });
                    } else {
                      temp[ti] = {
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "aadhar back document",
                      };
                    }

                    setDocuments(temp);
                  }}
                  handleRemove={() => {
                    let temp = [...documents];
                    let ti = -1;
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "aadhar back document") {
                        ti = i;
                        break;
                      }
                    }
                    if (ti === -1) {
                      temp.push({
                        obj: null,
                        url: "",
                        name: "aadhar back document",
                      });
                    } else {
                      temp[ti] = {
                        obj: null,
                        url: "",
                        name: "aadhar back document",
                      };
                    }
                    setDocuments(temp);
                  }}
                />
                <div></div>

                <InputField
                  type="text"
                  isLabel
                  lable={"PAN Number"}
                  value={panNumber}
                  onChange={(e: any) => {
                    const inputValue = e.target.value
                      .slice(0, 10)
                      .toUpperCase();
                    setPanNumber(inputValue);
                  }}
                  disabled={disableBD}
                />
                <DocumentInput
                  value={
                    documents?.filter((v: any) => v.name === "pan document")
                      .length === 0
                      ? { name: "pan document", obj: null, url: null }
                      : documents?.filter(
                          (v: any) => v.name === "pan document"
                        )[0]
                  }
                  label="PAN Document"
                  fileName={"PAN Document"}
                  placeholder={"Upload PAN Document"}
                  handleFileChange={(e: any) => {
                    let temp = [...documents];

                    let ti = -1;

                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "pan document") {
                        ti = i;
                        break;
                      }
                    }

                    if (ti === -1) {
                      temp.push({
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "pan document",
                      });
                    } else {
                      temp[ti] = {
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "pan document",
                      };
                    }

                    setDocuments(temp);
                  }}
                  handleRemove={() => {
                    let temp = [...documents];
                    let ti = -1;
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "pan document") {
                        ti = i;
                        break;
                      }
                    }
                    if (ti === -1) {
                      temp.push({
                        obj: null,
                        url: "",
                        name: "pan document",
                      });
                    } else {
                      temp[ti] = {
                        obj: null,
                        url: "",
                        name: "pan document",
                      };
                    }
                    setDocuments(temp);
                  }}
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"CIN Number"}
                  value={cinNumber}
                  onChange={(e: any) => {
                    const inputValue = e.target.value.slice(0, 21);
                    setCinNumber(inputValue);
                  }}
                />
                <DocumentInput
                  value={
                    documents?.filter((v: any) => v.name === "cin document")
                      .length === 0
                      ? { name: "cin document", obj: null, url: null }
                      : documents?.filter(
                          (v: any) => v.name === "cin document"
                        )[0]
                  }
                  label="CIN Document"
                  fileName={"CIN Document"}
                  placeholder={"Upload CIN Document"}
                  handleFileChange={(e: any) => {
                    let temp = [...documents];

                    let ti = -1;

                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "cin document") {
                        ti = i;
                        break;
                      }
                    }

                    if (ti === -1) {
                      temp.push({
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "cin document",
                      });
                    } else {
                      temp[ti] = {
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "cin document",
                      };
                    }

                    setDocuments(temp);
                  }}
                  handleRemove={() => {
                    let temp = [...documents];
                    let ti = -1;
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "cin document") {
                        ti = i;
                        break;
                      }
                    }
                    if (ti === -1) {
                      temp.push({
                        obj: null,
                        url: "",
                        name: "cin document",
                      });
                    } else {
                      temp[ti] = {
                        obj: null,
                        url: "",
                        name: "cin document",
                      };
                    }
                    setDocuments(temp);
                  }}
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"DPIIT Number"}
                  value={dpiitNumber}
                  onChange={(e: any) => {
                    const inputValue = e.target.value.slice(0, 10);
                    setDpiitNumber(inputValue);
                  }}
                />
                <DocumentInput
                  value={
                    documents?.filter((v: any) => v.name === "dpiit document")
                      .length === 0
                      ? { name: "dpiit document", obj: null, url: null }
                      : documents?.filter(
                          (v: any) => v.name === "dpiit document"
                        )[0]
                  }
                  label="DPIIT Document"
                  fileName={"DPIIT Document"}
                  placeholder={"Upload DPIIT Document"}
                  handleFileChange={(e: any) => {
                    let temp = [...documents];

                    let ti = -1;

                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "dpiit document") {
                        ti = i;
                        break;
                      }
                    }

                    if (ti === -1) {
                      temp.push({
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "dpiit document",
                      });
                    } else {
                      temp[ti] = {
                        obj: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                        name: "dpiit document",
                      };
                    }

                    setDocuments(temp);
                  }}
                  handleRemove={() => {
                    let temp = [...documents];
                    let ti = -1;
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].name === "dpiit document") {
                        ti = i;
                        break;
                      }
                    }
                    if (ti === -1) {
                      temp.push({
                        obj: null,
                        url: "",
                        name: "dpiit document",
                      });
                    } else {
                      temp[ti] = {
                        obj: null,
                        url: "",
                        name: "dpiit document",
                      };
                    }
                    setDocuments(temp);
                  }}
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"Address Line One*"}
                  value={address.addLineOne}
                  onChange={(e: any) =>
                    setAddress((prev) => ({
                      ...prev,
                      addLineOne: e.target.value,
                    }))
                  }
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"Address Line Two"}
                  value={address.addLineTwo}
                  onChange={(e: any) =>
                    setAddress((prev) => ({
                      ...prev,
                      addLineTwo: e.target.value,
                    }))
                  }
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"City*"}
                  value={address.city}
                  onChange={(e: any) =>
                    setAddress((prev) => ({
                      ...prev,
                      city: e.target.value,
                    }))
                  }
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"State*"}
                  value={address.state}
                  onChange={(e: any) =>
                    setAddress((prev) => ({
                      ...prev,
                      state: e.target.value,
                    }))
                  }
                />
                <InputField
                  type="text"
                  isLabel
                  lable={"Country*"}
                  value={address.country}
                  onChange={(e: any) =>
                    setAddress((prev) => ({
                      ...prev,
                      country: e.target.value,
                    }))
                  }
                  disabled={disableCountry}
                />
                <InputField
                  type="number"
                  isLabel
                  lable={"Pincode*"}
                  value={address.pincode}
                  onChange={(e: any) =>
                    setAddress((prev) => ({
                      ...prev,
                      pincode: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default KycRegistration;
