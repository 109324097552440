import styles from "./mostViewedProducts.module.css";
import HorizontalBarChart from "../../Charts/HorizontalBarChart";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { useAdminContext } from "../../../Store/adminContext";
import { isUserAllowed } from "../../../Utils/checkPermissions/checkForKycAndPlan";
import toast from "react-hot-toast";
import CustomButton from "../../CustomButton";
import { useNavigate } from "react-router-dom";

type Props = {
  products: { title: string; impression: number }[];
  header: string;
  subHeader: string;
  loading?: boolean;
};

const MostViewedProduct = (props: Props) => {
  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);
  const navigate = useNavigate();

  if (props.loading) {
    return (
      <>
        <h3 className={styles.productH1}>{props.header}</h3>
        <div
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      </>
    );
  }

  if (!props.loading && props.products.length === 0) {
    return (
      <>
        <h3 className={styles.productH1}>{props.header}</h3>
        <div
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
        >
          <span>No Data Found</span>
        </div>
      </>
    );
  }

  const data = {
    labels: props.products
      ?.slice(0, 5)
      .map((data) => `${data.title.substring(0, 16)}...`),
    datasets: [
      {
        label: null,
        data: props.products?.slice(0, 5).map((data) => data.impression),
        backgroundColor: "#397EE6",
        barThickness: 20,
      },
    ],
  };

  return (
    <>
      <h3 className={styles.productH1}>{props.header}</h3>
      <HorizontalBarChart data={data} />
      <div className={styles.btnContainer}>
        {isAllowed ? (
          // <Link to={"/analytics/mostViewedProducts"}
          // // className={styles.btn}
          // >
          <CustomButton
            text="See More"
            variant="link"
            icon="arrow-right"
            iconPosition="right"
            onClick={() => { navigate("/analytics/mostViewedProducts") }}
          />
          // </Link>
        ) : (
          <p
            // className={styles.btn}
            onClick={() => {
              toast.error("Please Complete Setup Steps to access this route.");
            }}
            style={{ cursor: "pointer" }}
          >
            <CustomButton
              text="See More"
              variant="link"
              icon="arrow-right"
              iconPosition="right"
            />
          </p>
        )}
      </div>
    </>
  );
};

export default MostViewedProduct;
