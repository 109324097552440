import React, { useEffect, useState } from 'react';
import RootLayout from '../RootLayout';
import { useKYC } from '../../../Store/kycContext';
import { handleUpdateDataLocally } from '../../../Apis/kycApi';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';

const BusinessType = () => {
  type BusinessType =
    | "Individual"
    | "Proprietorship"
    | "Partnership"
    | "Private Limited"
    | "Public Limited"
    | "LLP"
    | "Trust"
    | "Society"
    | "HUF";

  const { kycData, updateKYCData } = useKYC();
  const [selectedBusinessType, setSelectedBusinessType] = useState<BusinessType | null>(null);
  const navigate = useNavigate();
  const handleSelect = (type: BusinessType) => {
    setSelectedBusinessType(type);
    updateKYCData({ businessType: type });
    handleUpdateDataLocally({ businessType: type });
   
  };

  useEffect(() => {
    if (kycData.businessType) {
      setSelectedBusinessType(kycData.businessType as BusinessType);
    }
  }, [kycData.businessType]);

  return (
    <RootLayout>
      <div className='flex flex-col gap-6 justify-center items-center p-8'>
        <h2 className="text-3xl font-semibold mb-2">
          What's your business type?
        </h2>
        <p className="text-gray-500 mb-6">
          Pick only one that applies to your business
        </p>

        <div>
          <div className="mb-4">
            <p className="text-sm text-gray-700 uppercase">
              Individual Business <span className="text-gray-400"></span>
            </p>
            <button
              onClick={() => handleSelect("Individual")}
              className={`mt-2 px-4 py-2 rounded-md font-medium 
                ${
                  selectedBusinessType === "Individual"
                    ? "bg-blue-500 text-white"
                    : "bg-transparent border border-gray-300 text-black"
                }`}
            >
              Individual
            </button>
          </div>

          <div>
            <p className="text-sm text-gray-700 uppercase">
              Registered Business <span className="text-gray-400"></span>
            </p>
            <div className="flex flex-wrap gap-2 mt-2">
              {[
                "Proprietorship",
                "Partnership",
                "Private Limited",
                "Public Limited",
                "LLP",
                "Trust",
                "Society",
                "HUF",
              ].map((type) => (
                <button
                  key={type}
                  onClick={() => handleSelect(type as BusinessType)}
                  className={`px-4 py-2 rounded-md font-medium
                    ${
                      selectedBusinessType === type
                        ? "bg-blue-500 text-white"
                        : "bg-transparent border border-gray-300 text-black"
                    }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
        </div>
        <CustomButton
          text="Continue"
          variant="filled"
          fullWidth={true}
          disabled={!selectedBusinessType}
          onClick={() => {
            handleUpdateDataLocally({ businessType: selectedBusinessType }) 
            navigate("/onboarding/business-address");}}
        />
      </div>
    </RootLayout>
  );
};

export default BusinessType;