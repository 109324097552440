import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import withRouter from "../../Components/withRouter";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import langObj from "../../Utils/lang";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import DynamicDataTable from "../../UI/DynamicTable";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import TableLoading from "../../Components/LoadingUi/TableLoading";

interface CaseStudyProps {
  router: any;
}

const CaseStudy: React.FC<CaseStudyProps> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang }: any = useLangContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const [inFocus, setInFocus] = useState<string>("");
  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    let search = new URLSearchParams(location.search);
    setLoading(true);

    callApi({
      method: "get",
      url: `?identifier=${localStorage.getItem("identifier")}&page=${Number(search.get("page")) || 1
        }&limit=${Number(search.get("limit")) || 15}`,
      type: "casestudy",
      data: {},
    })
      .then((res) => {
        setData(res.data.caseStudies);
        setLoading(false);
        setTotal(res?.data?.blogsCount);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const deleteItem = () => {
    setModal(false);
    // call delete api
    const promise = new Promise<void>((resolve, reject) => {
      callApi({
        method: "delete",
        url: inFocus,
        data: {},
        header: true,
        type: "casestudy",
      })
        .then((res) => {
          setInFocus("");
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        getData();
      });
  };

  const handleAction = (val: string, id: string) => {
    if (val === "delete") {
      setModal(true);
      setInFocus(id);
    }
  };

  // Handle row reordering
  const handleReorder = (draggedRowIndex: number, targetRowIndex: number) => {
    // Create a copy of the data array
    const reorderedData = [...data];
    // Remove the dragged item
    const draggedItem = reorderedData.splice(draggedRowIndex, 1)[0];
    // Insert it at the target position
    reorderedData.splice(targetRowIndex, 0, draggedItem);
    
    // Update the state with reordered data
    setData(reorderedData);
    
    // Call API to update order in the backend (if required)
    // This is where you would typically save the new order to your database
    updateCaseStudyOrder(reorderedData.map(item => item._id));
  };

  // Function to update the order in the backend
  const updateCaseStudyOrder = (orderedIds: string[]) => {
    callApi({
      method: "put",
      url: "/reorder",
      type: "casestudy",
      data: {
        orderedIds: orderedIds,
        identifier: localStorage.getItem("identifier")
      },
      header: true
    })
      .then((res) => {
        toast.success("Order updated successfully");
      })
      .catch((err) => {
        toast.error("Failed to update order");
        // Revert back to original order by re-fetching data
        getData();
      });
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => setModal(false)}
        onBtn2Click={deleteItem}
        state={modal}
      />

      <Content>
        <ContentHead
          title={langObj.caseStudy[lang]}
          showBtn1={admin.role === "subadmin" ? admin.caseStudy_access.write : true}
          btn1Name="btn1"
          iconType1="plus"
          btn1Text={langObj.newCaseStudy[lang]}
          btn1type="filled"
          onClickHandler={() => {
            if (isAllowed) {
              router.navigate("/case-study/create");
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
          isIcon={true}
        />
        <ContentBody style={{ padding: 0, position: "relative" }}>
          {loading ? (
            <TableLoading head={[
              { title: "S.No." },
              { title: "Title" },
              { title: "" },
            ]} />
          ) : (
            <DynamicDataTable
              data={data}
              columns={[{accessorKey: "title", header: "Title"}]}
              actionsColumns={[{name: "delete", action: () => setModal(true)}]}
              onRowClick={(rowInfo) => {router.navigate(`/case-study/update/${rowInfo._id}`);}}
              enableRowReorder={true}
              onReorder={handleReorder}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

export default withRouter(CaseStudy);