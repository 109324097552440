import axios from "axios";
import { isVideo } from "../Utils/isVideo";
import { addShoppableVideo } from "./shoppableApi";

type props = {
  url: string;
  method: string;
  header?: boolean;
  data?: any;
  type:
    | "blogs"
    | "admins"
    | ""
    | "casestudy"
    | "discounts"
    | "productmedia"
    | "leads";
  identifier?: boolean;
};

let url = process.env.REACT_APP_BASE_URL || "https://api.shopiq.app/api/";

const callApi = (props: props) => {
  let data = props.data;

  if (props.identifier) {
    data.identifier = localStorage.getItem("identifier");
  }

  return axios({
    url: url + props.type + "/" + props.url,
    method: props.method,
    data: data,
    headers: props?.header
      ? { Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}` }
      : {},
  });
};

export const uploadBulkImages = async (images: File[]) => {
  try {
    const imageFormData = new FormData();
    const videoFormDataArray: { formData: FormData; file: File }[] = [];

    const urls: any[] = [];
    const baseURL = process.env.REACT_APP_BASE_URL;
    const identifier = localStorage.getItem("identifier");
    const token = localStorage.getItem("token-BO-ADMIN");

    if (!baseURL || !identifier || !token) {
      throw new Error("Missing necessary configuration or authentication data");
    }

    // Separate images and videos
    images.forEach((file) => {
      if (!isVideo(file)) {
        imageFormData.append("files", file);
      } else {
        const formData = new FormData();
        formData.append("files", file);
        formData.append("id", "");
        videoFormDataArray.push({ formData, file });
      }
    });

    // Upload Images
    if (imageFormData.has("files")) {
      const imageResponse = await axios.post(
        `${baseURL}uploadbulkimages/uploadbulkimages?identifier=${identifier}`,
        imageFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      urls.push(...imageResponse.data.images);
    }

    // Upload Videos
    const videoResponses = await Promise.all(
      videoFormDataArray.map(({ formData }) =>
        axios.post(
          `https://shopable-backend-epklwnz2xq-em.a.run.app/api/v1/staging/shopable-product`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
      )
    );

    videoResponses.forEach((response) => {
      urls.push(response.data.data.productVideo);
    });

    return {
      success: true,
      data: { images: urls },
    };
  } catch (error: any) {
    return {
      success: false,
      error: error,
    };
  }
};

export const getAllFiles = async () => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }uploadbulkimages/images?identifier=${localStorage.getItem("identifier")}`
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || "Something went wrong",
    };
  }
};

export default callApi;
