import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import ObjToExcel from "../../Utils/objToExcel";
import { useLocation, useNavigate } from "react-router-dom";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "./leads.module.css";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import { getResourceLimit } from "../../Utils/checkPermissions/planPermissions";
import DynamicDataTable from "../../UI/DynamicTable";
import SecondaryView from "./secondaryView";
let inFocus = "";

const Leads = () => {
  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);
  const resource_limit = getResourceLimit(admin.selectedPlan, "contacts");
  const location = useLocation();
  const navigate = useNavigate();
  const [secondaryView, setSecondaryView] = useState(false);
  const [inView, setInView]: any = useState({});
  const [tab, setTab] = useState("overview");

  const [contacts, setContacts]: any = useState([]);
  const [updatedContacts, setUpdatedContacts]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [subAdmins, setSubAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  const [contactOwnerDropdown, setContactOwnerDropdown] = useState(false);
  const [createDateDropdown, setCreateDateDropdown] = useState(false);
  const [lastActivityDropdown, setLastActivityDropdown] = useState(false);
  const [leadStatusDropdown, setLeadStatusDropdown] = useState(false);
  const [actionsDropdown, setActionsDropdown] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);

  const [navContactOwnerSearch, setNavContactOwnerSearch] = useState("");
  const [navCreateDateSearch, setNavCreateDateSearch] = useState("");
  const [navLastActivitySearch, setNavLastActivitySearch] = useState("");
  const [navLeadStatusSearch, setNavLeadStatusSearch] = useState("");

  const [remark, setRemark] = useState("");
  const [logs, setLogs] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [disableForAction, setDisableForAction] = useState(false);

  const [filter, setFilter]: any = useState({
    contactOwner: [],
    createDate: "",
    lastActivityDate: "",
    leadStatus: [],
    search: "",
  });

  const [deleteById, setDeleteById] = useState("");
  const [selectedRowForActions, setSelectedRowForActions] = useState<any[]>([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}sub-admin`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setSubAdmins(res.data.sub_admins);
      })
      .catch((err: any) => {});
  }, []);

  useEffect(() => {
    handlePage();
  }, [filter, location.search]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setFilter((prevFilter: any) => ({ ...prevFilter, search: searchTerm }));
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (contacts.length === 0) return;

    if (contacts.filter((val: any) => val.actions.select).length !== 0) {
      setDisableForAction(true);
    } else {
      setDisableForAction(false);
    }
  }, [contacts]);

  useEffect(() => {
    setUpdateMode(updatedContacts.length === 0 ? false : true);
  }, [updatedContacts]);

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handlePage = () => {
    getData();
  };

  const getLogs = () => {
    axios({
      method: "get",
      url:
        `${process.env.REACT_APP_BASE_URL}contact-logs?identifier=` +
        localStorage.getItem("identifier"),
    })
      .then((res: any) => {
        setLogs(res.data.data?.fromTo);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const getData = () => {
    setLoading(true);
    getLogs();
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    const data: any = {
      ...filter,
      status: filter.leadStatus,
      page: currentPage,
      limit: currentLimit,
    };

    if (data.contactOwner.length === 0) delete data.contactOwner;
    if (!data.createDate) delete data.createDate;
    if (!data.lastActivityDate) delete data.lastActivityDate;
    delete data.leadStatus;
    if (data.status.length === 0) delete data.leadStatus;
    if (!data.search) delete data.search;

    axios({
      method: "post",
      url: `${
        process.env.REACT_APP_BASE_URL
      }leads/search?identifier=${localStorage.getItem("identifier")}`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log("res:", res);
        setContacts([
          ...res.data.contacts.map((val: any) => {
            if (val?.modifiedAt) {
              const utc = new Date(val?.modifiedAt);
              const utc_createdAt = new Date(val?.createdAt);

              const local = new Date(
                utc.toLocaleString("en-us", { timeZone: "Asia/Kolkata" })
              );

              const local_createdAt = new Date(
                utc_createdAt.toLocaleString("en-us", {
                  timeZone: "Asia/Kolkata",
                })
              );

              const formattedLocal = `${local.getFullYear()}-${String(
                local.getMonth() + 1
              ).padStart(2, "0")}-${String(local.getDate()).padStart(
                2,
                "0"
              )}T${String(local.getHours()).padStart(2, "0")}:${String(
                local.getMinutes()
              ).padStart(2, "0")}`;

              const formattedLocal_createdAt = `${local_createdAt.getFullYear()}-${String(
                local_createdAt.getMonth() + 1
              ).padStart(2, "0")}-${String(local_createdAt.getDate()).padStart(
                2,
                "0"
              )}T${String(local_createdAt.getHours()).padStart(
                2,
                "0"
              )}:${String(local_createdAt.getMinutes()).padStart(2, "0")}`;

              if (inView._id === val._id) {
                setInView({
                  ...val,
                  modifiedAt: formattedLocal,
                  createdAt: formattedLocal_createdAt,
                  actions: { select: false },
                });
              }

              return {
                ...val,
                modifiedAt: formattedLocal,
                createdAt: formattedLocal_createdAt,
                actions: { select: false },
              };
            } else {
              if (inView._id === val._id) {
                setInView({
                  ...val,
                  actions: { select: false },
                });
              }
              return {
                ...val,
                actions: { select: false },
              };
            }
          }),
        ]);
        setTotal(res.data.totalContacts || 0);
        setPage(currentPage);
        setLimit(currentLimit);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
        setTotal(0);
        setContacts([]);
      });
  };

  const handleRemarkUpdate = (data: any, remark: any) => {
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}contacts/${data._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
      data: { remarks: remark },
    })
      .then((res: any) => {
        toast.success("Remark updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  const handleDropdownUpdate = (data: any, event: any) => {
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}contacts/${data._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
      data: { status: event.target.value },
    })
      .then((res: any) => {
        toast.success("Status updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  const handleDelete = async () => {
    setModal(false);
    setLoading(true);

    const temp = [...contacts]
      .filter((val) => selectedRowForActions.includes(val._id))
      .map((v) => v._id);

    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}contacts/multiple-delete`,
      data: { ids: temp },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    });

    getData();
    setLoading(false);
    toast.success("Deleted Successfully.");
  };

  const handleSingleDelete = () => {
    setModal(false);
    setLoading(true);

    let index: any;

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i]._id === selectedRowForActions) {
        index = i;
        break;
      }
    }

    let nextInView: any;

    if (index === 0) {
      nextInView = contacts[1];
    } else if (contacts.length - 1 === index) {
      nextInView = contacts[index - 1];
    } else {
      nextInView = contacts[index - 1];
    }

    axios({
      url: `${process.env.REACT_APP_BASE_URL}contacts/` + deleteById,
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        getData();
        setLoading(false);
        setInView(nextInView);
        toast.success("Deleted Successfully.");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong while delete the contact.");
      });
  };

  const handleBulkUpdate = async (to: any, selectedRows: any[]) => {
    setLoading(true);
    if (!Array.isArray(selectedRows)) {
      console.error("selectedRowForActions is not an array");
      return;
    }

    const temp = contacts
      .filter((val) =>
        selectedRowForActions.map((v) => v._id)?.includes(val._id)
      )
      .map((v) => ({ ...v, status: to }));

    await axios({
      url:
        `${process.env.REACT_APP_BASE_URL}contacts/multiple-updates?identifier=` +
        localStorage.getItem("identifier"),
      method: "patch",
      data: { updates: temp },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    });

    getData();
    setLoading(false);
    toast.success("Updated Successfully.");
  };

  const handleDeleteClick = () => {
    setModal(true);
  };

  const exportExcel = () => {
    ObjToExcel(contacts, "Leads", "Leads", [
      { title: "Name", value: "firstName" },
      { title: "Middle Name", value: "middleName" },
      { title: "Last Name", value: "lastName" },
      { title: "Mobile Number", value: "mobileNumber" },
      { title: "Email", value: "personalEmail" },
      { title: "Primary Company", value: "companyName" },
      { title: "Contact Owner", value: "leadOwner" },
      { title: "Lead Status", value: "status" },
      { title: "Created At", value: "createdAt" },
      { title: "Modified At", value: "modifiedAt" },
    ]);
  };

  const checkAll = () => {
    setContacts((prev: any) => {
      let temp = [...prev];
      if (
        temp.filter((val: any) => val.actions.select).length === temp.length
      ) {
        temp = temp.map((val) => ({ ...val, actions: { select: false } }));
      } else {
        temp = temp.map((val) => ({ ...val, actions: { select: true } }));
      }
      return temp;
    });
  };

  const changeSelect = (id: any) => {
    setContacts((prev: any) => {
      let temp: any = [...prev];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]._id === id) {
          temp[i].actions.select = !temp[i].actions.select;
          break;
        }
      }
      return temp;
    });
  };

  const updateContacts = async () => {
    setLoading(true);

    for (let i = 0; i < updatedContacts.length; i++) {
      const d = { ...updatedContacts[i], modifiedAt: new Date().toISOString() };
      delete d.actions;

      await axios({
        url: `${process.env.REACT_APP_BASE_URL}contacts/${d._id}`,
        method: "patch",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: d,
      });
    }

    toast.success("Updated successfully.");
    setUpdatedContacts([]);
    setLoading(false);
    getData();
  };

  const handleAddRemark = () => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}contacts/` + inView._id,
      method: "patch",
      data: {
        remarks: [
          { content: remark, date: new Date(), by: admin?.email || "" },
          ...inView?.remarks,
        ],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        getData();
        setRemark("");
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const getChangedFields = (fromObj: any, toObj: any) => {
    const changedFields = [];
    for (let key in fromObj) {
      if (fromObj[key] !== toObj[key]) {
        changedFields.push({
          field: key,
          fromValue: fromObj[key],
          toValue: toObj[key],
        });
      }
    }
    return changedFields;
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          inFocus = "";
          setDeleteById("");
        }}
        onBtn2Click={() => {
          if (deleteById) {
            handleSingleDelete();
          } else {
            handleDelete();
          }
        }}
        state={modal}
      />
      <Content>
        <ContentHead
          title={"Contacts"}
          showBtn1={resource_limit > contacts.length}
          btn1Name={updateMode ? "update_btn1" : "btn1"}
          btn1Text={"New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          isMoreSettings={true}
          moreSettingsList={[
            { icon: "import", title: "Import", name: "import" },
            { icon: "export", title: "Export", name: "export" },
            { icon: "logs", title: "Logs", name: "logs" },
          ]}
          handleMoreClick={(name: any) => {
            if (isAllowed) {
              if (name === "import") {
                navigate("/contacts/import-file");
              } else if (name === "export") {
                exportExcel();
              } else if (name === "logs") {
                navigate("/contacts/logs");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
          onClickHandler={(type: any) => {
            if (isAllowed) {
              if (type === "New") {
                navigate("/contacts/add");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search name, phone, email ..."
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          {(contactOwnerDropdown ||
            createDateDropdown ||
            lastActivityDropdown ||
            leadStatusDropdown ||
            actionsDropdown) && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "transparent",
                zIndex: 10,
              }}
              onClick={() => {
                setContactOwnerDropdown(false);
                setCreateDateDropdown(false);
                setLastActivityDropdown(false);
                setLeadStatusDropdown(false);
                setActionsDropdown(false);
              }}
            ></div>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div className={styles.filter_container}>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setContactOwnerDropdown(true)}
                  style={
                    filter?.contactOwner.length !== 0
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  {filter?.contactOwner.length !== 0 && (
                    <div
                      style={{
                        borderRadius: "100%",
                        color: "var(--brand-color)",
                        fontSize: ".8rem",
                      }}
                    >
                      {`(${filter.contactOwner.length})`}
                    </div>
                  )}
                  <p>Contact owner</p>
                  <DownIcon />
                  {filter?.contactOwner.length !== 0 && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({
                          ...prev,
                          contactOwner: [],
                        }));
                      }}
                    />
                  )}
                </div>
                {contactOwnerDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search contact owner"
                          value={navContactOwnerSearch}
                          onChange={(e: any) =>
                            setNavContactOwnerSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {subAdmins
                        .filter((v: any) => {
                          if (!navContactOwnerSearch) return true;

                          if (
                            v.firstName
                              .toLowerCase()
                              .trim()
                              .includes(
                                navContactOwnerSearch.toLowerCase().trim()
                              ) ||
                            v.lastName
                              .toLowerCase()
                              .trim()
                              .includes(
                                navContactOwnerSearch.toLowerCase().trim()
                              ) ||
                            `${v.firstName.toLowerCase().trim()} ${v.lastName
                              .toLowerCase()
                              .trim()}`.includes(
                              navContactOwnerSearch.toLowerCase().trim()
                            ) ||
                            v.email
                              ?.toLowerCase()
                              .trim()
                              .includes(
                                navContactOwnerSearch.toLowerCase().trim()
                              )
                          )
                            return true;

                          return false;
                        })
                        .map((subadmin: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => {
                                  let temp: any = [...filter.contactOwner];
                                  if (temp.length === 0)
                                    return {
                                      ...prev,
                                      contactOwner: [subadmin._id],
                                    };

                                  if (temp.includes(subadmin._id)) {
                                    temp.splice(temp.indexOf(subadmin._id), 1);
                                    return { ...prev, contactOwner: temp };
                                  } else {
                                    temp.push(subadmin._id);
                                    return { ...prev, contactOwner: temp };
                                  }
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={filter.contactOwner.includes(
                                  subadmin._id
                                )}
                              />
                              <div>
                                <p
                                  style={{ fontSize: ".9rem" }}
                                >{`${subadmin?.firstName} ${subadmin?.lastName}`}</p>
                                <p style={{ fontSize: ".8rem" }}>
                                  {subadmin?.email}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      {!navContactOwnerSearch ? (
                        <div
                          className={styles.contact_owner_single_list}
                          onClick={() => {
                            setFilter((prev: any) => {
                              let temp: any = [...filter.contactOwner];
                              if (temp.length === 0)
                                return {
                                  ...prev,
                                  contactOwner: ["unassigned"],
                                };

                              if (temp.includes("unassigned")) {
                                temp.splice(temp.indexOf("unassigned"), 1);
                                return { ...prev, contactOwner: temp };
                              } else {
                                temp.push("unassigned");
                                return { ...prev, contactOwner: temp };
                              }
                            });
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={filter.contactOwner.includes("unassigned")}
                          />
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Unassigned</p>
                          </div>
                        </div>
                      ) : "unassigned".includes(
                          navContactOwnerSearch.toLowerCase().trim()
                        ) ||
                        "unassigned".includes(
                          navContactOwnerSearch.toLowerCase().trim()
                        ) ||
                        "unassigned".includes(
                          navContactOwnerSearch.toLowerCase().trim()
                        ) ? (
                        <div
                          className={styles.contact_owner_single_list}
                          onClick={() => {
                            setFilter((prev: any) => {
                              let temp: any = [...filter.contactOwner];
                              if (temp.length === 0)
                                return {
                                  ...prev,
                                  contactOwner: ["unassigned"],
                                };

                              if (temp.includes("unassigned")) {
                                temp.splice(temp.indexOf("unassigned"), 1);
                                return { ...prev, contactOwner: temp };
                              } else {
                                temp.push("unassigned");
                                return { ...prev, contactOwner: temp };
                              }
                            });
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={filter.contactOwner.includes("unassigned")}
                          />
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Unassigned</p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setCreateDateDropdown(true)}
                  style={
                    filter?.createDate
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  <p>Create Date</p>
                  <DownIcon />
                  {filter?.createDate && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({ ...prev, createDate: "" }));
                      }}
                    />
                  )}
                </div>
                {createDateDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search"
                          value={navCreateDateSearch}
                          onChange={(e: any) =>
                            setNavCreateDateSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {date_values
                        .filter((v: any) => {
                          if (!navCreateDateSearch) return true;

                          const text = navCreateDateSearch.toLowerCase().trim();

                          if (v.title.toLowerCase().includes(text)) return true;
                          if (v.description.toLowerCase().includes(text))
                            return true;

                          return false;
                        })
                        .map((val: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => ({
                                  ...prev,
                                  createDate: val.value,
                                }));
                                setCreateDateDropdown(false);
                              }}
                            >
                              <div>
                                <p style={{ fontSize: ".9rem" }}>{val.title}</p>
                                <p style={{ fontSize: ".8rem" }}>
                                  {val.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setLastActivityDropdown(true)}
                  style={
                    filter?.lastActivityDate
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  <p>Last Activity Date</p>
                  <DownIcon />
                  {filter?.lastActivityDate && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({
                          ...prev,
                          lastActivityDate: "",
                        }));
                      }}
                    />
                  )}
                </div>
                {lastActivityDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search"
                          value={navLastActivitySearch}
                          onChange={(e: any) =>
                            setNavLastActivitySearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {date_values
                        .filter((v: any) => {
                          if (!navLastActivitySearch) return true;
                          const text = navLastActivitySearch
                            .toLowerCase()
                            .trim();

                          if (v.title.toLowerCase().includes(text)) return true;
                          if (v.description.toLowerCase().includes(text))
                            return true;

                          return false;
                        })
                        .map((val: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => ({
                                  ...prev,
                                  lastActivityDate: val.value,
                                }));
                                setLastActivityDropdown(false);
                              }}
                            >
                              <div>
                                <p style={{ fontSize: ".9rem" }}>{val.title}</p>
                                <p style={{ fontSize: ".8rem" }}>
                                  {val.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setLeadStatusDropdown(true)}
                  style={
                    filter?.leadStatus.length !== 0
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  {filter?.leadStatus.length !== 0 && (
                    <div
                      style={{
                        borderRadius: "100%",
                        color: "var(--brand-color)",
                        fontSize: ".8rem",
                      }}
                    >
                      {`(${filter.leadStatus.length})`}
                    </div>
                  )}
                  <p>Lead Status</p>
                  <DownIcon />
                  {filter?.leadStatus.length !== 0 && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({ ...prev, leadStatus: [] }));
                      }}
                    />
                  )}
                </div>
                {leadStatusDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search"
                          value={navLeadStatusSearch}
                          onChange={(e: any) =>
                            setNavLeadStatusSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {lead_values
                        .filter((v: any) => {
                          if (!navLeadStatusSearch) return true;

                          const text = navLeadStatusSearch.toLowerCase().trim();

                          if (v.title.toLowerCase().includes(text)) return true;

                          return false;
                        })
                        .map((val: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => {
                                  let temp: any = [...filter.leadStatus];
                                  if (temp.length === 0)
                                    return { ...prev, leadStatus: [val.value] };

                                  if (temp.includes(val.value)) {
                                    temp.splice(temp.indexOf(val.value), 1);
                                    return { ...prev, leadStatus: temp };
                                  } else {
                                    temp.push(val.value);
                                    return { ...prev, leadStatus: temp };
                                  }
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={filter.leadStatus.includes(val.value)}
                              />
                              <div>
                                <p style={{ fontSize: ".9rem" }}>{val.title}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              {disableForAction && (
                <div className={styles.drop_down_container}>
                  <div
                    className={styles.drop_down_content}
                    onClick={() => setActionsDropdown(true)}
                  >
                    <p>Actions</p>
                    <DownIcon />
                  </div>
                  {actionsDropdown && (
                    <div className={styles.drop_down_section}>
                      <div style={{ padding: "10px" }}>
                        <div className={styles.contact_owner_search}>
                          <FontAwesomeIcon icon={faSearch} />
                          <input type="text" placeholder="Search" />
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "0",
                          maxHeight: "250px",
                          overflowY: "auto",
                        }}
                      >
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteClick()}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Delete</p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate("new", selectedRowForActions)
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Set Status: New</p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate("open", selectedRowForActions)
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Open
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate(
                              "in_progress",
                              selectedRowForActions
                            )
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: In Progress
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate("open_deal", selectedRowForActions)
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Open Deal
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate(
                              "unqualified",
                              selectedRowForActions
                            )
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Unqualified
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate(
                              "attempted_to_contact",
                              selectedRowForActions
                            )
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Attempted to contact
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate("connected", selectedRowForActions)
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Connected
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate(
                              "bad_timing",
                              selectedRowForActions
                            )
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Bad Timing
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate(
                              "unassigned",
                              selectedRowForActions
                            )
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Unassigned
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {secondaryView ? (
              <SecondaryView
                contacts={contacts}
                inView={inView}
                setInView={setInView}
                changeSelect={changeSelect}
                setSecondaryView={setSecondaryView}
                setDeleteById={setDeleteById}
                setModal={setModal}
                tab={tab}
                setTab={setTab}
                remark={remark}
                setRemark={setRemark}
                handleAddRemark={handleAddRemark}
                logs={logs}
                getChangedFields={getChangedFields}
                contact_fields={contact_fields}
              />
            ) : (
              <>
                <DynamicDataTable
                  data={contacts}
                  columns={[
                    { accessorKey: "firstName", header: "First Name" },
                    { accessorKey: "lastName", header: "Last Name" },
                    { accessorKey: "personalEmail", header: "Email" },
                    { accessorKey: "mobileNumber", header: "Phone Number" },
                    { accessorKey: "contactOwner", header: "Contact Owner" },
                    {
                      accessorKey: "primaryCompany",
                      header: "Primary Company",
                    },
                    {
                      accessorKey: "modifiedAt",
                      header: "Last Activity Date",
                    },
                    { accessorKey: "status", header: "Lead Status" },
                    { accessorKey: "createdAt", header: "Create Date" },
                  ]}
                  enableRowReorder={false}
                  onRowClick={(rowId: any) => {
                    setSecondaryView(true);
                    setInView(rowId);
                  }}
                  checkboxRow={true}
                  onSelectedRowsChange={(selectedRows: number[]) => {
                    console.log("Selected Row function :::: ", selectedRows);
                    setDisableForAction(selectedRows.length > 0);
                    if (selectedRows.length > 0) {
                      const selectedRow = contacts.find(
                        (contact) => contact.id === selectedRows[0]
                      );
                      setSelectedRowForActions([
                        ...selectedRowForActions,
                        selectedRow,
                      ]);
                    } else {
                      setSelectedRowForActions([]);
                    }
                  }}
                  onPaginationChange={(newPage, newLimit) => {
                    setPage(newPage);
                    setLimit(newLimit);
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set("page", newPage.toString());
                    searchParams.set("limit", newLimit.toString());
                    navigate(`/leads?${searchParams.toString()}`);
                  }}
                  totalRows={total}
                  initialPageSize={limit}
                  initialPageIndex={page}
                />
              </>
            )}
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const date_values = [
  {
    title: "Today",
    description: "Today from midnight until the current time",
    value: "today",
  },
  {
    title: "Yesterday",
    description: "The previous 24 hour day",
    value: "yesterday",
  },
  {
    title: "Tomorrow",
    description: "The next 24 hour day",
  },
  {
    title: "This week",
    description: "The current calendar week up to now",
    value: "this_week",
  },
  {
    title: "This week so far",
    description: "The current calendar week up to now",
    value: "this_week_so_far",
  },
  {
    title: "Last week",
    description: "The previous calendar week",
    value: "last_week",
  },
  {
    title: "Next week",
    description: "The next calendar week",
    value: "next_week",
  },
  {
    title: "This month",
    description: "the current calendar month",
    value: "this_month",
  },
  {
    title: "This month so far",
    description: "The current calendar month up to now",
    value: "this_month_so_far",
  },
  {
    title: "Last month",
    description: "The previous calendar month",
    value: "last_month",
  },
  {
    title: "Next month",
    description: "The next calendar month",
    value: "next_month",
  },
  {
    title: "This quarter",
    description: "This current quarter",
    value: "this_quarter",
  },
  {
    title: "This fiscal quarter",
    description: "The current fiscal quarter",
    value: "this_fiscal_quarter",
  },
  {
    title: "This quarter so far",
    description: "The current quarter up to now",
    value: "this_quarter_so_far",
  },
  {
    title: "This fiscal quarter so far",
    description: "The current fiscal quarter up to now",
    value: "this_fiscal_quarter_so_far",
  },
  {
    title: "Last quarter",
    description: "The previous full quarter",
    value: "last_quarter",
  },
  {
    title: "Last fiscal quarter",
    description: "The previous full fiscal quarter",
    value: "last_fiscal_quarter",
  },
  {
    title: "Next quarter",
    description: "The next full quarter",
    value: "next_quarter",
  },
  {
    title: "Next fiscal quarter",
    description: "The next full fiscal quarter",
    value: "next_fiscal_quarter",
  },
  {
    title: "This year",
    description: "The current calendar year",
    value: "this_year",
  },
  {
    title: "This fiscal year",
    description: "The current fiscal year",
    value: "this_fiscal_year",
  },
  {
    title: "This year so far",
    description: "The current calendar year up to now",
    value: "this_year_so_far",
  },
  {
    title: "This fiscal year so far",
    description: "The current fiscal year up to now",
    value: "this_fiscal_year_so_far",
  },
  {
    title: "Last year",
    description: "The previous calendar year",
    value: "last_year",
  },
  {
    title: "Last fiscal year",
    description: "The previous fiscal year",
    value: "last_fiscal_year",
  },
  {
    title: "Next year",
    description: "The next calendar year",
    value: "next_year",
  },
  {
    title: "Next fiscal year",
    description: "The next fiscal year",
    value: "next_fiscal_year",
  },
  {
    title: "Last 7 days",
    description: "The previous 7 days before today",
    value: "last_7_days",
  },
  {
    title: "Last 14 days",
    description: "The previous 14 days before today",
    value: "last_14_days",
  },
  {
    title: "Last 30 days",
    description: "The previous 30 days before today",
    value: "last_30_days",
  },
  {
    title: "Last 60 days",
    description: "The previous 60 days",
    value: "last_60_days",
  },
  {
    title: "Last 90 days",
    description: "The previous 90 days",
    value: "last_90_days",
  },
  {
    title: "Last 180 days",
    description: "The previous 180 days",
    value: "last_180_days",
  },
  {
    title: "Last 365 days",
    description: "The previous 365 days",
    value: "last_365_days",
  },
];

const lead_values = [
  { title: "New", value: "new" },
  { title: "Open", value: "open" },
  { title: "Closed", value: "closed" },
  { title: "In Progress", value: "in_progress" },
  { title: "Marketing Qualified Lead", value: "mql" },
  { title: "Sales Qualified Lead", value: "sql" },
  { title: "Open Deal", value: "open_deal" },
  { title: "Unqualified", value: "unqualified" },
  { title: "Attempted to Contact", value: "attempted_to_contact" },
  { title: "Connected", value: "connected" },
  { title: "Bad Timing", value: "bad_timing" },
  { title: "Unassigned", value: "unassigned" },
];

const contact_fields: any = {
  firstName: "First Name",
  lastName: "Last Name",
  companyName: "Company Name",
  companyWebsite: "Company Website",
  companyHQ: "Company HQ",
  mobileNumber: "Mobile Number",
  altMobileNumber: "Alt. Mobile Number",
  personalEmail: "Email",
  cooperateEmail: "Cooperate Email",
  country: "Country",
  state: "State",
  city: "City",
  address: "Address",
  pincode: "Pincode",
  remarks: "Remarks",
  status: "Status",
};

export default Leads;
