import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";

function withRouter(Component: any) {
  const Wrapper = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { lang }: any = useLangContext();
    const { admin }: any = useAdminContext();
    const isAllowed = isUserAllowed(admin);

    return (
      <Component
        router={{ navigate: navigate, location: location, params: params }}
        lang={lang}
        isAllowed={isAllowed}
        admin={admin}
        {...props}
      />
    );
  };

  return Wrapper;
}

export default withRouter;
