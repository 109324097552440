import React from "react";
import styles from "./location.module.css";
import SingleLocation from "./SingleLocation";
import { ReactComponent as IndiaMap } from "./IndiaMap.svg";

type Props = {};

const Location = (props: Props) => {
  return (
    <>
      <h3>Location</h3>
      <div
        style={{
          width: "100%",
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "gray",
        }}
      >
        <span>No Data Found</span>
      </div>
    </>
  );
};

export default Location;
