import React, { Component } from "react";
import { toast } from "react-hot-toast";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import InputField from "../../../Components/InputField";
import withRouter from "../../../Components/withRouter";
import { Country, City } from "country-state-city";
import UploadImage from "../../../Utils/UploadImage";
import callApi from "../../../Apis/callApi";
import TextEditor from "../../../Components/TextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getFileName } from "../../../Utils/getFileName";
import langObj from "../../../Utils/lang";
import heic2any from "heic2any";
import ImageFolder from "../../../Components/ImageFolder";

class CreateBlog extends Component<any, any> {
  state = {
    imageUrl: "",
    imageObject: "",
    content: "", // body
    title: "",
    shortDescription: "", // description
    category: "",
    tags: "",
    adOneUrl: "",
    adTwoUrl: "",
    buttonOneUrl: "",
    buttonTwoUrl: "",
    loading: false,
    isImageChanged: false,
    author: "",
    type: "",
    blogImages: [], // Array to hold selected images from ImageFolder
  };

  componentDidMount(): void {
    if (this.props.router.params.id) {
      this.setState({ loading: true });
      this.getData();
    }
  }

  getData = () => {
    callApi({
      method: "get",
      url: this.props.router.params.id,
      data: {},
      type: "blogs",
    })
      .then((res) => {
        let data = res.data.blog;

        this.setState({
          adOneUrl: data.adOneUrl,
          adTwoUrl: data.adTwoUrl,
          content: data.body,
          buttonOneUrl: data.buttonOneUrl,
          buttonTwoUrl: data.buttonTwoUrl,
          category: data.category,
          shortDescription: data.description,
          identifier: data.identifier,
          tags: data.tags,
          title: data.title,
          imageUrl: data.image,
          author: data?.others?.author ? data.others?.author : "",
          loading: false,
          type: data?.type || "",
          // If using multiple images, initialize blogImages accordingly
          blogImages: data.image ? [data.image] : [],
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  // Legacy image handler (kept for backward compatibility)
  handleImage = async (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      this.setState({
        imageUrl: URL.createObjectURL(convertedFile),
        imageObject: convertedFile,
        isImageChanged: true,
      });
    } else
      this.setState({
        imageUrl: URL.createObjectURL(event.target.files[0]),
        imageObject: event.target.files[0],
        isImageChanged: true,
      });
  };

  // New method to update images from ImageFolder
  updateBlogImages = (images: string[]) => {
    this.setState({
      blogImages: images,
      // Set the first image as the main image for backward compatibility
      imageUrl: images.length > 0 ? images[0] : "",
      isImageChanged: true,
    });
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  validate = () => {
    let text = "";

    // check image - now checking the blogImages array
    if (this.state.blogImages.length === 0 && !text) {
      text = "Banner image";
    }

    // check title
    if (!this.state.title && !text) {
      text = "Blog title";
    }

    if (text) {
      toast.error(`${text} is Required.`);
      return false;
    }

    return true;
  };

  handleSubmit = (type: any) => {
    if (type === "Cancel") {
      this.props.router.navigate("/blogs");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });

        // If we're using the ImageFolder component and the images are already URLs
        // from the media library, we can use them directly
        if (this.state.blogImages.length > 0 && this.state.blogImages[0].startsWith('http')) {
          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.blogImages[0], // Use the first image as the main image
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
            status: type === "Save" ? "live" : "draft",
            others: { 
              author: this.state.author,
              additionalImages: this.state.blogImages.slice(1) // Store additional images if needed
            },
            type: this.state.type,
          };

          callApi({
            url: "",
            data,
            method: "post",
            type: "blogs",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Blog Created Successfully");
              this.props.router.navigate("/");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        } else if (this.state.imageObject) {
          // Legacy path for backward compatibility
          const filename = getFileName(this.state.imageObject);

          UploadImage({ file: this.state.imageObject, name: filename })
            .then((res) => {
              const image_url = res;
              const data = {
                title: this.state.title,
                description: this.state.shortDescription,
                image: image_url,
                body: this.state.content,
                adOneUrl: this.state.adOneUrl,
                adTwoUrl: this.state.adTwoUrl,
                buttonOneUrl: this.state.buttonOneUrl,
                buttonTwoUrl: this.state.buttonTwoUrl,
                category: this.state.category,
                tags: this.state.tags,
                status: type === "Save" ? "live" : "draft",
                others: { author: this.state.author },
                type: this.state.type,
              };

              callApi({
                url: "",
                data,
                method: "post",
                type: "blogs",
                header: true,
                identifier: true,
              })
                .then((res: any) => {
                  toast.success("Blog Created Successfully");
                  this.props.router.navigate("/");
                  this.setState({ loading: false });
                })
                .catch((err: any) => {
                  toast.error(err.response.data.message);
                  this.setState({ loading: false });
                });
            })
            .catch((res) => {
              this.setState({ loading: false });
              toast.error("Failed to upload image");
            });
        }
      }
    }
  };

  updateBlog = (type: any) => {
    if (type === "Cancel") {
      this.props.router.navigate("/blogs");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });

        // Case: Using ImageFolder and image hasn't changed
        if (this.state.blogImages.length > 0 && !this.state.isImageChanged) {
          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.blogImages[0],
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
            status: type === "Save" ? "live" : "draft",
            others: { 
              author: this.state.author,
              additionalImages: this.state.blogImages.slice(1)
            },
            type: this.state.type,
          };

          callApi({
            url: this.props.router.params.id,
            data,
            method: "patch",
            type: "blogs",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Blog Updated Successfully");
              this.props.router.navigate("/");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        } 
        // Case: Using ImageFolder and image has changed to a URL from media library
        else if (this.state.blogImages.length > 0 && this.state.isImageChanged && 
                this.state.blogImages[0].startsWith('http')) {
          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.blogImages[0],
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
            status: type === "Save" ? "live" : "draft",
            others: { 
              author: this.state.author,
              additionalImages: this.state.blogImages.slice(1)
            },
            type: this.state.type,
          };

          callApi({
            url: this.props.router.params.id,
            data,
            method: "patch",
            type: "blogs",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Blog Updated Successfully");
              this.props.router.navigate("/");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        }
        // Legacy path for directly uploaded file
        else if (this.state.imageObject && this.state.isImageChanged) {
          const filename = getFileName(this.state.imageObject);

          UploadImage({ file: this.state.imageObject, name: filename })
            .then((res) => {
              const image_url = res;
              const data = {
                title: this.state.title,
                description: this.state.shortDescription,
                image: image_url,
                body: this.state.content,
                adOneUrl: this.state.adOneUrl,
                adTwoUrl: this.state.adTwoUrl,
                buttonOneUrl: this.state.buttonOneUrl,
                buttonTwoUrl: this.state.buttonTwoUrl,
                category: this.state.category,
                tags: this.state.tags,
                status: type === "Save" ? "live" : "draft",
                others: { author: this.state.author },
                type: this.state.type,
              };

              callApi({
                url: this.props.router.params.id,
                data,
                method: "patch",
                type: "blogs",
                header: true,
                identifier: true,
              })
                .then((res: any) => {
                  toast.success("Blog Updated Successfully");
                  this.props.router.navigate("/");
                  this.setState({ loading: false });
                })
                .catch((err: any) => {
                  toast.error(err.response.data.message);
                  this.setState({ loading: false });
                });
            })
            .catch((res) => {
              this.setState({ loading: false });
              toast.error("Failed to upload image");
            });
        } else {
          // Case: No image change but we have existing URL
          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.imageUrl,
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
            status: type === "Save" ? "live" : "draft",
            others: { author: this.state.author },
            type: this.state.type,
          };

          callApi({
            url: this.props.router.params.id,
            data,
            method: "patch",
            type: "blogs",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Blog Updated Successfully");
              this.props.router.navigate("/");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        }
      }
    }
  };

  render() {
    const folder = localStorage.getItem("identifier")?.includes("bion")
      ? "bion/"
      : localStorage.getItem("identifier")?.includes("whitetales")
      ? "whitetales/"
      : localStorage.getItem("identifier")?.split(".").join("") + "/";

    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={
              this.props?.title
                ? this.props.title
                : langObj["Add New Blog"][this.props.lang]
            }
            showBtn1={
              this.props.admin.role === "subadmin"
                ? this.props.admin.blog_access.write
                : true
            }
            showBtn2={
              this.props.admin.role === "subadmin"
                ? this.props.admin.blog_access.write
                : true
            }
            showBtn3={true}
            btn1type="filled"
            btn2type="outlined"
            btn3type="outlined_del"
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text={langObj.save[this.props.lang]}
            btn2Text={langObj.saveAsDraft[this.props.lang]}
            btn3Text={langObj.cancel[this.props.lang]}
            onClickHandler={
              this.props.router.params.id ? this.updateBlog : this.handleSubmit
            }
            isSplitButton={true}
          />
          <ContentBody>
            <>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginBottom: "10px",
                }}
              >
                {langObj.bannerImage[this.props.lang]}
                <span style={{ color: "#a3a3a3", fontSize: ".8rem" }}>
                  {`(${langObj.uploadAnImageOfUpto5MB[this.props.lang]})`}
                </span>
              </p>

              
              <ImageFolder
                open={false}
                onSave={(val) => {}} 
                selectMultiple={false} 
                alreadySelected={this.state.blogImages}
                text={ "Upload Banner Image"}
                media={this.state.blogImages}
                updateMedia={this.updateBlogImages}
              />

              <InputField
                isLabel={true}
                value={this.state.title}
                lable={langObj["Blog Title"][this.props.lang]}
                type="text"
                isRequired={false}
                placeholder={langObj.blogHeading[this.props.lang]}
                onChange={(event: any) => {
                  this.setState({ title: event.target.value });
                }}
                disabled={
                  this.props.admin.role === "subadmin"
                    ? !this.props.admin.blog_access.write
                    : false
                }
              />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.tags}
                  lable={langObj.tags[this.props.lang]}
                  isRequired={false}
                  placeholder={langObj.commaSeperatedTags[this.props.lang]}
                  onChange={(event: any) => {
                    this.setState({
                      tags: event.target.value,
                    });
                  }}
                />
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.author}
                  lable={langObj.author[this.props.lang]}
                  isRequired={false}
                  placeholder={langObj.authorName[this.props.lang]}
                  onChange={(event: any) => {
                    this.setState({ author: event.target.value });
                  }}
                />

                <InputField
                  type="select"
                  lable={"Type"}
                  isLabel={true}
                  value={this.state.type}
                  options={[
                    { title: "Blog", value: "blog" },
                    { title: "Events", value: "events" },
                    { title: "Testimonial", value: "testimonial" },
                    { title: "Market Trends", value: "market trends" },
                    { title: "Innovation", value: "innovation" },
                    { title: "Trending", value: "trending" },
                  ]}
                  accessText="title"
                  accessValue="value"
                  onChange={(e: any) => {
                    this.setState({ type: e.target.value });
                  }}
                />
              </div>

              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginTop: "20px",
                }}
              >
                {langObj.shortDescription[this.props.lang]}
              </p>
              <textarea
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "20px",
                  borderRadius: "15px",
                  border: "1px solid black",
                  padding: "20px",
                  height: "100px",
                  background: "transparent",
                  fontFamily: "Poppins",
                }}
                value={this.state.shortDescription}
                onChange={(event: any) => {
                  this.setState({ shortDescription: event.target.value });
                }}
              />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginBottom: "10px",
                }}
              >
                {langObj.blogContent[this.props.lang]}
              </p>
              <TextEditor
                content={this.state.content}
                handleChange={(value: any) => {
                  this.setState({ content: value });
                }}
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(CreateBlog);