import React from "react";
import styles from "./analytics.module.css";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Content from "../../Components/Content/Content";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import MostViewedProduct from "../../Components/Analytics/mostViewedProducts/MostViewedProduct";

import Orders from "../../Components/Analytics/Orders/Orders";
import { UserLocation } from "../../Components/Analytics/userLocation/UserLocation";
import { MdInfoOutline } from "react-icons/md";
import HoverCardInfo from "../../Components/common/HoverCardInfo/HoverCardInfo";
import Location from "../../Components/Analytics/location/Location";
import ComingFrom from "../../Components/Analytics/comingFrom/ComingFrom";
import TopSellingProducts from "../../Components/Analytics/topSellingProducts/TopSellingProducts";
import { TopPerformingCategory } from "../../Components/Analytics/topPerformingCategory/topPerformingCategory";
import ContentOuterHead from "../../Components/Content/contentOuterHead";
import StoreSetup from "../../Components/StoreSetup/StoreSetup";
import { Outlet } from "react-router-dom";
// import ComingFrom from "../../Components/Analytics/comingFrom/ComingFrom";

type TopCardType = {
  totalOrderSum: number;
  totalNumberOfProducts: number;
  totalNumberOfOrders: number;
  topPerformingProduct: any;
  totalNumberOfUsers: number;
  totalView: number;
};

type MostViewedProducts = {
  title: string;
  impression: number;
}[];

type TopSellingProduct = {
  name: string;
  quantity: number;
}[];

export type ComingFromType = {
  label: string;
  value: number;
  session: number;
};

const url = process.env.REACT_APP_BASE_URL;
// const url = "http://localhost:8080/";

const Analytics = () => {
  const [data, setData] = useState<TopCardType>();
  const [mostViewedProducts, setMostViewedProducts] =
    useState<MostViewedProducts>();
  const [mvLoading, setMVLoading] = useState(true);
  const [topSellingProducts, setTopSellingProducts] =
    useState<TopSellingProduct>();
  const [tpLoading, setTPLoading] = useState(true);
  const [order, setOrder] = useState<any>(null);
  const [comingFrom, setComingFrom] = useState<ComingFromType[]>([]);
  const [cfLoading, setCFLoading] = useState(true);

  const today = new Date();

  const [endDate, setEndDate] = useState(today.toISOString());
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 2).toISOString()
  );

  function convertNumberToIndianRupee(number: number) {
    const numberFormat = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return numberFormat.format(number);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      method: "get",
      url: `${url}analytics/getTopCards?identifier=${localStorage.getItem(
        "identifier"
      )}`,
    })
      .then((res) => {
        setData(res.data);
        getMostViewedProducts();
      })
      .catch((err) => {
        getMostViewedProducts();
      });
  };

  const getMostViewedProducts = () => {
    axios({
      url: `${url}analytics/getTopViewedProducts?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        setMostViewedProducts(
          res.data.topViewedProducts.map((v: any) => ({
            title: v.title,
            impression: v.impression,
          }))
        );
        setMVLoading(false);
        getTopSellingProducts();
      })
      .catch((err) => {
        setMVLoading(false);
        getTopSellingProducts();
      });
  };

  const getTopSellingProducts = () => {
    axios({
      url: `${url}analytics/getTopSellingProducts?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        setTPLoading(false);
        setTopSellingProducts(res.data.topSellingProducts);
        getOrdersData();
      })
      .catch((err) => {
        setTPLoading(false);
        getOrdersData();
      });
  };

  const getOrdersData = () => {
    axios({
      url: `${url}analytics/orders?startDate=${startDate}&endDate=${endDate}&identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        setOrder(res.data);
        getComingFromData();
      })
      .catch((err) => {});
  };

  const getComingFromData = () => {
    axios({
      url: `${url}analytics/comingFromData?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        const totalValue = Object.values(res.data.comingFrom).reduce(
          (sum, value) => Number(sum) + Number(value),
          0
        ) as number;

        // Step 2: Convert the object to an array with percentage values
        const array = Object.keys(res.data.comingFrom).map((key) => ({
          label: key,
          value: Number(
            ((res.data.comingFrom[key] / totalValue) * 100).toFixed(2)
          ),
          session: res.data.comingFrom[key],
        }));
        const filteredArray = array.filter(
          (data) => !data.label.includes("www.pulpofficial.com")
        );
        setComingFrom([...filteredArray]);
        setCFLoading(false);
      })
      .catch((err) => {
        setCFLoading(false);
      });
  };

  useEffect(() => {
    getOrdersData();
  }, [startDate, endDate]);

  return (
    <Content>
      <ContentBody style={{ padding: "10px" }}>
        <StoreSetup />
        <ContentHead title={"Overview"} />
        <ContentBody style={{ padding: "10px" }}>
          <div className="grid">
            {data && (
              <ul className={styles.top_cards}>
                <li className={styles.card}>
                  <div className={styles.Header}>
                    <h4>Total Views </h4>
                    <HoverCardInfo
                      header=""
                      description="the total no. of views on the website "
                    />
                  </div>
                  <p>{data?.totalView}</p>
                </li>
                <li className={styles.card}>
                  <div className={styles.Header}>
                    <h4>Total Orders </h4>
                    <HoverCardInfo
                      header=""
                      description="the total no. of orders fulfilled"
                    />
                  </div>
                  <p>{data?.totalNumberOfOrders}</p>
                </li>
                <li className={styles.card}>
                  <div className={styles.Header}>
                    <h4>Total Revenue </h4>
                    <HoverCardInfo
                      header=""
                      description="The sum of revenue from purchases minus the cancelled orders."
                    />
                  </div>
                  <p>{convertNumberToIndianRupee(data?.totalOrderSum)}</p>
                </li>
                <li className={styles.card}>
                  <div className={styles.Header}>
                    <h4>Total Products </h4>
                    <HoverCardInfo
                      header="Total Products"
                      description="the total no. of products available on our website "
                    />
                  </div>
                  <p>{data?.totalNumberOfProducts}</p>
                </li>
                <li className={styles.card}>
                  <div className={styles.Header}>
                    <h4>Total Users </h4>
                    <HoverCardInfo
                      right="0px"
                      header="Total Users"
                      description="the total no. of Users"
                    />
                  </div>
                  <p>{data?.totalNumberOfUsers}</p>
                </li>
              </ul>
            )}
            <div className="grid_2">
              <div className="card">
                {mostViewedProducts?.length !== 0 && (
                  <MostViewedProduct
                    subHeader="Most Viewed"
                    header="Most Viewed Products"
                    products={mostViewedProducts!}
                    loading={mvLoading}
                  />
                )}
              </div>
              <div className="card">
                <TopSellingProducts
                  loading={tpLoading}
                  subHeader="Top Selling"
                  header="Top Selling Products"
                  products={topSellingProducts!}
                />
              </div>
              <div className="card">
                {order && (
                  <Orders
                    order={order}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                )}
              </div>
              <div className="card">
                <ComingFrom comingFrom={comingFrom} loading={cfLoading} />
              </div>
              <TopPerformingCategory />
            </div>
            <div className="card">
              <Location />
            </div>
          </div>
        </ContentBody>
      </ContentBody>
    </Content>
  );
};

export default Analytics;
