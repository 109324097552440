import React, { useState, useEffect } from "react";
import Modal from "../../Components/Modal";
import toast from "react-hot-toast";
import callApi from "../../Apis/callApi";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import withRouter from "../../Components/withRouter";
import { useAdminContext } from "../../Store/adminContext";
import DynamicDataTable from "../../UI/DynamicTable";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import TableLoading from "../../Components/LoadingUi/TableLoading";

const ProductVideo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");
  const [plan, setPlan] = useState(-1);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getWebsiteData();
  };

  const getWebsiteData = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/getWebsiteData`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        if (res.data.data.productVideo.planType === "basic") {
          setPlan(5);
        } else if (res.data.data.productVideo.planType === "essential") {
          setPlan(50);
        } else if (res.data.data.productVideo.planType === "advance") {
          setPlan(200);
        } else if (res.data.data.productVideo.planType === "unlimited") {
          setPlan(100000000);
        } else {
          setPlan(5);
        }

        getData();
      })
      .catch((error: any) => {
        getData();
      });
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      url: `${process.env.REACT_APP_BASE_URL
        }productmedia?identifier=${localStorage.getItem("identifier")}&page=${Number(search.get("page")) || 1
        }&limit=${Number(search.get("limit")) || 15}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setData(res.data?.productMedias);
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: inFocus,
          method: "delete",
          type: "productmedia",
          header: true,
        })
          .then((res) => {
            setInFocus("");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  // Prepare columns for DynamicDataTable
  const getColumns = () => {
    const baseColumns = [
      {
        accessorKey: "title",
        header: "Video Title",
      }
    ];

    // Add delete action column if user has permission
    if ((admin.role !== "subadmin") || 
        (admin.role === "subadmin" && admin.shoppableVideo_access.delete)) {
      return baseColumns;
    }

    return baseColumns;
  };

  // Actions for the table
  const getActionsColumns = () => {
    if ((admin.role !== "subadmin") || 
        (admin.role === "subadmin" && admin.shoppableVideo_access.delete)) {
      return [
        {
          name: "Delete",
          action: (id: string) => {
            setModal(true);
            setInFocus(id);
          }
        }
      ];
    }
    return [];
  };

  // Handle page change from DynamicDataTable
  const handlePageChange = (pageIndex: number, pageSize: number) => {
    let search = new URLSearchParams(location.search);
    search.set("page", String(pageIndex + 1)); // +1 because DynamicDataTable uses 0-indexed pages
    search.set("limit", String(pageSize));
    navigate(`/product-video?${String(search)}`);
  };

  const search = new URLSearchParams(location.search);
  const currentPage = Number(search.get("page")) || 1;
  const limit = Number(search.get("limit")) || 15;

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleAction}
      />

      <Content>
        <ContentHead
          title="Shoppable Videos"
          showBtn1={data.length < plan ? isUserAllowedToEdit(admin) : false}
          btn1Name="btn1"
          iconType1="plus"
          btn1Text="New"
          btn1type="filled"
          onClickHandler={() => navigate("/product-video/add")}
          isIcon={true}
          iconType="add"
        />
        <ContentBody style={{ padding: 0, position: "relative" }}>
          {plan === data.length && (
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                width: "100%",
                bottom: 0,
                color: "var(--brand-color)",
                padding: "20px",
                textAlign: "center",
                zIndex: 10
              }}
            >
              Your shoppable video plan is at its limit. Please contact ShopIQ
              support to increase the limit.
            </div>
          )}
          
          {loading ? (
            <TableLoading head={productVideoHead(admin)} />
          ) : (
            <DynamicDataTable
              columns={getColumns()}
              data={data}
              actionsColumns={getActionsColumns()}
              title="Shoppable Videos"
              onRowClick={(rowData) => {
                navigate(`/product-video/update/${rowData._id}`);
              }}
              onAdd={data.length < plan && isUserAllowedToEdit(admin) ? 
                () => navigate("/product-video/add") : undefined}
              
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.shoppableVideo_access.write)
    return true;

  return false;
};

const productVideoKeys = (admin: any) => {
  const base = ["title"];

  if (admin.role !== "subadmin") return [...base, "action:delete"];

  if (admin.role === "subadmin") {
    if (admin.shoppableVideo_access.delete) {
      return [...base, "action:delete"];
    }
    if (admin.shoppableVideo_access.write) {
      return base;
    }
  }

  return base;
};

const productVideoHead = (admin: any) => {
  const base = [{ title: "S.No." }, { title: "Video Title" }];

  if (admin.role !== "subadmin") return [...base, { title: "" }];

  if (admin.role === "subadmin") {
    if (admin.shoppableVideo_access.delete) {
      return [...base, { title: "" }];
    }
    if (admin.shoppableVideo_access.write) {
      return base;
    }
  }

  return base;
};

export default withRouter(ProductVideo);