import React, { useState, useEffect } from "react";
import axios from "axios";
import withRouter from "../../Components/withRouter";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faExternalLink,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import Modal from "../../Components/Modal";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import styles from "./users.module.css";
import ContentBody from "../../Components/Content/ContentBody";
import SingleRowUser from "./singleRowUser";
import InputField from "../../Components/InputField";
import ToggleSwitch from "../../Components/ToggleSwitch";
import DynamicDataTable from "../../UI/DynamicTable";
import TableLoading from "../../Components/LoadingUi/TableLoading";

interface UserProps {
  router: any;
}

const Users: React.FC<UserProps> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [secondaryView, setSecondaryView] = useState(false);
  const [inView, setInView]: any = useState({});
  const [tab, setTab] = useState("overview");

  const [contacts, setContacts]: any = useState([]);
  const [updatedContacts, setUpdatedContacts]: any = useState([]);

  const [modal, setModal] = useState(false);

  const [subAdmins, setSubAdmins] = useState([]);

  const [contactOwnerDropdown, setContactOwnerDropdown] = useState(false);
  const [createDateDropdown, setCreateDateDropdown] = useState(false);
  const [lastActivityDropdown, setLastActivityDropdown] = useState(false);
  const [leadStatusDropdown, setLeadStatusDropdown] = useState(false);
  const [actionsDropdown, setActionsDropdown] = useState(false);

  const [updateMode, setUpdateMode] = useState(false);

  const [remark, setRemark] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [disableForAction, setDisableForAction] = useState(false);

  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  const [block, setBlock] = useState(false);

  const [segmentDropdown, setSegmentDropdown] = useState(false);

  const [filter, setFilter] = useState({
    segment: "",
  });

  const [segments, setSegments] = useState([]);

  useEffect(() => {
    getSegments();
  }, []);

  useEffect(() => {
    handlePage();
  }, [location.search, filter]);

  const handlePage = () => {
    getData();
  };

  const getSegments = () => {
    axios({
      url: `https://backend.retaino.in/api/segments/?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res: any) => {
        setSegments(res.data.segments);
      })
      .catch((err: any) => {
        setSegments([]);
      });
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    let segment_id = filter?.segment || "";
    let query = "";

    if (segment_id) {
      query = `&segmentId=${segment_id}`;
    }

    axios({
      url: `https://backend.retaino.in/api/users/filter-user?identifier=${localStorage.getItem(
        "identifier"
      )}${query}`,
      method: "GET",
    })
      .then((res) => {
        console.log("res:", res);
        setUsers(res.data.allowedUsers);
        setTotal(res.data.totalUsers || res.data.allowedUsers.length);
        setPage(currentPage);
        setLimit(currentLimit);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setUsers([]);
        setTotal(0);
      });
  };

  const handleRowClick = (val: any) => {
    router.navigate(`/users/${val._id}`);
  };

  return (
    <>
      {loading && (
        <TableLoading
          head={[
            { title: "S.No." },
            { title: "First Name" },
            { title: "Last Name" },
            { title: "Email" },
            { title: "Phone Number" },
            { title: "DOB" },
            { title: "Last Activity Date" },
            { title: "Created Date" },
          ]}
        />
      )}
      {!loading && (
        <Content>
          <ContentHead
            title={"Users"}
            showBtn1={false}
            btn1Name={updateMode ? "update_btn1" : "btn1"}
            btn1Text={"New"}
            isIcon1={true}
            iconType1={faPlus}
            btn1type="filled"
            isMoreSettings={false}
            moreSettingsList={[
              { icon: "import", title: "Import", name: "import" },
              { icon: "export", title: "Export", name: "export" },
              // { icon: "logs", title: "Logs", name: "logs" },
            ]}
            handleMoreClick={(name: any) => {
              if (name === "import") {
                navigate("/contacts/import-file");
              } else if (name === "export") {
                // exportExcel();
              } else if (name === "logs") {
                navigate("/contacts/logs");
              }
            }}
            onClickHandler={(e: any) => {
              if (e.target.name === "btn1") {
                navigate("/users/add");
              }
            }}
            customElement={
              <div className={styles.search_section}>
                <FontAwesomeIcon icon={faSearch} />
                <input
                  placeholder="Search name, phone, email ..."
                  value={searchTerm}
                  // onChange={handleChange}
                />
              </div>
            }
          />
          <ContentBody style={{ padding: 0 }}>
            {(segmentDropdown ||
              createDateDropdown ||
              lastActivityDropdown ||
              leadStatusDropdown ||
              actionsDropdown) && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "transparent",
                  zIndex: 100,
                }}
                onClick={() => {
                  setSegmentDropdown(false);
                  setCreateDateDropdown(false);
                  setLastActivityDropdown(false);
                  setLeadStatusDropdown(false);
                  setActionsDropdown(false);
                }}
              ></div>
            )}

            {/* Table Head and Table Body with secondary view */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <FilterContainer
                filter={filter}
                setFilter={setFilter}
                segmentDropdown={segmentDropdown}
                setSegmentDropdown={setSegmentDropdown}
                segments={segments}
              />
              {secondaryView ? (
                <div className={styles.secondary_view}>
                  <div
                    className={`${styles.secondary_list_view} ${styles.mobile_list_view}`}
                  >
                    {users.map((val: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className={styles.secondary_short_item}
                          style={
                            val._id === inView?._id
                              ? {
                                  background: "var(--brand-color)",
                                  color: "white",
                                }
                              : {}
                          }
                          onClick={() => setInView(val)}
                        >
                          <div>
                            <p>
                              {val?.firstName || val?.lastName
                                ? [val?.firstName, val?.lastName].join(" ")
                                : "--"}
                            </p>
                            <span
                              style={{
                                color:
                                  val._id === inView?._id ? "white" : "grey",
                              }}
                            >
                              {val?.email}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={`${styles.secondary_detail_view} ${
                      inView
                        ? styles.show_mobile_details
                        : styles.hide_mobile_details
                    }`}
                  >
                    <div>
                      <div className={styles.sdv_head}>
                        <p style={{ textTransform: "capitalize" }}>
                          {inView?.firstName ||
                          inView?.middleName ||
                          inView?.lastName
                            ? [
                                inView?.firstName?.trim() || "",
                                inView?.middleName?.trim() || "",
                                inView?.lastName?.trim() || "",
                              ]?.join(" ")
                            : "Undefined"}
                        </p>
                        <div className={styles.sdv_buttons}>
                          <button
                            className={styles.back_to_list_mobile}
                            onClick={() => {
                              setSecondaryView(false);
                              setInView(null);
                            }}
                          >
                            Back to list
                          </button>
                          <CloseIcon
                            className={styles.sdv_close_btn}
                            onClick={() => {
                              setSecondaryView(false);
                              setInView(null);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="font-semibold">Personal Details</h3>
                      <div className={styles.secondary_content}>
                        {inView?.salutation && (
                          <div>
                            <span>Title</span>
                            <p>{inView?.salutation || "--"}</p>
                          </div>
                        )}
                        <div>
                          <span>First Name</span>
                          <p>{inView?.firstName || "--"}</p>
                        </div>

                        <div>
                          <span>Middle Name</span>
                          <p>{inView?.middleName || "--"}</p>
                        </div>
                        <div>
                          <span>Last Name</span>
                          <p>{inView?.lastName || "--"}</p>
                        </div>
                        {inView?.gender && (
                          <div>
                            <span>Gender</span>
                            <p>{inView?.gender || "--"}</p>
                          </div>
                        )}
                        <div>
                          <span>Email</span>
                          <p>{inView?.email || "--"}</p>
                        </div>
                        <div>
                          <span>Mobile</span>
                          <p>{inView?.mobile?.number || "--"}</p>
                        </div>
                        <div>
                          <span>Alternate Mobile</span>
                          <p>{inView?.altMobile?.number || "--"}</p>
                        </div>
                        <div>
                          <span>Landline</span>
                          <p>{inView?.landline || "--"}</p>
                        </div>
                        {inView?.motherTongue && (
                          <div>
                            <span>Mother Tongue</span>
                            <p>{inView?.motherTongue || "--"}</p>
                          </div>
                        )}
                        {inView?.nativePlace && (
                          <div>
                            <span>Native Place</span>
                            <p>{inView?.nativePlace || "--"}</p>
                          </div>
                        )}
                        {inView?.fatherOrHusbandName && (
                          <div>
                            <span>Father Or Husband Name</span>
                            <p>{inView?.fatherOrHusbandName || "--"}</p>
                          </div>
                        )}
                        {inView?.dob && (
                          <div>
                            <span>DOB</span>
                            <p>{inView?.dob || "--"}</p>
                          </div>
                        )}
                        {inView?.anniversaryDate && (
                          <div>
                            <span>Anniversary Date</span>
                            <p>
                              {inView?.anniversaryDate?.split("T")?.[0] || "--"}
                            </p>
                          </div>
                        )}
                        {inView?.socialMedia?.map((sm, index) => {
                          return (
                            <div key={index}>
                              <span>{sm.website}</span>
                              <a
                                href={sm.link}
                                className="p-0 bg-transparent text-[var(--brand-color)] inline-flex items-center gap-2"
                                target="_blank"
                              >
                                Link{" "}
                                <FontAwesomeIcon
                                  className="text-[.75rem]"
                                  icon={faExternalLink}
                                />
                              </a>
                            </div>
                          );
                        })}
                        {inView?.bloodGroup && (
                          <div>
                            <span>Blood Group</span>
                            <p>{inView?.bloodGroup || "--"}</p>
                          </div>
                        )}
                        {inView?.maritalStatus && (
                          <div>
                            <span>Marital Status</span>
                            <p>{inView?.maritalStatus || "--"}</p>
                          </div>
                        )}
                        <div className="col-span-3">
                          <span>Shipping/Residence Address</span>
                          <p>
                            {/* @ts-ignore */}
                            {[
                              inView?.shippingAddress[0]?.name,
                              inView?.shippingAddress[0]?.addLineOne,
                              inView?.shippingAddress[0]?.addLineOne,
                              inView?.shippingAddress[0]?.landmark,
                              inView?.shippingAddress[0]?.city,
                              inView?.shippingAddress[0]?.state,
                              inView?.shippingAddress[0]?.pincode,
                            ]
                              .filter((v) => v)
                              .join(", ") || "--"}
                          </p>
                        </div>
                        <div className="col-span-3">
                          <span>Billing/Office Address Address</span>
                          <p>
                            {/* @ts-ignore */}
                            {[
                              inView?.billingAddress[0]?.name,
                              inView?.billingAddress[0]?.addLineOne,
                              inView?.billingAddress[0]?.addLineTwo,
                              inView?.billingAddress[0]?.landmark,
                              inView?.billingAddress[0]?.city,
                              inView?.billingAddress[0]?.state,
                              inView?.billingAddress[0]?.pincode,
                            ]
                              .filter((v) => v)
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="font-semibold">Other Details</h3>
                      <div className={styles.secondary_content}>
                        {inView?.residenceProof && (
                          <div>
                            <span>ResidenceProof</span>
                            <a
                              href={
                                inView?.uploadedDocuments?.filter(
                                  (v: any) =>
                                    v.documentType === "residenceProofDocument"
                                )?.[0]?.documentUrl
                              }
                              target="_blank"
                              className="bg-transparent text-[var(--brand-color)] p-0 inline-flex items-center gap-2"
                            >
                              {inView?.residenceProof}
                              <FontAwesomeIcon
                                icon={faExternalLink}
                                className="text-[.75rem]"
                              />
                            </a>
                          </div>
                        )}
                        {inView?.PAN && (
                          <div>
                            <span>PAN</span>
                            <p>{inView?.PAN}</p>
                          </div>
                        )}
                        {inView?.uploadedDocuments?.filter(
                          (v) => v.documentType === "PanDocument"
                        )?.[0] && (
                          <div>
                            <span>PAN Document</span>
                            {inView?.uploadedDocuments?.filter(
                              (v) => v.documentType === "PanDocument"
                            )?.[0] ? (
                              <a
                                href={
                                  inView?.uploadedDocuments?.filter(
                                    (v: any) => v.documentType === "PanDocument"
                                  )?.[0]?.documentUrl
                                }
                                target="_blank"
                                className="bg-transparent text-[var(--brand-color)] p-0 inline-flex items-center gap-2"
                              >
                                Link
                                <FontAwesomeIcon
                                  icon={faExternalLink}
                                  className="text-[.75rem]"
                                />
                              </a>
                            ) : (
                              <p>--</p>
                            )}
                          </div>
                        )}
                        {inView?.aadhar && (
                          <div>
                            <span>Aadhar</span>
                            <p>{inView?.aadhar}</p>
                          </div>
                        )}
                        {inView?.uploadedDocuments?.filter(
                          (v) => v.documentType === "aadharFrontDocument"
                        )?.[0] && (
                          <div>
                            <span>Aadhar Front Document</span>
                            {inView?.uploadedDocuments?.filter(
                              (v) => v.documentType === "aadharFrontDocument"
                            )?.[0] ? (
                              <a
                                href={
                                  inView?.uploadedDocuments?.filter(
                                    (v: any) =>
                                      v.documentType === "aadharFrontDocument"
                                  )?.[0]?.documentUrl
                                }
                                target="_blank"
                                className="bg-transparent text-[var(--brand-color)] p-0 inline-flex items-center gap-2"
                              >
                                Link
                                <FontAwesomeIcon
                                  icon={faExternalLink}
                                  className="text-[.75rem]"
                                />
                              </a>
                            ) : (
                              <p>--</p>
                            )}
                          </div>
                        )}
                        {inView?.uploadedDocuments?.filter(
                          (v) => v.documentType === "aadharBackDocument"
                        )?.[0] && (
                          <div>
                            <span>Aadhar Back Document</span>
                            {inView?.uploadedDocuments?.filter(
                              (v) => v.documentType === "aadharBackDocument"
                            )?.[0] ? (
                              <a
                                href={
                                  inView?.uploadedDocuments?.filter(
                                    (v: any) =>
                                      v.documentType === "aadharBackDocument"
                                  )?.[0]?.documentUrl
                                }
                                target="_blank"
                                className="bg-transparent text-[var(--brand-color)] p-0 inline-flex items-center gap-2"
                              >
                                Link
                                <FontAwesomeIcon
                                  icon={faExternalLink}
                                  className="text-[.75rem]"
                                />
                              </a>
                            ) : (
                              <p>--</p>
                            )}
                          </div>
                        )}
                        {inView?.qualification && (
                          <div>
                            <span>Qualification</span>
                            <p>{inView?.qualification || "--"}</p>
                          </div>
                        )}
                        {inView?.businessDetails?.legalName && (
                          <div>
                            <span>Profession/Business</span>
                            <p>{inView?.businessDetails?.legalName}</p>
                          </div>
                        )}
                        {inView?.companyName && (
                          <div>
                            <span>Company Name</span>
                            <p>{inView?.companyName}</p>
                          </div>
                        )}
                        {inView?.companyWebsite && (
                          <div>
                            <span>Company Website</span>
                            <p>{inView?.companyWebsite}</p>
                          </div>
                        )}
                        {inView?.areaOfWork && (
                          <div>
                            <span>Area of Work</span>
                            <p>{inView?.areaOfWork}</p>
                          </div>
                        )}
                        {inView?.interests?.[0] && (
                          <div>
                            <span>Interest</span>
                            <p>{inView?.interests?.[0] || "--"}</p>
                          </div>
                        )}
                        {inView?.FamilyMemberName && (
                          <div>
                            <span>JITO Family member name</span>
                            <p>{inView?.FamilyMemberName}</p>
                          </div>
                        )}
                        {inView?.availability && (
                          <div>
                            <span>Availability</span>
                            <p>{inView?.availability || "--"}</p>
                          </div>
                        )}
                        {inView?.occupation && (
                          <>
                            {inView?.occupation?.length !== 0 ? (
                              <div>
                                <span>Occupation</span>
                                <p>{inView?.occupation.join(", ")}</p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        {inView?.industry && (
                          <>
                            {inView?.industry?.length !== 0 ? (
                              <div>
                                <span>Industry</span>
                                <p>{inView?.industry.join(", ")}</p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        {inView?.uploadedDocuments?.filter(
                          (v) => v.documentType === "signature"
                        )?.[0] && (
                          <div>
                            <span>
                              Signature{" "}
                              <a
                                href={
                                  inView?.uploadedDocuments?.filter(
                                    (v) => v.documentType === "signature"
                                  )?.[0]?.documentUrl
                                }
                                target="_blank"
                                className="p-0 bg-transparent text-[var(--brand-color)]"
                              >
                                <FontAwesomeIcon icon={faExternalLink} />
                              </a>
                            </span>
                            <img
                              src={
                                inView?.uploadedDocuments?.filter(
                                  (v) => v.documentType === "signature"
                                )?.[0]?.documentUrl
                              }
                              style={{
                                height: "100px",
                                width: "150px",
                                borderRadius: "15px",
                                border: "1px solid lightgrey",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {(inView?.referredBy?.memberName ||
                      inView?.referredBy?.mobileNumber ||
                      inView?.referredBy?.date ||
                      inView?.referredBy?.signature) && (
                      <div>
                        <h3 className="font-semibold">Referred By</h3>
                        <div className={styles.secondary_content}>
                          {inView?.referredBy?.memberName && (
                            <div>
                              <span>Member Name</span>
                              <p>{inView?.referredBy?.memberName}</p>
                            </div>
                          )}
                          {inView?.referredBy?.mobileNumber && (
                            <div>
                              <span>Member Number</span>
                              <p>{inView?.referredBy?.mobileNumber}</p>
                            </div>
                          )}
                          {inView?.referredBy?.date && (
                            <div>
                              <span>Date</span>
                              <p>{inView?.referredBy?.date?.split("T")?.[0]}</p>
                            </div>
                          )}
                          {inView?.referredBy?.signature && (
                            <div>
                              <span>
                                Signature{" "}
                                <a
                                  href={inView?.referredBy?.signature}
                                  target="_blank"
                                  className="p-0 bg-transparent text-[var(--brand-color)]"
                                >
                                  <FontAwesomeIcon icon={faExternalLink} />
                                </a>
                              </span>
                              <img
                                src={inView?.referredBy?.signature}
                                style={{
                                  height: "100px",
                                  width: "150px",
                                  borderRadius: "15px",
                                  border: "1px solid lightgrey",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={styles.secondary_content}>
                      <div style={{ gridColumn: "1/4" }}>
                        <h3 style={{ fontWeight: "500" }}>Settings</h3>
                      </div>
                      <div
                        style={{
                          gridColumn: "1/4",
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                        }}
                      >
                        <div
                          style={{
                            height: "45px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border: "1px solid lightgrey",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          <p>Block User</p>
                          <ToggleSwitch
                            value={block}
                            onClick={() => setBlock((prev) => !prev)}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          gridColumn: "1/4",
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                        }}
                      >
                        <InputField
                          isLabel={true}
                          lable={"Payment Method Allowed"}
                          type="select"
                          options={[
                            { title: "All", value: "all" },
                            { title: "Online", value: "online" },
                            { title: "COD", value: "cod" },
                          ]}
                          accessText="title"
                          accessValue="value"
                          value=""
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.table_container}>
                  <DynamicDataTable
                    data={users}
                    columns={[
                      { accessorKey: "firstName", header: "First Name" },
                      { accessorKey: "lastName", header: "Last Name" },
                      { accessorKey: "email", header: "Email" },
                      { accessorKey: "mobile.number", header: "Phone Number" },
                      { accessorKey: "dob", header: "DOB" },
                      {
                        accessorFn: (row) => {
                          if (!row.lastLogin?.length) return "--";
                          const lastDate =
                            row.lastLogin[row.lastLogin.length - 1]?.date;
                          if (!lastDate) return "--";
                          const date = new Date(lastDate);
                          return `${date.getFullYear()}/${String(
                            date.getMonth() + 1
                          ).padStart(2, "0")}/${String(date.getDate()).padStart(
                            2,
                            "0"
                          )}`;
                        },
                        header: "Last Activity Date",
                      },
                      {
                        accessorFn: (row) => {
                          if (!row.createdAt) return "--";
                          const date = new Date(row.createdAt);
                          return `${date.getFullYear()}/${String(
                            date.getMonth() + 1
                          ).padStart(2, "0")}/${String(date.getDate()).padStart(
                            2,
                            "0"
                          )}`;
                        },
                        header: "Created Date",
                      },
                    ]}
                    onPaginationChange={(newPage, newLimit) => {
                      setPage(newPage);
                      setLimit(newLimit);
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("page", newPage.toString());
                      searchParams.set("limit", newLimit.toString());
                      navigate(`/users?${searchParams.toString()}`);
                    }}
                    totalRows={total}
                    initialPageSize={limit}
                    initialPageIndex={page}
                    onRowClick={(v) => {
                      setInView(v);
                      setSecondaryView(true);
                    }}
                  />
                </div>
              )}
            </div>
          </ContentBody>
        </Content>
      )}
    </>
  );
};

const FilterContainer = ({
  filter = {},
  setFilter,
  segments = [],
  segmentDropdown,
  setSegmentDropdown,
}: any) => {
  const [navSegmentSearch, setNavSegmentSearch] = useState("");

  return (
    <div className={styles.filter_container}>
      <div
        className={styles.drop_down_container}
        style={{
          opacity: "1",
          cursor: "pointer",
          pointerEvents: "all",
        }}
      >
        <div
          className={styles.drop_down_content}
          onClick={() => setSegmentDropdown(true)}
          style={
            filter?.segment
              ? {
                  padding: "7px 10px",
                  borderRadius: "5px",
                  background: "#d9e7f8",
                }
              : {}
          }
        >
          <p>Segments</p>
          <DownIcon />
          {filter?.segment && (
            <FontAwesomeIcon
              icon={faClose}
              color={"grey"}
              style={{ marginLeft: "5px" }}
              onClick={(e: any) => {
                e.stopPropagation();
                setFilter((prev: any) => ({
                  ...prev,
                  segment: "",
                }));
              }}
            />
          )}
        </div>
        {segmentDropdown && (
          <div className={styles.drop_down_section}>
            <div style={{ padding: "10px" }}>
              <div className={styles.contact_owner_search}>
                <FontAwesomeIcon icon={faSearch} />
                <input
                  type="text"
                  placeholder="Search contact owner"
                  value={navSegmentSearch}
                  onChange={(e: any) => setNavSegmentSearch(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                padding: "0",
                maxHeight: "250px",
                overflowY: "auto",
              }}
            >
              {segments
                .filter((v: any) => {
                  if (!navSegmentSearch) return true;

                  return v.segmentName
                    .toLowerCase()
                    .trim()
                    .includes(navSegmentSearch.toLowerCase().trim());
                })
                .map((segment: any, index: any) => {
                  return (
                    <div
                      className={styles.contact_owner_single_list}
                      key={index}
                      onClick={() => {
                        setFilter((prev: any) => ({
                          ...prev,
                          segment: segment._id,
                        }));
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={filter.segment === segment._id}
                      />
                      <div>
                        <p
                          style={{ fontSize: ".9rem" }}
                        >{`${segment?.segmentName}`}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Users);
