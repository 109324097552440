import { useNavigate } from "react-router-dom";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import { category_head } from "../../Utils/Data";
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "../../Components/Modal";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import { useAdminContext } from "../../Store/adminContext";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import { getResourceLimit } from "../../Utils/checkPermissions/planPermissions";
import DynamicDataTable from "../../UI/DynamicTable";
import { useLocation } from "react-router-dom";

let inFocus = "";

const Collections = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(15);

  const { admin }:any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  useEffect(() => {
    getData();
  }, [location.search]);

  const getData = () => {
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }productcollection/?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${currentPage}&limit=${currentLimit}`,
    })
      .then(async (res) => {
       
        const data = res.data.productCollections;
        setTotal(res.data.totalProductCollectionsCount);

        let found = false;

        // check if data have priority value
        for (let i = 0; i < data.length; i++) {
          if (data[i].priority === undefined) {
            found = true;
            // add priority
            await axios({
              method: "PATCH",
              url:
                `${process.env.REACT_APP_BASE_URL}productcollection/` +
                data[i]._id,
              data: { ...data[i], priority: i },
            });
          }
        }

        if (found) getData();

        setData(
          data
            .map((val) => ({ ...val, id: val._id }))
            .sort((a, b) => a.priority - b.priority)
        );

        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Error fetching collections");
        setData([]);
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    inFocus = id;
    setModal(true);
  };

  const confirmDelete = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}productcollection/` + inFocus,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res) => {
            inFocus = "";
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: () => {
          getData();
          return "Deleted Successfully!";
        },
        error: () => {
          return "Error in deleting collection.";
        },
      }
    );
  };

  const handleEdit = (id) => {
    if (isAllowed) {
      navigate("/collections/update/" + id);
    } else {
      toast.error("Please Complete Setup Steps to access this route.");
    }
  };

  const handleReorder = async (draggedRowIndex, targetRowIndex) => {
    if (draggedRowIndex === targetRowIndex) return;
    
    const updatedData = [...data];
    const [movedItem] = updatedData.splice(draggedRowIndex, 1);
    updatedData.splice(targetRowIndex, 0, movedItem);
    
    // Update priorities
    const reorderedData = updatedData.map((item, index) => ({
      ...item,
      priority: index
    }));
    
    setData(reorderedData);
    setLoading(true);
    
    try {
      // Update all affected items in the database
      for (const item of reorderedData) {
        await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_BASE_URL}productcollection/${item.id}`,
          data: { priority: item.priority },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        });
      }
      getData();
    } catch (error) {
      toast.error("Error updating collection order");
      getData(); // Refresh to original order on error
    }
  };

  const columns = [
    {
      accessorKey: "title",
      header: "Name",
      size: 200,
    },
    {
      accessorKey: "priority",
      header: "Priority",
      size: 100,
    }
  ];

  const actionsColumns = [
    {
      name: "Edit",
      action: (id) => handleEdit(id),
    }
  ];

  // Only add delete action if user has permission
  if (admin.role !== "subadmin" || admin.catalog_access?.delete) {
    actionsColumns.push({
      name: "Delete",
      action: (id) => handleDelete(id),
    });
  }
  
  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          inFocus = "";
          setModal(false);
        }}
        onBtn2Click={confirmDelete}
      />
      <Content>
        <ContentHead
          title="Collections"
          showBtn1={isUserAllowedToEdit(admin)}
          btn1Name="btn1"
          btn1Text={"New"}
          iconType1="plus"
          btn1type="filled"
          onClickHandler={() => {
            if (isAllowed) {
              if (
                getResourceLimit(admin.selectedPlan, "collection") > data.length
              ) {
                navigate("/collections/add");
              } else {
                toast.error("You have reached your plan's limit.");
              }
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
        />
        <ContentBody style={{ padding: 0 }}>
          {loading ? (
            <TableLoading head={category_head} />
          ) : (
            <DynamicDataTable
              columns={columns}
              data={data}
              enableRowReorder={isUserAllowedToEdit(admin)}
              onReorder={handleReorder}
              actionsColumns={actionsColumns}
              onRowClick={(row) => handleEdit(row.id)}
              title="Collections"
              onPaginationChange={(page, limit) => {
                setPageNo(page);
                setPerPage(limit);
                // Update URL with new page number and limit
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('page', page.toString());
                searchParams.set('limit', limit.toString());
                navigate(`/collections?${searchParams.toString()}`);
              }}
              totalRows={total}
              initialPageSize={perPage}
              initialPageIndex={pageNo}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin) => {
  if (admin.role !== "subadmin") return true;
  if (admin.role === "subadmin" && admin.catalog_access?.write) return true;
  return false;
};

export default Collections;