import React, { useEffect, useState } from "react";
import styles from "./invoice.module.css";
import { createPlaceholderImage } from "../../Utils/imageUtils";

interface PrintableLogoProps {
  logoUrl: string;
}

const PrintableLogo: React.FC<PrintableLogoProps> = ({ logoUrl }) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrinting(true);
    };

    const handleAfterPrint = () => {
      setIsPrinting(false);
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    console.error("Error loading logo image:", e);
    setError("Failed to load logo image");

    // Create a placeholder image
    const placeholder = createPlaceholderImage("LOGO");
    if (e.currentTarget) {
      e.currentTarget.src = placeholder;
    }
  };

  const handleImageLoad = () => {
    console.log("Logo image loaded successfully");
    setImageLoaded(true);
    setError(null);
  };

  if (!logoUrl) {
    return null;
  }

  return (
    <div
      className={styles.logoContainer}
      style={{
        border: isPrinting ? "1px solid red" : "none",
        display: "block",
        visibility: "visible",
      }}
    >
      <img
        src={logoUrl}
        alt="logo"
        className={styles.logoImage}
        style={{
          border: isPrinting ? "1px solid red" : "none",
          display: "block",
          visibility: "visible",
        }}
        crossOrigin="anonymous"
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
      {error && (
        <div
          style={{
            fontSize: "8px",
            color: "red",
            position: "absolute",
            bottom: "-15px",
            left: "0",
            width: "100%",
            textAlign: "center",
          }}
        >
          {error}
        </div>
      )}
      {!imageLoaded && !error && (
        <div
          style={{
            fontSize: "8px",
            color: "blue",
            position: "absolute",
            bottom: "-15px",
            left: "0",
            width: "100%",
            textAlign: "center",
          }}
        >
          Loading logo...
        </div>
      )}
    </div>
  );
};

export default PrintableLogo;
