import { useContext, useEffect, useState } from "react";
import callApi from "../../Apis/callApi";
import styles from "../../Styles/Login.module.css";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { SideBarContext } from "../../Store/sideBar";
import img1 from "../../Assets/images/commerce.webp";
import { useAdminContext } from "../../Store/adminContext";
import { checkInputType } from "../../Utils/checkInputType";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSubmited, setEmailSubmited] = useState(false);
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [showError, setShowError] = useState("granted");
  const context: any = useContext(SideBarContext);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const { setAdmin }: any = useAdminContext();

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const sentOtp = () => {
    setLoading(true);

    let temp_data: any = {};

    if (checkInputType(email.trim()) === "email") {
      temp_data.email = email;
    } else if (checkInputType(email.trim()) === "mobile") {
      temp_data.phone = email;
    } else {
      setLoading(false);
      toast.error("Invalid Input.");
      return;
    }

    callApi({
      method: "post",
      url: "sendotp",
      type: "admins",
      data: temp_data,
    })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Otp has been send to your email address.");
          setLoading(false);
          setToken(res.data.token);
          // localStorage.setItem("token-BO-ADMIN", res.data.token);
          setEmailSubmited(true);
          setCounter(60);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // showError();
        setLoading(false);
      });
  };

  const handleOtp = () => {
    if (!email) {
      toast.error("Email is required.");
    } else {
      sentOtp();
    }
  };

  const handleLogin = () => {
    if (!otp) {
      toast.error("OTP is required.");
    } else {
      // make a call to verify otp

      axios({
        method: "post",
        url: `${BASE_URL}admins/verifyotp`,
        headers: { Authorization: `${token}` },
        data: { receivedOtp: otp },
      })
        .then((res: any) => {
          if (res?.status == 200) {
            localStorage.setItem("token-BO-ADMIN", res.data.sessionToken);
            localStorage.setItem("identifier", res.data.admin.identifier);
            localStorage.setItem("login_log", new Date().toISOString());
            context.updateSideBar({ ...res.data.admin });
            setShowError(res.access);
            window.location.reload();
          }
          if (res?.access === "suspended" || res?.access === "paused") {
            setShowError(res.access);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const resendOtp = () => {
    setResendOtpLoading(true);
    callApi({
      method: "post",
      url: "sendotp",
      type: "admins",
      data: { email },
    })
      .then((res) => {
        if (res.data.message === "Email sent successfully.") {
          toast.success(
            "Your OTP has been reissued and sent to your designated email address. Kindly ensure to check both your inbox and spam folder.",
            { duration: 10000 }
          );
          setToken(res.data.token);
          // localStorage.setItem("token-BO-ADMIN", res.data.token);
          setEmailSubmited(true);
          setResendOtpLoading(false);
          setCounter(60);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // showError();
        setResendOtpLoading(false);
      });
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <div className={styles.container}>
      <div className={`${styles.main}`}>
        <div
          className={`${styles.slide} ${emailSubmited ? styles.forward : ""}`}
        >
          {showError === "granted" && (
            <>
              {/* modal container 1 */}
              <div className={styles.modal_container}>
                <div className={styles.modal}>
                  <h1 className="text-2xl md:text-3xl">
                    Merchant Dashboard <br /> Login
                  </h1>
                  <div className={styles.fields_container}>
                    <div className={styles.label_and_field}>
                      <span className="text-sm">Phone Number or Email</span>
                      <input
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="text-sm"
                      />
                      <button
                        onClick={handleOtp}
                        disabled={loading}
                        className="bg-[var(--brand-color)] text-sm"
                      >
                        {loading ? (
                          <span className={styles.loader}></span>
                        ) : (
                          <>Request OTP</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* modal container 2 */}
              <div className={styles.modal_container_2}>
                <div className={styles.modal}>
                  <h1 className="text-2xl md:text-3xl">Confirm OTP</h1>
                  <div className={styles.fields_container}>
                    <div className={styles.label_and_field}>
                      <span className="text-sm">OTP</span>
                      <input
                        type="password"
                        placeholder="Enter the OTP"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        className="text-sm"
                      />
                      <button
                        onClick={() => {
                          setEmailSubmited(true);
                          handleLogin();
                        }}
                        className="bg-[var(--brand-color)] text-sm"
                      >
                        Login
                      </button>
                      {counter === 0 ? (
                        <button
                          style={{
                            background: "transparent",
                            color: "#fff",
                            textDecoration: "underline",
                            height: "40px",
                            marginTop: "10px",
                            paddingTop: "0px",
                          }}
                          className="text-sm"
                          onClick={() => {
                            resendOtp();
                          }}
                          disabled={resendOtpLoading}
                        >
                          {resendOtpLoading ? (
                            <span
                              className={styles.loader}
                              style={{
                                borderColor: "#fff",
                                borderBottomColor: "transparent",
                              }}
                            ></span>
                          ) : (
                            <>Resend OTP</>
                          )}
                        </button>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                            opacity: ".5",
                            whiteSpace: "nowrap",
                          }}
                          className="text-sm"
                        >
                          <span
                            style={{
                              textDecoration: "underline",
                              display: "inline-block",
                            }}
                          >
                            Resend OTP
                          </span>{" "}
                          - {counter} sec
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showError === "paused" ||
            (showError === "suspended" && (
              <div className={styles.modal_container}>
                <div className={styles.modal}>
                  <h1>Error for {showError}</h1>
                </div>
              </div>
            ))}
        </div>
        {/* back button */}
        <div className={styles.arrow_container}>
          <div
            className={`${styles.back_arrow} ${
              emailSubmited ? styles.show_arrow : ""
            }`}
            onClick={() => {
              setEmailSubmited(false);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>
      </div>
      <div
        className={styles.carousel}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Splide
          options={{
            width: "100%",
            height: "100vh",
            type: "loop",
            autoplay: true,
            arrows: false,
            drag: false,
          }}
        >
          <SplideSlide>
            <div
              style={{
                background: `url(${img1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
              }}
            ></div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
};

export default Login;
