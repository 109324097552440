import React, { useEffect, useRef, useState } from 'react';
import RootLayout from '../RootLayout';
import logo from '../../../Assets/images/logo-rounded-bg.png';
import { FaCheckCircle } from 'react-icons/fa';
import { IoCloudDone } from "react-icons/io5";
import toast from 'react-hot-toast';
import { verifyGST, uploadMedia, handleUpdateDataLocally } from '../../../Apis/kycApi';
import { useNavigate } from 'react-router-dom';
import { useKYC } from '../../../Store/kycContext';

const GSTVerification = () => {
  const navigate = useNavigate();
  const { kycData, updateKYCData } = useKYC();
  const [gstNumber, setGstNumber] = useState(kycData.gstVerification.gstNumber || "");
  const [isGSTVerified, setIsGSTVerified] = useState(kycData.gstVerification.isVerified || false);
  const gstRef = useRef<any>();
  const [isGSTUploaded, setIsGSTUploaded] = useState(false);
  const [fileName, setFileName] = useState<any>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Please recheck your GST number");

  const [gstLoading, setGstLoading] = useState(false);

  const checkBusinessType = (apiName: any, selectedBusinessName: any) => {
    if (selectedBusinessName === "LLP" && apiName === "Limited Liability Partnership") {
      return true;
    }
    return apiName.toLowerCase().includes(selectedBusinessName?.toLowerCase()?.split(" ")[0] || "");
  };

  const handleGST = async () => {
    setGstLoading(true);
    try {
      await verifyGST(gstNumber).then((res: any) => {
        console.log(res);
      }).catch((err: any) => {
        console.log(err);
        toast.error(err.response.data.error_description);
        setError(true);
        return;
      });
      
      // if (res.data.error_description) {
      //   toast.error(res.data.error_description);
      //   setError(true);
      //   return;
      // }

      // Extract PAN from GST number
      let temp_val = gstNumber.split("");
      temp_val.splice(0, 2);
      temp_val.splice(-3, 3);
      const panNumber = temp_val.join("");

      // Update local data
      await handleUpdateDataLocally({
        gstVerification: {
          gstNumber: gstNumber,
          isVerified: true
        },
        panNumber: panNumber
      });

      // Update KYC context
      updateKYCData({
     
        gstVerification: {
          gstNumber: gstNumber,
          isVerified: true
        },
        panNumber: {
          ...kycData.panNumber,
          number: panNumber
        }
      });
      
      setError(false);
      setIsGSTVerified(true);
     
    } catch (err) {
      console.error("Error verifying GST:", err);
      setError(true);
      toast.error("Error verifying GST number. Please try again.");
    } finally {
      setGstLoading(false);
    }
  };

  const handleUploadGST = (e: any) => {
    setFileName(e.target.files[0]);
    onUpload(e.target.files[0]);
  };

  const onUpload = async (file: any) => {
    try {
      
      const localFileUrl = URL.createObjectURL(file);
      
   
      
   
      const res: any = await uploadMedia({ file: file });
      console.log('res', res, 'file', file);
      if (res.status <= 300) {
      
        updateKYCData({
          documents: [{ name: "gstn document", url: localFileUrl, obj: file }]
        });
        setIsGSTUploaded(true);
        toast.success("GST document uploaded successfully");
        
        await handleUpdateDataLocally({
          documents: [{ name: "gstn document", url: localFileUrl }]
        });
        URL.revokeObjectURL(localFileUrl);
      } else {
        toast.error("Failed to upload GST document");
      }
    } catch (error) {
      console.error("Error uploading GST document:", error);
      toast.error("Failed to upload GST document");
    }
  };

  const handleContinue = () => {
    if (gstNumber && isGSTUploaded && isGSTVerified) {
      navigate("/onboarding/pan-number");
    }
  };

  return (
    <RootLayout>
      <div className="flex flex-col gap-6 justify-center items-center p-8">
        <span className="grid gap-[2rem]">
          <img src={logo} alt="ShopIQ Logo" className="h-[3rem] md:h-[3.5rem] w-auto rounded-lg" />
        </span>
        
        <div className="w-full max-w-md">
          <span>
            <h2 className="text-3xl font-semibold mb-6">Add Your GST number</h2>
            <div className="flex items-center rounded-md px-3 py-2 border border-[#DDD] h-[55px] mb-2">
              <input
                type="text"
                name="domain"
                placeholder="Enter GST number"
                value={gstNumber}
                onChange={(e: any) => setGstNumber(e.target.value)}
                className="h-full w-full outline-none pl-5"
                maxLength={15}
              />
              {isGSTVerified ? (
                <FaCheckCircle size={20} color="green" />
              ) : (
                <button 
                  onClick={handleGST} 
                  className='text-blue-500 underline'
                  disabled={gstLoading || !gstNumber}
                 
                >
                  {gstLoading ? 'Verifying...' : 'Verify'}
                </button>
              )}
            </div>
     
            {kycData.firstName && <p className="text-[grey] text-sm">{"Legal Name : " + kycData.firstName + " " + kycData.lastName}</p>}
            </span>

          {isGSTUploaded ? (
            <div className="border-dashed border-2 border-gray-300 p-4 rounded-md text-center flex justify-between items-center mt-4">
              <span>
                <h4>File Uploaded</h4>
                <input
                  type="file"
                  ref={gstRef}
                  className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 hidden"
                  onChange={handleUploadGST}
                  accept="image/*,application/pdf"
                />
                <button
                  className="text-blue-500 text-xs"
                  onClick={() => gstRef.current.click()}
                >
                  Re-Upload
                </button>
              </span>
              <IoCloudDone color="green" size={25} />
            </div>
          ) : (
            <span className="block mt-4">
              <div className="flex justify-between">
                <h4>Upload GST Certificate</h4>
                <input
                  type="file"
                  className="hidden"
                  ref={gstRef}
                  onChange={handleUploadGST}
                  accept="image/*,application/pdf"
                />
                <button
                  className="text-blue-500"
                  onClick={() => gstRef.current.click()}
                >
                  Upload
                </button>
              </div>
            </span>
          )}

          <button
            className={`w-full mt-6 py-3 px-4 rounded-md ${
              gstNumber && isGSTUploaded && isGSTVerified
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
            disabled={!(gstNumber && isGSTUploaded && isGSTVerified)}
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </RootLayout>
  );
};

export default GSTVerification;
