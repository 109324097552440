import { useEffect, useReducer, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import InputField from "../../Components/InputField";
import TextEditor from "../../Components/TextEditor";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { useNavigate, useParams } from "react-router-dom";
import { uploadMedia } from "../../Utils/uploadMedia";
import toast from "react-hot-toast";
import axios from "axios";
import ImageFolder from "../../Components/ImageFolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import { getFileName } from "../../Utils/getFileName";
import ImageComponent from "../../Components/Image/ImageComponent";
import HoverCardInfo from "../../Components/common/HoverCardInfo/HoverCardInfo";
import { useAdminContext } from "../../Store/adminContext";
import heic2any from "heic2any";
import { uploadBulkImages } from "../../Apis/callApi";

let image_for = "";

function reducer(state: any, action: any) {
  switch (action.type) {
    case "TITLE":
      return { ...state, title: action.payload };
    case "DESCRIPTION":
      return { ...state, description: action.payload };
    case "VISIBILITY":
      return { ...state, visibility: action.payload };
    case "LOADING":
      return { ...state, loading: true };
    case "ADD_IMAGE":
      return {
        ...state,
        mediaUrl: [
          ...state.mediaUrl,
          {
            mediaUrl: {
              obj: action.payload.obj,
              url: action.payload.url,
            },
            position: -1,
          },
        ],
      };
    case "ADD_IMAGE2":
      return {
        ...state,
        mediaUrl2: [
          ...state.mediaUrl2,
          {
            mediaUrl2: {
              obj: action.payload.obj,
              url: action.payload.url,
            },
            position: -1,
          },
        ],
      };
    case "REMOVE_MEDIA":
      return {
        ...state,
        mediaUrl: [],
      };
    case "REMOVE_MEDIA2":
      return {
        ...state,
        mediaUrl2: [],
      };
    case "METADATA":
      return {
        ...state,
        metaData: action.payload,
      };
    case "ALL":
      return {
        ...state,
        title: action.payload.title,
        description: action.payload?.description,
        visibility: action.payload?.visibility,
        mediaUrl: action.payload?.media?.map((val: any) => ({
          mediaUrl: { obj: null, url: val.mediaUrl },
          position: val.position,
        })),
        mediaUrl2: action.payload.media2?.map((val: any) => ({
          mediaUrl2: { obj: null, url: val.mediaUrl },
          position: val.position,
        })),
        metaData: action.payload?.metaData || {
          metaTitle: "",
          metaDescription: "",
        },
      };
    default:
      throw Error("Unknown Action");
  }
}

const AddUpdateCategory = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { admin }: any = useAdminContext();

  const [open, setOpen] = useState(false);
  const [data, dataDispatch] = useReducer(reducer, {
    title: "",
    description: "",
    visibility: false,
    mediaUrl: [],
    loading: false,
    mediaUrl2: [],
    metaData: {
      metaTitle: "",
      metaDescription: "",
    },
  });
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories]: any = useState([]);

  useEffect(() => {
    getSubCategories();
    if (params?.id) {
      getData();
    }
  }, []);

  const getSubCategories = () => {
    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }productsubcategory/?identifier=${localStorage.getItem("identifier")}`,
      method: "get",
    })
      .then((res: any) => {
        setSubCategories(res.data.productSubCategories);
      })
      .catch((err: any) => {
        setSubCategories([]);
      });
  };

  const getData = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}productcategory/` + params?.id,
      method: "GET",
    })
      .then((res: any) => {
        dataDispatch({ type: "ALL", payload: res.data.productCategory });
        setSelectedSubCategories(res.data.productCategory?.subCategory);
      })
      .catch((err: any) => {
        toast.error("Error in fetching data.");
        navigate("/categories");
      });
  };

  const handleVisibility = () => {
    dataDispatch({
      type: "VISIBILITY",
      payload: !data.visibility,
    });
  };

  const uploadImage = async () => {
    if (data.mediaUrl.length === 0) {
      return [];
    }

    if (data.mediaUrl[0].mediaUrl.obj !== null) {
      const filename = getFileName(data.mediaUrl[0].mediaUrl.obj);
      let vv = await uploadBulkImages([data.mediaUrl[0].mediaUrl.obj]);
      return [{ mediaUrl: vv.data.images[0], position: -1 }];
    } else {
      return [{ mediaUrl: data.mediaUrl[0].mediaUrl.url, position: -1 }];
    }
  };

  const uploadImage2 = async () => {
    if (data.mediaUrl2.length === 0) {
      return [];
    }

    if (data.mediaUrl2[0].mediaUrl2.obj !== null) {
      let vv = await uploadBulkImages([data.mediaUrl2[0].mediaUrl2.obj]);
      return [{ mediaUrl: vv.data.images[0], position: -1 }];
    } else {
      return [{ mediaUrl: data.mediaUrl2[0].mediaUrl2.url, position: -1 }];
    }
  };

  const handleCreateAndUpdate = async () => {
    dataDispatch({ type: "LOADING" });

    let body: any = {
      identifier: localStorage.getItem("identifier"),
      title: data.title,
      description: data.description,
      visibility: data.visibility,
      // media: data.mediaUrl,
      subCategory: selectedSubCategories,
      metaData: data.metaData,
    };

    body.media = await uploadImage();

    body.media2 = await uploadImage2();

    axios({
      method: params?.id ? "PATCH" : "POST",
      url: `${process.env.REACT_APP_BASE_URL}productcategory${
        params?.id ? "/" + params.id : "/"
      }`,
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        navigate("/categories");
        toast.success("Category Added Successfully.");
      })
      .catch((err: any) => {
        toast.error("Something went wrong, while adding a category");
      });
  };

  const onMediaSelect = (media: any) => {
    dataDispatch({ type: "ADD_IMAGE", payload: media[0] });
    setOpen(false);
  };

  return (
    <>
      {data.loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={params?.id ? "Update Category" : "Add Category"}
          showBtn1={isUserAllowedToEdit(admin)}
          btn1type={"filled"}
          btn1Text={"Save"}
          btn1Name={"btn1"}
          showBtn2={isUserAllowedToEdit(admin)}
          btn2Text={"Save as Draft"}
          btn2type="outlined"
          btn2Name="btn2"
          showBtn3={true}
          btn3type={"outlined_del"}
          btn3Text={"Cancel"}
          btn3Name={"btn3"}
          onClickHandler={(type: any) => {
            if (type === "Save") {
              handleCreateAndUpdate();
            } else if (type === "Save as Draft") {  
              dataDispatch({ type: "VISIBILITY", payload: true });
              handleCreateAndUpdate();
            } else if (type === "Cancel") {
              navigate("/categories");
            }
          }}
        />
        <ContentBody>
          <div className="grid">
            <div className="card grid">
              <div className="grid_2">
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Category Name"}
                  value={data.title}
                  onChange={(e: any) => {
                    dataDispatch({
                      type: "TITLE",
                      payload: e.target.value.toLowerCase(),
                    });
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    border: "1px solid rgb(222,222,222)",
                    borderRadius: "10px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "20px",
                    marginTop: "27px",
                  }}
                >
                  <p>Visibility</p>
                  <ToggleSwitch
                    value={data.visibility}
                    onClick={() => {
                      dataDispatch({
                        type: "VISIBILITY",
                        payload: !data.visibility,
                      });
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  height: "fit-content",
                }}
              >
                <p>Banner Image </p>
                <HoverCardInfo
                  header=""
                  description="Use 16:9 image which is at least 2560 x 1400 for best result."
                />
              </div>
              {data.mediaUrl.length === 0 && (
                <div className="media_container">
                  <input
                    type="file"
                    accept=".heic,image/*"
                    onChange={async (e: any) => {
                      const file = e.target.files[0];
                      if (file && file.type === "image/heic") {
                        const convertedBlob: any = await heic2any({
                          blob: file,
                          toType: "image/jpeg",
                        });
                        const convertedFile = new File(
                          [convertedBlob],
                          "converted.jpg",
                          {
                            type: "image/jpeg",
                          }
                        );
                        dataDispatch({
                          type: "ADD_IMAGE",
                          payload: {
                            url: URL.createObjectURL(convertedFile),
                            obj: convertedFile,
                          },
                        });
                      } else
                        dataDispatch({
                          type: "ADD_IMAGE",
                          payload: {
                            url: URL.createObjectURL(e.target.files[0]),
                            obj: e.target.files[0],
                          },
                        });
                    }}
                  />
                  <p>+</p>
                  <p>Add Media</p>
                </div>
              )}
              {data.mediaUrl.length !== 0 && (
                <ul className="selected_media_container">
                  {data.mediaUrl?.map((val: any, index: any) => {
                    return (
                      <div className="media_content">
                        <ImageComponent
                          isFile={!!val.mediaUrl.obj}
                          url={
                            !!val.mediaUrl.obj
                              ? val.mediaUrl.url
                              : val.mediaUrl.url
                          }
                          fileUrl={val.mediaUrl.url}
                        />
                        <FontAwesomeIcon
                          icon={faClose}
                          color={"#36619b"}
                          onClick={() =>
                            dataDispatch({
                              type: "REMOVE_MEDIA",
                              payload: index,
                            })
                          }
                        />
                      </div>
                    );
                  })}
                </ul>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  height: "fit-content",
                }}
              >
                <p>Thumbnail Image </p>
                <HoverCardInfo
                  header=""
                  description="Use 1:1 image which is at least 1080x1080 and with no-background for best result."
                />
              </div>
              {data.mediaUrl2.length === 0 && (
                <div className="media_container">
                  <input
                    type="file"
                    accept=".heic,image/*"
                    onChange={async (e: any) => {
                      const file = e.target.files[0];
                      if (file && file.type === "image/heic") {
                        const convertedBlob: any = await heic2any({
                          blob: file,
                          toType: "image/jpeg",
                        });
                        const convertedFile = new File(
                          [convertedBlob],
                          "converted.jpg",
                          {
                            type: "image/jpeg",
                          }
                        );
                        dataDispatch({
                          type: "ADD_IMAGE2",
                          payload: {
                            url: URL.createObjectURL(convertedFile),
                            obj: convertedFile,
                          },
                        });
                      } else
                        dataDispatch({
                          type: "ADD_IMAGE2",
                          payload: {
                            url: URL.createObjectURL(e.target.files[0]),
                            obj: e.target.files[0],
                          },
                        });
                    }}
                  />
                  <p>+</p>
                  <p>Add Media</p>
                </div>
              )}
              {data.mediaUrl2.length !== 0 && (
                <ul className="selected_media_container">
                  {data.mediaUrl2?.map((val: any, index: any) => {
                    return (
                      <div className="media_content">
                        <ImageComponent
                          isFile={!!val.mediaUrl2.obj}
                          url={
                            !!val.mediaUrl2.obj
                              ? val.mediaUrl2.url
                              : val.mediaUrl2.url
                          }
                          fileUrl={val.mediaUrl2.url}
                        />
                        <FontAwesomeIcon
                          icon={faClose}
                          color={"#36619b"}
                          onClick={() =>
                            dataDispatch({
                              type: "REMOVE_MEDIA2",
                              payload: index,
                            })
                          }
                        />
                      </div>
                    );
                  })}
                </ul>
              )}
              <InputField
                type="select"
                isLabel={true}
                lable={"Sub Categories"}
                options={subCategories
                  .filter((v: any) => {
                    return !selectedSubCategories.includes(v.title);
                  })
                  .map((val: any) => ({
                    title: val.title,
                    value: val.title,
                  }))}
                accessText="title"
                accessValue="value"
                value={""}
                placeholder="Select Sub Categories"
                onChange={(e: any) =>
                  setSelectedSubCategories((prev: any) => {
                    return [...prev, e.target.value];
                  })
                }
              />
              {selectedSubCategories.length !== 0 && (
                <ul
                  style={{
                    listStyle: "none",
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  {selectedSubCategories.map((val: any, index: any) => {
                    return (
                      <li
                        key={index}
                        style={{
                          background: "#eff5fc",
                          color: "#36619b",
                          padding: "10px 15px",
                          borderRadius: "10px",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {val}
                        <FontAwesomeIcon
                          icon={faClose}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let temp = [...selectedSubCategories];
                            temp.splice(index, 1);
                            setSelectedSubCategories(temp);
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
              <InputField
                type="textarea"
                isLabel={true}
                lable={"Description"}
                value={data.description}
                onChange={(e: any) =>
                  dataDispatch({ type: "DESCRIPTION", payload: e.target.value })
                }
              />
            </div>
            <div className="card grid">
              <h3>Meta Data</h3>
              <InputField
                isLabel={true}
                lable={"Meta Title"}
                value={data.metaData.metaTitle}
                onChange={(e: any) => {
                  dataDispatch({
                    type: "METADATA",
                    payload: { ...data.metaData, metaTitle: e.target.value },
                  });
                }}
                type="text"
              />
              <InputField
                isLabel={true}
                lable={"Meta Description"}
                value={data.metaData.metaDescription}
                onChange={(e: any) => {
                  dataDispatch({
                    type: "METADATA",
                    payload: {
                      ...data.metaData,
                      metaDescription: e.target.value,
                    },
                  });
                }}
                type="textarea"
              />
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

export default AddUpdateCategory;
