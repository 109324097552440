import React, { useState, useEffect } from "react";
import styles from "../../Styles/content.module.css";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../CustomButton";

import { ReactComponent as ImportIcon } from "../../Assets/newIcons/import.svg";
import { ReactComponent as ExportIcon } from "../../Assets/newIcons/export.svg";
import { ReactComponent as LogsIcon } from "../../Assets/newIcons/logs.svg";


type props = {
  title: any;
  showBtn1?: boolean;
  showBtn2?: boolean;
  showBtn3?: boolean;
  showBtn4?: boolean;
  showStatusUpdateBtn?: boolean;
  showReturnBtn?: boolean;
  showRefundBtn?: boolean;
  statusUpdateBtnText?: any;
  refundStatusBtnText?: any;
  returnStatusBtnText?: any;
  refundStatusBtnType?: any;
  returnStatusBtnType?: any;
  btn1Text?: any;
  btn2Text?: any;
  btn3Text?: any;
  btn4Text?: any;
  btn1type?:
  | "outlined"
  | "filled"
  | "outlined_del"
  | "light_grey"
  | "outlined_success"
  | "filled_red"
  | "filled_green"
  | "filled_blue"
  | undefined;
  btn2type?:
  | "outlined"
  | "filled"
  | "outlined_del"
  | "light_grey"
  | "outlined_success"
  | "filled_red"
  | "filled_green"
  | "filled_blue"
  | undefined;
  btn3type?:
  | "outlined"
  | "filled"
  | "outlined_del"
  | "light_grey"
  | "outlined_success"
  | "filled_red"
  | "filled_green"
  | "filled_blue"
  | undefined;
  btn4type?:
  | "outlined"
  | "filled"
  | "outlined_del"
  | "light_grey"
  | "outlined_success"
  | "filled_red"
  | "filled_green"
  | "filled_blue"
  | undefined;
  statusUpdateBtnType?:
  | "outlined"
  | "filled"
  | "outlined_del"
  | "light_grey"
  | "outlined_success"
  | "filled_red"
  | "filled_blue"
  | "filled_green";
  onClickHandler?: any;
  btn1Name?: string;
  btn2Name?: string;
  btn3Name?: string;
  refundStatusBtnName?: any;
  returnStatusBtnName?: any;
  btn4Name?: string;
  statusUpdateBtnName?: string;
  isIcon?: boolean;
  iconType?: any;
  isIcon1?: boolean;
  iconType1?: any;
  isIcon2?: boolean;
  iconType2?: any;
  isIcon3?: boolean;
  iconType3?: any;
  isIcon4?: boolean;
  iconType4?: any;
  customElement?: any;
  isMoreSettings?: any;
  moreSettingsList?: any;
  handleMoreClick?: any;
  isSplitButton?:boolean
};

const ContentHead = (props: props) => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const renderButton = (show, name, type, text, iconProps, style = {}, isSplitButton = false) => {
    if (!show) return null;
    console.log(isSplitButton, text);
    
   
    if (isSplitButton && (text === "Save" || text==="Save As Draft" )) {
      
      const dropdownItems = [
        { 
          label: "Save", 
          value: "save",
          onClick: (e) => props.onClickHandler("default")
        },
        { 
          label: "Save As Draft", 
          value: "saveAsDraft",
          onClick: (e) => props.onClickHandler("Save As Draft")
        },
    
        
      ];
      if(text=='Save As Variant'){
        dropdownItems.push(
          {
            label:'Save As Variant',
            value:"saveAsVariant",
            onClick:(e)=>{props.onClickHandler("Save as Variant")}
          }
        )
      }
      
      
      return (
        <CustomButton
          size="sm"
          name={name}
          variant={type}
          text="Save"
          style={{ marginRight: "10px", ...style }}
          icon={iconProps.iconType}
          iconPosition="left"
          isSplitButton={true}
          withDropdown={true}
          dropdownItems={dropdownItems}
          onClick={(e) => props.onClickHandler("default")}
        />
      );
    } 
    
    

    else if((text!=='Save as Draft' && text!=='Save as Variant' && isSplitButton) || !isSplitButton) {
      
      return (
        <CustomButton
          size="sm"
          name={name}
          variant={type}
          text={text}
          style={{ marginRight: "10px", ...style }}
          onClick={()=>{
            props.onClickHandler(text)
            console.log('button clicked:', text)

          }}
          icon={iconProps.iconType}
          iconPosition="left"
        />
      );
    }
  };

  const buttons = [
    {
      show: props.showBtn4,
      name: props.btn4Name,
      type: props.btn4type,
      text: props.btn4Text,
      iconProps: { isIcon: props.isIcon4, iconType: props.iconType4 },
    },
    {
      show: props.showBtn3,
      name: props.btn3Name,
      type: props.btn3type,
      text: props.btn3Text,
      iconProps: { isIcon: props.isIcon3, iconType: props.iconType3 },
    },
    {
      show: props.showBtn2,
      name: props.btn2Name,
      type: props.btn2type,
      text: props.btn2Text,
      iconProps: { isIcon: props.isIcon2, iconType: props.iconType2 },
    },
    {
      show: props.showStatusUpdateBtn,
      name: props.statusUpdateBtnName,
      type: props.statusUpdateBtnType,
      text: props.statusUpdateBtnText,
      iconProps: { isIcon: props.isIcon1, iconType: props.iconType1 },
    },
    {
      show: props.showReturnBtn,
      name: props.returnStatusBtnName,
      type: props.returnStatusBtnType,
      text: props.returnStatusBtnText,
      iconProps: { isIcon: props.isIcon, iconType: props.iconType },
    },
    {
      show: props.showRefundBtn,
      name: props.refundStatusBtnName,
      type: props.refundStatusBtnType,
      text: props.refundStatusBtnText,
      iconProps: { isIcon: props.isIcon, iconType: props.iconType },
    },
    {
      show: props.showBtn1,
      name: props.btn1Name,
      type: props.btn1type,
      text: props.btn1Text,
      iconProps: { isIcon: props.isIcon1, iconType: props.iconType1 },
    },
  ];

  const visibleButtons = buttons.filter((btn) => btn.show);

  return (
    <div className={styles.content_container}>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          textWrap: "nowrap",
        }}
      >
        {props.title}
      </h1>
      <div style={{ flexGrow: 1 }}></div>
      {props.customElement}

      {isMobile ? (
        <div className={styles.more_menu}>
           <CustomButton
              icon="ellipsis"
              variant="filled_blue"
              withDropdown={true}
              // style={{position:'fixed'}}
              dropdownItems={visibleButtons?.map((item: any) => ({
                label: item.text,
                value: item.name,
                onClick: (e) => props.onClickHandler(e)
              }))}
              size="sm"
              dropdownPosition="bottom-right"


            />
         
        </div>
      ) : (
        <>
          {visibleButtons.map((btn, index) =>
            renderButton(
              btn.show,
              btn.name,
              btn.type,
              btn.text,
              btn.iconProps,
              { order: visibleButtons.length - index },
              props.isSplitButton
            )
          )}
          {props.isMoreSettings && (
            <CustomButton
              size="sm"
              icon="ellipsis"
              variant="filled_blue"
              withDropdown={true}
              dropdownItems={props?.moreSettingsList?.map((item: any) => ({
                label: item.title,
                value: item.name,
                onClick: () => {
                  console.log(item.name)
                  props.handleMoreClick(item.name)},
              }))}
              dropdownPosition="bottom-right"


            />
          )}
        </>
      )}

    </div>
  );
};

const more_list_icons = {
  import: ImportIcon,
  export: ExportIcon,
  logs: LogsIcon,
};

export default React.memo(ContentHead);
