import React, { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import InputField from "../../Components/InputField";
import { useLangContext } from "../../Store/langContext";
import styles from "../../Styles/settings.module.css";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import axios from "axios";
import toast from "react-hot-toast";
import { getFileName } from "../../Utils/getFileName";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import { first, identity } from "lodash";
import SubAdminPanel from "../../Components/Settings/subAdminPanel/SubAdminPanel";
import { useAdminContext } from "../../Store/adminContext";
import heic2any from "heic2any";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faClose,
  faCopy,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import ContentOuterHead from "../../Components/Content/contentOuterHead";
import { uploadBulkImages } from "../../Apis/callApi";
import { useLocation } from "react-router-dom";
import DomainManagement from "../../Components/DomainManagement";
import { hasPermission } from "../../Utils/checkPermissions/planPermissions";

type ColorType = {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  neutralColor: string;
  bodyColor: string;
};

const Settings = () => {
  const { lang, setLang }: any = useLangContext();

  const [logo, setLogo]: any = useState({ url: "", obj: null });
  const [isChanged, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const { admin, getAdminData }: any = useAdminContext();
  const [minOrderValue, setMinOrderValue] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [scripts, setScripts]: any = useState([]);
  const [isMinOrderValueActive, setIsMinOrderValueActive] = useState(false);
  const [isShippingChargeActive, setIsShippingChargeActive] = useState(false);
  const [isCancellationRules, setIsCancellationRules] = useState(false);
  const [color, setColor] = useState<ColorType>({
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
    neutralColor: "",
    bodyColor: "",
  });
  const [orderCancelList, setOrderCancelList]: any = useState([]);
  const [on, setOn] = useState("profile");
  const [isProfileChanged, setIsProfileChanged] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState({
    addLineOne: "",
    addLineTwo: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  const [domain, setDomain] = useState("");

  const setAdminData = () => {};

  const getData = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/getWebsiteData`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        const d = res.data.data;

        setColor(d.color);
        setLogo({
          url: d.logoUrl,
          obj: null,
        });
        setMinOrderValue(d?.minOrderValue || 0);
        setShippingCharge(d?.shippingCharge || 0);
        setIsMinOrderValueActive(d?.isMinOrderValueActive);
        setIsShippingChargeActive(d?.isShippingChargeActive);
        setScripts(d?.scripts || []);
        setIsCancellationRules(d?.isCancellationRules);
        setOrderCancelList(d?.orderCancelList || []);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
  }, []);

  const addImg = async (e: any) => {
    setChanged(true);
    const file = e.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      setLogo({
        url: URL.createObjectURL(convertedFile),
        obj: convertedFile,
      });
    } else
      setLogo({
        url: URL.createObjectURL(e.target.files[0]),
        obj: e.target.files[0],
      });
  };

  const uploadImage = async () => {
    if (!logo.url) return "";

    let formData = new FormData();

    formData.append("file", logo.obj);
    formData.append(
      "path",
      localStorage
        .getItem("identifier")
        .replace(/\s+/g, "")
        .replace(/\.+/g, "_")
    );

    if (logo.obj) {
      let vv = await axios({
        url: "https://api.shopiq.app/api/media/",
        method: "post",
        data: formData,
      });

      return vv.data.fileUrl;
    } else {
      return logo.url;
    }
  };

  const handleSave = async () => {
    setLoading(true);

    const res = await uploadImage();

    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/updateSelf`,
      method: "patch",
      data: {
        identity: localStorage.getItem("identifier"),
        data: {
          color,
          logoUrl: res,
          minOrderValue,
          isMinOrderValueActive,
          shippingCharge,
          isShippingChargeActive,
          scripts,
          isCancellationRules,
          orderCancelList,
        },
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        toast.success("Website data updated successfully.");
        setLoading(false);
        setChanged(false);
      })
      .catch((err) => {
        toast.error("Something went wrong while updating website data.");
        setLoading(false);
      });
  };

  const handleProfileUpdate = () => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/updateSelf`,
      method: "patch",
      data: {
        identity: localStorage.getItem("identifier"),
        data: {
          firstName,
          lastName,
          address,
        },
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        toast.success("Profile Updated Successfully.");
        setLoading(false);
        setIsProfileChanged(false);
        getData();
        getAdminData();
      })
      .catch((err: any) => {
        toast.error(`Error : ${err.response.data.message}`);
        setLoading(false);
      });
  };

  return (
    <Content>
      <ContentHead title={"settings"} />
      <ContentBody>
        <div className={styles.tabs}>
          <div
            className={on === "profile" ? styles.active : ""}
            onClick={() => setOn("profile")}
          >
            Profile
          </div>
          <div
            className={on === "setting" ? styles.active : ""}
            onClick={() => setOn("setting")}
          >
            Settings
          </div>
          {on === "profile" && isProfileChanged && (
            <button
              style={{
                marginLeft: "auto",
                background: "var(--brand-color)",
                border: "none",
                padding: "10px 15px",
                color: "white",
                borderRadius: "10px",
              }}
              onClick={handleProfileUpdate}
            >
              Save
            </button>
          )}
          {on === "setting" && isChanged && (
            <button
              style={{
                marginLeft: "auto",
                background: "var(--brand-color)",
                border: "none",
                padding: "10px 15px",
                color: "white",
                borderRadius: "10px",
              }}
              onClick={handleSave}
            >
              Save
            </button>
          )}
        </div>
        {on == "setting" && (
          <div className="grid">
            {canAdminAddSubAdmin(admin) && (
              <div className={"card grid"}>
                <SubAdminPanel />
              </div>
            )}
            <div className="grid_2">
              {admin.role !== "subadmin" && (
                <div className="card grid">
                  <h3>Store Settings</h3>
                  <div className={styles.input}>
                    <p>Logo</p>
                    {logo.url ? (
                      <div className={styles.img_container}>
                        <img src={logo.url} />
                        <div
                          className={styles.close_icon}
                          onClick={() => setLogo({ url: "", obj: null })}
                        >
                          <CloseIcon />
                        </div>
                      </div>
                    ) : (
                      <div className={styles.img_input}>
                        +
                        <input
                          type="file"
                          accept=".heic,image/*"
                          onChange={addImg}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.input}>
                    <p>Colors</p>
                    <div className={styles.color_input}>
                      <label>Primary Color</label>
                      <input
                        type="color"
                        value={color.primaryColor}
                        onChange={(e: any) => {
                          setChanged(true);
                          setColor((prev) => ({
                            ...prev,
                            primaryColor: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className={styles.color_input}>
                      <label>Secondary Color</label>
                      <input
                        type="color"
                        value={color.secondaryColor}
                        onChange={(e: any) => {
                          setChanged(true);
                          setColor((prev) => ({
                            ...prev,
                            secondaryColor: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    {/* <div className={styles.color_input}>
                      <label>Tertiary Color</label>
                      <input
                        type="color"
                        value={color.tertiaryColor}
                        onChange={(e: any) => {
                          setChanged(true);
                          setColor((prev) => ({
                            ...prev,
                            tertiaryColor: e.target.value,
                          }));
                        }}
                      />
                    </div> */}
                    <div className={styles.color_input}>
                      <label>Neutral Color</label>
                      <input
                        type="color"
                        value={color.neutralColor}
                        onChange={(e: any) => {
                          setChanged(true);
                          setColor((prev) => ({
                            ...prev,
                            neutralColor: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className={styles.color_input}>
                      <label>Body Color</label>
                      <input
                        type="color"
                        value={color.bodyColor}
                        onChange={(e: any) => {
                          setChanged(true);
                          setColor((prev) => ({
                            ...prev,
                            bodyColor: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="grid">
                <div className="card grid" style={{ alignSelf: "start" }}>
                  <h3>Preferred language</h3>
                  <p>
                    When you're logged in to ShopIQ, this is the language you
                    will see. It doesn't affect the language your customers see
                    on your online store.
                  </p>
                  <InputField
                    type="select"
                    isLabel={false}
                    lable={"Language"}
                    value={lang}
                    onChange={(val: any) => setLang(val.target.value)}
                    options={[
                      { value: "en", title: "English" },
                      { value: "hn", title: "Hindi" },
                    ]}
                    accessText="title"
                    accessValue="value"
                  />
                </div>
                <SelectedPlan admin={admin} />
              </div>
            </div>
            <div className="grid_2">
              {admin.role !== "subadmin" && (
                <div className="card grid">
                  <div className="grid">
                    <h3>Store Rules</h3>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        type="checkbox"
                        checked={isMinOrderValueActive}
                        onChange={(e: any) => {
                          setChanged(true);
                          setIsMinOrderValueActive(e.target.checked);
                        }}
                        id="isMinOrderValueActiveValue"
                      />
                      <label htmlFor="isMinOrderValueActiveValue">
                        Set Min. Order Value
                      </label>
                    </div>
                    {isMinOrderValueActive && (
                      <InputField
                        type="number"
                        value={String(minOrderValue)}
                        isLabel={true}
                        lable={"Min. Order Value"}
                        onChange={(e: any) => {
                          setChanged(true);
                          setMinOrderValue(e.target.value);
                        }}
                      />
                    )}
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        type="checkbox"
                        checked={isShippingChargeActive}
                        onChange={(e: any) => {
                          setChanged(true);
                          setIsShippingChargeActive(e.target.checked);
                        }}
                        id="isShippingChargeActiveValue"
                      />
                      <label htmlFor="isShippingChargeActiveValue">
                        Set Shipping Charge
                      </label>
                    </div>
                    {isShippingChargeActive && (
                      <InputField
                        type="number"
                        value={String(shippingCharge)}
                        isLabel={true}
                        lable={"Shipping Charges"}
                        onChange={(e: any) => {
                          setChanged(true);
                          setShippingCharge(e.target.value);
                        }}
                      />
                    )}
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        type="checkbox"
                        checked={isCancellationRules}
                        onChange={(e: any) => {
                          setChanged(true);
                          setIsCancellationRules(e.target.checked);
                        }}
                        id="isCancellationRules"
                      />
                      <label htmlFor="isCancellationRules">
                        Cancellation Rules
                      </label>
                    </div>
                    {isCancellationRules && (
                      <>
                        <div>
                          <InputField
                            isLabel={true}
                            lable={
                              "User can cancel Order, When Fulfillment Status is :"
                            }
                            type="select"
                            options={[
                              { title: "Received", value: "received" },
                              { title: "Packaging", value: "packaging" },
                              { title: "Shipped", value: "shipped" },
                              {
                                title: "Out for delivery",
                                value: "outfordelivery",
                              },
                              { title: "Completed", value: "completed" },
                            ].filter((v: any) => {
                              return !orderCancelList.includes(v.value);
                            })}
                            accessText="title"
                            accessValue="value"
                            value=""
                            onChange={(e: any) => {
                              setOrderCancelList((prev: any) => [
                                ...prev,
                                e.target.value,
                              ]);
                            }}
                          />
                          <p
                            style={{
                              fontSize: ".8rem",
                              color: "grey",
                              marginTop: "5px",
                            }}
                          >
                            If you don't want the user to cancel the order at
                            any stage, mark the cancellation rules as true and
                            leave the above list empty.
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {orderCancelList.map((v: any, i: any) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  background: "var(--blue-light)",
                                  padding: "5px 10px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  textTransform: "capitalize",
                                  color: "var(--blue-dark)",
                                  borderRadius: "10px",
                                }}
                              >
                                <p>
                                  {v === "outfordelivery"
                                    ? "out for delivery"
                                    : v}
                                </p>
                                <FontAwesomeIcon
                                  icon={faClose}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let temp = [...orderCancelList];
                                    temp.splice(i, 1);
                                    setOrderCancelList(temp);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="card grid">
                <DomainManagement />
              </div>

              {/* {admin.role !== "subadmin" && (
                <div className="card grid">
                  <h3>
                    Scripts{" "}
                    <button
                      onClick={() => {
                        setScripts((prev: any) => [
                          ...prev,
                          { scriptType: "", scriptPosition: "", value: "" },
                        ]);
                      }}
                    >
                      Add
                    </button>
                  </h3>

                  {scripts.map((script: any, index: any) => {
                    return (
                      <div className={styles.scripts_list_item} key={index}>
                        <CloseIcon
                          onClick={() => {
                            setScripts((prev: any) => {
                              let temp = [...prev];
                              temp.splice(index, 1);
                              return temp;
                            });
                          }}
                        />
                        <InputField
                          type="select"
                          options={[
                            {
                              title: "Google Tag Manager",
                              value: "google_tag_manager",
                            },
                            {
                              title: "Google Analytics",
                              value: "google_analytics",
                            },
                            {
                              title: "Google Search Console",
                              value: "google_search_console",
                            },
                            { title: "Meta Pixel", value: "meta_pixel" },
                          ]}
                          accessText="title"
                          accessValue="value"
                          value={script.scriptType}
                          isLabel={true}
                          lable={"Script Type"}
                          onChange={(e: any) => {
                            setChanged(true);
                            setScripts((prev: any) => {
                              let temp = [...prev];
                              temp[index].scriptType = e.target.value;
                              return temp;
                            });
                          }}
                          placeholder="Select Script type"
                        />
                        {(script.scriptType === "google_tag_manager" ||
                          script.scriptType === "other") && (
                            <InputField
                              type="select"
                              options={[
                                {
                                  title: "Head",
                                  value: "head",
                                },
                                {
                                  title: "Body",
                                  value: "body",
                                },
                              ]}
                              accessText="title"
                              accessValue="value"
                              value={script.scriptPosition}
                              isLabel={true}
                              lable={"Script Position"}
                              onChange={(e: any) => {
                                setChanged(true);
                                setScripts((prev: any) => {
                                  let temp = [...prev];
                                  temp[index].scriptPosition = e.target.value;
                                  return temp;
                                });
                              }}
                              placeholder="Select Script Position"
                            />
                          )}
                        <InputField
                          type="text"
                          value={script.value}
                          isLabel={true}
                          lable={"Script Value"}
                          onChange={(e: any) => {
                            setChanged(true);
                            setScripts((prev: any) => {
                              let temp = [...prev];
                              temp[index].value = e.target.value;
                              return temp;
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )} */}
            </div>
          </div>
        )}
        {on == "profile" && (
          <ProfileSection
            admin={admin}
            isProfileChanged={isProfileChanged}
            setIsProfileChanged={setIsProfileChanged}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            address={address}
            setAddress={setAddress}
            domain={domain}
            setDomain={setDomain}
          />
        )}
      </ContentBody>
    </Content>
  );
};

const SelectedPlan = ({ admin }: any) => {
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    if (admin.selectedPlan) {
      getPlanById(admin.selectedPlan);
    } else {
      setLoading(false);
    }
  }, [admin]);

  const getPlanById = (id: any) => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}plan/${id}`,
      method: "get",
    })
      .then((res) => {
        setPlan(res.data.data);
        setLoading(null);
      })
      .catch(() => {
        setPlan(null);
        setLoading(false);
      });
  };

  return (
    // <div className="grid_2">
    <div className="card grid" style={{ alignSelf: "start" }}>
      <h3>Selected Plan</h3>
      {loading ? (
        <div>
          <span className="btn_loader_black"></span>
        </div>
      ) : plan !== null ? (
        <div>
          <h4 style={{ color: "var(--brand-color)" }}>{plan.name} Plan</h4>
          <p style={{ color: "grey" }}>{plan.description}</p>
        </div>
      ) : (
        <div>No Plan Found</div>
      )}
    </div>
    // </div>
  );
};

const ProfileSection = ({
  admin,
  isProfileChanged,
  setIsProfileChanged,
  firstName,
  setFirstName,
  countryCode,
  setCountryCode,
  lastName,
  setLastName,
  address,
  setAddress,
}: any) => {
  const [modal, setModal] = useState(false);
  const [key, setKey] = useState("");
  const [websiteData, setWebsiteData]: any = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setFirstName(admin.firstName);
    setLastName(admin.lastName);
    setAddress({
      addLineOne: admin?.address?.addLineOne || "",
      addLineTwo: admin?.address?.addLineTwo || "",
      pincode: admin?.address?.pincode || "",
      city: admin?.address?.city || "",
      state: admin?.address?.state || "",
      country: admin?.address?.country || "",
    });
  }, []);

  useEffect(() => {
    checkKey();
  }, []);

  const checkKey = () => {
    axios({
      method: "get",
      url: `https://api.shopiq.app/api/admins/getWebsiteData`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setWebsiteData(res.data.data);
      })
      .catch((err: any) => {});
  };

  const handleGenerateKey = () => {
    axios({
      method: "post",
      url: "https://api.shopiq.app/api/admins/generateSecretKey",
      data: { identifier: localStorage.getItem("identifier") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        checkKey();
        setKey(res.data.secretKey);
        setModal(true);
      })
      .catch((err: any) => {});
  };

  const handleRevokeKey = () => {
    axios({
      method: "post",
      url: "https://api.shopiq.app/api/admins/revokeSecretKey",
      data: { identifier: localStorage.getItem("identifier") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then(() => {
        checkKey();
        toast.success("Secret Key is revoked");
      })
      .catch(() => {
        toast.error("Error in Removing secret Key");
      });
  };

  return (
    <>
      {modal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.1)",
            zIndex: 100,
          }}
        >
          <div
            style={{
              width: "40vw",
              background: "white",
              padding: "0px",
              borderRadius: "5px",
            }}
          >
            {/* header */}
            <div
              style={{
                padding: "10px",
                borderBottom: "1px solid lightgrey",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>Authentication Keys</p>
              <FontAwesomeIcon
                icon={faClose}
                style={{ cursor: "pointer" }}
                onClick={() => setModal(false)}
              />
            </div>
            {/* body */}
            <div
              style={{
                padding: "10px 10px 20px 10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <p>Secret Key:</p>{" "}
                <input
                  type={show ? "text" : "password"}
                  value={key}
                  disabled
                  style={{
                    display: "flex",
                    gap: "10px",
                    color: "grey",
                    background: "white",
                    border: "1px solid grey",
                    width: "200px",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                ></input>
                <span>
                  <FontAwesomeIcon
                    icon={show ? faEyeSlash : faEye}
                    style={{ color: "grey", cursor: "pointer" }}
                    onClick={() => setShow((prev) => !prev)}
                  />
                  <FontAwesomeIcon
                    icon={faCopy}
                    style={{
                      color: "grey",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(key)
                        .then(() => {
                          toast.success("Copied Successfully.");
                        })
                        .catch((err) => {
                          console.error("Failed to copy text: ", err);
                        });
                    }}
                  />
                </span>
              </div>
            </div>

            {/* footer */}
            {/* <div
              style={{
                padding: "10px",
                borderTop: "1px solid lightgrey",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  border: "2px solid var(--brand-color)",
                  padding: "5px 10px",
                  color: "var(--brand-color)",
                  background: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Download as CSV
              </button>
              <button
                style={{
                  border: "2px solid var(--brand-color)",
                  padding: "5px 10px",
                  color: "white",
                  background: "var(--brand-color)",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Done
              </button>
            </div> */}
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.field_container}>
          <div className={styles.inner_box}>
            <h2>Personal Information</h2>
            <div>
              <InputField
                type={"text"}
                isLabel={true}
                lable="Name"
                name="firstName"
                value={firstName}
                onChange={(e: any) => {
                  setFirstName(e.target.value);
                  setIsProfileChanged(true);
                }}
              />
              <InputField
                type={"text"}
                isLabel={true}
                lable="Last Name"
                name="lastName"
                value={lastName}
                onChange={(e: any) => {
                  setLastName(e.target.value);
                  setIsProfileChanged(true);
                }}
              />

              <InputField
                type={"text"}
                isLabel={true}
                lable="Email"
                name="email"
                value={admin.email}
                onChange={(e: any) => {}}
                disabled
              />
              <InputField
                type={"text"}
                isLabel={true}
                lable="Mobile No."
                name="mobileNumber"
                value={
                  "+" +
                  (admin?.mobileNumber?.countryCode || "") +
                  " " +
                  (admin?.mobileNumber?.value || "")
                }
                onChange={(e: any) => {}}
                disabled
              />
              <InputField
                type={"text"}
                isLabel={true}
                lable="Address Line One"
                name="addLineOne"
                value={address?.addLineOne}
                onChange={(e: any) => {
                  setAddress((prev) => ({
                    ...prev,
                    addLineOne: e.target.value,
                  }));
                  setIsProfileChanged(true);
                }}
              />
              <InputField
                type={"text"}
                isLabel={true}
                lable="Address Line Two"
                name="addLineTwo"
                value={address?.addLineTwo}
                onChange={(e: any) => {
                  setAddress((prev) => ({
                    ...prev,
                    addLineTwo: e.target.value,
                  }));
                  setIsProfileChanged(true);
                }}
              />
              <InputField
                type={"number"}
                isLabel={true}
                lable="Pincode"
                name="pincode"
                value={address?.pincode}
                onChange={(e: any) => {
                  setAddress((prev) => ({
                    ...prev,
                    pincode: e.target.value,
                  }));
                  setIsProfileChanged(true);
                }}
              />
              <InputField
                type={"text"}
                isLabel={true}
                lable="City"
                name="city"
                value={address?.city}
                onChange={(e: any) => {
                  setAddress((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }));
                  setIsProfileChanged(true);
                }}
              />
              <InputField
                type={"text"}
                isLabel={true}
                lable="State"
                name="state"
                value={address?.state}
                onChange={(e: any) => {
                  setAddress((prev) => ({
                    ...prev,
                    state: e.target.value,
                  }));
                  setIsProfileChanged(true);
                }}
              />
              <InputField
                type={"text"}
                isLabel={true}
                lable="Country"
                name="country"
                value={address?.country}
                onChange={(e: any) => {
                  setAddress((prev) => ({
                    ...prev,
                    country: e.target.value,
                  }));
                  setIsProfileChanged(true);
                }}
              />
            </div>
          </div>
          {hasPermission(
            admin.selectedPlan,
            "settings",
            "authentication_key"
          ) && (
            <div className={styles.inner_box}>
              <h2>Authentication Key</h2>
              {websiteData?.secretKeyGenerated ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <p>Secret Key:</p>
                    <input
                      disabled
                      type="password"
                      value="asfasdfasfasdfasf"
                      style={{
                        border: "1px solid grey",
                        padding: "5px",
                        borderRadius: "5px",
                        background: "#fefefe",
                        fontSize: "1.2rem",
                      }}
                    />
                    <p
                      style={{
                        color: "grey",
                        fontSize: ".9rem",
                        marginTop: "10px",
                      }}
                    >
                      The secret key has already been generated and cannot be
                      viewed again. However, you can generate a new key to
                      retrieve it.
                    </p>
                  </div>
                  <button
                    style={{
                      background: "var(--brand-color)",
                      color: "white",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      width: "100px",
                    }}
                    onClick={handleRevokeKey}
                  >
                    Revoke Key
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p style={{ color: "grey" }}>
                    You don't have any active key.
                  </p>
                  <button
                    style={{
                      background: "var(--brand-color)",
                      color: "white",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={handleGenerateKey}
                  >
                    Generate Key
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const canAdminAddSubAdmin = (admin: any) => {
  if (admin.role === "subadmin") return false;

  if (!admin.subAdmin) return false;

  return true;
};

export default Settings;
