import React, { useEffect, useRef, useState } from 'react';
import styles from "./leads.module.css";
import { ReactComponent as TrashIcon } from "../../Assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../Assets/newIcons/edit.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from '../../Components/CustomButton';

interface SecondaryViewProps {
    contacts: any;
    inView: any;
    setInView: (contact: any) => void;
    changeSelect: (id: string) => void;
    setSecondaryView: (value: boolean) => void;
    setDeleteById: (id: string) => void;
    setModal: (value: boolean) => void;
    tab: string;
    setTab: (tab: string) => void;
    remark: string;
    setRemark: (remark: string) => void;
    handleAddRemark: () => void;
    logs: any[];
    getChangedFields: (from: any, to: any) => any[];
    contact_fields: { [key: string]: string };
}

const SecondaryView: React.FC<SecondaryViewProps> = ({ 
    contacts, 
    inView, 
    setInView, 
    changeSelect, 
    setSecondaryView, 
    setDeleteById, 
    setModal, 
    tab, 
    setTab, 
    remark, 
    setRemark, 
    handleAddRemark, 
    logs, 
    getChangedFields, 
    contact_fields 
}) => {
    const navigate = useNavigate();
    const inViewRef = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    useEffect(() => {
        if (inViewRef.current) {
            inViewRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [inView]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`${styles.secondary_view} ${isMobile ? styles.mobile_view : ''}`}>
            {/* Mobile view toggle button */}
            {isMobile && (
                <div className={styles.mobile_toggle}>
                    <button 
                        className={`${styles.mobile_toggle_btn} ${tab === 'list' ? styles.active : ''}`} 
                        onClick={() => setTab('list')}
                    >
                        Contact List
                    </button>
                    <button 
                        className={`${styles.mobile_toggle_btn} ${tab !== 'list' ? styles.active : ''}`} 
                        onClick={() => inView?._id ? setTab('overview') : null}
                    >
                        Details
                    </button>
                </div>
            )}

            {/* List view (hidden on mobile when details are shown) */}
            <div className={`${styles.secondary_list_view} ${isMobile && tab !== 'list' ? styles.hidden : ''}`}>
                {contacts.map((val: any, index: any) => {
                    return (
                        <div
                            key={index}
                            className={styles.secondary_short_item}
                            style={
                                val._id === inView?._id
                                    ? {
                                        background: "var(--brand-color)",
                                        color: "white",
                                    }
                                    : {}
                            }
                            onClick={() => {
                                setInView(val);
                                if(isMobile) setTab('overview');
                            }}
                            ref={val._id === inView?._id ? inViewRef : null}
                        >
                            <input
                                type="checkbox"
                                checked={val.actions.select}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeSelect(val._id);
                                }}
                            />
                            <div>
                                <p>
                                    {val?.firstName || val?.lastName
                                        ? [val?.firstName, val?.lastName].join(" ")
                                        : "--"}
                                </p>
                                <span>{val?.personalEmail}</span>
                            </div>
                        </div>
                    );
                })}
            </div>

          
            <div className={`${styles.secondary_detail_view} ${isMobile && tab === 'list' ? styles.hidden : ''}`}>
                <div>
                    <div className={styles.sdv_head}>
                        <p style={{ textTransform: "capitalize" }}>
                            {inView?.firstName ||
                                inView?.middleName ||
                                inView?.lastName
                                ? [
                                    inView?.firstName?.trim() || "",
                                    inView?.middleName?.trim() || "",
                                    inView?.lastName?.trim() || "",
                                ]?.join(" ")
                                : "Undefined"}
                        </p>
                        <div className={styles.sdv_buttons}>
                            <CustomButton
                                text="Edit"
                                variant='outlined'
                                icon="edit"
                                onClick={()=>navigate("/contacts/update/" + inView?._id)}
                            />
                            <CustomButton
                                text='Delete'
                                variant='outlined_del'
                                icon='delete'
                                onClick={()=>{
                                    setDeleteById(inView?._id)
                                    setModal(true)
                                }}
                            />
                            <CloseIcon
                                className={styles.sdv_close_btn}
                                onClick={() => {
                                    setSecondaryView(false);
                                    setInView({});
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.tabs_section}>
                        <div
                            className={
                                tab === "overview" ? styles.tab_selected : ""
                            }
                            onClick={() => setTab("overview")}
                        >
                            Overview
                        </div>
                        <div
                            className={tab === "remarks" ? styles.tab_selected : ""}
                            onClick={() => setTab("remarks")}
                        >
                            Remarks
                        </div>
                        <div
                            className={tab === "history" ? styles.tab_selected : ""}
                            onClick={() => setTab("history")}
                        >
                            Logs
                        </div>
                    </div>
                </div>
                {tab === "overview" && (
                    <div className={styles.tab_content_container}>
                        <div className={styles.secondary_content}>
                            <div>
                                <span>First Name</span>
                                <p>{inView?.firstName || "--"}</p>
                            </div>

                            <div>
                                <span>Middle Name</span>
                                <p>{inView?.middleName || "--"}</p>
                            </div>
                            <div>
                                <span>Last Name</span>
                                <p>{inView?.lastName || "--"}</p>
                            </div>
                            <div>
                                <span>Company Name</span>
                                <p>{inView?.companyName || "--"}</p>
                            </div>
                            <div>
                                <span>Company Website</span>
                                <p>{inView?.companyWebsite || "--"}</p>
                            </div>
                            <div>
                                <span>Company HQ</span>
                                <p>{inView?.companyHQ || "--"}</p>
                            </div>
                            <div>
                                <span>Mobile Number</span>
                                <p>{inView?.mobileNumber}</p>
                            </div>
                            <div>
                                <span>Alt. Mobile Number</span>
                                <p>{inView?.altMobileNumber || "--"}</p>
                            </div>
                            <div>
                                <span>Email</span>
                                <p>{inView?.personalEmail || "--"}</p>
                            </div>
                            <div>
                                <span>Cooperate Email</span>
                                <p>{inView?.cooperateEmail || "--"}</p>
                            </div>
                            <div>
                                <span>Domain</span>
                                <p>{inView?.domain || "--"}</p>
                            </div>
                            <div>
                                <span>Address</span>
                                <p>{inView?.address || "--"}</p>
                            </div>
                            <div>
                                <span>City</span>
                                <p>{inView?.city || "--"}</p>
                            </div>
                            <div>
                                <span>State</span>
                                <p>{inView?.state || "--"}</p>
                            </div>
                            <div>
                                <span>Country</span>
                                <p>{inView?.country || "--"}</p>
                            </div>
                            <div>
                                <span>Pincode</span>
                                <p>{inView?.pincode || "--"}</p>
                            </div>
                        </div>

                        <div className={styles.secondary_content_2}>
                            {inView?.typeName && (
                                <div>
                                    <span>Type Name</span>
                                    <p>{inView?.typeName || "--"}</p>
                                </div>
                            )}
                            {inView?.date && (
                                <div>
                                    <span>Date</span>
                                    <p>{inView?.date.split("T")[0] || "--"}</p>
                                </div>
                            )}
                            {inView?.time && (
                                <div>
                                    <span>Time</span>
                                    <p>{inView?.time || "--"}</p>
                                </div>
                            )}
                            {inView?.reason1 && (
                                <div>
                                    <span>Reason 1</span>
                                    <p>{inView?.reason1 || "--"}</p>
                                </div>
                            )}
                            {inView?.reason2 && (
                                <div>
                                    <span>Reason 2</span>
                                    <p>{inView?.reason2 || "--"}</p>
                                </div>
                            )}
                            {inView?.message && (
                                <div>
                                    <span>Message</span>
                                    <p>{inView?.message || "--"}</p>
                                </div>
                            )}
                            {inView?.terms && (
                                <div>
                                    <span>Terms</span>
                                    <p>{inView?.terms || "--"}</p>
                                </div>
                            )}
                        </div>
                        <div className={styles.secondary_content}>
                            <div
                                style={{
                                    gridColumn: "1/4",
                                    fontSize: ".9rem",
                                }}
                            >
                                <h4 style={{ fontWeight: 500 }}>Campaign Data</h4>
                            </div>
                            {inView?.campaign?.id && (
                                <div>
                                    <span>Id</span>
                                    <p>{inView?.campaign?.id || "--"}</p>
                                </div>
                            )}
                            {inView?.campaign?.source && (
                                <div>
                                    <span>Source</span>
                                    <p>{inView?.campaign?.source || "--"}</p>
                                </div>
                            )}
                            {inView?.campaign?.medium && (
                                <div>
                                    <span>Medium</span>
                                    <p>{inView?.campaign?.medium || "--"}</p>
                                </div>
                            )}
                            {inView?.campaign?.campaign && (
                                <div>
                                    <span>Campaign</span>
                                    <p>{inView?.campaign?.campaign || "--"}</p>
                                </div>
                            )}
                            {inView?.campaign?.name && (
                                <div>
                                    <span>Name</span>
                                    <p>{inView?.campaign?.name || "--"}</p>
                                </div>
                            )}
                            {inView?.campaign?.term && (
                                <div>
                                    <span>Term</span>
                                    <p>{inView?.campaign?.term || "--"}</p>
                                </div>
                            )}
                            {inView?.campaign?.referrer && (
                                <div>
                                    <span>Referrer</span>
                                    <p>{inView?.campaign?.referrer || "--"}</p>
                                </div>
                            )}
                            {inView?.campaign?.content && (
                                <div>
                                    <span>Content</span>
                                    <p>{inView?.campaign?.content || "--"}</p>
                                </div>
                            )}
                        </div>
                        <div className={styles.secondary_content}>
                            <div
                                style={{
                                    gridColumn: "1/4",
                                    fontSize: ".9rem",
                                }}
                            >
                                <h4 style={{ fontWeight: 500 }}>
                                    About User's Device
                                </h4>
                            </div>
                            <div>
                                <span>User's Ip Address</span>
                                <p>{inView?.ipAddress || "--"}</p>
                            </div>
                            <div>
                                <span>User Agent</span>
                                <p>{inView?.userDeviceDetails?.userAgent || "--"}</p>
                            </div>
                            <div>
                                <span>Browser Name</span>
                                <p>
                                    {inView?.userDeviceDetails?.browserName || "--"}
                                </p>
                            </div>
                            <div>
                                <span>Browser Version</span>
                                <p>
                                    {inView?.userDeviceDetails?.browserVersion || "--"}
                                </p>
                            </div>
                            <div>
                                <span>Platform</span>
                                <p>{inView?.userDeviceDetails?.platform || "--"}</p>
                            </div>
                            <div>
                                <span>Language</span>
                                <p>{inView?.userDeviceDetails?.language || "--"}</p>
                            </div>
                            <div>
                                <span>Screen Width</span>
                                <p>
                                    {inView?.userDeviceDetails?.screenWidth || "--"}
                                </p>
                            </div>
                            <div>
                                <span>Screen Height</span>
                                <p>
                                    {inView?.userDeviceDetails?.screenHeight || "--"}
                                </p>
                            </div>
                            <div>
                                <span>Pixel Ratio</span>
                                <p>{inView?.userDeviceDetails?.pixelRatio || "--"}</p>
                            </div>
                        </div>
                    </div>
                )}
                {tab === "remarks" && (
                    <div className={styles.remarks}>
                        <div className={styles.remark_input}>
                            <label>Add Remark</label>
                            <textarea
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                            />
                            <div className={styles.remark_btn}>
                                <button onClick={handleAddRemark}>Add</button>
                            </div>
                        </div>
                        {inView?.remarks?.length !== 0 && (
                            <div className={styles.old_remarks}>
                                <h3>Old Remarks</h3>
                                <div className={styles.old_remarks_container}>
                                    {inView?.remarks
                                        ?.filter((v: any) => v)
                                        ?.map((r: any, index: any) => {
                                            return (
                                                <div
                                                    className={styles.old_remarks_sub_container}
                                                    key={index}
                                                >
                                                    <div
                                                        className={styles.old_remarks_dot}
                                                    ></div>
                                                    <div className={styles.old_remarks_heading}>
                                                        <p>
                                                            {`${r.date
                                                                .split("T")[0]
                                                                .split("-")
                                                                .reverse()
                                                                .join("/")} ${r.date.split("T")[1].split(".")[0]
                                                                } by ${r.by}`}
                                                        </p>
                                                        <p>{r.content}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {tab === "history" && (
                    <div className={styles.history}>
                        <div className={styles.history_container}>
                            {logs
                                ?.filter((l: any) => {
                                    let from_temp = l?.from || [];
                                    let to_temp = l?.to || [];

                                    for (let i = 0; i < from_temp.length; i++) {
                                        const single_from = from_temp[i];
                                        if (single_from._id === inView._id) {
                                            return true;
                                        }
                                    }

                                    for (let i = 0; i < to_temp.length; i++) {
                                        const single_to = to_temp[i];
                                        if (single_to._id === inView._id) {
                                            return true;
                                        }
                                    }

                                    return false;
                                })
                                ?.map((log: any, index: any) => {
                                    let from = log?.from?.filter(
                                        (v: any) => v?._id === inView._id
                                    );
                                    let to = log?.to?.filter(
                                        (v: any) => v?._id === inView._id
                                    );

                                    let Type = "";

                                    let changed: any = [];

                                    if (from.length === to.length) {
                                        // something is updated
                                        const single_from = from[0];
                                        const single_to = to[0];

                                        const changedFields = getChangedFields(
                                            single_from,
                                            single_to
                                        );

                                        if (
                                            changedFields.length === 1 &&
                                            changedFields[0].field === "remarks"
                                        ) {
                                            Type = "Added Remark";
                                        } else {
                                            Type = "Updated";
                                            changed = changedFields.filter(
                                                (v) =>
                                                    v.field !== "remarks" &&
                                                    v.field !== "createdAt" &&
                                                    v.field !== "modifiedAt"
                                            );
                                        }
                                    } else {
                                        // Newly added
                                        Type =
                                            log?.type === "createdByAdmin"
                                                ? "Created By Admin"
                                                : "Submitted By User";
                                        const single_to = to[0];
                                    }

                                    return (
                                        <div
                                            className={styles.history_sub_container}
                                            key={index}
                                        >
                                            <div className={styles.history_dot}></div>
                                            <div className={styles.history_heading}>
                                                <h4>{Type}</h4>
                                                <p className={styles.history_time}>
                                                    {`${log?.date
                                                        ?.split("T")[0]
                                                        .split("-")
                                                        .reverse()
                                                        .join("/")} ${log?.date?.split("T")[1]?.split(".")[0]
                                                        } by ${log?.by}`}
                                                </p>
                                                {Type === "Added Remark" ? (
                                                    <div>{to[0]?.remarks[0]?.content}</div>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.history_table_container
                                                            }
                                                        >
                                                            <table className={styles.responsive_table}>
                                                                <thead>
                                                                    {Type === "Created By Admin" ||
                                                                        Type === "Submitted By Admin" ? (
                                                                        <tr>
                                                                            <th
                                                                                style={{ textAlign: "left" }}
                                                                            >
                                                                                Fields
                                                                            </th>
                                                                            <th
                                                                                style={{ textAlign: "left" }}
                                                                            >
                                                                                Values
                                                                            </th>
                                                                        </tr>
                                                                    ) : (
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>From</th>
                                                                            <th>To</th>
                                                                        </tr>
                                                                    )}
                                                                </thead>
                                                                {contacts.length === 0 ||
                                                                    !contacts ? (
                                                                    <tr>
                                                                        <td
                                                                            colSpan={9}
                                                                            className="h-[400px] xl:h-[70vh] 2xl:h-[80vh] align-middle"
                                                                        >
                                                                            <div className="flex items-center justify-center h-full">
                                                                                <p className="text-gray-500 text-lg font-medium">
                                                                                    No data available
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tbody>
                                                                        {Type === "Created By Admin" ||
                                                                            Type === "Submitted By Admin" ? (
                                                                            <>
                                                                                {Object.keys(to[0])
                                                                                    ?.filter((v) => {
                                                                                        return (
                                                                                            v !== "identifier" &&
                                                                                            v !==
                                                                                            "mobileCountryCode" &&
                                                                                            v !==
                                                                                            "altMobileCountryCode" &&
                                                                                            v !== "_id" &&
                                                                                            v !== "__v" &&
                                                                                            v !== "id" &&
                                                                                            v !== "createdAt" &&
                                                                                            v !== "modifiedAt" &&
                                                                                            v !== "campaign"
                                                                                        );
                                                                                    })
                                                                                    ?.map((v: any, i: any) => {
                                                                                        const obj = to[0];

                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                >
                                                                                                    {contact_fields[v]}
                                                                                                </td>
                                                                                                <td
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                >
                                                                                                    {v === "remarks"
                                                                                                        ? obj?.remarks[0]
                                                                                                            ?.content ||
                                                                                                        "--"
                                                                                                        : obj[v] || "--"}
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {from.map(
                                                                                    (fromObj: any, i: any) => {
                                                                                        const toObj = to[i];
                                                                                        const changedFields =
                                                                                            getChangedFields(
                                                                                                fromObj,
                                                                                                toObj
                                                                                            );

                                                                                        return changed.map(
                                                                                            (
                                                                                                field: any,
                                                                                                j: any
                                                                                            ) => {
                                                                                                if (
                                                                                                    typeof field.fromValue ===
                                                                                                    "object"
                                                                                                ) {
                                                                                                    let u_keys =
                                                                                                        Array.from(
                                                                                                            new Set([
                                                                                                                ...Object.keys(
                                                                                                                    field?.fromValue ||
                                                                                                                    {}
                                                                                                                ),
                                                                                                                ...Object.keys(
                                                                                                                    field?.toValue ||
                                                                                                                    {}
                                                                                                                ),
                                                                                                            ])
                                                                                                        );

                                                                                                    u_keys =
                                                                                                        u_keys.filter(
                                                                                                            (v) =>
                                                                                                                field
                                                                                                                    .fromValue[
                                                                                                                v
                                                                                                                ] !==
                                                                                                                field.toValue[
                                                                                                                v
                                                                                                                ]
                                                                                                        );

                                                                                                    return (
                                                                                                        <>
                                                                                                            {u_keys.map(
                                                                                                                (
                                                                                                                    k: any,
                                                                                                                    i: any
                                                                                                                ) => {
                                                                                                                    return (
                                                                                                                        <tr
                                                                                                                            key={`${j}-${i}`}
                                                                                                                        >
                                                                                                                            <td className={styles.responsive_cell} data-label="Field">
                                                                                                                                {field.field +
                                                                                                                                    " " +
                                                                                                                                    k}
                                                                                                                            </td>
                                                                                                                            <td className={styles.responsive_cell} data-label="From">
                                                                                                                                {field
                                                                                                                                    .fromValue[
                                                                                                                                    k
                                                                                                                                ] ||
                                                                                                                                    "-"}
                                                                                                                            </td>
                                                                                                                            <td className={styles.responsive_cell} data-label="To">
                                                                                                                                {field
                                                                                                                                    .toValue[
                                                                                                                                    k
                                                                                                                                ] ||
                                                                                                                                    "--"}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </>
                                                                                                    );
                                                                                                }
                                                                                                return (
                                                                                                    <tr key={j}>
                                                                                                        <td className={styles.responsive_cell} data-label="Field">
                                                                                                            {
                                                                                                                contact_fields[
                                                                                                                field.field
                                                                                                                ]
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className={styles.responsive_cell} data-label="From">
                                                                                                            {
                                                                                                                field.fromValue
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className={styles.responsive_cell} data-label="To">
                                                                                                            {field.toValue}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SecondaryView;