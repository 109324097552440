import { forwardRef, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import InputField from "../InputField";
import toast from "react-hot-toast";
import axios from "axios";
import ToggleSwitch from "../ToggleSwitch";
import Modal from "../Modal";
import { useAdminContext } from "../../Store/adminContext";

const IntegrationModel = ({
  open,
  setOpen,
  app,
  setApp,
  getAllIntegrations,
  setReady,
}: any) => {
  const { getAdminData }: any = useAdminContext();

  const [loading, setLoading] = useState(false);
  const [delloading, setDelLoading] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [obj, setObj]: any = useState({
    key1: "",
    key2: "",
    integrationName: "",
  });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    if (open && app) {
      setAnimate(true);
      if (app?.fetch) {
        setFetch(true);
        getIntegration();
      }
      setObj((prev: any) => ({
        ...prev,
        integrationName: app.integrationName,
        category: app?.category,
      }));
    } else {
      setAnimate(false);
    }
  }, [open, app]);

  const getIntegration = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/get-single-integration?name=${app.integrationName}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setObj({
          integrationName: res.data.data?.integrationName,
          key1: res.data.data?.key1,
          key2: res.data.data?.key2,
          access: res.data.data?.access,
        });
        setFetch(false);
      })
      .catch((err: any) => {
        toast.error("Something went wrong.");
        setFetch(false);
        handleClose();
      });
  };

  const validate = () => {
    for (let i = 0; i < app.fields.length; i++) {
      const field = app.fields[i];
      if (field.required) {
        if (!obj[field.name]) {
          toast.error(`${field.label} is required.`);
          return false;
        }
      }
    }

    return true;
  };

  const handleSave = () => {
    if (validate()) {
      setLoading(true);

      axios({
        url: `${process.env.REACT_APP_BASE_URL}admins/add-integration`,
        method: "post",
        data: obj,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res: any) => {
          toast.success("Integration Added Successfully.");
          setLoading(false);
          handleClose("get-integration");
          getAdminData();
        })
        .catch((err: any) => {
          toast.error("Something went wrong.");
          setLoading(false);
        });
    }
  };

  const handleUpdate = () => {
    if (validate()) {
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_BASE_URL}admins/update-integration?name=${app.integrationName}`,
        method: "patch",
        data: obj,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res: any) => {
          toast.success("Integration Updated Successfully.");
          setLoading(false);
          handleClose();
        })
        .catch((err: any) => {
          toast.error("Something went wrong");
          setLoading(false);
        });
    }
  };

  const handleClose = (v: any = ""): any => {
    if (!loading) {
      setAnimate(false);
      setTimeout(() => {
        setOpen(false);
        setObj({ key1: "", key2: "", integrationName: "" });
        setApp(null);
        if (v === "get-integration") {
          getAllIntegrations();
        }
      }, 500);
    }
  };

  const handleAction = () => {
    setModal(false);
    setDelLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/remove-integration?name=${app.integrationName}`,
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        toast.success("Deleted Successfully.");
        setDelLoading(false);
        handleClose("get-integration");
        getAdminData();
      })
      .catch((err: any) => {
        toast.error("Something went wrong.");
        setDelLoading(false);
      });
  };

  if (!open) return <></>;

  return (
    <>
      {delloading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
        }}
        onBtn2Click={handleAction}
      />
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: animate ? "rgba(0,0,0,.5)" : "rgba(0,0,0,0)",
          zIndex: 100,
          display: "flex",
          justifyContent: "flex-end",
          transition: "background 0.5s ease-in-out", // Animate background opacity
        }}
        onClick={handleClose}
      >
        <div
          style={{
            height: "100vh",
            width: "30vw",
            background: "white",
            transform: animate ? "translateX(0)" : "translateX(100%)", // Animate from right to left
            transition: "transform 0.5s ease-in-out", // Animate the sliding effect
            display: "flex",
            flexDirection: "column",
          }}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {/* Header */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              borderBottom: "1px solid lightgrey",
              padding: "15px",
            }}
          >
            <img src={app.logo} style={{ height: "25px", width: "25px" }} />
            <h3
              style={{
                fontWeight: 500,
                marginRight: "auto",
                fontSize: "1.3rem",
              }}
            >
              {app.title}
            </h3>
            {!fetch && app.fetch && (
              <ToggleSwitch
                value={obj.access}
                onClick={() => {
                  setObj((prev: any) => ({ ...prev, access: !obj.access }));
                }}
              />
            )}
            <CloseIcon
              style={{ height: "24px", width: "24px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>

          {/* Body */}
          {fetch ? (
            <div
              style={{
                flex: 1,
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span className="loader"></span>
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {app.fields.map((field: any, index: any) => {
                if (field.type === "select") {
                  return (
                    <InputField
                      type={field.type}
                      placeholder={field.placeholder}
                      key={index}
                      lable={field.label}
                      isLabel={true}
                      options={field.options}
                      accessText={field.accessText}
                      accessValue={field.accessValue}
                      value={obj[field.name]}
                      onChange={(e: any) => {
                        setObj((prev: any) => ({
                          ...prev,
                          [field.name]: e.target.value,
                        }));
                      }}
                    />
                  );
                }
                return (
                  <InputField
                    type={field.type}
                    placeholder={field.placeholder}
                    key={index}
                    lable={field.label}
                    isLabel={true}
                    value={obj[field.name]}
                    onChange={(e: any) => {
                      setObj((prev: any) => ({
                        ...prev,
                        [field.name]: e.target.value,
                      }));
                    }}
                  />
                );
              })}
              {app.fetch && (
                <button
                  style={{
                    marginTop: "auto",
                    width: "fit-content",
                    background: "none",
                    border: "none",
                    outline: "none",
                    color: "red",
                    textDecoration: "underline",
                    marginInline: "auto",
                    opacity: ".7",
                    cursor: "pointer",
                  }}
                  onClick={() => setModal(true)}
                >
                  Remove
                </button>
              )}
            </div>
          )}

          {/* Footer */}
          <div
            style={{
              padding: "15px",
              borderTop: "1px solid lightgrey",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "15px",
            }}
          >
            <button
              style={{
                border: "none",
                padding: "10px",
                borderRadius: "5px",
                outline: "none",
                cursor: "pointer",
                height: "40px",
              }}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              style={{
                border: "none",
                padding: "10px",
                borderRadius: "5px",
                outline: "none",
                background: "var(--brand-color)",
                color: "white",
                cursor: "pointer",
                height: "40px",
              }}
              onClick={app.fetch ? handleUpdate : handleSave}
            >
              {loading ? (
                <span className="btn_loader_white"></span>
              ) : app.fetch ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationModel;
