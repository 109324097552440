export function numberToWords(number: any): string {
  number = number.toFixed(2);
  // Convert to string and handle different formats
  let numStr = number.toString().trim();
  if (isNaN(parseFloat(numStr))) return "Invalid number";

  // Process decimal part if exists
  const parts = numStr.split(".");
  const integerPart = parts[0];
  const decimalPart = parts.length > 1 ? parts[1] : "";

  // Arrays for translation
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  // Handle zero case
  if (integerPart === "0" && decimalPart === "") return "Zero only";

  // Convert a number less than 1000 to words
  function convertLessThanOneThousand(num: number): string {
    if (num === 0) return "";

    if (num < 20) {
      return ones[num] + " ";
    }

    const ten = Math.floor(num / 10) % 10;
    const unit = num % 10;

    if (num < 100) {
      return tens[ten] + (unit !== 0 ? " " + ones[unit] : "") + " ";
    }

    const hundred = Math.floor(num / 100);
    const remainder = num % 100;

    return (
      ones[hundred] +
      " Hundred" +
      (remainder !== 0 ? " and " + convertLessThanOneThousand(remainder) : " ")
    );
  }

  let result = "";
  const intValue = parseInt(integerPart, 10);

  // Handle crores (10 million)
  const crore = Math.floor(intValue / 10000000);
  if (crore > 0) {
    result += convertLessThanOneThousand(crore) + "Crore ";
  }

  // Handle lakhs (100 thousand)
  const lakh = Math.floor((intValue % 10000000) / 100000);
  if (lakh > 0) {
    result += convertLessThanOneThousand(lakh) + "Lakh ";
  }

  // Handle thousands
  const thousand = Math.floor((intValue % 100000) / 1000);
  if (thousand > 0) {
    result += convertLessThanOneThousand(thousand) + "Thousand ";
  }

  // Handle remaining hundreds
  const remaining = intValue % 1000;
  if (remaining > 0) {
    result += convertLessThanOneThousand(remaining);
  } else if (intValue === 0) {
    result += "Zero ";
  }

  // Handle decimal part
  if (decimalPart !== "") {
    result = result.trim();
    result += " point ";

    // Read each digit individually
    for (let i = 0; i < decimalPart.length; i++) {
      const digit = parseInt(decimalPart[i], 10);
      if (digit === 0) {
        result += "zero ";
      } else {
        result += ones[digit] + " ";
      }
    }
  }

  return (result.trim() || "Zero") + " only";
}
