import { useEffect, useState } from "react";
import styles from "./plan.module.css";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faStar,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../../Store/adminContext";
import CustomButton from "../../Components/CustomButton";

const PlanPage = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const { admin }: any = useAdminContext();

  useEffect(() => {
    getPlan();
  }, []);

  const getPlan = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}plan`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setPlans(res.data.plans.sort((a, b) => a.amount - b.amount));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead title="Select Plan" />
        <ContentBody>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "24px",
              maxWidth: "1200px",
              margin: "0 auto",
              padding: "10px 16px",
            }}
          >
            {plans.map((plan, index) => {
              return (
                <PlanCard
                  razorpayId={plan?.razorpayPlanId}
                  key={plan?._id}
                  PlanId={plan?._id}
                  title={plan?.name}
                  price={plan.amount}
                  period="/month"
                  gst={plan?.subDescription}
                  popular={plan?.isPopular}
                  features={plan?.keyPoints || []}
                  isHovered={hoveredCard === index}
                  onHover={() => setHoveredCard(index)}
                  onLeave={() => setHoveredCard(null)}
                  setLoading={setLoading}
                  showAllFeatures={showAllFeatures}
                  country={admin?.address?.country}
                />
              );
            })}
          </div>
          <div className={styles.seeMoreContainer}>
            <button
              className={styles.seeMoreButton}
              onClick={() => setShowAllFeatures(!showAllFeatures)}
            >
              {showAllFeatures ? (
                <>
                  See Less <FontAwesomeIcon icon={faChevronUp} />
                </>
              ) : (
                <>
                  See More <FontAwesomeIcon icon={faChevronDown} />
                </>
              )}
            </button>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const PlanCard = ({
  razorpayId,
  PlanId,
  title,
  price,
  period,
  features,
  popular,
  gst,
  isHovered,
  onHover,
  onLeave,
  setLoading,
  showAllFeatures,
  country,
}) => {
  const { getAdminData, admin }: any = useAdminContext();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const displayedFeatures = showAllFeatures ? features : features.slice(0, 4);
  const disabled = country !== "India" && title !== "Starter";

  const handlePlanSelection = () => {
    setOpen(false);
    setLoading(true);

    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/create-recurring-payment?id=${admin._id}&planId=${PlanId}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.message === "This is Free Plan.") {
          axios({
            url: `${process.env.REACT_APP_BASE_URL}admins/updateSelf`,
            method: "patch",
            data: {
              identifier: localStorage.getItem("identifier"),
              data: {
                selectedPlan: PlanId,
              },
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
            },
          })
            .then(() => {
              getAdminData();
              setLoading(false);
              toast.success("Plan Selected Successfully.");
              navigate("/");
            })
            .catch(() => {
              getAdminData();
              setLoading(false);
              toast.error("Error updating plan, please try again.");
            });
        } else {
          // Tell user to pay
          const temp = {
            key: "rzp_live_9yludyM00Qt3OA", // Get this from Razorpay dashboard
            // key: "rzp_test_dBxPiyugXuU1aY",
            subscription_id: res.data.subscriptionId, // Subscription ID from backend
            order_id: res.data.orderId,
            name: "ShopIQ",
            description: "Monthly Subscription",
            callback_url: `https://grow.shopiq.app/payment-status?order-id=${res.data.orderId}&subId=${res.data.subscriptionId}`,
            method: {
              netbanking: true,
              card: true,
              wallet: true,
              upi: true,
            },
            theme: {
              color: "#0E81F0",
            },
            modal: {
              ondismiss: function () {
                setLoading(false);
                toast.error("Payment cancelled. You can try again anytime.");
              },
            },
            handler: (response: any) => {
              // Update admin's subscription status after successful payment
              const subscriptionStatusUpdate = {
                data: new Date(),
                type: title,
                orderId: res.data.orderId,
                subscriptionId: res.data.subscriptionId,
                isPaid: true,
              };

              axios({
                url: `${process.env.REACT_APP_BASE_URL}admins/updateSelf`,
                method: "patch",
                data: {
                  identifier: localStorage.getItem("identifier"),
                  data: {
                    selectedPlan: PlanId,
                    subscriptionStatus: [
                      ...(admin.subscriptionStatus || []),
                      subscriptionStatusUpdate,
                    ],
                  },
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "token-BO-ADMIN"
                    )}`,
                  },
                },
              })
                .then(() => {
                  getAdminData();
                  setLoading(false);
                  toast.success(
                    "Payment successful! Your subscription is now active."
                  );
                  navigate("/");
                })
                .catch((err) => {
                  getAdminData();
                  console.error("Failed to update subscription status:", err);
                  toast.error(
                    "Payment completed, but we couldn't update your subscription status. Please contact support."
                  );
                  setLoading(false);
                });
            },
          };

          // @ts-ignore
          const razorpay = new window.Razorpay(temp);
          razorpay.open();
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      {open && (
        <div className={styles.confirmationModelStyle}>
          <div className={styles.confirmationCardStyle}>
            <div className={styles.confirmationCardHeadStyle}>
              <p className="text-heading">Confirm Your Plan Selection</p>
              <FontAwesomeIcon icon={faClose} onClick={() => setOpen(false)} />
            </div>
            <div className={styles.confirmationCardBodyStyle}>
              <p className="text-primary">
                You have selected the <span>{title} plan</span> at{" "}
                <span>{price} per month</span>. You can change your plan anytime
                from Profile Settings.
              </p>
            </div>
            <div className={styles.confirmationCardFooterStyle}>
              <CustomButton
                text="Cancel"
                variant="light_grey"
                onClick={() => setOpen(false)}
              />
              <CustomButton
                text="Proceed"
                variant="filled"
                onClick={handlePlanSelection}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className={`${styles.cardStyle} ${isHovered ? styles.hover : ""} ${
          country === "India" && popular ? styles.popular : ""
        } ${disabled && "opacity-50"}`}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
      >
        {popular && country === "India" && (
          <div className={styles.popularBadgeStyle}>
            <FontAwesomeIcon icon={faStar} />
            Popular Choice
          </div>
        )}

        <h3
          className={`${styles.titleStyle} ${
            country === "India" && popular && styles.popular
          }`}
        >
          {title}
        </h3>

        <div className={styles.priceContainerStyle}>
          <span className={styles.priceStyle}>
            {country !== "India" ? "$" : "₹"}
            {price
              ? country !== "India"
                ? (price / 87).toFixed(0)
                : price
              : 0}
          </span>
          <span className={styles.periodStyle}>{period}</span>
          {gst && <span className={styles.gstStyle}>{gst}</span>}
        </div>

        <div>
          <p style={{ fontWeight: 500, marginBottom: "16px" }}>Features</p>
          <ul className={styles.featureListStyle}>
            {displayedFeatures.map((feature, index) => (
              <li key={index} className={styles.featureItemStyle}>
                <FontAwesomeIcon
                  icon={faCheck}
                  color={
                    (popular && country === "India") || isHovered
                      ? "#2563eb"
                      : "#9ca3af"
                  }
                />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>

        {disabled ? (
          <button className={`${styles.buttonStyle}`}>Coming Soon</button>
        ) : (
          <button
            className={`${styles.buttonStyle} ${
              ((popular && country === "India") || isHovered) && styles.popular
            }`}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        )}
      </div>
    </>
  );
};

export default PlanPage;
