import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faEllipsisVertical,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FaArrowRight } from "react-icons/fa";
import { ReactComponent as TrashIcon } from "../../Assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../Assets/newIcons/edit.svg";

type ButtonSize = "sm" | "md" | "lg";

type ButtonVariant =
  | "filled"
  | "outlined"
  | "outlined_del"
  | "outlined_success"
  | "light_grey"
  | "filled_red"
  | "filled_green"
  | "filled_blue"
  | "rounded_grey"
  | "transparent"
  | "link"
  | "none";

interface DropdownItem {
  label: string;
  value: string;
  icon?: IconDefinition;
  onClick?: (e: any) => void;
}

interface ButtonProps {
  text?: string;
  parentClass?: string;
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: any | "plus" | "arrow-right" | "ellipsis";
  iconPosition?: "left" | "right";
  fullWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  // New dropdown props
  withDropdown?: boolean;
  dropdownItems?: DropdownItem[] | DropdownItem;
  dropdownPosition?: "bottom-left" | "bottom-right";
  isSplitButton?: boolean;
  dropdownArrow?: boolean;
}

const CustomButton: React.FC<ButtonProps> = ({
  text,
  parentClass = "",
  loading = false,
  variant = "filled",
  size = "md",
  icon,
  iconPosition = "left",
  fullWidth = false,
  className = "",
  style,
  name,
  onClick,
  disabled = false,
  withDropdown = false,
  dropdownItems,
  dropdownPosition = "bottom-right",
  isSplitButton = false,
  dropdownArrow,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getIconComponent = () => {
    if (!icon) return null;

    if (icon === "plus") return <FontAwesomeIcon icon={faPlus} />;
    if (icon === "arrow-right") return <FaArrowRight size={16} />;
    if (icon === "ellipsis")
      return <FontAwesomeIcon icon={faEllipsisVertical} />;
    if(icon==='delete') return <TrashIcon />
    if(icon=== 'edit') return <EditIcon/>
    return <FontAwesomeIcon icon={icon} />;
  };

  const getSizeClasses = () => {
    switch (size) {
      case "sm":
        return "h-8 text-primary px-2";
      case "lg":
        return "h-12 text-base px-4";
      default:
        return "h-9 text-base px-3";
    }
  };

  const getVariantClasses = () => {
    switch (variant) {
      case "filled":
        return "bg-[var(--brand-color)] text-white";
      case "outlined":
        return "border-2 border-[var(--brand-color)] text-[var(--brand-color)] bg-transparent";
        case "outlined_del":
          return "border-2 border-red-600 text-red-500 bg-transparent";        
      case "outlined_success":
        return "border-2 border-green-500 text-green-500 bg-transparent";
      case "light_grey":
        return "bg-gray-100 text-gray-700";
      case "filled_red":
        return "bg-red-500 text-white";
      case "filled_green":
        return "bg-green-500 text-white";
      case "filled_blue":
        return "bg-[var(--blue-light)] text-[var(--brand-color)]";
      case "rounded_grey":
        return "bg-gray-200 text-gray-700 rounded-full";
      case "link":
        return "flex flex-row bg-transparent items-center text-[#397ee6]";
      case "transparent":
      case "none":
        return "bg-transparent";
      default:
        return "bg-[var(--brand-color)] text-white";
    }
  };

  const renderDropdownContent = () => {
    console.log('dropdown opened:', dropdownItems)
    if (!dropdownItems) return null;

    const items = Array.isArray(dropdownItems)
      ? dropdownItems
      : [dropdownItems];

    return (
      <div
        className={`
          fixed  mt-1 py-1 bg-white rounded-md shadow-lg
          border border-gray-200 min-w-[200px]
          ${dropdownPosition === "bottom-right" ? "right-0" : "right-0"}
        `}
        style={{zIndex:99999999}}
        onClick={(e) => e.stopPropagation()}
      >
        {items.map((item, index) => (
          <button
            key={index}
            className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2 z-100"
            name={item.value}
            onClick={(e: any) => {
              e.stopPropagation()
              console.log('button clicked')
              item.onClick?.(e);

              setIsDropdownOpen(false);
            }}

          >
            {item.icon && <FontAwesomeIcon icon={item.icon} />}
            {item.label}
          </button>
        ))}
      </div>
    );
  };

  if (isSplitButton) {
    return (
      <div className="relative inline-flex">
        <button
          name={name}
          className={`
            inline-flex items-center justify-center
            rounded-l-md
            transition-colors
            duration-200
            ${getSizeClasses()}
            ${getVariantClasses()}
            ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
          `}
          onClick={onClick}
          disabled={disabled}
        >
          {icon && iconPosition === "left" && (
            <span className="mr-2">{getIconComponent()}</span>
          )}
          {text}
          {icon && iconPosition === "right" && (
            <span className="ml-2">{getIconComponent()}</span>
          )}
        </button>
        <button
          className={`
            inline-flex items-center justify-center
            rounded-r-md border-l
            transition-colors
            duration-200
            ${getSizeClasses()}
            ${getVariantClasses()}
            ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
          `}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <FontAwesomeIcon icon={faChevronDown} className="w-4 h-4" />
        </button>
        {isDropdownOpen && renderDropdownContent()}
      </div>
    );
  }

  return (
    <div
      className={`relative ${fullWidth ? "w-full" : "w-fit"} ${parentClass}`}
    >
      <button
        name={name}
        className={`
          inline-flex gap-2 items-center justify-center
          rounded-md
          transition-colors
          duration-200
          ${getSizeClasses()}
          ${getVariantClasses()}
          ${fullWidth ? "w-full" : "w-fit"}
          ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
          ${className}
        `}
        style={style}
        onClick={
          withDropdown ? () => setIsDropdownOpen(!isDropdownOpen) : onClick
        }
        disabled={disabled}
      >
        {loading ? (
          <span
            className="btn_loader_white"
            style={{ height: "20px", width: "20px" }}
          ></span>
        ) : (
          <>
            {icon && iconPosition === "left" && (
              <span className="mr-1">{getIconComponent()}</span>
            )}
            {text}
            {icon && iconPosition === "right" && (
              <span className="ml-1">{getIconComponent()}</span>
            )}
            {withDropdown && !isSplitButton && dropdownArrow && (
              <FontAwesomeIcon icon={faChevronDown} className="ml-2 w-4 h-4" />
            )}
          </>
        )}
      </button>
      {isDropdownOpen && withDropdown && renderDropdownContent()}
      {isDropdownOpen && (
        <div
          className=""
          onClick={() => setIsDropdownOpen(false)}
        />
      )}
    </div>
  );
};

export default CustomButton;
