import React, { useEffect, useState } from "react";
import Modal from "../../Components/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { useAdminContext } from "../../Store/adminContext";
import { isUserAllowed } from "../../Utils/checkPermissions/checkForKycAndPlan";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import DynamicDataTable from "../../UI/DynamicTable";
import TableLoading from "../../Components/LoadingUi/TableLoading";

const base_url = process.env.REACT_APP_BASE_URL;
let inFocus = "";

const Stores = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin }: any = useAdminContext();
  const isAllowed = isUserAllowed(admin);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(15);

  useEffect(() => {
    getData();
  }, [location.search]);

  const getData = () => {
    let search = new URLSearchParams(location.search);
    const currentPage = Number(search.get("page")) || 1;
    const currentLimit = Number(search.get("limit")) || 15;

    axios({
      url: `${base_url}admins/getAllStores/?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${currentPage}&limit=${currentLimit}`,
      method: "GET",
    })
      .then((res: any) => {
        setLoading(false);
        setData(res.data?.data || []);
        setTotal(res.data?.results || 0);
      })
      .catch((err: any) => {
        setData([]);
        setTotal(0);
        setLoading(false);
      });
  };

  const handleAction = () => {
    setModal(false);

    const promise = new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_BASE_URL
          }admins/deleteStoreById/?identifier=${localStorage.getItem(
            "identifier"
          )}&storeId=${inFocus}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res) => {
          inFocus = "";
          resolve(true);
          getData();
        })
        .catch(() => {
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Deleting...",
        success: "Deleted Successfully.",
        error: "Error when deleting the entry.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  // Prepare columns for DynamicDataTable
  const columns = storeHead(admin).map(header => ({
    accessorKey: header.value === 's.no' ? '' : header.value,
    header: header.title,
    size: header.value === 's.no' ? 80 : undefined,
  }));

  // Filter out action column as we'll handle it differently
  const filteredColumns = columns.filter(col => col.accessorKey !== 'action');

  // Prepare action columns for the DynamicDataTable
  const actionsColumns = isUserAllowedToEdit(admin) && storeKeys(admin).includes('action:delete') ? [
    {
      name: "Delete",
      action: (id) => {
        if (isAllowed) {
          inFocus = id;
          setModal(true);
        } else {
          toast.error("Please Complete Setup Steps to access this route.");
        }
      },
    },
  ] : [];

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          inFocus = "";
          setModal(false);
        }}
        onBtn2Click={handleAction}
      />
      <Content>
        <ContentHead
          title={"Stores"}
          showBtn1={isUserAllowedToEdit(admin)}
          btn1Name="btn1"
          iconType1={"plus"}
          btn1Text={"New"}
          btn1type={"filled"}
          onClickHandler={() => {
            if (isAllowed) {
              navigate("/stores/add");
            } else {
              toast.error("Please Complete Setup Steps to access this route.");
            }
          }}
        />
        <ContentBody style={{ padding: 0, position: "relative" }}>
          {loading ? (
            <TableLoading head={storeHead(admin)} />
          ) : (
            <DynamicDataTable
              columns={filteredColumns}
              data={data}
              actionsColumns={actionsColumns}
              title="Stores"
              onRowClick={(rowData) => {
                if (isAllowed) {
                  navigate("/stores/update/" + rowData._id);
                } else {
                  toast.error("Please Complete Setup Steps to access this route.");
                }
              }}
              onAdd={isUserAllowedToEdit(admin) ? () => {
                if (isAllowed) {
                  navigate("/stores/add");
                } else {
                  toast.error("Please Complete Setup Steps to access this route.");
                }
              } : undefined}
              onPaginationChange={(page, limit) => {
                setPageNo(page);
                setPerPage(limit);
                // Update URL with new page number and limit
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('page', page.toString());
                searchParams.set('limit', limit.toString());
                navigate(`/stores?${searchParams.toString()}`);
              }}
              totalRows={total}
              initialPageSize={perPage}
              initialPageIndex={pageNo}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.store_access.write) return true;

  return false;
};

const storeKeys = (admin: any) => {
  const base = ["title"];

  if (admin.role !== "subadmin") return [...base, "action:delete"];

  if (admin.role === "subadmin") {
    if (admin.store_access.delete) {
      return [...base, "action:delete"];
    }
    if (admin.store_access.write) {
      return [...base];
    }
  }
  return base;
};

const storeHead = (admin: any) => {
  const base = [
    { title: "Title", value: "title" },
  ];

  if (admin.role !== "subadmin")
    return [...base, { title: "Action", value: "action" }];

  if (admin.role === "subadmin") {
    if (admin.store_access.delete) {
      return [...base, { title: "Action", value: "action" }];
    }
    if (admin.store_access.write) {
      return [...base];
    }
  }
  return base;
};

export default Stores;